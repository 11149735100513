import React, { useState } from "react";
import '../../../../css/ContactCity.scss'
import { useSelector } from "react-redux";


const Jurisdiction = () => {

    const { dynoJurisResponse } = useSelector((state) => state.DynoInfo);
    console.log("jojo2",dynoJurisResponse)
    return (
        <>
            <div className="help-jury">
                <h1 >Jurisdiction Info</h1>
                <div className="jury-card">
                    <div className="jury-header">
                        <h3>Jurisdictions</h3>
                    </div>
                    <div className="jury-body">

                        <p className="jury-info">UrbanForm’s interpretation of the zoning regulations is meant
                            to serve as a starting point for an in-depth exploration of the
                            many factors necessary to fully understand building potential.
                            To gain a more in-depth and fuller understanding of the regulations
                            and building potential for any property,
                            please consult the appropriate qualified professional(s).</p>


                    </div>
                </div>
            </div>



            <div className="help-jury">
                <div className="jury-card">
                    <div className="jury-header">
                        <h3>{dynoJurisResponse.jurisdiction}</h3>
                    </div>
                    <div className="jury-body">
                        <p className="juris-contact" style={{textAlign:"justify"}}>Contact: <span> <p dangerouslySetInnerHTML={{__html:dynoJurisResponse.contact}}></p> </span></p>
                        <p className="juris-link">source: <a target="_blank" href={dynoJurisResponse.url_link}>{dynoJurisResponse.url_title}</a></p>
                    </div>
                </div>
            </div>





        </>

    );

}



export default Jurisdiction;
