import React from 'react';
import { Grid } from "semantic-ui-react";
import '../../../css/zone.scss';
import { useSelector } from "react-redux";
import { checkboxUpdate } from "../../../utils/Utils";
import { UpdateFilter } from "../../../actions/actions_filters";

const CheckBoxGrid = ({ dispatch, info, columns, selectedFilter, style }) => {
    const { show } = useSelector(state => state.dataWindow)
    const onChangeSelect = (e) => {
        let { name, checked } = e.target;
        let currentFilter = show.showData;
        let UpdatedFilter = {
            name: currentFilter,
            filterData: checkboxUpdate(currentFilter, selectedFilter, name, checked)
        }
        dispatch(UpdateFilter(UpdatedFilter));
    }
    return (
        <>
            <Grid columns={columns} style={style ?? {}}>
                <Grid.Row >
                    <Grid.Column style={{padding:"5px 0px"}}>
                        <div className="ui checkbox">
                            <input
                                type={info.type}
                                name={info.name}
                                onChange={onChangeSelect}
                                checked={info.action}
                            />
                            <label style={{ width: "155px" }}>{info.label}</label>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                {info?.checkbox && info.checkbox.map(field => {
                    return (
                        <Grid.Column style={{padding:"7px 0px"}}>
                            <div className="ui checkbox">
                                <input
                                    type={field.type}
                                    name={field.name}
                                    required={field.required}
                                    onChange={onChangeSelect}
                                    checked={field.action}
                                />
                                <label>{field.label}</label>
                            </div>
                        </Grid.Column>
                    )
                })}
            </Grid>
            {/* <hr className="horizontal-row" /> */}
        </>

    );
};

export default CheckBoxGrid;