import selectedEnv from "../Universal_CONFIG";

export const SET_FILTER_SEARCH = "SET_FILTER_SEARCH";
export const SET_FILTER_PRIME_ACCNUM = "SET_FILTER_PRIME_ACCNUM";
export const SET_MAP = "SET_MAP";
export const SET_HOME_GEOCODER = "SET_HOME_GEOCODER";

export function getFilterSearch(query) {
  return (dispatch) => {
    return fetch(selectedEnv.REACT_APP_API_URL + "/api/taxlotswzoning/search" + query)
      .then((resp) => resp.json())
      .then((resp) => dispatch(setFilterSearch(resp)));
  };
}
export function setMaap(map) {
  return {
    type: SET_MAP,
    payload: map
  } 
};
export function setHomeGeocoder(homeGeocoder) {
  return {
    type: SET_HOME_GEOCODER,
    payload: homeGeocoder
  } 
};

export function setFilterSearch(result) {
  return {
    type: SET_FILTER_SEARCH,
    payload: result,
  };
}

export function setFilterPrimeAccountNumbers(data) {
  return {
    type: SET_FILTER_PRIME_ACCNUM,
    payload: data,
  };
}