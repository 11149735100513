import React, { useState, useEffect } from "react";
import { positions, Provider } from "react-alert";
import { useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import Sidebar from "./Sidebar";
import Map from "./Map";
import { connect, useSelector } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "../css/Sidebar.scss";
import { useBeforeunload } from 'react-beforeunload';

import {
  activityLogger,
  getPlanPrice,
  getUpdatedAccessToken,
  handleUserLogin,
  setLoader,
  setUserLoginError,
  userNotAuth,
  verifyToken
} from "../actions/actions_user";
import DataWindow from './dataWindow/DataWindow';
import { Dimmer, Icon, Loader, Message, Image } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import "../css/App.scss";
import { getFiltersData, seRunFilter } from "../actions/actions_filters";
import { UF_TOKEN } from "../constants";
import { setDataWindowContent } from "../actions/actions_datawindow";
import { getReportCount } from "../actions/actions_info";
import { UnAuthorizedUserModal } from "./dataWindow/components/UnAuthorizedUserModal";
import jwtDecode from "jwt-decode";
import packageJson from "../../package.json";
import selectedEnv from "../Universal_CONFIG";
const App = ({ dispatch }) => {

  const [sidebarVisible, changeSidebarVisible] = useState(window.innerWidth >= 768 ? true : false);
  const [currentContent, setCurrentContent] = useState("Home");
  const [unAuthorizedModal, setUnAuthorizedModal] = useState(false)
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  const dataWindow = useSelector(state => state.dataWindow.dataWindow);
  const { loader, user } = useSelector(state => state.user);
  const [showPanel, setShowPanel] = useState(true);
  const { filterValues, runFilter } = useSelector(state => state.filters);
  let historyToken = window.localStorage.getItem('_uft');
  let alreadyLogin = window.localStorage.getItem('already');
  const navigate = useNavigate();
  const sessionToken = new URL(window.location).searchParams.get('t');
  const token = localStorage.getItem("_uft")
  const options = {
    timeout: 5000,
    position: positions.TOP_CENTER
  };
  const successAlert = {
    backgroundColor: '#43c1f3',
    marginTop: '5px',
    color: '#ffffff'
  }
  const errorAlert = {
    backgroundColor: '#E2444A',
    marginTop: '5px',
    color: '#ffffff'
  }
  const emptyCache = () => {
    let version = localStorage.getItem('version');
    if (version != packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  }
  useEffect(()=>{
    localStorage.setItem("sub-city",selectedEnv.REACT_APP_REGION)
  },[])
  useEffect(() => {
    if (sessionToken != null) {
      try {
        const decodedToken = jwtDecode(sessionToken);
        if (decodedToken.exp < new Date().getTime()) {
          dispatch(getUpdatedAccessToken(navigate, sessionToken));
        } else {
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 10);
        }
      } catch (error) {
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 10);
      }
    } else {
      const session = new URL(window.location).searchParams.get('s');
      if (session != null && session === 'expired') {
        localStorage.removeItem(UF_TOKEN);
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 10);
      }
    }
  }, [sessionToken])
  useEffect(() => {
    if (sessionToken != null) return;
    if (historyToken === null && alreadyLogin === 'true') {
      window.localStorage.removeItem('already');
      dispatch(setUserLoginError("Your account login in other device."));
      dispatch(userNotAuth());
      dispatch(setDataWindowContent({ show: { showData: 'invalidToken', showControl: 'login' } }));
      dispatch(setLoader(false))
    }
  }, [historyToken, alreadyLogin, sessionToken]);
  useEffect(() => {
    if (sessionToken != null) return;
    let body = {
      'event': 'Session start'
    }
    dispatch(activityLogger(body))
    if ((Object.keys(filterValues.zoning).length === 0) && !runFilter)
      dispatch(getFiltersData("portland"));
    dispatch(seRunFilter(false))
    // check for token on app render
    dispatch(verifyToken(token));
  }, [sessionToken]);

  useBeforeunload((event) => {
    if (sessionToken != null) return;
    let body = {
      'event': 'Session end'
    }
    dispatch(activityLogger(body))
  });
  useEffect(() => {
    emptyCache()
  }, [loader]);
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
  }, []);
  useEffect(() => { 
    // to get the count of downloads/remaining of reports
    !isMobile && token && ['Professional', 'Enterprise'].includes(user?.status) && dispatch(getReportCount(token))
    // display popup if user is not logged in
    setTimeout(() => {
      if (!token && !window.localStorage.getItem(UF_TOKEN))
        handleUnauthorizedUserModal();
    }, 30000)
  }, [token])
  useEffect(() => {
    if (sessionToken != null) return;
    if (isMobile) {
      setCurrentContent("mobileMap")
    }
    dispatch(getPlanPrice());
  }, [sessionToken])

  const AlertTemplate = ({ message, options }) => (
    <Message icon style={options.type === 'success' ? successAlert : options.type === 'error' ? errorAlert : ""}>
      {options.type === 'success' && <Icon name='check square outline' />}
      {options.type === 'error' && <Icon name='x icon' />}
      <Message.Content>
        <Message.Header>{message}</Message.Header>
      </Message.Content>
    </Message>
  );
  const getPanelWidth = () => {
    if (window.innerWidth <= 768) {
      return window.innerWidth === 768 ? '218px' : window.innerWidth - 56 + 'px'
    } else {
      return dataWindow === true ? '650px' : '218px'
    }
  }
  const getMapWidth = () => {
    if (window.innerWidth <= 768) {
      return window.innerWidth === 768 ? '0px' : '0px'
    } else {
      return dataWindow === true ? '640px' : '0px'
    }
  }
  const showMapHandler = () => {
    return window.innerWidth < 768 && showPanel === false;
  }
  const handleSideBarPanel = () => {
    setShowPanel(true)
  }
  const handleUnauthorizedUserModal = () => {
    setUnAuthorizedModal(!unAuthorizedModal)
  } 
  return (
    <Provider template={AlertTemplate} {...options} >
      <div id="urbanform-styles">
        <div className="menu-columns">

          {/* {isMobile === true &&
            <>
              <div className="mbl-header">
                <div className="mapbox-search" style={{ padding: isMobile ? "13px 0px 13px 10px" : "32px" }}>
                  <div id="mapbox-geocoder-home"></div>
                </div>

                <div className='see-result-icons'>
                  <div className="heart">
                    <Image src={ICON_HEART} className='image-size' />
                  </div>
                  <div className="file">
                    <Image src={ICON_FILE} className='image-size' />
                  </div>
                </div>
              </div>
            </> 
          } */}
          <div className="thick-column" style={{ width: window.innerWidth <= 768 ? 'calc(100% - 80px)' : 'calc(100% - 85px)' }}>
            <div className="main-columns">




              {showMapHandler && <div className={"map-container"} style={{ width: `calc( 100% - ${getMapWidth()} )` }}>
                <Map sidebarVisible={sidebarVisible} setCurrentContent={setCurrentContent} />
              </div>}




              {/* {isMobile === true ?
                <div className={"sidebar-container"} style={{ width: `${getPanelWidth()})` }}>
                  <MobileSidebar currentContent={currentContent} setCurrentContent={setCurrentContent} showPanel={showPanel} setShowPanel={setShowPanel} />
                  {dataWindow && <DataWindow currentContent={currentContent} setCurrentContent={setCurrentContent} />}
                </div>
                :
                <div className={"sidebar-container"} style={{ width: `${getPanelWidth()})` }}>
                  <Sidebar currentContent={currentContent} setCurrentContent={setCurrentContent} showPanel={showPanel} setShowPanel={setShowPanel} />
                  {dataWindow && <DataWindow currentContent={currentContent} setCurrentContent={setCurrentContent} />}
                </div>

              } */}
              <div className={"sidebar-container"} style={{ width: `${getPanelWidth()})` }}>
                <Sidebar currentContent={currentContent} setCurrentContent={setCurrentContent} showPanel={showPanel} setShowPanel={setShowPanel} />

                <div>
                  <div style={dataWindow && currentContent !== "User" && currentContent !== "Help" ? { position: isMobile ? "absolute" : "", left: isMobile ? "47px" : "", top: isMobile ? "7px" : "", padding: isMobile ? "" : "27px 10px 0 10px", background: currentContent === "Search" ? "#FFEFF9" : "#F2F2F2" } : { display: "none" }} className="info-heading">
                    <div id="mapbox-geocoder-info"></div>
                  </div>
                  {dataWindow && (
                    <DataWindow
                      currentContent={currentContent}
                      setCurrentContent={setCurrentContent}
                    />
                  )}
                </div>

              </div>

              {/* <div className="thin-column" style={{ width: window.innerWidth <= 768 ? '56px' : '75px' }}>
              <SideMenu currentContent={currentContent} setCurrentContent={setCurrentContent} dispatch={dispatch} showPanel={showPanel} setShowPanel={setShowPanel} showMap={currentContent === 'mobileMap'} />
            </div> */}
            </div>
          </div>
          <div className="thin-column" style={{ width: window.innerWidth <= 768 ? '80px' : '85px' }}>
            <SideMenu currentContent={currentContent} setCurrentContent={setCurrentContent} dispatch={dispatch} showPanel={showPanel} setShowPanel={setShowPanel} showMap={currentContent === 'mobileMap'} />
          </div>

          {/* {isMobile === true ?
            <div className="mobile-thin-column" >
              <MobileSideMenu currentContent={currentContent} setCurrentContent={setCurrentContent} dispatch={dispatch} showPanel={showPanel} setShowPanel={setShowPanel} showMap={currentContent === 'mobileMap'} />
            </div>
            :
            <div className="thin-column" style={{ width: window.innerWidth <= 768 ? '56px' : '75px' }}>
              <SideMenu currentContent={currentContent} setCurrentContent={setCurrentContent} dispatch={dispatch} showPanel={showPanel} setShowPanel={setShowPanel} showMap={currentContent === 'mobileMap'} />
            </div>} */}
          {loader && isMobile === false && <Dimmer active>
            <Loader content='Loading' />
          </Dimmer>}
          {loader && isMobile && <Dimmer active>
            <Loader className={'mobile-loader'} content='Loading' />
          </Dimmer>}
          {/* <UnAuthorizedUserModal handleUnauthorizedUserModal={handleUnauthorizedUserModal} unAuthorizedModal={unAuthorizedModal} /> */}
        </div>
      </div>
    </Provider>
  );
};

export default connect()(App);
