import React, { Fragment } from "react";
import { Table } from "semantic-ui-react";

const SidebarTable = ({ title, content, subCategories }) => {
  return (
    <Table className="sidebar-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">{title}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      {subCategories ? (
        subCategories.map((category) => (
          <Fragment>
            <Table.Body>
              <Table.Row className="sub-category-container">
                <Table.Cell colSpan="3" className="sub-category">
                  {category}
                </Table.Cell>
              </Table.Row>
              {content.map(
                (data) =>
                  data.subCategory === category && (
                    <Table.Row>
                      <Table.Cell>
                        <b>{data.title}</b>
                      </Table.Cell>
                      <Table.Cell textAlign="right">{data.content}</Table.Cell>
                    </Table.Row>
                  )
              )}
            </Table.Body>
          </Fragment>
        ))
      ) : (
        <Table.Body>
          {content.map((data) => (
            <Table.Row key={data.content}>
              <Table.Cell>
                <b>{data.title}</b>
              </Table.Cell>
              <Table.Cell textAlign="right">{data.content}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      )}
    </Table>
  );
};

export default SidebarTable;
