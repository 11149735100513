import React, { useEffect } from 'react';
import '../../../css/CreateAccount.scss'
import { Image } from "semantic-ui-react";
import checkMark from '../../../images/checkMark.png';
import plus from '../../../images/plus.png';
import { setDataWindow, setDataWindowContent } from '../../../actions/actions_datawindow';
import { PLANS } from '../../../constants';
import { useSelector } from "react-redux";
import { setUserUpdateError } from '../../../actions/actions_user';
import { setFilterPlan } from "../../../actions/actions_filters";

const ChoosePlan = ({ dataWindowContent, dispatch }) => {
    const activeContent = dataWindowContent && dataWindowContent.choosePlan ? dataWindowContent.choosePlan.activeContent : null;
    const { user } = useSelector((state) => state.user);
    const { filterPlan } = useSelector(state => state.filters)
    const { updateContent } = useSelector(state => state.dataWindow);
    const dataWindow = useSelector((state) => state.dataWindow);

    let priv = [{
        status: 'null',
        order: 0
    }, {
        status: null,
        order: 0
    }, {
        status: 'Basic',
        order: 1
    },
    {
        status: 'Professional',
        order: 2
    },
    {
        status: 'Enterprise',
        order: 3
    }];
    useEffect(() => {
        // if update modal of contact info is closed then reset activeSession
        if (updateContent === false)
            dispatch(setFilterPlan(null))
    }, [updateContent]);
    const handlePlanType = (type, order) => {
        dispatch(setDataWindow({ dataWindow: true, content: { isContact: false, choosePlan: { plans: PLANS, activeContent: type } } }));
        dispatch(setDataWindowContent({ show: { showData: 'choose-plan', showControl: 'choose-plan' } }));
    }
    return (
        <div className="user-signup-container">
            <h4 className="grayed-text">Choose a plan</h4>
            <div>
                <div className={[`${activeContent === 'non-subscriber' ? 'active' : 'un-active'} curve-wrapper`]}>
                    <span className="top-curve"></span>
                </div>
                <div className={[`${activeContent === 'non-subscriber' ? 'active' : 'un-active'} signup-header`]} onClick={() => handlePlanType('non-subscriber', 1)}>
                    <Image src={checkMark} className="signup-icon signup-icon-account" />
                    <h3 className="contact-text" style={{ "marginLeft": "6px" }}>Free</h3>
                </div>
                <div className={[`${activeContent === 'non-subscriber' ? 'active' : 'un-active'} curve-wrapper`]}>
                    <span className="bottom-curve"></span>
                </div>

                <div className={[`${activeContent === 'pro' ? 'active' : 'un-active'} curve-wrapper`]}>
                    <span className="top-curve"></span>
                </div>
                <div className={[`${activeContent === 'pro' ? 'active' : 'un-active'} signup-header`]} onClick={() => handlePlanType('pro', 2)}>
                    <Image src={plus} className="signup-icon signup-icon-card" />
                    <h3 className="payment-text">Pro</h3>
                </div>
                <div className={[`${activeContent === 'pro' ? 'active' : 'un-active'} curve-wrapper`]}>
                    <span className="bottom-curve"></span>
                </div>
                
                <div className={[`${activeContent === 'ent' ? 'active' : 'un-active'} curve-wrapper`]}>
                    <span className="top-curve"></span>
                </div>
                <div className={[`${activeContent === 'ent' ? 'active' : 'un-active'} signup-header`]} onClick={() => handlePlanType('ent', 3)}>
                    <Image src={plus} className="signup-icon signup-icon-card" />
                    <h3 className="payment-text">Enterprise</h3>
                </div>
                <div className={[`${activeContent === 'ent' ? 'active' : 'un-active'} curve-wrapper`]}>
                    <span className="bottom-curve"></span>
                </div>
            </div>
            <div className='compare-prices-btn'>
                <a className='upgrade-button' href="https://urbanform.us/pricing" target="_blank" style={{ "paddingLeft": "28%", "paddingRight": "28%", "fontWeight": "500" }}>Compare Plans</a>
            </div>
            <div className='want-to-talk'>
                <p>Want to talk to somebody</p>
                <p>about the different options?</p>
                <p>
                    <a target="_blank" href="https://urbanform.us/pricing"> -contact us-</a>
                </p>
            </div>
        </div>
    )
}

export default ChoosePlan;