import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import selectedEnv from '../../../../Universal_CONFIG';
import { getPaymentMethodsQuota } from "../../../../actions/actions_user";
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(selectedEnv.REACT_APP_STRIPE_KEY);

const Payment = ({ plan, dispatch, title, quota }) => {
    const { token } = useSelector(state => state.user);
    useEffect(() => {
        token && dispatch(getPaymentMethodsQuota(token))
    }, []);

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm plan={plan} dispatch={dispatch} title={title} quota={quota} />
        </Elements>
    );
};

export default Payment;