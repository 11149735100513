import React from "react";
import '../../../../css/Total.scss';
import "semantic-ui-css/semantic.min.css";
import Container from "../Container";
import SliderSection from "../SliderSection";
import Header from "../Header";
import { useSelector } from "react-redux";
const UnitsFilter = ({ dispatch }) => {
    const { units } = useSelector(state => state.filters.filterValues)
   
    return (
        <>
            {units.map(field => {
                return (
                    <>
                        <Container>
                            <Header content={field} />
                            <div className='slider-section'>
                                <SliderSection
                                    range={field.range}
                                    content={field.fields}
                                    selectedFilter={units}
                                    dispatch={dispatch}
                                    checkboxField={field.checkbox}
                                    maxRangeValue={field?.maxValue ?? ""}
                                    columnName={field.column_name}
                                />
                            </div>
                            <br />
                        </Container>
                    </>
                )
            })};
        </>
    )
}
export default UnitsFilter;