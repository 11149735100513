export const aus_env = {
  REACT_APP_API_URL: "https://sea-dev.urbanform.us/api",
  REACT_APP_MAPBOX_ACCESS_TOKEN:
    "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw",
  REACT_APP_MAPBOX_STYLE_URL_BASIC:
    "mapbox://styles/qvt282/clquwzt5100zz01nwhxh96et0", //multi-region-style
  REACT_APP_MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/clfq1w5fd002501nsr1y9tdv8",
  REACT_APP_MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/clfq1wuw2000n01n2982xsf7w",
  REACT_APP_MAPBOX_LAYER_NAME: "aus_taxlots",
  REACT_APP_STRIPE_KEY:
    "pk_test_51J3keRKwuRPRmetLYA1Zgpg1T50OlJj7L5U7p60MB7hvR8VaaXhoDk0llLi95tOaWUU77c4rNEx7fa22nyEStrxD00X4iN7RXg",
  REACT_APP_REGION: "austin",
  REACT_APP_BBOX: "-98.074951, 30.086919, -97.520828, 30.534468",
  REACT_APP_MAP_CENTER: "-97.743559, 30.270928",
  REACT_APP_SUBREGION_LAYER: "",
  REACT_APP_SUB_REGION: "",
  REACT_APP_SUB_CITY_NAME: "",
  REACT_APP_CITY_NAME: "Austin, Texas",
  REACT_APP_CITIES_LIST:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [], "los_angeles": [], "yam8":[], "yam9":[] }',
  REACT_APP_PROXIMITY: "-97.743559, 30.270928",
  REACT_APP_MB_TILES_LIST: "taxlots-fill",
  REACT_APP_ZOOM_LEVEL: 11,
};
