import React from 'react';
import { Grid } from "semantic-ui-react";
import '../../../../css/ExistingStructure.scss';
import SliderSection from "../SliderSection";
import Header from "../Header";
import Container from "../Container";
import { useSelector } from "react-redux";
import { checkboxUpdate } from "../../../../utils/Utils";
import { UpdateFilter } from "../../../../actions/actions_filters";

const ExistingStructure = ({ dispatch }) => {
    const { existing_structures } = useSelector(state => state.filters.filterValues)
    const { show } = useSelector(state => state.dataWindow)
    const onChangeSelect = (e) => {
        let { name, checked } = e.target;
        let currentFilter = show.showData;
        let UpdatedFilter = {
            name: currentFilter,
            filterData: checkboxUpdate(currentFilter, existing_structures, name, checked)
        }
        dispatch(UpdateFilter(UpdatedFilter));
    }
    const contentExisting = {
        icon: 'ICON_HOME',
        title: 'Existing Structure'
    }
    let count = 0;
    return (
        <div className="existing-structure">
            {existing_structures.map(field => {
                return (
                    <>
                        <Container>
                            <Header content={field} />
                            <div className='slider-section'>
                                <SliderSection
                                    range={field.range}
                                    content={field.fields}
                                    selectedFilter={existing_structures}
                                    dispatch={dispatch}
                                    checkboxField={field.checkbox}
                                    maxRangeValue={field?.maxValue ?? 99999999}
                                    columnName={field.column_name}
                                />
                            </div>
                            <br />
                        </Container>
                    </>
                )
            })};
            {/* <Container>
                <Header content={contentExisting} />
                <div className="existing-structure-section">
                    <Grid columns={1}>
                        {
                            existing_structures.map(info => {
                                return (
                                    <Grid.Column>
                                        {info.sub_fields &&
                                            <div>
                                                <label style={{ marginLeft: "58px", color: "#000" }}>{info.label}</label>
                                                <SliderSection
                                                    content={info.fields}
                                                    selectedFilter={existing_structures}
                                                    dispatch={dispatch}
                                                    maxRangeValue={info?.maxValue ?? 99999999}
                                                    columnName={info.column_name}
                                                />
                                            </div>
                                        }
                                    </Grid.Column>
                                )
                            })
                        }
                    </Grid>
                </div>
            </Container> */}
        </div>
    );
};

export default ExistingStructure;