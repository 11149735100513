import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Grid, Icon } from "semantic-ui-react";
import selectedEnv from "../../../../Universal_CONFIG";
import { setCitiesAndPlans } from "../../../../actions/actions_plans";
import '../../../../css/Segment.scss';
import { Capitalize, getTotalPrice, isEnterpriseChecked, manageCitiesAndPlans, setAllCancelledPlans } from "../../../../utils/Utils";

const ChooseCitiesAndPlans = ({ allowAll }) => {
    const { activeContent } = useSelector(state => state.dataWindow.content.choosePlan);
    const { subscriptions } = useSelector(state => state.plans);
    const { planPrice } = useSelector(state => state.user);
    const { plans, citiesAndPlans } = useSelector(state => state.plans);
    const dispatch = useDispatch();
    const header = ['', 'Basic', 'Pro', 'Ent.'];
     
    const onChange = (evt, data) => {
        
        const [cityCode, planName] = data.value.split('_');
        // v2.0
        let newstate = JSON.parse(selectedEnv.REACT_APP_CITIES_LIST) // add new section for any upcoming city

        Object.keys(citiesAndPlans).map((city) => citiesAndPlans[city].map((plan) => {
            if (planName === 'ent' || planName === 'pro') {
                setAllCancelledPlans(data.checked, cityCode, planName, city, plan, subscriptions, citiesAndPlans);
            }

            if (plan.value === data.value) {
                plan.isChecked = (planName === 'pro' && isEnterpriseChecked(citiesAndPlans[city]) === true) ? true : data.checked;
                newstate[city].push(plan);
                return
            };

            if (planName === 'ent' && plan.value === cityCode + '_pro' && data.checked === true) {
                plan.isChecked = true;
                newstate[city].push(plan);
                return
            }

            newstate[city].push(plan);
        }));

        dispatch(setCitiesAndPlans({ ...newstate }));
    };

    useEffect(() => {
        manageCitiesAndPlans(citiesAndPlans, activeContent, dispatch, subscriptions);

    }, [activeContent, subscriptions]);

    return (
        <div className="section">
            <div className={[`section-display section-display-cities`]}>
                <Grid columns='equal' className="plan-headers">
                    {
                        header.map((head, index) => {
                            return (
                                <Grid.Column key={index}>
                                    <span className="span-styles">{head}</span>
                                </Grid.Column>
                            )
                        })
                    }
                </Grid>
                {
                    
                    Object.keys(citiesAndPlans).map((city, index) => {
                     
                        let checkboxes = [...citiesAndPlans[city]];
                        return <Grid key={index} columns='equal' textAlign="center" className="plan-content">
                            <Grid.Column key={index}>
                                <span className="span-styles">{Capitalize(city)}</span>
                            </Grid.Column>
                            {
                                checkboxes.map((box, index) => {
                                    return (
                                        <Grid.Column key={index}>
                                            {
                                                box.isSubscribed ? <Icon name='checkmark box' size="large" className="green-check" /> :
                                                    <Checkbox className={"custom-checkboxes"} value={box.value} checked={box.isChecked ? true : undefined}
                                                        disabled={box.isDisable ? true : undefined} onChange={onChange} />
                                            }
                                        </Grid.Column>
                                    )
                                })
                            }
                        </Grid>
                    })
                }

                {allowAll !== true && <div className="total-price-section">
                    <div>
                        <Icon name='checkmark box' size="large" className="green-check" />
                        <span className="span-styles purchase"> = already purchased</span>
                    </div>
                    <div>
                        <Icon name='dot circle' size="large" className="yellow-check" />
                        <span className="span-styles purchase"> = selected</span>
                    </div>
                    <div className="price-section">
                        <div className="price">
                            <span className="span-styles">Total:</span>
                        </div>
                        <div>
                            <span className="span-styles value-01">
                                ${getTotalPrice({ ...citiesAndPlans }, [...planPrice], [...plans])}
                            </span>
                        </div>
                        <div>
                            <span className="span-styles">payable now</span>
                        </div>
                    </div>
                </div>}

            </div>

        </div>
    )
}
export default ChooseCitiesAndPlans;