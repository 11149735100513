export const yam9_env = {
  REACT_APP_API_URL: "https://sea-dev.urbanform.us/api",
  REACT_APP_MAPBOX_ACCESS_TOKEN:
    "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw",
  REACT_APP_MAPBOX_STYLE_URL_BASIC:
    "mapbox://styles/qvt282/cm2f21m5m011l01omgjcs8zzv", //multi-region-style", //multi-region-style
  REACT_APP_MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/cm2f21iiz011k01om9ha8dios",
  REACT_APP_MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/cm2f21efi011j01omfv773trk",
  REACT_APP_MAPBOX_LAYER_NAME: "yam_bndry",
  REACT_APP_STRIPE_KEY:
    "pk_test_51J3keRKwuRPRmetLYA1Zgpg1T50OlJj7L5U7p60MB7hvR8VaaXhoDk0llLi95tOaWUU77c4rNEx7fa22nyEStrxD00X4iN7RXg",
  REACT_APP_REGION: "yamhill_county",
  REACT_APP_BBOX:
    "-123.51417037723357,45.03218731082961,-122.87686705441986,45.3872438649021",
  REACT_APP_MAP_CENTER: "-123.19551871582667,45.2099926419165",

  REACT_APP_SUBREGION_LAYER: "yam_bndry",
  REACT_APP_SUB_REGION: "Yamhill County, OR",
  REACT_APP_SUB_CITY_NAME: null,
  REACT_APP_CITY_NAME: "Yamhill County, OR",
  REACT_APP_SUB_CITY_NAME_1: "Amity, OR",
  REACT_APP_SUB_CITY_NAME_2: "Carlton, IL",
  REACT_APP_SUB_CITY_NAME_3: "Dundee, TX",
  REACT_APP_SUB_CITY_NAME_4: "Dayton, AZ",
  REACT_APP_SUB_CITY_NAME_5: "Newberg, CA",
  REACT_APP_SUB_CITY_NAME_6: "Yamhill, TX",
  REACT_APP_SUB_CITY_NAME_7: "Willamina, CA",
  REACT_APP_SUB_CITY_NAME_8: "Lafayette, CA",

  REACT_APP_CITIES_LIST:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [], "los_angeles": [], "yam8":[], "yam9":[] }',
  REACT_APP_PROXIMITY: "-123.19551871582667,45.2099926419165",
  REACT_APP_MB_TILES_LIST: "taxlots-fill",
  REACT_APP_ZOOM_LEVEL: 10.3,
};
