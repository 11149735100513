import React, { useState } from "react";
import '../../../../css/ContactCity.scss'


const ZoningGuide = () => {

    return (
        <>

            <div className="help-zone">
                <h1 >Guides To Zoning</h1>
                <div className="zone-card">
                    <div className="zone-header">
                        <h3>zoning guides</h3>
                    </div>
                    <div className="zone-body"> 
                        <p>UrbanForm creates written Guides to Zoning for each
                            jurisdiction we service to help anyone and everyone
                            understand more about the process of obtaining zoning
                            information, with or without UrbanForm.
                        </p> 
                        <u> 
                        <a href="https://www.urbanform.us/how-do-i-find-my-zoning" target="_blank">Click to see our Guides to zoning...</a>
                        </u>
                    </div>
                </div>
            </div>
        </>

    );

}



export default ZoningGuide;
