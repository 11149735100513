import fetchIntercept from "fetch-intercept";
import { PRODUCT } from "../constants";
import { isMobile } from "react-device-detect";

export const unregister = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    const token = window.localStorage.getItem("_uft");
    const device = isMobile ? "M" : "D";
    const headers = token
      ? {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
          region: PRODUCT,
          "Device-Type": device,
        }
      : {
          "Content-type": "application/json",
          region: PRODUCT,
          "Device-Type": device,
        };
    if (url && config) config["headers"] = { ...headers, ...config.headers };
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    if (response.status === 402) {
      window.localStorage.removeItem("_uft");
      window.localStorage.setItem("already", "true");
    }
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});
