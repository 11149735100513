import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import selectedEnv from "../Universal_CONFIG";
import { setCitiesAndPlans } from "../actions/actions_plans";
import { EARTH_DIRECTIONS, PLANS, PRODUCT } from "../constants";



export const getHeaders = (token = null) => {
  return token
    ? {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
      region: PRODUCT,
    }
    : {
      "Content-type": "application/json",
      region: PRODUCT,
    };
};

export function validateUserAccount(dataWindowContent) {
  let errors = [];

  Object.values(dataWindowContent.values).forEach((value) => {
    if (value.required === true) {
      if (typeof value.value === "string" && value.value.trim() === "") {
        errors.push(value);
      } else if ([0, null, undefined].includes(value.value)) {
        errors.push(value);
      }
    }
    // let textValue = (value.required && value.value !== "") && (value.value).toString();
    value.required && value.value === "" && errors.push(value);
  });
  let email = dataWindowContent.values.email.value;
  let phone_number = dataWindowContent.values.phone_number.value;
  let isPasswordMatched =
    dataWindowContent.values.password.value ===
    dataWindowContent.values.password_confirm.value;
  let response = {};
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  if (email !== null && !pattern.test(email)) {
    response = { isError: true, isEmailValid: false };
  } else if (
    phone_number !== null &&
    phone_number.trim().length < 12 &&
    phone_number.trim().length > 0
  ) {
    response.isError = true;
    response.isPhoneValid = false;
  } else if (errors.length > 0 || isPasswordMatched === false) {
    response.isError = true;
    response.isPasswordMatched = isPasswordMatched;
  } else {
    let data;
    Object.entries(dataWindowContent.values).map(([key, element]) => {
      data = { ...data, [key]: element.value };
    });
    response = { data };
  }
  return response;
}

export function downloadPdf(url, file_name) {
  fetch(`${selectedEnv.REACT_APP_API_URL}` + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      'region': localStorage.getItem("sub-city")
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      let fileName = file_name + ".pdf";
      link.setAttribute("download", fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}
export function checkboxUpdate(currentFilter, filterData, field, action) {
  filterData.forEach((info) => {
    let selected = [];
    if (info.name === field) {
      info.action = action;
      info?.checkbox &&
        info.checkbox.forEach((input) => {
          input.action = action;
        });
    } else if (info?.checkbox) {
      info?.checkbox &&
        info.checkbox.forEach((input) => {
          if (input.name === field) input.action = action;
          selected.push(input.action);
        });
      info.action = !selected.includes(false);
    }
  });
  return filterData;
}

export function JurisCheckboxUpdate(currentFilter, filterData, field, action) {
  filterData.forEach((info) => {
    let selected = [];
 if (info?.checkbox) {
      info?.checkbox &&
        info.checkbox.forEach((input) => {
          if (input.name === field) 
          input.action = action;
          else input.action =!action;
          selected.push(input.action);
        });
      info.action = !selected.includes(false);
    }
  });
  return filterData;
}

export function singleCheckboxUpdate(currentFilter, filterData, field, action, groupId) {
  console.log(groupId,"groupId");
  filterData.forEach((info) => {
    if (info.name === field) {
      info.action = action;
    } else if (info.checkbox && info.title_column_name === groupId) {
      info.checkbox.forEach((input) => {
        input.action = input.name === field ? action : false;
      });
    }
  });
  return filterData;
}



export function updateSlider(
  currentFilter,
  filterData,
  field,
  value,
  rangeSlider = null,
  maxRangeValue = null,
  minRangeValue = 0
) {
  let firstInputValue =
    rangeSlider === "doubleSlider" ? parseInt(value[0]) : parseInt(value);
  filterData.forEach((info) => {
    if (info?.fields) {
      info.fields.forEach((slider) => {
        if (slider.name === field + "_min") {
          slider.value = parseInt(firstInputValue);
          slider.action = true;
          if (
            parseInt(firstInputValue) === minRangeValue &&
            parseInt(value[1]) === maxRangeValue
          )
            slider.action = false;
          if (
            rangeSlider !== "doubleSlider" &&
            parseInt(firstInputValue) === minRangeValue
          )
            slider.action = false;
          slider.leftDefault = parseInt(firstInputValue) === minRangeValue;
        } else if (slider.name === field + "_max") {
          slider.value = parseInt(value[1]);
          slider.action = true;
          if (
            parseInt(firstInputValue) === minRangeValue &&
            parseInt(value[1]) === maxRangeValue
          )
            slider.action = false;

          slider.rightDefault = parseInt(value[1]) === maxRangeValue;
        }
        if (
          slider.name === field + "_max" &&
          ["envp_bldg_max_area", "envp_delt_area"].includes(field)
        ) {
          slider.is_far_right = parseInt(value[1]) === maxRangeValue;
        }
      });
    }
  });
  return filterData;
}
export function updateMinMax(
  currentFilter,
  filterData,
  field,
  value,
  columnName = null,
  maxRangeValue,
  minMax = [],
  minRangeValue = 0
) {
  let minInput = minMax[0];
  let maxInput = minMax[1];
  filterData.forEach((info) => {
    if (info?.fields) {
      info.fields.forEach((slider) => {
        if (slider.name === field) {
          slider.value = value === "" ? 0 : parseInt(value);
          slider.action = true;
          if (slider.label === "Min") {
            minInput = slider.value;
            slider.leftDefault = slider.value === minRangeValue;
          }
          if (slider.label === "Max") {
            maxInput = slider.value;
            slider.rightDefault = slider.value === maxRangeValue;
          }
        } else if (slider.column_name === columnName) {
          slider.action = true;
        } else if (
          columnName === "residential_units" &&
          ["unit_min_lot_valu", "unit_max_lot_valu"].includes(
            slider.column_name
          )
        )
          slider.action = true;
        if (
          slider.name === field &&
          ["envp_bldg_max_area", "envp_delt_area"].includes(columnName)
        )
          slider.is_far_right = parseInt(value) === maxRangeValue;
      });
      // set the slider to its default state
      info.fields.forEach((slider) => {
        if (slider.column_name === columnName) {
          if (
            parseInt(minInput) === minRangeValue &&
            parseInt(maxInput) === parseInt(maxRangeValue) &&
            !["htlim_bonx_valu"].includes(columnName)
          )
            slider.action = false;
        } else if (columnName === "htlim_bonx")
          if (parseInt(minInput) === minRangeValue) slider.action = false;
        if (
          columnName === "residential_units" &&
          ["unit_min_lot_valu", "unit_max_lot_valu"].includes(
            slider.column_name
          )
        ) {
          if (
            parseInt(minInput) === minRangeValue &&
            parseInt(maxInput) === parseInt(maxRangeValue)
          ) {
            slider.action = false;
          }
        }
      });
    }
  });
  return filterData;
}
export function validateEmail(email) {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  let response = false;
  if (email !== null && !pattern.test(email)) response = true;
  return response;
}

export function parseInfoResponse(constantResponse, apiResponse) {

  // constantResponse = {...constantResponse,massing:""}
  constantResponse.summary.map(function (item, key) {

    let { summary } = apiResponse;
    // let { max_build } = apiResponse;
    switch (item.id) {

      case "zone_detail":
        summary = summary[0];
        // item.title = summary.title;
        item.subTitle = summary.description;
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 0:
              item.map_id = summary.properties.id.replace(/,/g, '');
              item.premacuim_no = summary.properties.primaccnum ?? "N/A";
              //item.title_desc = summary.properties.zone_desc ?? "N/A";
              break;
            case 1:
              item.title = summary.properties.zone ?? "N/A";
              break;
            case 2:
              item.title = summary.properties.zone_desc ?? "N/A";
              break;
            case 3:
              if (summary.properties.zone_cat1 !== null) {
                item.title = summary.properties.zone_cat1 ?? "N/A";
                item.small_heading = summary.properties.zone_cat1_desc+ ":" ?? "N/A";
              }
              break;
            case 4:
              if (summary.properties.zone_cat2 !== null) {
                item.small_heading = summary.properties.zone_cat2_desc + ":" ?? "N/A";
                item.title = summary.properties.zone_cat2 ?? "N/A";
              }
              break;
            case 5:
              item.source_p = summary.properties.zone_srce ?? "N/A";
              item.link = summary.properties.zone_link;
              break;
            case 9:
              // item.source = summary.properties.zone_srce ?? 'N/A';
              item.link = summary.properties.zone_link;
              break;
          }
        });
        break;
      case "lot_size":
        const lot_info = summary[1];
        item.title = lot_info.title;
        item.subTitle = lot_info.description;
        if (lot_info.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              if (lot_info.properties.area_sqft !== null) {
                item.title = lot_info.properties.area_sqft?Number(lot_info.properties.area_sqft).toLocaleString() + " square feet" : "N/A";
              }
              break;
            case 2:
              if (lot_info.properties.area_acre !== null) {
                item.title = Math.round(lot_info.properties.area_acre * 100) / 100 .toLocaleString() + " acres" ?? "N/A";
              }
              break;
            case 3:
              if (lot_info.properties.lot_wide_feet !== null) {
                item.title = Number(lot_info.properties.lot_wide_feet).toLocaleString() + " feet" ?? "N/A";
              }
              break;
            case 4:
              if (lot_info.properties.lot_deep_feet !== null) {
                item.title = Number(lot_info.properties.lot_deep_feet).toLocaleString() + " feet" ?? "N/A";
              }
              break;
            case 5:
              if (lot_info.properties.lot_strt_feet !== null) {
                item.title = Number(lot_info.properties.lot_strt_feet).toLocaleString() + " feet" ?? "N/A";
              }
              break;
          }
        });
        break;


      case "base_far":
        summary = summary[3];
        // item.title = summary.title || 'N/A';
        item.subTitle = "";
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = summary.properties.far_base_cond ?? "N/A";
              break;
            case 2:
              if (summary.properties.summary_base_valu !== null) {
                item.title = summary.properties.far_base_valu;
              }
              break;
            case 3:
              item.title = summary.properties.far_base_cmmt ?? "N/A";
              break;
            case 4:
              item.title = summary.properties.far_adjx_appl ?? "N/A";
              break;
            case 5:
              item.title = summary.properties.far_bonx_appl ?? "N/A";
              break;
            case 6:
              item.source_p = summary.properties.far_base_srce;
              item.link = summary.properties.far_base_link;
              break;
            case 7:
              item.link = summary.properties.far_base_link;
              break;
          }
        });
        break;
      case "height_limit_base":
        summary = summary[4];
        // item.title = summary.title || "N/A";
        item.subTitle = "";
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = summary.properties.htlim_base_cond ?? "N/A";
              break;
            case 2:
              if (summary.properties.htlim_base_valu !== null) {
                item.title =
                  summary.properties.htlim_base_valu;
              }
              else {
                item.display = false
              }
              break;
            case 3:
              item.title = summary.properties.htlim_base_cmmt ?? "N/A";
              break;
            case 4:
              item.title = summary.properties.htlim_adjx_appl ?? "N/A";
              break;
            case 5:
              item.title = summary.properties.htlim_bonx_appl ?? "N/A";
              break;
            case 6:
              item.source_p = summary.properties.htlim_base_srce;
              item.link = summary.properties.htlim_base_link;
              break;
            case 7:
              item.link = summary.properties.htlim_base_link;
              break;
          }
        });
        break;
      case "max_buildable_square_footage":
        summary = summary[5];
        item.title = summary.title;
        item.subTitle = summary.description;
        if (summary.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              if (summary.properties.envp_bldg_max_area !== null) {
                var numericValue = parseFloat(summary.properties.envp_bldg_max_area?.replace(/,/g, ""));
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + " square feet";
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;

            case 2:
              item.title = summary.properties.envp_bldg_max_cmmt ?? "N/A";
              break;
            case 4:
              item.sourceHr = true
              item.display = true
              break;
            case 5:

              if (summary.properties.envp_bldg_bonus_area !== null) {
                var numericValue = parseFloat(summary?.properties?.envp_bldg_bonus_area?.replace(/,/g, ""));
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + " square feet";
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;

            case 6:
              item.title =
                summary.properties.envp_bldg_bonus_cmmt ?? "N/A";
              break;
          }
        });
        break;
      case "building_delta":
        const building_delta = summary[6];
        item.title = building_delta.title;
        item.subTitle = building_delta.description;
        if (building_delta.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map((item, key) => {
          switch (item.id) {
            case 1:

              if (building_delta.properties.envp_delt_area !== null) {
                var numericValue = parseFloat(building_delta?.properties?.envp_delt_area?.replace(/,/g, ""));
                if (!isNaN(numericValue)) {
                  item.title = Number(Math.round(numericValue)).toLocaleString() + " square feet";
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;

            case 3:

              if (building_delta.properties.envp_delt_bonus !== null) {
                var numericValue = parseFloat(building_delta?.properties?.envp_delt_bonus?.replace(/,/g, ""));
                if (!isNaN(numericValue)) {
                  item.title = Number(Math.round(numericValue)).toLocaleString() + " square feet";
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;


            case 5:

              if (building_delta.properties.envp_grnd_ftpt_delta !== null) {
                var numericValue = parseFloat(building_delta?.properties?.envp_grnd_ftpt_delta?.replace(/,/g, ""));
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + " square feet";
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;

            case 7:
              item.sourceHr = true;
              item.display = true;
              break;
          }
        });

    }
  });
  constantResponse.identifiers.map(function (item, key) {
    let { identifiers } = apiResponse;
    switch (item.id) {
      case "address":
        identifiers = identifiers[0];
        item.title = identifiers.title;
        item.subTitle = identifiers.description;
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = Capitalize(identifiers.properties.addr);
              break;
            case 2:
              item.title = Capitalize(identifiers.properties.addr_city) + ", " + Capitalize(identifiers.properties.addr_state);

              break;
            case 3:
              item.title = identifiers.properties.addr_zip;
              break;
          }
        });
        break;

      case "jurisdication":

        identifiers = identifiers[1];
        item.title = identifiers.title;
        item.subTitle = identifiers.juris_cmmt;
        item.link = identifiers.juris_link;
        item.source_p = identifiers.juris_srce;
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = identifiers.properties.juris ?? "N/A";
              break;
            case 2:
              item.title = identifiers.properties.juris_cmmt ?? "N/A";
              item.style = { fontStyle: "italic" };

              break;
            case 3:
              item.source_p = identifiers.properties.juris_srce ?? "N/A";
              item.link = identifiers.properties.juris_link ?? "N/A";
              break;
          }
        });
        break;

      case "identifiers":
        identifiers = identifiers[2];
        item.title = identifiers.title;
        item.subTitle = identifiers.description;
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              if (identifiers.properties.id01_name !== null) {
                item.small_heading = identifiers.properties.id01_name + ":";
                item.title = identifiers.properties.id01;
                item.hr = false
              }
              else {
                item.display = false
              }

              break;
            case 2:
              if (identifiers.properties.id02_name !== null) {
                item.small_heading = identifiers.properties.id02_name + ":";
                item.title = identifiers.properties.id02;
                item.hr = false
              }
              else {
                item.display = false
              }
              break;
            case 3:
              if (identifiers.properties.id03_name !== null) {
                item.small_heading = identifiers.properties.id03_name + ":";
                item.title = identifiers.properties.id03;
                item.hr = false
              }
              else {
                item.display = false
              }
              break;
            case 4:
              if (identifiers.properties.id04_name !== null) {
                item.small_heading = identifiers.properties.id04_name + ":";
                item.title = identifiers.properties.id04;
                item.hr = false
              }
              else {
                item.display = false
              }
              break;
            case 5:
              item.source_p = identifiers.properties.id_srce ?? "N/A";
              item.link = identifiers.properties.id_link ?? "N/A";
              break;
          }
        });
        break;


      case "assessor":
        identifiers = identifiers[3];
        item.link = identifiers.assr_link;
        item.source_p = identifiers.assr_srce;
        item.properties.map(function (item, key) {
          if (identifiers.properties.assr_srce !== null) {
            switch (item.id) {
              case 1:
                item.source_p = identifiers.properties.assr_srce ?? "N/A";
                item.link = identifiers.properties.assr_link;
                break;

            }
          }

        });
        break;


    }
  });
  constantResponse.zoning.map(function (item, key) {

    let { zoning } = apiResponse;
    switch (item.id) {
      case "zone_detail":
        zoning = zoning[0];
        item.title = zoning.title;
        item.subTitle = zoning.description;
        if (zoning.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = zoning.properties.zone ?? "N/A";
              break;
            case 2:
              item.title = zoning.properties.zone_desc ?? "N/A";
              break;
            case 3:
              if (zoning.properties.zone_cat1 !== null) {
                item.title = zoning.properties.zone_cat1 ?? "N/A";
                item.small_heading = zoning.properties.zone_cat1_desc+ ":" ?? "N/A";
              }
              break;
            case 4:
              if (zoning.properties.zone_cat2 !== null) {
                item.small_heading = zoning.properties.zone_cat2 + ":" ?? "N/A";
                item.title = zoning.properties.zone_cat2_desc ?? "N/A";

              }
              break;
            case 5:
              item.source_p = zoning.properties.zone_srce ?? "N/A";
              item.link = zoning.properties.zone_link;
              break;
          }
        });
        break;


      case "location-specification":
        zoning = zoning[1];
        item.title = zoning.title || "N/A";
        if (zoning.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }

        item.properties.map(function (item, key) {
          switch (item.id) {

            case 1:
              if (
                zoning.properties[`lc01_titl`] &&
                zoning.properties[`lc01_valu`] !== null &&
                zoning.properties[`lc01_valu`]?.trim() !== "" &&
                zoning.properties["lc01_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc01_titl`] + ":";
                item.title = zoning.properties[`lc01_valu`] ?? "N/A";

              }
              else {
                item.display = false
              }
              break;
            case 2:
              if (
                zoning.properties[`lc01_titl`] &&
                zoning.properties[`lc01_valu`] !== null &&
                zoning.properties[`lc01_valu`]?.trim() !== "" &&
                zoning.properties["lc01_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc01_cmmt`] ?? "N/A";

              }
              else {
                item.display = false
              }
              break;
            case 3:
              if (
                zoning.properties[`lc01_titl`] &&
                zoning.properties[`lc01_valu`] !== null &&
                zoning.properties[`lc01_valu`]?.trim() !== "" &&
                zoning.properties["lc01_valu"] !== ","
              ) {
                if (zoning.properties[`lc01_srce`] === null ||
                  zoning.properties[`lc01_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc01_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc01_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 4:
              if (
                zoning.properties[`lc01_titl`] &&
                zoning.properties[`lc01_valu`] !== null &&
                zoning.properties[`lc01_valu`]?.trim() !== "" &&
                zoning.properties["lc01_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 2
            case 5:
              if (
                zoning.properties[`lc02_titl`] &&
                zoning.properties[`lc02_valu`] !== null &&
                zoning.properties[`lc02_valu`]?.trim() !== "" &&
                zoning.properties["lc02_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc02_titl`] + ":";
                item.title = zoning.properties[`lc02_valu`] ?? "N/A";

              }
              else {
                item.display = false
              }
              break;
            case 6:
              if (
                zoning.properties[`lc02_titl`] &&
                zoning.properties[`lc02_valu`] !== null &&
                zoning.properties[`lc02_valu`]?.trim() !== "" &&
                zoning.properties["lc02_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc02_cmmt`] ?? "N/A";

              }
              else {
                item.display = false
              }
              break;
            case 7:
              if (
                zoning.properties[`lc02_titl`] &&
                zoning.properties[`lc02_valu`] !== null &&
                zoning.properties[`lc02_valu`]?.trim() !== "" &&
                zoning.properties["lc02_valu"] !== ","
              ) {
                if (zoning.properties[`lc02_srce`] === null ||
                  zoning.properties[`lc02_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc02_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc02_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 8:
              if (
                zoning.properties[`lc02_titl`] &&
                zoning.properties[`lc02_valu`] !== null &&
                zoning.properties[`lc02_valu`]?.trim() !== "" &&
                zoning.properties["lc02_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 3
            case 9:
              if (
                zoning.properties[`lc03_titl`] &&
                zoning.properties[`lc03_valu`] !== null &&
                zoning.properties[`lc03_valu`]?.trim() !== "" &&
                zoning.properties["lc03_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc03_titl`] + ":";
                item.title = zoning.properties[`lc03_valu`] ?? "N/A";

              }
              else {
                item.display = false
              }
              break;
            case 10:
              if (
                zoning.properties[`lc03_titl`] &&
                zoning.properties[`lc03_valu`] !== null &&
                zoning.properties[`lc03_valu`]?.trim() !== "" &&
                zoning.properties["lc03_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc03_cmmt`] ?? "N/A";

              }
              else {
                item.display = false
              }
              break;
            case 11:
              if (
                zoning.properties[`lc03_titl`] &&
                zoning.properties[`lc03_valu`] !== null &&
                zoning.properties[`lc03_valu`]?.trim() !== "" &&
                zoning.properties["lc03_valu"] !== ","
              ) {
                if (zoning.properties[`lc03_srce`] === null ||
                  zoning.properties[`lc03_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc03_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc03_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 12:
              if (
                zoning.properties[`lc03_titl`] &&
                zoning.properties[`lc03_valu`] !== null &&
                zoning.properties[`lc03_valu`]?.trim() !== "" &&
                zoning.properties["lc03_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 4
            case 13:
              if (
                zoning.properties[`lc04_titl`] &&
                zoning.properties[`lc04_valu`] !== null &&
                zoning.properties[`lc04_valu`]?.trim() !== "" &&
                zoning.properties["lc04_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc04_titl`] + ":";
                item.title = zoning.properties[`lc04_valu`] ?? "N/A";

              }
              else {
                item.display = false
              }
              break;
            case 14:
              if (
                zoning.properties[`lc04_titl`] &&
                zoning.properties[`lc04_valu`] !== null &&
                zoning.properties[`lc04_valu`]?.trim() !== "" &&
                zoning.properties["lc04_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc04_cmmt`] ?? "N/A";

              }
              else {
                item.display = false
              }
              break;
            case 15:
              if (
                zoning.properties[`lc04_titl`] &&
                zoning.properties[`lc04_valu`] !== null &&
                zoning.properties[`lc04_valu`]?.trim() !== "" &&
                zoning.properties["lc04_valu"] !== ","
              ) {
                if (zoning.properties[`lc04_srce`] === null ||
                  zoning.properties[`lc04_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc04_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc04_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 16:
              if (
                zoning.properties[`lc04_titl`] &&
                zoning.properties[`lc04_valu`] !== null &&
                zoning.properties[`lc04_valu`]?.trim() !== "" &&
                zoning.properties["lc04_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;

            case 17:
              if (
                zoning.properties[`lc05_titl`] &&
                zoning.properties[`lc05_valu`] !== null &&
                zoning.properties[`lc05_valu`]?.trim() !== "" &&
                zoning.properties["lc05_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc05_titl`] + ":";
                item.title = zoning.properties[`lc05_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 18:
              if (
                zoning.properties[`lc05_titl`] &&
                zoning.properties[`lc05_valu`] !== null &&
                zoning.properties[`lc05_valu`]?.trim() !== "" &&
                zoning.properties["lc05_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc05_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 19:
              if (
                zoning.properties[`lc05_titl`] &&
                zoning.properties[`lc05_valu`] !== null &&
                zoning.properties[`lc05_valu`]?.trim() !== "" &&
                zoning.properties["lc05_valu"] !== ","
              ) {
                if (zoning.properties[`lc05_srce`] === null ||
                  zoning.properties[`lc05_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc05_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc05_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 20:
              if (
                zoning.properties[`lc05_titl`] &&
                zoning.properties[`lc05_valu`] !== null &&
                zoning.properties[`lc05_valu`]?.trim() !== "" &&
                zoning.properties["lc05_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 6
            case 21:
              if (
                zoning.properties[`lc06_titl`] &&
                zoning.properties[`lc06_valu`] !== null &&
                zoning.properties[`lc06_valu`]?.trim() !== "" &&
                zoning.properties["lc06_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc06_titl`] + ":";
                item.title = zoning.properties[`lc06_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 22:
              if (
                zoning.properties[`lc06_titl`] &&
                zoning.properties[`lc06_valu`] !== null &&
                zoning.properties[`lc06_valu`]?.trim() !== "" &&
                zoning.properties["lc06_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc06_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 23:
              if (
                zoning.properties[`lc06_titl`] &&
                zoning.properties[`lc06_valu`] !== null &&
                zoning.properties[`lc06_valu`]?.trim() !== "" &&
                zoning.properties["lc06_valu"] !== ","
              ) {
                if (zoning.properties[`lc06_srce`] === null ||
                  zoning.properties[`lc06_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc06_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc06_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 24:
              if (
                zoning.properties[`lc06_titl`] &&
                zoning.properties[`lc06_valu`] !== null &&
                zoning.properties[`lc06_valu`]?.trim() !== "" &&
                zoning.properties["lc06_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 7
            case 25:
              if (
                zoning.properties[`lc07_titl`] &&
                zoning.properties[`lc07_valu`] !== null &&
                zoning.properties[`lc07_valu`]?.trim() !== "" &&
                zoning.properties["lc07_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc07_titl`] + ":";
                item.title = zoning.properties[`lc07_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 26:
              if (
                zoning.properties[`lc07_titl`] &&
                zoning.properties[`lc07_valu`] !== null &&
                zoning.properties[`lc07_valu`]?.trim() !== "" &&
                zoning.properties["lc07_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc07_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 27:
              if (
                zoning.properties[`lc07_titl`] &&
                zoning.properties[`lc07_valu`] !== null &&
                zoning.properties[`lc07_valu`]?.trim() !== "" &&
                zoning.properties["lc07_valu"] !== ","
              ) {
                if (zoning.properties[`lc07_srce`] === null ||
                  zoning.properties[`lc07_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc07_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc07_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 28:
              if (
                zoning.properties[`lc07_titl`] &&
                zoning.properties[`lc07_valu`] !== null &&
                zoning.properties[`lc07_valu`]?.trim() !== "" &&
                zoning.properties["lc07_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 8
            case 29:
              if (
                zoning.properties[`lc08_titl`] &&
                zoning.properties[`lc08_valu`] !== null &&
                zoning.properties[`lc08_valu`]?.trim() !== "" &&
                zoning.properties["lc08_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc08_titl`] + ":";
                item.title = zoning.properties[`lc08_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 30:
              if (
                zoning.properties[`lc08_titl`] &&
                zoning.properties[`lc08_valu`] !== null &&
                zoning.properties[`lc08_valu`]?.trim() !== "" &&
                zoning.properties["lc08_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc08_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 31:
              if (
                zoning.properties[`lc08_titl`] &&
                zoning.properties[`lc08_valu`] !== null &&
                zoning.properties[`lc08_valu`]?.trim() !== "" &&
                zoning.properties["lc08_valu"] !== ","
              ) {
                if (zoning.properties[`lc08_srce`] === null ||
                  zoning.properties[`lc08_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc08_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc08_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 32:
              if (
                zoning.properties[`lc08_titl`] &&
                zoning.properties[`lc08_valu`] !== null &&
                zoning.properties[`lc08_valu`]?.trim() !== "" &&
                zoning.properties["lc08_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 9
            case 33:
              if (
                zoning.properties[`lc09_titl`] &&
                zoning.properties[`lc09_valu`] !== null &&
                zoning.properties[`lc09_valu`]?.trim() !== "" &&
                zoning.properties["lc09_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc09_titl`] + ":";
                item.title = zoning.properties[`lc09_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 34:
              if (
                zoning.properties[`lc09_titl`] &&
                zoning.properties[`lc09_valu`] !== null &&
                zoning.properties[`lc09_valu`]?.trim() !== "" &&
                zoning.properties["lc09_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc09_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 35:
              if (
                zoning.properties[`lc09_titl`] &&
                zoning.properties[`lc09_valu`] !== null &&
                zoning.properties[`lc09_valu`]?.trim() !== "" &&
                zoning.properties["lc09_valu"] !== ","
              ) {
                if (zoning.properties[`lc09_srce`] === null ||
                  zoning.properties[`lc09_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc09_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc09_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 36:
              if (
                zoning.properties[`lc09_titl`] &&
                zoning.properties[`lc09_valu`] !== null &&
                zoning.properties[`lc09_valu`]?.trim() !== "" &&
                zoning.properties["lc09_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 10
            case 37:
              if (
                zoning.properties[`lc10_titl`] &&
                zoning.properties[`lc10_valu`] !== null &&
                zoning.properties[`lc10_valu`]?.trim() !== "" &&
                zoning.properties["lc10_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc10_titl`] + ":";
                item.title = zoning.properties[`lc10_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 38:
              if (
                zoning.properties[`lc10_titl`] &&
                zoning.properties[`lc10_valu`] !== null &&
                zoning.properties[`lc10_valu`]?.trim() !== "" &&
                zoning.properties["lc10_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc10_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 39:
              if (
                zoning.properties[`lc10_titl`] &&
                zoning.properties[`lc10_valu`] !== null &&
                zoning.properties[`lc10_valu`]?.trim() !== "" &&
                zoning.properties["lc10_valu"] !== ","
              ) {
                if (zoning.properties[`lc10_srce`] === null ||
                  zoning.properties[`lc10_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc10_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc10_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 40:
              if (
                zoning.properties[`lc10_titl`] &&
                zoning.properties[`lc10_valu`] !== null &&
                zoning.properties[`lc10_valu`]?.trim() !== "" &&
                zoning.properties["lc10_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 11
            case 41:
              if (
                zoning.properties[`lc11_titl`] &&
                zoning.properties[`lc11_valu`] !== null &&
                zoning.properties[`lc11_valu`]?.trim() !== "" &&
                zoning.properties["lc11_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc11_titl`] + ":";
                item.title = zoning.properties[`lc11_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 42:
              if (
                zoning.properties[`lc11_titl`] &&
                zoning.properties[`lc11_valu`] !== null &&
                zoning.properties[`lc11_valu`]?.trim() !== "" &&
                zoning.properties["lc11_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc11_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 43:
              if (
                zoning.properties[`lc11_titl`] &&
                zoning.properties[`lc11_valu`] !== null &&
                zoning.properties[`lc11_valu`]?.trim() !== "" &&
                zoning.properties["lc11_valu"] !== ","
              ) {
                if (zoning.properties[`lc11_srce`] === null ||
                  zoning.properties[`lc11_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc11_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc11_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 44:
              if (
                zoning.properties[`lc11_titl`] &&
                zoning.properties[`lc11_valu`] !== null &&
                zoning.properties[`lc11_valu`]?.trim() !== "" &&
                zoning.properties["lc11_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 12
            case 45:
              if (
                zoning.properties[`lc12_titl`] &&
                zoning.properties[`lc12_valu`] !== null &&
                zoning.properties[`lc12_valu`]?.trim() !== "" &&
                zoning.properties["lc12_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc12_titl`] + ":";
                item.title = zoning.properties[`lc12_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 46:
              if (
                zoning.properties[`lc12_titl`] &&
                zoning.properties[`lc12_valu`] !== null &&
                zoning.properties[`lc12_valu`]?.trim() !== "" &&
                zoning.properties["lc12_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc12_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 47:
              if (
                zoning.properties[`lc12_titl`] &&
                zoning.properties[`lc12_valu`] !== null &&
                zoning.properties[`lc12_valu`]?.trim() !== "" &&
                zoning.properties["lc12_valu"] !== ","
              ) {
                if (zoning.properties[`lc12_srce`] === null ||
                  zoning.properties[`lc12_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc12_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc12_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 48:
              if (
                zoning.properties[`lc12_titl`] &&
                zoning.properties[`lc12_valu`] !== null &&
                zoning.properties[`lc12_valu`]?.trim() !== "" &&
                zoning.properties["lc12_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 13
            case 49:
              if (
                zoning.properties[`lc13_titl`] &&
                zoning.properties[`lc13_valu`] !== null &&
                zoning.properties[`lc13_valu`]?.trim() !== "" &&
                zoning.properties["lc13_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc13_titl`] + ":";
                item.title = zoning.properties[`lc13_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 50:
              if (
                zoning.properties[`lc13_titl`] &&
                zoning.properties[`lc13_valu`] !== null &&
                zoning.properties[`lc13_valu`]?.trim() !== "" &&
                zoning.properties["lc13_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc13_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 51:
              if (
                zoning.properties[`lc13_titl`] &&
                zoning.properties[`lc13_valu`] !== null &&
                zoning.properties[`lc13_valu`]?.trim() !== "" &&
                zoning.properties["lc13_valu"] !== ","
              ) {
                if (zoning.properties[`lc13_srce`] === null ||
                  zoning.properties[`lc13_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc13_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc13_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 52:
              if (
                zoning.properties[`lc13_titl`] &&
                zoning.properties[`lc13_valu`] !== null &&
                zoning.properties[`lc13_valu`]?.trim() !== "" &&
                zoning.properties["lc13_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 14
            case 53:
              if (
                zoning.properties[`lc14_titl`] &&
                zoning.properties[`lc14_valu`] !== null &&
                zoning.properties[`lc14_valu`]?.trim() !== "" &&
                zoning.properties["lc14_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc14_titl`] + ":";
                item.title = zoning.properties[`lc14_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 54:
              if (
                zoning.properties[`lc14_titl`] &&
                zoning.properties[`lc14_valu`] !== null &&
                zoning.properties[`lc14_valu`]?.trim() !== "" &&
                zoning.properties["lc14_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc14_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 55:
              if (
                zoning.properties[`lc14_titl`] &&
                zoning.properties[`lc14_valu`] !== null &&
                zoning.properties[`lc14_valu`]?.trim() !== "" &&
                zoning.properties["lc14_valu"] !== ","
              ) {
                if (zoning.properties[`lc14_srce`] === null ||
                  zoning.properties[`lc14_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc14_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc14_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 56:
              if (
                zoning.properties[`lc14_titl`] &&
                zoning.properties[`lc14_valu`] !== null &&
                zoning.properties[`lc14_valu`]?.trim() !== "" &&
                zoning.properties["lc14_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 15
            case 57:
              if (
                zoning.properties[`lc15_titl`] &&
                zoning.properties[`lc15_valu`] !== null &&
                zoning.properties[`lc15_valu`]?.trim() !== "" &&
                zoning.properties["lc15_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc15_titl`] + ":";
                item.title = zoning.properties[`lc15_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 58:
              if (
                zoning.properties[`lc15_titl`] &&
                zoning.properties[`lc15_valu`] !== null &&
                zoning.properties[`lc15_valu`]?.trim() !== "" &&
                zoning.properties["lc15_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc15_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 59:
              if (
                zoning.properties[`lc15_titl`] &&
                zoning.properties[`lc15_valu`] !== null &&
                zoning.properties[`lc15_valu`]?.trim() !== "" &&
                zoning.properties["lc15_valu"] !== ","
              ) {
                if (zoning.properties[`lc15_srce`] === null ||
                  zoning.properties[`lc15_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc15_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc15_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 60:
              if (
                zoning.properties[`lc15_titl`] &&
                zoning.properties[`lc15_valu`] !== null &&
                zoning.properties[`lc15_valu`]?.trim() !== "" &&
                zoning.properties["lc15_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 16
            case 61:
              if (
                zoning.properties[`lc16_titl`] &&
                zoning.properties[`lc16_valu`] !== null &&
                zoning.properties[`lc16_valu`]?.trim() !== "" &&
                zoning.properties["lc16_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc16_titl`] + ":";
                item.title = zoning.properties[`lc16_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 62:
              if (
                zoning.properties[`lc16_titl`] &&
                zoning.properties[`lc16_valu`] !== null &&
                zoning.properties[`lc16_valu`]?.trim() !== "" &&
                zoning.properties["lc16_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc16_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 63:
              if (
                zoning.properties[`lc16_titl`] &&
                zoning.properties[`lc16_valu`] !== null &&
                zoning.properties[`lc16_valu`]?.trim() !== "" &&
                zoning.properties["lc16_valu"] !== ","
              ) {
                if (zoning.properties[`lc16_srce`] === null ||
                  zoning.properties[`lc16_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc16_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc16_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 64:
              if (
                zoning.properties[`lc16_titl`] &&
                zoning.properties[`lc16_valu`] !== null &&
                zoning.properties[`lc16_valu`]?.trim() !== "" &&
                zoning.properties["lc16_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 17
            case 65:
              if (
                zoning.properties[`lc17_titl`] &&
                zoning.properties[`lc17_valu`] !== null &&
                zoning.properties[`lc17_valu`]?.trim() !== "" &&
                zoning.properties["lc17_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc17_titl`] + ":";
                item.title = zoning.properties[`lc17_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 66:
              if (
                zoning.properties[`lc17_titl`] &&
                zoning.properties[`lc17_valu`] !== null &&
                zoning.properties[`lc17_valu`]?.trim() !== "" &&
                zoning.properties["lc17_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc17_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 67:
              if (
                zoning.properties[`lc17_titl`] &&
                zoning.properties[`lc17_valu`] !== null &&
                zoning.properties[`lc17_valu`]?.trim() !== "" &&
                zoning.properties["lc17_valu"] !== ","
              ) {
                if (zoning.properties[`lc17_srce`] === null ||
                  zoning.properties[`lc17_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc17_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc17_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 68:
              if (
                zoning.properties[`lc17_titl`] &&
                zoning.properties[`lc17_valu`] !== null &&
                zoning.properties[`lc17_valu`]?.trim() !== "" &&
                zoning.properties["lc17_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 18
            case 69:
              if (
                zoning.properties[`lc18_titl`] &&
                zoning.properties[`lc18_valu`] !== null &&
                zoning.properties[`lc18_valu`]?.trim() !== "" &&
                zoning.properties["lc18_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc18_titl`] + ":";
                item.title = zoning.properties[`lc18_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 70:
              if (
                zoning.properties[`lc18_titl`] &&
                zoning.properties[`lc18_valu`] !== null &&
                zoning.properties[`lc18_valu`]?.trim() !== "" &&
                zoning.properties["lc18_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc18_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 71:
              if (
                zoning.properties[`lc18_titl`] &&
                zoning.properties[`lc18_valu`] !== null &&
                zoning.properties[`lc18_valu`]?.trim() !== "" &&
                zoning.properties["lc18_valu"] !== ","
              ) {
                if (zoning.properties[`lc18_srce`] === null ||
                  zoning.properties[`lc18_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc18_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc18_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 72:
              if (
                zoning.properties[`lc18_titl`] &&
                zoning.properties[`lc18_valu`] !== null &&
                zoning.properties[`lc18_valu`]?.trim() !== "" &&
                zoning.properties["lc18_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 19
            case 73:
              if (
                zoning.properties[`lc19_titl`] &&
                zoning.properties[`lc19_valu`] !== null &&
                zoning.properties[`lc19_valu`]?.trim() !== "" &&
                zoning.properties["lc19_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc19_titl`] + ":";
                item.title = zoning.properties[`lc19_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 74:
              if (
                zoning.properties[`lc19_titl`] &&
                zoning.properties[`lc19_valu`] !== null &&
                zoning.properties[`lc19_valu`]?.trim() !== "" &&
                zoning.properties["lc19_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc19_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 75:
              if (
                zoning.properties[`lc19_titl`] &&
                zoning.properties[`lc19_valu`] !== null &&
                zoning.properties[`lc19_valu`]?.trim() !== "" &&
                zoning.properties["lc19_valu"] !== ","
              ) {
                if (zoning.properties[`lc19_srce`] === null ||
                  zoning.properties[`lc19_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc19_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc19_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 76:
              if (
                zoning.properties[`lc19_titl`] &&
                zoning.properties[`lc19_valu`] !== null &&
                zoning.properties[`lc19_valu`]?.trim() !== "" &&
                zoning.properties["lc19_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 20
            case 77:
              if (
                zoning.properties[`lc20_titl`] &&
                zoning.properties[`lc20_valu`] !== null &&
                zoning.properties[`lc20_valu`]?.trim() !== "" &&
                zoning.properties["lc20_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc20_titl`] + ":";
                item.title = zoning.properties[`lc20_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 78:
              if (
                zoning.properties[`lc20_titl`] &&
                zoning.properties[`lc20_valu`] !== null &&
                zoning.properties[`lc20_valu`]?.trim() !== "" &&
                zoning.properties["lc20_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc20_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 79:
              if (
                zoning.properties[`lc20_titl`] &&
                zoning.properties[`lc20_valu`] !== null &&
                zoning.properties[`lc20_valu`]?.trim() !== "" &&
                zoning.properties["lc20_valu"] !== ","
              ) {
                if (zoning.properties[`lc20_srce`] === null ||
                  zoning.properties[`lc20_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc20_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc20_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 80:
              if (
                zoning.properties[`lc20_titl`] &&
                zoning.properties[`lc20_valu`] !== null &&
                zoning.properties[`lc20_valu`]?.trim() !== "" &&
                zoning.properties["lc20_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 21
            case 81:
              if (
                zoning.properties[`lc21_titl`] &&
                zoning.properties[`lc21_valu`] !== null &&
                zoning.properties[`lc21_valu`]?.trim() !== "" &&
                zoning.properties["lc21_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc21_titl`] + ":";
                item.title = zoning.properties[`lc21_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 82:
              if (
                zoning.properties[`lc21_titl`] &&
                zoning.properties[`lc21_valu`] !== null &&
                zoning.properties[`lc21_valu`]?.trim() !== "" &&
                zoning.properties["lc21_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc21_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 83:
              if (
                zoning.properties[`lc21_titl`] &&
                zoning.properties[`lc21_valu`] !== null &&
                zoning.properties[`lc21_valu`]?.trim() !== "" &&
                zoning.properties["lc21_valu"] !== ","
              ) {
                if (zoning.properties[`lc21_srce`] === null ||
                  zoning.properties[`lc21_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc21_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc21_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 84:
              if (
                zoning.properties[`lc21_titl`] &&
                zoning.properties[`lc21_valu`] !== null &&
                zoning.properties[`lc21_valu`]?.trim() !== "" &&
                zoning.properties["lc21_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 22
            case 85:
              if (
                zoning.properties[`lc22_titl`] &&
                zoning.properties[`lc22_valu`] !== null &&
                zoning.properties[`lc22_valu`]?.trim() !== "" &&
                zoning.properties["lc22_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc22_titl`] + ":";
                item.title = zoning.properties[`lc22_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 86:
              if (
                zoning.properties[`lc22_titl`] &&
                zoning.properties[`lc22_valu`] !== null &&
                zoning.properties[`lc22_valu`]?.trim() !== "" &&
                zoning.properties["lc22_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc22_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 87:
              if (
                zoning.properties[`lc22_titl`] &&
                zoning.properties[`lc22_valu`] !== null &&
                zoning.properties[`lc22_valu`]?.trim() !== "" &&
                zoning.properties["lc22_valu"] !== ","
              ) {
                if (zoning.properties[`lc22_srce`] === null ||
                  zoning.properties[`lc22_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc22_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc22_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 88:
              if (
                zoning.properties[`lc22_titl`] &&
                zoning.properties[`lc22_valu`] !== null &&
                zoning.properties[`lc22_valu`]?.trim() !== "" &&
                zoning.properties["lc22_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 23
            case 89:
              if (
                zoning.properties[`lc23_titl`] &&
                zoning.properties[`lc23_valu`] !== null &&
                zoning.properties[`lc23_valu`]?.trim() !== "" &&
                zoning.properties["lc23_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc23_titl`] + ":";
                item.title = zoning.properties[`lc23_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 90:
              if (
                zoning.properties[`lc23_titl`] &&
                zoning.properties[`lc23_valu`] !== null &&
                zoning.properties[`lc23_valu`]?.trim() !== "" &&
                zoning.properties["lc23_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc23_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 91:
              if (
                zoning.properties[`lc23_titl`] &&
                zoning.properties[`lc23_valu`] !== null &&
                zoning.properties[`lc23_valu`]?.trim() !== "" &&
                zoning.properties["lc23_valu"] !== ","
              ) {
                if (zoning.properties[`lc23_srce`] === null ||
                  zoning.properties[`lc23_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc23_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc23_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 92:
              if (
                zoning.properties[`lc23_titl`] &&
                zoning.properties[`lc23_valu`] !== null &&
                zoning.properties[`lc23_valu`]?.trim() !== "" &&
                zoning.properties["lc23_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 24
            case 93:
              if (
                zoning.properties[`lc24_titl`] &&
                zoning.properties[`lc24_valu`] !== null &&
                zoning.properties[`lc24_valu`]?.trim() !== "" &&
                zoning.properties["lc24_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc24_titl`] + ":";
                item.title = zoning.properties[`lc24_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 94:
              if (
                zoning.properties[`lc24_titl`] &&
                zoning.properties[`lc24_valu`] !== null &&
                zoning.properties[`lc24_valu`]?.trim() !== "" &&
                zoning.properties["lc24_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc24_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 95:
              if (
                zoning.properties[`lc24_titl`] &&
                zoning.properties[`lc24_valu`] !== null &&
                zoning.properties[`lc24_valu`]?.trim() !== "" &&
                zoning.properties["lc24_valu"] !== ","
              ) {
                if (zoning.properties[`lc24_srce`] === null ||
                  zoning.properties[`lc24_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc24_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc24_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 96:
              if (
                zoning.properties[`lc24_titl`] &&
                zoning.properties[`lc24_valu`] !== null &&
                zoning.properties[`lc24_valu`]?.trim() !== "" &&
                zoning.properties["lc24_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 25
            case 97:
              if (
                zoning.properties[`lc25_titl`] &&
                zoning.properties[`lc25_valu`] !== null &&
                zoning.properties[`lc25_valu`]?.trim() !== "" &&
                zoning.properties["lc25_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc25_titl`] + ":";
                item.title = zoning.properties[`lc25_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 98:
              if (
                zoning.properties[`lc25_titl`] &&
                zoning.properties[`lc25_valu`] !== null &&
                zoning.properties[`lc25_valu`]?.trim() !== "" &&
                zoning.properties["lc25_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc25_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 99:
              if (
                zoning.properties[`lc25_titl`] &&
                zoning.properties[`lc25_valu`] !== null &&
                zoning.properties[`lc25_valu`]?.trim() !== "" &&
                zoning.properties["lc25_valu"] !== ","
              ) {
                if (zoning.properties[`lc25_srce`] === null ||
                  zoning.properties[`lc25_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc25_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc25_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 100:
              if (
                zoning.properties[`lc25_titl`] &&
                zoning.properties[`lc25_valu`] !== null &&
                zoning.properties[`lc25_valu`]?.trim() !== "" &&
                zoning.properties["lc25_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 26
            case 101:
              if (
                zoning.properties[`lc26_titl`] &&
                zoning.properties[`lc26_valu`] !== null &&
                zoning.properties[`lc26_valu`]?.trim() !== "" &&
                zoning.properties["lc26_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc26_titl`] + ":";
                item.title = zoning.properties[`lc26_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 102:
              if (
                zoning.properties[`lc26_titl`] &&
                zoning.properties[`lc26_valu`] !== null &&
                zoning.properties[`lc26_valu`]?.trim() !== "" &&
                zoning.properties["lc26_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc26_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 103:
              if (
                zoning.properties[`lc26_titl`] &&
                zoning.properties[`lc26_valu`] !== null &&
                zoning.properties[`lc26_valu`]?.trim() !== "" &&
                zoning.properties["lc26_valu"] !== ","
              ) {
                if (zoning.properties[`lc26_srce`] === null ||
                  zoning.properties[`lc26_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc26_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc26_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 104:
              if (
                zoning.properties[`lc26_titl`] &&
                zoning.properties[`lc26_valu`] !== null &&
                zoning.properties[`lc26_valu`]?.trim() !== "" &&
                zoning.properties["lc26_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 27
            case 105:
              if (
                zoning.properties[`lc27_titl`] &&
                zoning.properties[`lc27_valu`] !== null &&
                zoning.properties[`lc27_valu`]?.trim() !== "" &&
                zoning.properties["lc27_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc27_titl`] + ":";
                item.title = zoning.properties[`lc27_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 106:
              if (
                zoning.properties[`lc27_titl`] &&
                zoning.properties[`lc27_valu`] !== null &&
                zoning.properties[`lc27_valu`]?.trim() !== "" &&
                zoning.properties["lc27_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc27_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 107:
              if (
                zoning.properties[`lc27_titl`] &&
                zoning.properties[`lc27_valu`] !== null &&
                zoning.properties[`lc27_valu`]?.trim() !== "" &&
                zoning.properties["lc27_valu"] !== ","
              ) {
                if (zoning.properties[`lc27_srce`] === null ||
                  zoning.properties[`lc27_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc27_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc27_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 108:
              if (
                zoning.properties[`lc27_titl`] &&
                zoning.properties[`lc27_valu`] !== null &&
                zoning.properties[`lc27_valu`]?.trim() !== "" &&
                zoning.properties["lc27_valu"] !== ","
              ) {
                item.sourceHr = true;
              }
              else {
                item.display = false
              }
              break;
            // 28
            case 109:
              if (
                zoning.properties[`lc28_titl`] &&
                zoning.properties[`lc28_valu`] !== null &&
                zoning.properties[`lc28_valu`]?.trim() !== "" &&
                zoning.properties["lc28_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc28_titl`] + ":";
                item.title = zoning.properties[`lc28_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 110:
              if (
                zoning.properties[`lc28_titl`] &&
                zoning.properties[`lc28_valu`] !== null &&
                zoning.properties[`lc28_valu`]?.trim() !== "" &&
                zoning.properties["lc28_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc28_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 111:
              if (
                zoning.properties[`lc28_titl`] &&
                zoning.properties[`lc28_valu`] !== null &&
                zoning.properties[`lc28_valu`]?.trim() !== "" &&
                zoning.properties["lc28_valu"] !== ","
              ) {
                if (zoning.properties[`lc28_srce`] === null ||
                  zoning.properties[`lc28_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc28_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc28_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 112:
              if (
                zoning.properties[`lc28_titl`] &&
                zoning.properties[`lc28_valu`] !== null &&
                zoning.properties[`lc28_valu`]?.trim() !== "" &&
                zoning.properties["lc28_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 29
            case 113:
              if (
                zoning.properties[`lc29_titl`] &&
                zoning.properties[`lc29_valu`] !== null &&
                zoning.properties[`lc29_valu`]?.trim() !== "" &&
                zoning.properties["lc29_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc29_titl`] + ":";
                item.title = zoning.properties[`lc29_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 114:
              if (
                zoning.properties[`lc29_titl`] &&
                zoning.properties[`lc29_valu`] !== null &&
                zoning.properties[`lc29_valu`]?.trim() !== "" &&
                zoning.properties["lc29_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc29_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 115:
              if (
                zoning.properties[`lc29_titl`] &&
                zoning.properties[`lc29_valu`] !== null &&
                zoning.properties[`lc29_valu`]?.trim() !== "" &&
                zoning.properties["lc29_valu"] !== ","
              ) {
                if (zoning.properties[`lc29_srce`] === null ||
                  zoning.properties[`lc29_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc29_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc29_link`];
                }
              }
              else {
                item.display = false
              }
              break;
            case 116:
              if (
                zoning.properties[`lc29_titl`] &&
                zoning.properties[`lc29_valu`] !== null &&
                zoning.properties[`lc29_valu`]?.trim() !== "" &&
                zoning.properties["lc29_valu"] !== ","
              ) {
                item.sourceHr = true
              }
              else {
                item.display = false
              }
              break;
            // 30
            case 117:
              if (
                zoning.properties[`lc30_titl`] &&
                zoning.properties[`lc30_valu`] !== null &&
                zoning.properties[`lc30_valu`]?.trim() !== "" &&
                zoning.properties["lc30_valu"] !== ","
              ) {
                item.small_heading = zoning.properties[`lc30_titl`] + ":";
                item.title = zoning.properties[`lc30_valu`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 118:
              if (
                zoning.properties[`lc30_titl`] &&
                zoning.properties[`lc30_valu`] !== null &&
                zoning.properties[`lc30_valu`]?.trim() !== "" &&
                zoning.properties["lc30_valu"] !== ","
              ) {
                item.title = zoning.properties[`lc30_cmmt`] ?? "N/A";
              }
              else {
                item.display = false
              }
              break;
            case 119:
              if (
                zoning.properties[`lc30_titl`] &&
                zoning.properties[`lc30_valu`] !== null &&
                zoning.properties[`lc30_valu`]?.trim() !== "" &&
                zoning.properties["lc30_valu"] !== ","
              ) {
                if (zoning.properties[`lc30_srce`] === null ||
                  zoning.properties[`lc30_link`] === null) {
                  item.display = false
                }
                else {
                  item.source_p = zoning.properties[`lc30_srce`] ?? "N/A";
                  item.link = zoning.properties[`lc30_link`];
                }
              }
              else {
                item.display = false
              }
              break;

          }



        });
        break;

    }
  });
  constantResponse.lot_info.map(function (item, key) {
    let { lot_info } = apiResponse;
    switch (item.id) {
      case "lot_size":
        lot_info = lot_info[0];
        item.title = lot_info.title;
        item.subTitle = lot_info.description;
        if (lot_info.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              if (lot_info.properties.area_sqft !== null) {
                item.title = lot_info?.properties?.area_sqft?Number(lot_info?.properties.area_sqft)?.toLocaleString() + " square feet":"N/A";
              }
              break;
            case 2:
              if (lot_info.properties.area_acre !== null) {
                item.title = Math.round(lot_info.properties.area_acre * 100) / 100 .toLocaleString() + " acres";
              }
              break;
            case 3:
              if (lot_info.properties.lot_wide_feet !== null) {
                item.title = Number(lot_info?.properties?.lot_wide_feet)?.toLocaleString() + " feet";
              }
              break;
            case 4:
              if (lot_info.properties.lot_deep_feet !== null) {
                item.title = Number(lot_info?.properties?.lot_deep_feet)?.toLocaleString() + " feet";
              }

              break;
            case 5:
              if (lot_info.properties.lot_strt_feet !== null) {
                item.title = Number(lot_info?.properties?.lot_strt_feet)?.toLocaleString() + " feet";
              }

              break;
          }
        });
        break;
      case "lot_type":
        lot_info = lot_info[1];
        item.title = lot_info.title;
        item.subTitle = lot_info.description;
        if (lot_info.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = lot_info.properties.lot_type ?? "N/A";
              break;
            case 2:
              item.title = lot_info.properties.lot_alley ?? "N/A";
              break;

          }
        });
        break;
    }
  });
  constantResponse.use_regulation.map(function (item, key) {
    let { use_regulations } = apiResponse;
    let getAllValues = [];

    use_regulations.map((val) => {
      if (val?.properties) getAllValues.push(val.properties);
    });
    let singleObj = new Map();
    getAllValues.map((obj) => {
      Object.entries(obj).map(([index, value]) => {
        singleObj.set(index, value);
      });
    });
    const useRegulationAllowed = Array.from(singleObj).reduce(
      (obj, [key, value]) => Object.assign(obj, { [key]: value }),
      {}
    );

    switch (item.id) {
      // case "no_data":
      //   const filteredUseRegulations = use_regulations.map(item => item.title !== null && item.title !== "");

      //   if (filteredUseRegulations.includes(false)) {
      //     item.properties.map(function (item, key) {
      //       switch (item.id) {
      //         case 1:
      //           item.title = "No data"
      //           break;
      //       }
      //     })
      //   }
      //   else {
      //     item.hidden = true
      //   }
      //   break;
      case "checkbox":
        // const filteredUse = use_regulations.map(item => item.description !== null && item.description !== "");
        // if (filteredUse.includes(true)) {
        //   item.properties.map(function (item, key) {
        //     switch (item.id) {
        //       case 1:
        //         item.display = true
        //         break;
        //       case 2:
        //         item.display = true
        //         break;
        //     }
        //   })
        // }
        // else {
        //   item.hidden = true
        // }

        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.display = true
              break;
            case 2:
              item.display = true
              break;
          }
        })

        break;

      case "residential":
        use_regulations = use_regulations[0];
        // item.title = use_regulations.description;
        item.title = "Residential"
        item.subTitle = use_regulations.description;
        // if (use_regulations.description === "")
        //   item.hidden = true;
        if (use_regulations.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title_desc_inline =
                use_regulations.properties.use_a_01 ?? "N/A";
              break;
            case 2:
              item.title_desc_inline =
                use_regulations.properties.use_a_02 ?? "N/A";
              break;
            case 3:
              item.title_desc_inline =
                use_regulations.properties.use_a_03 ?? "N/A";
              break;
            case 4:
              item.title_desc_inline =
                use_regulations.properties.use_a_04 ?? "N/A";
              break;
            case 5:
              item.title_desc_inline =
                use_regulations.properties.use_a_05 ?? "N/A";
              break;
            case 6:
              item.title_desc_inline =
                use_regulations.properties.use_a_06 ?? "N/A";
              break;
            case 7:
              item.title_desc_inline =
                use_regulations.properties.use_a_07 ?? "N/A";
              break;
            case 8:
              item.title_desc_inline =
                use_regulations.properties.use_a_08 ?? "N/A";
              break;
            case 9:
              item.title_desc_inline =
                use_regulations.properties.use_a_09 ?? "N/A";
              break;
            case 10:
              item.title_desc_inline =
                use_regulations.properties.use_a_10 ?? "N/A";
              break; 
            case 11:
              item.source_p =
                use_regulations.properties.use_srce != null
                  ? use_regulations.properties.use_srce
                  : "N/A";
              item.link = use_regulations.properties.use_link;
              break;
          }
        });
        break;
      case "commercial":
        use_regulations = use_regulations[1];
        // item.title = use_regulations.description;
        item.title = "Commercial"
        item.subTitle = use_regulations.description;
        // if (use_regulations.description === "")
        //   item.hidden = true
        if (use_regulations.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title_desc_inline =
                use_regulations.properties.use_b_01 ?? "N/A";
              break;
            case 2:
              item.title_desc_inline =
                use_regulations.properties.use_b_02 ?? "N/A";
              break;
            case 3:
              item.title_desc_inline =
                use_regulations.properties.use_b_03 ?? "N/A";
              break;
            case 4:
              item.title_desc_inline =
                use_regulations.properties.use_b_04 ?? "N/A";
              break;
            case 5:
              item.title_desc_inline =
                use_regulations.properties.use_b_05 ?? "N/A";
              break;
            case 6:
              item.title_desc_inline =
                use_regulations.properties.use_b_06 ?? "N/A";
              break;
            case 7:
              item.title_desc_inline =
                use_regulations.properties.use_b_07 ?? "N/A";
              break;
            case 8:
              item.title_desc_inline =
                use_regulations.properties.use_b_08 ?? "N/A";
              break;
            case 9:
              item.title_desc_inline =
                use_regulations.properties.use_b_09 ?? "N/A";
              break;
            case 10:
              item.title_desc_inline =
                use_regulations.properties.use_b_10 ?? "N/A";
              break;
            case 11:
              item.title_desc_inline =
                use_regulations.properties.use_b_11 ?? "N/A";
              break;
            case 12:
              item.title_desc_inline =
                use_regulations.properties.use_b_12 ?? "N/A";
              break;
            case 13:
              item.title_desc_inline =
                use_regulations.properties.use_b_13 ?? "N/A";
              break;
            case 14:
              item.title_desc_inline =
                use_regulations.properties.use_b_14 ?? "N/A";
              break;
            case 15:
              item.title_desc_inline =
                use_regulations.properties.use_b_15 ?? "N/A";
              break;
            case 16:
              item.title_desc_inline =
                use_regulations.properties.use_b_16 ?? "N/A";
              break;
            case 17:
              item.title_desc_inline =
                use_regulations.properties.use_b_17 ?? "N/A";
              break;
            case 18:
              item.title_desc_inline =
                use_regulations.properties.use_b_18 ?? "N/A";
              break;
            case 19:
              item.title_desc_inline =
                use_regulations.properties.use_b_19 ?? "N/A";
              break;
            case 20:
              item.title_desc_inline =
                use_regulations.properties.use_b_20 ?? "N/A";
              break;
            case 21:
              item.title_desc_inline =
                use_regulations.properties.use_b_21 ?? "N/A";
              break;
            case 22:
              item.title_desc_inline =
                use_regulations.properties.use_b_22 ?? "N/A";
              break;
            case 23:
              item.title_desc_inline =
                use_regulations.properties.use_b_23 ?? "N/A";
              break;
            case 24:
              item.title_desc_inline =
                use_regulations.properties.use_b_24 ?? "N/A";
              break;
            case 25:
              item.title_desc_inline =
                use_regulations.properties.use_b_25 ?? "N/A";
              break;
            case 26:
              item.title_desc_inline =
                use_regulations.properties.use_b_26 ?? "N/A";
              break;
            case 27:
              item.title_desc_inline =
                use_regulations.properties.use_b_27 ?? "N/A";
              break;
            case 28:
              item.title_desc_inline =
                use_regulations.properties.use_b_28 ?? "N/A";
              break;
            case 29:
              item.title_desc_inline =
                use_regulations.properties.use_b_29 ?? "N/A";
              break;
            case 30:
              item.title_desc_inline =
                use_regulations.properties.use_b_30 ?? "N/A";
              break;
            case 31:
              item.title_desc_inline =
                use_regulations.properties.use_b_31 ?? "N/A";
              break;
            case 32:
              item.title_desc_inline =
                use_regulations.properties.use_b_32 ?? "N/A";
              break;
            case 33:
              item.title_desc_inline =
                use_regulations.properties.use_b_33 ?? "N/A";
              break;
            case 34:
              item.title_desc_inline =
                use_regulations.properties.use_b_34 ?? "N/A";
              break;
            case 35:
              item.title_desc_inline =
                use_regulations.properties.use_b_35 ?? "N/A";
              break;
            case 36:
              item.title_desc_inline =
                use_regulations.properties.use_b_36 ?? "N/A";
              break;
            case 37:
              item.title_desc_inline =
                use_regulations.properties.use_b_37 ?? "N/A";
              break;
            case 38:
              item.title_desc_inline =
                use_regulations.properties.use_b_38 ?? "N/A";
              break;
            case 39:
              item.title_desc_inline =
                use_regulations.properties.use_b_39 ?? "N/A";
              break;
            case 40:
              item.title_desc_inline =
                use_regulations.properties.use_b_40 ?? "N/A";
              break;
            case 41:
              item.title_desc_inline =
                use_regulations.properties.use_b_41 ?? "N/A";
              break;
            case 42:
              item.title_desc_inline =
                use_regulations.properties.use_b_42 ?? "N/A";
              break;
            case 43:
              item.title_desc_inline =
                use_regulations.properties.use_b_43 ?? "N/A";
              break;
            case 44:
              item.title_desc_inline =
                use_regulations.properties.use_b_44 ?? "N/A";
              break;
            case 45:
              item.title_desc_inline =
                use_regulations.properties.use_b_45 ?? "N/A";
              break;
            case 46:
              item.title_desc_inline =
                use_regulations.properties.use_b_46 ?? "N/A";
              break;
            case 47:
              item.title_desc_inline =
                use_regulations.properties.use_b_47 ?? "N/A";
              break;
            case 48:
              item.title_desc_inline =
                use_regulations.properties.use_b_48 ?? "N/A";
              break;
            case 49:
              item.title_desc_inline =
                use_regulations.properties.use_b_49 ?? "N/A";
              break;
            case 50:
              item.title_desc_inline =
                use_regulations.properties.use_b_50 ?? "N/A";
              break;
            case 51:
              item.title_desc_inline =
                use_regulations.properties.use_b_51 ?? "N/A";
              break; 
            case 52:
              item.source_p =
                use_regulations.properties.use_srce != null
                  ? use_regulations.properties.use_srce
                  : "N/A";
              item.link = use_regulations.properties.use_link;
              break;
          }
        });
        break;
      case "industrial":
        use_regulations = use_regulations[2];
        // item.title = use_regulations.description;
        item.title = "Industrial"
        item.subTitle = use_regulations.description;
        // if (use_regulations.description === "")
        //   item.hidden = true
        if (use_regulations.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              if(use_regulations.properties.use_c_01?.trim() !== "" )
              {
                item.title_desc_inline =
                use_regulations.properties.use_c_01 ?? "N/A";
              }
              else{
                item.title_desc_inline =  "N/A";
              }
             
              break;
            case 2:
              if(use_regulations.properties.use_c_02?.trim() !== "" )
              {
                item.title_desc_inline =
                use_regulations.properties.use_c_02 ?? "N/A";
              } 
                else{
                  item.title_desc_inline =  "N/A";
                }
              break;
            case 3:
              if(use_regulations.properties.use_c_03?.trim() !== "" )
              {
                item.title_desc_inline =
                use_regulations.properties.use_c_03 ?? "N/A";
              }
             
                else{
                  item.title_desc_inline =  "N/A";
                }
              break;
            case 4:
              if(use_regulations.properties.use_c_04?.trim() !== "" )
              {
                item.title_desc_inline =
                use_regulations.properties.use_c_04 ?? "N/A";
              } 
                else{
                  item.title_desc_inline =  "N/A";
                }
              break;
            case 5:
              if(use_regulations.properties.use_c_05?.trim() !== "" )
              {
                item.title_desc_inline =
                use_regulations.properties.use_c_05 ?? "N/A";
              } 
                else{
                  item.title_desc_inline =  "N/A";
                }
              break;
            case 6:
              if(use_regulations.properties.use_c_06?.trim() !== "" )
              {
                item.title_desc_inline =
                use_regulations.properties.use_c_06 ?? "N/A";
              } 
                else{
                  item.title_desc_inline =  "N/A";
                }
              break;
            case 7:
              if(use_regulations.properties.use_c_07?.trim() !== "" )
              {
                item.title_desc_inline =
                use_regulations.properties.use_c_07 ?? "N/A";
              } 
                else{
                  item.title_desc_inline =  "N/A";
                }
              break;
            case 8:
              if(use_regulations.properties.use_c_08?.trim() !== "" )
              {
                item.title_desc_inline =
                use_regulations.properties.use_c_08 ?? "N/A";
              } 
                else{
                  item.title_desc_inline =  "N/A";
                }
              break;
              case 9:
                if(use_regulations.properties.use_c_09?.trim() !== "" )
                {
                  item.title_desc_inline =
                  use_regulations.properties.use_c_09 ?? "N/A";
                } 
                  else{
                    item.title_desc_inline =  "N/A";
                  }
              break;
            case 10:
              item.title_desc_inline =
                use_regulations.properties.use_c_10 ?? "N/A";
              break;
            case 11:
              item.title_desc_inline =
                use_regulations.properties.use_c_11 ?? "N/A";
              break;
            case 12:
              item.title_desc_inline =
                use_regulations.properties.use_c_12 ?? "N/A";
              break;
            case 13:
              item.title_desc_inline =
                use_regulations.properties.use_c_13 ?? "N/A";
              break;
            case 14:
              item.title_desc_inline =
                use_regulations.properties.use_c_14 ?? "N/A";
              break;
            case 15:
              item.title_desc_inline =
                use_regulations.properties.use_c_15 ?? "N/A";
              break;
            case 16:
              item.title_desc_inline =
                use_regulations.properties.use_c_16 ?? "N/A";
              break;
              case 17:
                item.title_desc_inline =
                  use_regulations.properties.use_c_17 ?? "N/A";
                break;
              case 18:
                item.title_desc_inline =
                  use_regulations.properties.use_c_18 ?? "N/A";
                break;
              case 19:
                item.title_desc_inline =
                  use_regulations.properties.use_c_19 ?? "N/A";
                break;
              case 20:
                item.title_desc_inline =
                  use_regulations.properties.use_c_20 ?? "N/A";
                break; 
            case 21:
              item.source_p =
                use_regulations.properties.use_srce != null
                  ? use_regulations.properties.use_srce
                  : "N/A";
              item.link = use_regulations.properties.use_link;
              break;
          }
        });
        break;
      case "agriculture":
        use_regulations = use_regulations[3];
        // item.title = use_regulations.description;
        item.title = "Agriculture"
        item.subTitle = use_regulations.description;
        // if (use_regulations.description === "")
        //   item.hidden = true
        if (use_regulations.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title_desc_inline =
                use_regulations.properties.use_d_01 ?? "N/A";
              break;
            case 2:
              item.title_desc_inline =
                use_regulations.properties.use_d_02 ?? "N/A";
              break;
            case 3:
              item.title_desc_inline =
                use_regulations.properties.use_d_03 ?? "N/A";
              break;
            case 4:
              item.title_desc_inline =
                use_regulations.properties.use_d_04 ?? "N/A";
              break;
            case 5:
              item.title_desc_inline =
                use_regulations.properties.use_d_05 ?? "N/A";
              break;
            case 6:
              item.title_desc_inline =
                use_regulations.properties.use_d_06 ?? "N/A";
              break;
            case 7:
              item.title_desc_inline =
                use_regulations.properties.use_d_07 ?? "N/A";
              break;
            case 8:
              item.title_desc_inline =
                use_regulations.properties.use_d_08 ?? "N/A";
              break; 
            case 9:
              item.source_p =
                use_regulations.properties.use_srce != null
                  ? use_regulations.properties.use_srce
                  : "N/A";
              item.link = use_regulations.properties.use_link;
              break;
          }
        });
        break;
      case "transportation":
        use_regulations = use_regulations[4];
        // item.title = use_regulations.description;
        item.title = "Transportation";
        item.subTitle = use_regulations.description;
        // if (use_regulations.description === "")
        //   item.hidden = true
        if (use_regulations.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title_desc_inline =
                use_regulations.properties.use_e_01 ?? "N/A";
              break;
            case 2:
              item.title_desc_inline =
                use_regulations.properties.use_e_02 ?? "N/A";
              break;
            case 3:
              item.title_desc_inline =
                use_regulations.properties.use_e_03 ?? "N/A";
              break;
            case 4:
              item.title_desc_inline =
                use_regulations.properties.use_e_04 ?? "N/A";
              break;
            case 5:
              item.title_desc_inline =
                use_regulations.properties.use_e_05 ?? "N/A";
              break;
            case 6:
              item.title_desc_inline =
                use_regulations.properties.use_e_06 ?? "N/A";
              break;
            case 7:
              item.title_desc_inline =
                use_regulations.properties.use_e_07 ?? "N/A";
              break;
            case 8:
              item.title_desc_inline =
                use_regulations.properties.use_e_08 ?? "N/A";
              break;
            case 9:
              item.title_desc_inline =
                use_regulations.properties.use_e_09 ?? "N/A";
              break;
            case 10:
              item.title_desc_inline =
                use_regulations.properties.use_e_10 ?? "N/A";
              break;
            case 11:
              item.title_desc_inline =
                use_regulations.properties.use_e_11 ?? "N/A";
              break;
            case 12:
              item.title_desc_inline =
                use_regulations.properties.use_e_12 ?? "N/A";
              break;
            case 13:
              item.title_desc_inline =
                use_regulations.properties.use_e_13 ?? "N/A";
              break;
            case 14:
              item.title_desc_inline =
                use_regulations.properties.use_e_14 ?? "N/A";
              break;
            case 15:
              item.title_desc_inline =
                use_regulations.properties.use_e_15 ?? "N/A";
              break;
            case 16:
              item.title_desc_inline =
                use_regulations.properties.use_e_16 ?? "N/A";
              break;
            case 17:
              item.title_desc_inline =
                use_regulations.properties.use_e_17 ?? "N/A";
              break; 
            case 18:
              item.source_p = use_regulations.properties.use_srce != null
                ? use_regulations.properties.use_srce
                : "N/A";
              item.link = use_regulations.properties.use_link;
              break;
          }
        });
        break;

        case "utilities":
          use_regulations = use_regulations[4];
          // item.title = use_regulations.description;
          item.title = "utilities";
          item.subTitle = use_regulations.description;
          // if (use_regulations.description === "")
          //   item.hidden = true
          if (use_regulations.properties.locked) {
            item.lock = true;
            item.subTitle = "";
          } else {
            item.lock = false;
          }
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title_desc_inline =
                  use_regulations.properties.use_i_01 ?? "N/A";
                break;
              case 2:
                item.title_desc_inline =
                  use_regulations.properties.use_i_02 ?? "N/A";
                break;
              case 3:
                item.title_desc_inline =
                  use_regulations.properties.use_i_03 ?? "N/A";
                break;
              case 4:
                item.title_desc_inline =
                  use_regulations.properties.use_i_04 ?? "N/A";
                break;
              case 5:
                item.title_desc_inline =
                  use_regulations.properties.use_i_05 ?? "N/A";
                break;
              case 6:
                item.title_desc_inline =
                  use_regulations.properties.use_i_06 ?? "N/A";
                break;
              case 7:
                item.title_desc_inline =
                  use_regulations.properties.use_i_07 ?? "N/A";
                break; 
              case 8:
                item.source_p = use_regulations.properties.use_srce != null
                  ? use_regulations.properties.use_srce
                  : "N/A";
                item.link = use_regulations.properties.use_link;
                break;
            }
          });
          break;

          case "manufacturing":
            use_regulations = use_regulations[4];
            // item.title = use_regulations.description;
            item.title = "manufacturing";
            item.subTitle = use_regulations.description;
            // if (use_regulations.description === "")
            //   item.hidden = true
            if (use_regulations.properties.locked) {
              item.lock = true;
              item.subTitle = "";
            } else {
              item.lock = false;
            }
            item.properties.map(function (item, key) {
              switch (item.id) {
                case 1:
                  item.title_desc_inline =
                    use_regulations.properties.use_e_01 ?? "N/A";
                  break;
                case 2:
                  item.title_desc_inline =
                    use_regulations.properties.use_e_02 ?? "N/A";
                  break;
                case 3:
                  item.title_desc_inline =
                    use_regulations.properties.use_e_03 ?? "N/A";
                  break;
                case 4:
                  item.title_desc_inline =
                    use_regulations.properties.use_e_04 ?? "N/A";
                  break; 
                case 5:
                  item.source_p = use_regulations.properties.use_srce != null
                    ? use_regulations.properties.use_srce
                    : "N/A";
                  item.link = use_regulations.properties.use_link;
                  break;
              }
            });
            break;
  
            case "public":
              use_regulations = use_regulations[4];
              // item.title = use_regulations.description;
              item.title = "public";
              item.subTitle = use_regulations.description;
              // if (use_regulations.description === "")
              //   item.hidden = true
              if (use_regulations.properties.locked) {
                item.lock = true;
                item.subTitle = "";
              } else {
                item.lock = false;
              }
              item.properties.map(function (item, key) {
                switch (item.id) {
                  case 1:
                    item.title_desc_inline =
                      use_regulations.properties.use_f_01 ?? "N/A";
                    break;
                  case 2:
                    item.title_desc_inline =
                      use_regulations.properties.use_f_02 ?? "N/A";
                    break;
                  case 3:
                    item.title_desc_inline =
                      use_regulations.properties.use_f_03 ?? "N/A";
                    break; 
                  case 4:
                    item.source_p = use_regulations.properties.use_srce != null
                      ? use_regulations.properties.use_srce
                      : "N/A";
                    item.link = use_regulations.properties.use_link;
                    break;
                }
              });
              break;
    
              case "storage":
                use_regulations = use_regulations[4];
                // item.title = use_regulations.description;
                item.title = "storage";
                item.subTitle = use_regulations.description;
                // if (use_regulations.description === "")
                //   item.hidden = true
                if (use_regulations.properties.locked) {
                  item.lock = true;
                  item.subTitle = "";
                } else {
                  item.lock = false;
                }
                item.properties.map(function (item, key) {
                  switch (item.id) {
                    case 1:
                      item.title_desc_inline =
                        use_regulations.properties.use_g_01 ?? "N/A";
                      break;
                    case 2:
                      item.title_desc_inline =
                        use_regulations.properties.use_g_02 ?? "N/A";
                      break;
                    case 3:
                      item.title_desc_inline =
                        use_regulations.properties.use_g_03 ?? "N/A";
                      break; 
                    case 4:
                      item.source_p = use_regulations.properties.use_srce != null
                        ? use_regulations.properties.use_srce
                        : "N/A";
                      item.link = use_regulations.properties.use_link;
                      break;
                  }
                });
                break;
       
                case "misc":
                use_regulations = use_regulations[4];
                // item.title = use_regulations.description;
                item.title = "Miscellaneous";
                item.subTitle = use_regulations.description;
                // if (use_regulations.description === "")
                //   item.hidden = true
                if (use_regulations.properties.locked) {
                  item.lock = true;
                  item.subTitle = "";
                } else {
                  item.lock = false;
                }
                item.properties.map(function (item, key) {
                  switch (item.id) {
                    case 1:
                      item.title_desc_inline =
                        use_regulations.properties.use_j_01 ?? "N/A";
                      break; 
                    case 4:
                      item.source_p = use_regulations.properties.use_srce != null
                        ? use_regulations.properties.use_srce
                        : "N/A";
                      item.link = use_regulations.properties.use_link;
                      break;
                  }
                });
                break;
      
      case "not_permitted":

        item.lock = use_regulations[0].properties.locked;
        let allowance = [];
        Object.entries(useRegulationAllowed).map(([index, value]) => {
          if (value === "A") {

            let text = searchUseRegulation(index);
            allowance.push({ text, value });
          }
        });

        item.properties = allowance;
        break;
      case "conditional":
        item.lock = use_regulations[0].properties.locked;
        let conditional = [];
        Object.entries(useRegulationAllowed).map(([index, value]) => {
          if (value === "CL") {
            let text = searchUseRegulation(index);
            conditional.push({ text, value });
          }
        });
        item.properties = conditional;
        break;
      case "Permitted Use":
        item.lock = use_regulations[0].properties.locked;
        let Permitted = [];
        Object.entries(useRegulationAllowed).map(([index, value]) => {
          if (value === "P") {

            let text = searchUseRegulation(index);
            Permitted.push({ text, value });
          }
        });
        item.properties = Permitted;
        break;
    }
  });
  constantResponse.far.map(function (item, key) {
    let { far } = apiResponse;

    switch (item.id) {
      case "info_far":
        far = far[0];
        item.title = far.title;
        item.subTitle = far.description;
        if (far.properties.locked) {
          item.lock = true;
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = far.properties.far_base_cond ?? "N/A";
              break;
            case 2:
              if (far.properties.far_base_valu !== null) {
                item.title = far?.properties?.far_base_valu?.toFixed(2) + ' to 1';
              }
              else {
                item.display = false
              }
              break;
            case 3:
              item.title = far.properties.far_base_cmmt ?? "N/A";
              break;
            case 4:
              item.source_p = far.properties.far_base_srce;
              item.link = far.properties.far_base_link;
              break;

          }
        });
        break;

      case "far_allowable":
        far = far[1];
        // item.title = far.title;
        item.subTitle = far.description;
        if (far.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {

          switch (item.id) {
            case 1:
              item.title = far.properties.far_adjx_cond ?? "N/A";
              break;
            case 2:
              if (far.properties.far_adjx_valu !== null) {
                item.title = far?.properties?.far_adjx_valu?.toFixed(2) + ' to 1';
              }
              else item.title = "N/A"
              break;
            case 3:
              item.title = far.properties.far_adjx_cmmt ?? "N/A";
              break;
            case 4:
              item.source_p = far.properties.far_adjx_srce;
              item.link = far.properties.far_adjx_link;
              break;
            // resulting buildable area
            case 5:
              if (far.properties.envp_bldg_pfar_lot !== null) {
                item.title =far.properties.envp_bldg_pfar_lot? Number(far.properties.envp_bldg_pfar_lot).toLocaleString() + " square feet":"N/A";
              }
              else {
                item.display = false
              }
              break;
          }

        });
        break;


      case "far_bonus":
        far = far[3];
        // item.title = far.title;
        item.subTitle = far.description;
        if (far.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (
          far.properties.far_bon1_cond === null &&
          far.properties.far_bon2_cond === null &&
          far.properties.far_bon3_cond === null &&
          far.properties.far_bon4_cond === null &&
          far.properties.far_bon5_cond === null) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.small_heading = "Are FAR bonuses available for this lot?: "
                item.title =  far.properties.far_bonx_appl??" No";
                item.display = true;
                break;
              case 2:
                item.display = false;
                break;
              case 3:
                item.display = false;
                break;
              case 4:
                item.display = false;

                break;
              case 5:
                item.display = false;

                break;
              case 6:
                item.display = false;
                break;
              case 7:
                item.display = false;
                break;
              case 8:
                item.display = false;
                break;
              case 9:
                item.display = false;
                break;
              case 10:
                item.display = false;
                break;
              case 11:
                item.display = false;
                break;
              case 12:
                item.display = false;
                break;
              case 13:
                item.display = false;
                break;
              case 14:
                item.display = false;
                break;
              case 15:
                item.display = false;
                break;
              case 16:
                item.display = false;
                break;
              case 17:
                item.display = false;
                break;
              case 18:
                item.display = false;
                break;
              case 19:
                item.display = false;
                break;
              case 20:
                item.display = false;
                break;
              case 21:
                item.display = false;
                break;
              case 22:
                item.display = false;
                break;
              case 23:
                item.display = false;
                break;
              case 24:
                item.display = false;
                break;
              case 25:
                item.display = false;
                break;
              case 26:
                item.display = false;
                break;
              case 27:
                item.display = false;
                break;
                case 28:
                  item.display = false;
                  break;
                  case 29:
                item.display = false;
                break;
                case 30:
                  item.display = false;
                  break;
                  case 31:
                item.display = false;
                break;
                case 32:
                item.display = false;
                break;
                case 33:
                item.display = false;
                break;
            }
          });
        } else {
          item.properties.map(function (item, key) {
            item.display=true

            switch (item.id) {
              case 1:
                item.display=false
              case 2:
                
                item.title = far.properties.far_bonx_appl??" No";
                break;
              case 3:
                // if(far.properties.far_bonx_appl)
                item.sourceHr = true;
                break;

              // 1
              case 4:
                if (far.properties.far_bon1_cond !== null && far.properties.far_bon1_appl === "Yes") {
                  item.title = far.properties.far_bon1_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 5:
                if (far.properties.far_bon1_cond !== null && far.properties.far_bon1_appl === "Yes") {
                  if (far.properties.far_bon1_valu !== null) {
                    item.title = Math.round(far.properties.far_bon1_valu * 100) / 100 + " to 1";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 6:
                if (far.properties.far_bon1_cond !== null && far.properties.far_bon1_appl === "Yes") {
                  item.title = far.properties.far_bon1_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 7:
                if (far.properties.far_bon1_cond !== null && far.properties.far_bon1_appl === "Yes") {
                  item.title = far.properties.far_bon1_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 8:
                if (far.properties.far_bon1_cond !== null && far.properties.far_bon1_appl === "Yes") {
                  item.source_p = far.properties.far_bon1_srce ?? "N/A";
                  item.link = far.properties.far_bon1_link;
                } else {
                  item.display = false
                }
                break;
              case 9:
                if (far.properties.far_bon1_cond !== null && far.properties.far_bon1_appl === "Yes") {
                  item.sourceHr = true
                } else {
                  item.display = false
                }

              // 2

              case 10:
                if (far.properties.far_bon2_cond !== null && far.properties.far_bon2_appl === "Yes") {
                  item.title = far.properties.far_bon2_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 11:
                if (far.properties.far_bon2_cond !== null && far.properties.far_bon2_appl === "Yes") {
                  if (far.properties.far_bon2_valu !== null) {
                    item.title = Math.round(far.properties.far_bon2_valu * 100) / 100 + " to 1";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 12:
                if (far.properties.far_bon2_cond !== null && far.properties.far_bon2_appl === "Yes") {
                  item.title = far.properties.far_bon2_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 13:
                if (far.properties.far_bon2_cond !== null && far.properties.far_bon2_appl === "Yes") {
                  item.title = far.properties.far_bon2_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 14:
                if (far.properties.far_bon2_cond !== null && far.properties.far_bon2_appl === "Yes") {
                  item.source_p = far.properties.far_bon2_srce ?? "N/A";
                  item.link = far.properties.far_bon2_link;
                } else {
                  item.display = false
                }
                break;
              case 15:
                if (far.properties.far_bon2_cond !== null && far.properties.far_bon2_appl === "Yes") {
                  item.sourceHr = true
                } else {
                  item.display = false
                }
              // 3
              case 16:
                if (far.properties.far_bon3_cond !== null && far.properties.far_bon3_appl === "Yes") {
                  item.title = far.properties.far_bon3_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 17:
                if (far.properties.far_bon3_cond !== null && far.properties.far_bon3_appl === "Yes") {
                  if (far.properties.far_bon3_valu !== null) {
                    item.title = Math.round(far.properties.far_bon3_valu * 100) / 100 + " to 1";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 18:
                if (far.properties.far_bon3_cond !== null && far.properties.far_bon3_appl === "Yes") {
                  item.title = far.properties.far_bon3_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 19:
                if (far.properties.far_bon3_cond !== null && far.properties.far_bon3_appl === "Yes") {
                  item.title = far.properties.far_bon3_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 20:
                if (far.properties.far_bon3_cond !== null && far.properties.far_bon3_appl === "Yes") {
                  item.source_p = far.properties.far_bon3_srce ?? "N/A";
                  item.link = far.properties.far_bon3_link;
                } else {
                  item.display = false
                }
                break;
              case 21:
                if (far.properties.far_bon3_cond !== null && far.properties.far_bon3_appl === "Yes") {
                  item.sourceHr = true
                } else {
                  item.display = false
                  item.sourceHr = false
                }
              // 4
              case 22:
                if (far.properties.far_bon4_cond !== null && far.properties.far_bon4_appl === "Yes") {
                  item.title = far.properties.far_bon4_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 23:
                if (far.properties.far_bon4_cond !== null && far.properties.far_bon4_appl === "Yes") {
                  if (far.properties.far_bon4_valu !== null) {
                    item.title = Math.round(far.properties.far_bon4_valu * 100) / 100 + " to 1";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 24:
                if (far.properties.far_bon4_cond !== null && far.properties.far_bon4_appl === "Yes") {
                  item.title = far.properties.far_bon4_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 25:
                if (far.properties.far_bon4_cond !== null && far.properties.far_bon4_appl === "Yes") {
                  item.title = far.properties.far_bon4_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 26:
                if (far.properties.far_bon4_cond !== null && far.properties.far_bon4_appl === "Yes") {
                  item.source_p = far.properties.far_bon4_srce ?? "N/A";
                  item.link = far.properties.far_bon4_link;
                } else {
                  item.display = false
                }
                break;
              case 27:
                if (far.properties.far_bon4_cond !== null && far.properties.far_bon4_appl === "Yes") {
                  item.sourceHr = true
                } else {
                  item.display = false
                  item.sourceHr = false
                }
              // 5
              case 28:
                if (far.properties.far_bon5_cond !== null && far.properties.far_bon5_appl === "Yes") {
                  item.title = far.properties.far_bon5_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 29:
                if (far.properties.far_bon5_cond !== null && far.properties.far_bon5_appl === "Yes") {
                  if (far.properties.far_bon5_valu !== null) {
                    item.title = Math.round(far.properties.far_bon5_valu * 100) / 100 + " to 1";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 30:
                if (far.properties.far_bon5_cond !== null && far.properties.far_bon5_appl === "Yes") {
                  item.title = far.properties.far_bon5_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 31:
                if (far.properties.far_bon5_cond !== null && far.properties.far_bon5_appl === "Yes") {
                  item.title = far.properties.far_bon5_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 32:
                if (far.properties.far_bon5_cond !== null && far.properties.far_bon5_appl === "Yes") {
                  item.source_p = far.properties.far_bon5_srce ?? "N/A";
                  item.link = far.properties.far_bon5_link;
                } else {
                  item.display = false
                }
                break;
              // case 33:
              //   if (far.properties.far_bon1_appl == "Yes" ||
              //     far.properties.far_bon2_appl == "Yes" ||
              //     far.properties.far_bon3_appl == "Yes" ||
              //     far.properties.far_bon4_appl == "Yes" ||
              //     far.properties.far_bon5_appl == "Yes") {
              //     item.display = false
              //   }
              //   else {
              //     item.title = "No Data Found"
              //   }
              //   break;


            }
          });
        }

        break;


      case "far_Restriction":
        far = far[2];
        // item.title = far.title;
        item.subTitle = far.description;
        if (far.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (far.properties.far_res1_cond === null && far.properties.far_res2_cond === null) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.small_heading = "No FAR restriction exist for this site";
                item.title = " "
                item.display=true


                break;
              case 2:
                item.display = false;
                item.sourceHr = false;
                break;
              case 3:
                item.display = false;
                item.sourceHr = false;
                break;
              case 4:
                item.display = false;
                item.sourceHr = false;
                break;
              case 5:
                item.display = false;
                item.sourceHr = false;
                break;
              case 6:
                item.display = false;
                item.sourceHr = false;
                break;
              case 7:
                item.display = false;
                item.sourceHr = false;
                break;
              case 8:
                item.display = false;
                item.sourceHr = false;
                break;
              case 9:
                item.display = false;
                item.sourceHr = false;
                break;
              case 10:
                item.display = false;
                item.sourceHr = false;
                break;
                case 11:
                  item.display = false;
                  item.sourceHr = false;
                  break;
                  case 12:
                    item.display = false;
                    item.sourceHr = false;
                    break;
                    case 13:
                      item.display = false;
                      item.sourceHr = false;
                      break;
                      case 14:
                        item.display = false;
                        item.sourceHr = false;
                        break;
                        case 15:
                          item.display = false;
                          item.sourceHr = false;
                          break;




            }
          });
        } else {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.display=false;
                break;
              case 2:
                item.small_heading = "Any FAR restrictions that may apply to this site. See below for more details.";
                item.title = " "
                item.display=true
                break;
              // 1
              case 3:
                if (far.properties.far_res1_cond !== null && far.properties.far_res1_appl === "Yes") {
                item.display=true
                item.title = far.properties.far_res1_cond ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 4:
                if (far.properties.far_res1_cond !== null && far.properties.far_res1_appl === "Yes") {
                  if (far.properties.far_res1_valu !== null) {
                item.display=true
                item.title = Math.round(far.properties.far_res1_valu * 100) / 100 + " to 1";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 5:
                if (far.properties.far_res1_cond !== null && far.properties.far_res1_appl === "Yes") {
                item.display=true
                item.title = far.properties.far_res1_cmmt ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 6:
                if (far.properties.far_res1_cond !== null && far.properties.far_res1_appl === "Yes") {
                item.display=true
                item.title = far.properties.far_res1_appl ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 7:
                if (far.properties.far_res1_cond !== null && far.properties.far_res1_appl === "Yes") {
                  item.source_p = far.properties.far_res1_srce ?? "N/A";
                item.display=true
                item.link = far.properties.far_res1_link;
                }
                else {
                  item.display = false
                }
                break;
              case 8:
                if (far.properties.far_res1_cond !== null && far.properties.far_res1_appl === "Yes") {
                item.display=true
                item.sourceHr = true;
                }
                else {
                  item.sourceHr = false;
                  item.display = false
                }

                break;
              // 2

              case 9:
                if (far.properties.far_res2_cond !== null && far.properties.far_res2_appl === "Yes") {
                item.display=true
                item.title = far.properties.far_res2_cond ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 10:
                if (far.properties.far_res2_cond !== null && far.properties.far_res2_appl === "Yes") {
                  if (far.properties.far_res2_valu !== null) {
                item.display=true
                item.title = Math.round(far.properties.far_res2_valu * 100) / 100 + " to 1";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 11:
                if (far.properties.far_res2_cond !== null && far.properties.far_res2_appl === "Yes") {
                item.display=true
                item.title = far.properties.far_res2_cmmt ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 12:
                if (far.properties.far_res2_cond !== null && far.properties.far_res2_appl === "Yes") {
                item.display=true
                item.title = far.properties.far_res2_appl ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 13:
                if (far.properties.far_res2_cond !== null && far.properties.far_res2_appl === "Yes") {
                item.display=true
                item.source_p = far.properties.far_res2_srce ?? "N/A";
                  item.link = far.properties.far_res2_link
                }
                else {
                  item.display = false
                }
                break;
              case 14:
                if (far.properties.far_res2_cond !== null && far.properties.far_res2_appl === "Yes") {
                item.display=true
                item.sourceHr = true;
                }
                else {
                  item.sourceHr = false;
                  item.display = false
                }
                break;
              // change the cases when reamaining are becomes available in constant too
              // case 15:
              //   if (far.properties.far_res1_appl == "Yes" ||
              //     far.properties.far_res2_appl == "Yes") {
              //   item.display=true
              //   item.display = false
              //   }
              //   else {
              //     item.title = "No Data Found"
              //   }
                break;

              // 3
              // case 15:
              //   if (far.properties.far_res3_cond !== null && far.properties.far_res3_appl === "Yes") {
              //     item.title = far.properties.far_res3_cond ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 16:
              // if (far.properties.far_res3_cond !== null && far.properties.far_res3_appl === "Yes") {
              //   if (far.properties.far_res3_valu !== null) {
              //     item.title = Math.round(far.properties.far_res3_valu * 100) / 100 + " to 1";
              //   } else {
              //     item.title = "N/A";
              //   }
              // } else {
              //   item.display = false;
              // }  
              //   break;
              // case 17:
              //   if (far.properties.far_res3_cond !== null && far.properties.far_res3_appl === "Yes") {
              //     item.title = far.properties.far_res3_cmmt ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 18:
              //   if (far.properties.far_res3_cond !== null && far.properties.far_res3_appl === "Yes") {
              //     item.title = far.properties.far_res3_appl ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 19:
              //   if (far.properties.far_res3_cond !== null && far.properties.far_res3_appl === "Yes") {
              //     item.source_p = far.properties.far_res3_srce ?? "N/A";
              //     item.link = far.properties.far_res3_link;
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 19:
              //   if (far.properties.far_res3_cond !== null && far.properties.far_res3_appl === "Yes") {
              //     item.sourceHr = true;
              //   }
              //   item.sourceHr = false;
              //   item.display = false
              //   break;
              // // 4
              // case 20:
              //   if (far.properties.far_res4_cond !== null && far.properties.far_res4_appl === "Yes") {
              //     item.title = far.properties.far_res4_cond ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 21:
              // if (far.properties.far_res4_cond !== null && far.properties.far_res4_appl === "Yes") {
              //   if (far.properties.far_res4_valu !== null) {
              //     item.title = Math.round(far.properties.far_res4_valu * 100) / 100 + " to 1";
              //   } else {
              //     item.title = "N/A";
              //   }
              // } else {
              //   item.display = false;
              // } 

              //   break;
              // case 22:
              //   if (far.properties.far_res4_cond !== null && far.properties.far_res4_appl === "Yes") {
              //     item.title = far.properties.far_res4_cmmt ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 23:
              //   if (far.properties.far_res4_cond !== null && far.properties.far_res4_appl === "Yes") {
              //     item.title = far.properties.far_res4_appl ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 24:
              //   if (far.properties.far_res4_cond !== null && far.properties.far_res4_appl === "Yes") {
              //     item.source_p = far.properties.far_res4_srce ?? "N/A";
              //     item.link = far.properties.far_res4_link;
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 25:
              //   if (far.properties.far_res4_cond !== null && far.properties.far_res4_appl === "Yes") {
              //     item.sourceHr = true;
              //   }
              // else{
              //   item.sourceHr = false;
              //   item.display = false
              // }
              //   break;
              // // 5
              // case 26:
              //   if (far.properties.far_res5_cond !== null && far.properties.far_res5_appl === "Yes") {
              //     item.title = far.properties.far_res5_cond ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 27:
              // if (far.properties.far_res5_cond !== null && far.properties.far_res5_appl === "Yes") {
              //   if (far.properties.far_res5_valu !== null) {
              //     item.title = Math.round(far.properties.far_res5_valu * 100) / 100 + " to 1";
              //   } else {
              //     item.title = "N/A";
              //   }
              // } else {
              //   item.display = false;
              // }  
              //   break;
              // case 28:
              //   if (far.properties.far_res5_cond !== null && far.properties.far_res5_appl === "Yes") {
              //     item.title = far.properties.far_res5_cmmt ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 29:
              //   if (far.properties.far_res5_cond !== null && far.properties.far_res5_appl === "Yes") {
              //     item.title = far.properties.far_res5_appl ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 30:
              //   if (far.properties.far_res5_cond !== null && far.properties.far_res5_appl === "Yes") {
              //     item.source_p = far.properties.far_res5_srce ?? "N/A";
              //     item.link = far.properties.far_res5_link;
              //   }
              //   break;



            }
          });
        }

        break;


      case "max_far_bonus":

        far = far[4];
        // item.title = far.title;
        item.subTitle = far.description;
        if (far.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (far.properties.far_bonx_appl === null || far.properties.far_bonx_appl === "No" ) {
          item.show = false
          // item.properties.map(function (item, key) {
          //   switch (item.id) {
          //     case 1:
          //       item.title = " ";
          //       break;

          //   }
          // });
        } else {
          item.properties.map(function (item, key) {
            if (far.properties.far_bonx_appl !== null) {
              switch (item.id) {
                case 2:
                  item.title = far.properties.far_bonx_cond ?? "N/A";
                  break;
                case 3:
                  if (far.properties.far_bonx_valu !== null) {
                    item.title = Math.round(far.properties.far_bonx_valu * 100) / 100 + " to 1" ?? "N/A";
                  }
                  else {
                    item.title = "n/a"
                  }
                  break;
                case 4:
                  item.title = far.properties.far_bonx_cmmt ?? "N/A";
                  break;
                case 5:
                  item.title = far.properties.far_bonx_appl ?? "N/A";
                  break;
                case 6:
                  item.source_p = far.properties.far_bonx_srce ?? "N/A";
                  item.link = far.properties.far_bonx_link
                  break;
                case 7:
                  if (far.properties.envp_bldg_pfar_bonus !== null) {
                    var numericValue = parseFloat(far.properties.envp_bldg_pfar_bonus?.replace(/,/g, ""));

                    if (!isNaN(numericValue)) {
                      item.title = Math.round(numericValue).toLocaleString() + " square feet";
                    } else {
                      item.title = "N/A";
                    }
                  } else {
                    item.title = "N/A";
                  }

                  break;
              }
            }

          });
        }
        break;


    }
  });
  constantResponse.height_limit.map(function (item, key) {
    let { height_limit } = apiResponse;
    switch (item.id) {

      case "height_limit":
        height_limit = height_limit[0];
        item.title = height_limit.title;
        item.subTitle = height_limit.description;
        if (height_limit.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = height_limit.properties.htlim_base_cond ?? "N/A";
              break;
            case 2:
              if (height_limit.properties.htlim_base_valu !== null) {
                item.title = Math.round(height_limit.properties.htlim_base_valu) + " feet" ?? "N/A";
              }
              break;
            case 3:
              item.title = height_limit.properties.htlim_base_cmmt ?? "N/A";
              break;
            case 4:
              item.source_p = height_limit.properties.htlim_base_srce;
              item.link = height_limit.properties.htlim_base_link;
              break;
          }
        });
        break;
      case "allowable_height":
        height_limit = height_limit[1];
        // item.title = height_limit.title;
        item.subTitle = height_limit.description;
        if (height_limit.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }

        item.properties.map(function (item, key) {
          switch (item.id) {

            case 1:
              item.title = height_limit.properties.htlim_adjx_cond ?? "N/A";
              break;
            case 2:
              if (height_limit.properties.htlim_adjx_valu !== null) {
                item.title = Math.round(height_limit.properties.htlim_adjx_valu) + " feet";
              }
              else {
                item.display = false
              }
              break;
            case 3:
              item.title =
                height_limit.properties.htlim_adjx_cmmt ?? "N/A";
              break;
            case 4:
              item.source_p =
                height_limit.properties.htlim_adjx_srce ?? "N/A";
              item.link = height_limit.properties.htlim_adjx_link;
              break;
            // resulting buildable area
            case 5:
              if (height_limit.properties.envp_bldg_htlim_lot !== null) {
                item.title = height_limit.properties.envp_bldg_htlim_lot?Number(height_limit.properties.envp_bldg_htlim_lot).toLocaleString() + " square feet":"N/A";
              }
              break;

          }
        });


        break;
      case "height_restriction":
        height_limit = height_limit[2];
        // item.title = height_limit.title;
        item.subTitle = height_limit.description;
        if (height_limit.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (height_limit.properties.htlim_res1_cond === null &&  height_limit.properties.htlim_res2_cond === null
        ) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.small_heading = "No Height restriction exist for this site"
                item.title = " ";
                item.display=true;
                
                break;
              case 2:
                item.display = false;
                item.sourceHr = false;
                break;
              case 3:
                item.display = false;
                item.sourceHr = false;
                break;
              case 4:
                item.display = false;
                item.sourceHr = false;
                break;
              case 5:
                item.display = false;
                item.sourceHr = false;
                break;
              case 6:
                item.display = false;
                item.sourceHr = false;
                break;
              case 7:
                item.display = false;
                item.sourceHr = false;
                break;
              case 8:
                item.display = false;
                item.sourceHr = false;
                break;
              case 9:
                item.display = false;
                item.sourceHr = false;
                break;
              case 10:
                item.display = false;
                item.sourceHr = false;
                break;
                case 11:
                  item.display = false;
                  item.sourceHr = false;
                  break;
                  case 12:
                item.display = false;
                item.sourceHr = false;
                break;
                case 13:
                item.display = false;
                item.sourceHr = false;
                break;
                case 14:
                item.display = false;
                item.sourceHr = false;
                break;
            }
          });
        } else {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.display=false
              break;
              case 2:
                item.small_heading = "Any Height restrictions that may apply to this site. See below for more details.";
                item.title = " ";
                item.display=true;
                break;
              // 1
              case 3:
                if (height_limit.properties.htlim_res1_cond !== null && height_limit.properties.htlim_res1_appl === "Yes") {
                item.display=true;
                item.title = height_limit.properties.htlim_res1_cond ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 4:
                if (height_limit.properties.htlim_res1_cond !== null && height_limit.properties.htlim_res1_appl === "Yes") {
                  if (height_limit.properties.htlim_res1_valu !== null) {
                item.display=true;
                item.title = Math.round(height_limit.properties.htlim_res1_valu) + " feet";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 5:
                if (height_limit.properties.htlim_res1_cond !== null && height_limit.properties.htlim_res1_appl === "Yes") {
                item.display=true;
                item.title = height_limit.properties.htlim_res1_cmmt ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 6:
                if (height_limit.properties.htlim_res1_cond !== null && height_limit.properties.htlim_res1_appl === "Yes") {
                item.display=true;
                item.title = height_limit.properties.htlim_res1_appl ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 7:
                if (height_limit.properties.htlim_res1_cond !== null && height_limit.properties.htlim_res1_appl === "Yes") {
                  item.source_p = height_limit.properties.htlim_res1_srce ?? "N/A";
                item.display=true;
                item.link = height_limit.properties.htlim_res1_link;
                }
                else {
                  item.display = false
                }
                break;
              case 8:
                if (height_limit.properties.htlim_res1_cond !== null && height_limit.properties.htlim_res1_appl === "Yes") {
                item.display=true;
                item.sourceHr = true
                }
                else {
                  item.display = false;
                  item.sourceHr = false;
                }

              // 2

              case 9:
                if (height_limit.properties.htlim_res2_cond !== null && height_limit.properties.htlim_res2_appl === "Yes") {
                item.display=true;
                item.title = height_limit.properties.htlim_res2_cond ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 10:
                if (height_limit.properties.htlim_res2_cond !== null && height_limit.properties.htlim_res2_appl === "Yes") {
                  if (height_limit.properties.htlim_res2_valu !== null) {
                item.display=true;
                item.title = Math.round(height_limit.properties.htlim_res2_valu) + " feet";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 11:
                if (height_limit.properties.htlim_res2_cond !== null && height_limit.properties.htlim_res2_appl === "Yes") {
                item.display=true;
                item.title = height_limit.properties.htlim_res2_cmmt ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 12:
                if (height_limit.properties.htlim_res2_cond !== null && height_limit.properties.htlim_res2_appl === "Yes") {
                item.display=true;
                item.title = height_limit.properties.htlim_res2_appl ?? "N/A";
                }
                else {
                  item.display = false
                }
                break;
              case 13:
                if (height_limit.properties.htlim_res2_cond !== null && height_limit.properties.htlim_res2_appl === "Yes") {
                item.display=true;
                item.source_p = height_limit.properties.htlim_res2_srce ?? "N/A";
                  item.link = height_limit.properties.htlim_res2_link;
                }
                else {
                  item.display = false
                }
                break;
              case 14:
                if (height_limit.properties.htlim_res2_cond !== null && height_limit.properties.htlim_res2_appl === "Yes") {
                item.display=true;
                item.sourceHr = true
                }
                else {
                  item.display = false;
                  item.sourceHr = false;
                }
              // case 14:
              //   if (height_limit.properties.height_limit_res1_appl == "Yes" ||
              //     height_limit.properties.height_limit_res2_appl == "Yes") {
              //     item.display = false
              //   }
              //   else {
              //     item.title = "No Height restriction exist for this site"
              //   }

              //   break;
              // 3
              // case 14:
              //   if (height_limit.properties.htlim_res3_cond !== null && height_limit.properties.height_limit_res3_appl === "Yes") {
              //     item.title = height_limit.properties.htlim_res3_cond ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 15:
              //   if (height_limit.properties.htlim_res3_cond !== null && height_limit.properties.height_limit_res3_appl === "Yes") {
              //     item.title = Math.round(height_limit.properties.height_limit_res3_valu) + " feet" ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 16:
              //   if (height_limit.properties.htlim_res3_cond !== null && height_limit.properties.height_limit_res3_appl === "Yes") {
              //     item.title = height_limit.properties.height_limit_res3_cmmt ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 17:
              //   if (height_limit.properties.htlim_res3_cond !== null && height_limit.properties.height_limit_res3_appl === "Yes") {
              //     item.title = height_limit.properties.height_limit_res3_appl ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 18:
              //   if (height_limit.properties.htlim_res3_cond !== null && height_limit.properties.height_limit_res3_appl === "Yes") {
              //     item.source_p = height_limit.properties.htlim_res3_srce ?? "N/A";
              //     item.link = height_limit.properties.htlim_res3_link;
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              //   case 19:
              //     if (height_limit.properties.htlim_res3_cond !== null && height_limit.properties.height_limit_res3_appl === "Yes") {
              //       item.sourceHr = true
              //     }
              //     else {
              //       item.display = false;
              //       item.sourceHr = false;
              //     }
              // // 4
              // case 20:
              //   if (height_limit.properties.htlim_res4_cond !== null && height_limit.properties.height_limit_res4_appl === "Yes") {
              //     item.title = height_limit.properties.htlim_res4_cond ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 21:
              //   if (height_limit.properties.htlim_res4_cond !== null && height_limit.properties.height_limit_res4_appl === "Yes") {
              //     item.title = Math.round(height_limit.properties.height_limit_res4_valu) + " feet" ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 22:
              //   if (height_limit.properties.htlim_res4_cond !== null && height_limit.properties.height_limit_res4_appl === "Yes") {
              //     item.title = height_limit.properties.height_limit_res4_cmmt ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 23:
              //   if (height_limit.properties.htlim_res4_cond !== null && height_limit.properties.height_limit_res4_appl === "Yes") {
              //     item.title = height_limit.properties.height_limit_res4_appl ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 24:
              //   if (height_limit.properties.htlim_res4_cond !== null && height_limit.properties.height_limit_res4_appl === "Yes") {
              //     item.source_p = height_limit.properties.htlim_res4_srce ?? "N/A";
              //     item.link = height_limit.properties.htlim_res4_link;
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              //   case 25:
              //     if (height_limit.properties.htlim_res4_cond !== null && height_limit.properties.height_limit_res4_appl === "Yes") {
              //       item.sourceHr = true
              //     }
              //     else {
              //       item.display = false;
              //       item.sourceHr = false;
              //     }
              // // 5
              // case 26:
              //   if (height_limit.properties.htlim_res5_cond !== null && height_limit.properties.height_limit_res5_appl === "Yes") {
              //     item.title = height_limit.properties.htlim_res5_cond ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;

              // case 27:
              //   if (height_limit.properties.htlim_res5_cond !== null && height_limit.properties.height_limit_res5_appl === "Yes") {
              //     item.title = Math.round(height_limit.properties.height_limit_res5_valu) + " feet" ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 28:
              //   if (height_limit.properties.htlim_res5_cond !== null && height_limit.properties.height_limit_res5_appl === "Yes") {
              //     item.title = height_limit.properties.height_limit_res5_cmmt ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 29:
              //   if (height_limit.properties.htlim_res5_cond !== null && height_limit.properties.height_limit_res5_appl === "Yes") {
              //     item.title = height_limit.properties.height_limit_res5_appl ?? "N/A";
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;
              // case 30:
              //   if (height_limit.properties.htlim_res5_cond !== null && height_limit.properties.height_limit_res5_appl === "Yes") {
              //     item.source_p = height_limit.properties.htlim_res5_srce ?? "N/A";
              //     item.link = height_limit.properties.htlim_res5_link;
              //   }
              //   else {
              //     item.display = false
              //   }
              //   break;

            }
          });
        }

        break;
      case "height_bonuses":
        height_limit = height_limit[3];
        // item.title = height_limit.title;
        item.subTitle = height_limit.description;
        if (height_limit.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (height_limit.properties.htlim_bon1_cond === null &&
          height_limit.properties.htlim_bon2_cond === null &&
          height_limit.properties.htlim_bon3_cond === null &&
          height_limit.properties.htlim_bon4_cond === null &&
          height_limit.properties.htlim_bon5_cond === null 

        ) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.display=true
                item.small_heading = "No Height bonuses available for this lot";
                item.title = " "
                break; 
              case 2:
                item.display = false;
                item.sourceHr = false;
                break;
              case 3:
                item.display = false;
                item.sourceHr = false;
                break;
              case 4:
                item.display = false;
                item.sourceHr = false;
                break;
              case 5:
                item.display = false;
                item.sourceHr = false;
                break;
              case 6:
                item.display = false;
                item.sourceHr = false;
                break;
              case 7:
                item.display = false;
                item.sourceHr = false;
                break;
              case 8:
                item.display = false;
                item.sourceHr = false;
                break;
              case 9:
                item.display = false;
                item.sourceHr = false;
                break;
              case 10:
                item.display = false;
                item.sourceHr = false;
                break;
              case 11:
                item.display = false;
                item.sourceHr = false;
                break;
              case 12:
                item.display = false;
                item.sourceHr = false;
                break;
              case 13:
                item.display = false;
                item.sourceHr = false;
                break;
              case 14:
                item.display = false;
                item.sourceHr = false;
                break;
              case 15:
                item.display = false;
                item.sourceHr = false;
                break;
              case 16:
                item.display = false;
                item.sourceHr = false;
                break;
              case 17:
                item.display = false;
                item.sourceHr = false;
                break;
              case 18:
                item.display = false;
                item.sourceHr = false;
                break;
              case 19:
                item.display = false;
                item.sourceHr = false;
                break;
              case 20:
                item.display = false;
                item.sourceHr = false;
                break;
              case 21:
                item.display = false;
                item.sourceHr = false;
                break;
              case 22:
                item.display = false;
                item.sourceHr = false;
                break;
              case 23:
                item.display = false;
                item.sourceHr = false;
                break;
              case 24:
                item.display = false;
                item.sourceHr = false;
                break;
              case 25:
                item.display = false;
                item.sourceHr = false;
                break;
            }
          });
        } else {
          item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.display=false;
              break;
              case 2:
                item.title = height_limit.properties.htlim_bonx_appl;
                break;
              case 3:
                item.sourceHr = true;
                break;
              // 1
              case 4:
                if (height_limit.properties.htlim_bon1_cond !== null && height_limit.properties.htlim_bon1_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon1_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 5:
                if (height_limit.properties.htlim_bon1_cond !== null && height_limit.properties.htlim_bon1_appl === "Yes") {
                  if (height_limit.properties.htlim_bon1_valu !== null) {
                    item.display=true
                    item.title = Math.round(height_limit.properties.htlim_bon1_valu) + " feet";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 6:
                if (height_limit.properties.htlim_bon1_cond !== null && height_limit.properties.htlim_bon1_appl === "Yes") {
                  item.title = height_limit.properties.htlim_bon1_cmmt ?? "N/A";
                  item.display=true
                } else {
                  item.display = false
                }
                break;
              case 7:
                if (height_limit.properties.htlim_bon1_cond !== null && height_limit.properties.htlim_bon1_appl === "Yes") {
                  item.title = height_limit.properties.htlim_bon1_appl ?? "N/A";
                  item.display=true
                } else {
                  item.display = false
                }
                break;
              case 8:
                if (height_limit.properties.htlim_bon1_cond !== null && height_limit.properties.htlim_bon1_appl === "Yes") {
                  item.source_p = height_limit.properties.htlim_bon1_srce ?? "N/A";
                  item.display=true
                  item.link = height_limit.properties.htlim_bon1_link;
                } else {
                  item.display = false
                }
                break;
              case 9:
                if (height_limit.properties.htlim_bon1_cond !== null && height_limit.properties.htlim_bon1_appl === "Yes") {
                  item.sourceHr = true
                  item.display=true
                } else {
                  item.display = false
                }
                break;
              // 2

              case 10:
                if (height_limit.properties.htlim_bon2_cond !== null && height_limit.properties.htlim_bon2_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon2_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 11:
                if (height_limit.properties.htlim_bon2_cond !== null && height_limit.properties.htlim_bon2_appl === "Yes") {
                  if (height_limit.properties.htlim_bon2_valu !== null) {
                  item.display=true
                  item.title = Math.round(height_limit.properties.htlim_bon2_valu) + " feet";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 12:
                if (height_limit.properties.htlim_bon2_cond !== null && height_limit.properties.htlim_bon2_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon2_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 13:
                if (height_limit.properties.htlim_bon2_cond !== null && height_limit.properties.htlim_bon2_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon2_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 14:
                if (height_limit.properties.htlim_bon2_cond !== null && height_limit.properties.htlim_bon2_appl === "Yes") {
                  item.display=true
                  item.source_p = height_limit.properties.htlim_bon2_srce ?? "N/A";
                  item.link = height_limit.properties.htlim_bon2_link;
                } else {
                  item.display = false
                }
                break;
              case 15:
                if (height_limit.properties.htlim_bon2_cond !== null && height_limit.properties.htlim_bon2_appl === "Yes") {
                  item.display=true
                  item.sourceHr = true
                } else {
                  item.display = false
                }
                break;
              // 3
              case 16:
                if (height_limit.properties.htlim_bon3_cond !== null && height_limit.properties.htlim_bon3_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon3_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 17:
                if (height_limit.properties.htlim_bon3_cond !== null && height_limit.properties.htlim_bon3_appl === "Yes") {
                  if (height_limit.properties.htlim_bon3_valu !== null) {
                  item.display=true
                  item.title = Math.round(height_limit.properties.htlim_bon3_valu) + " feet";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 18:
                if (height_limit.properties.htlim_bon3_cond !== null && height_limit.properties.htlim_bon3_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon3_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 19:
                if (height_limit.properties.htlim_bon3_cond !== null && height_limit.properties.htlim_bon3_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon3_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 20:
                if (height_limit.properties.htlim_bon3_cond !== null && height_limit.properties.htlim_bon3_appl === "Yes") {
                  item.display=true
                  item.source_p = height_limit.properties.htlim_bon3_srce ?? "N/A";
                  item.link = height_limit.properties.htlim_bon3_link;
                } else {
                  item.display = false
                }
                break;
              case 21:
                if (height_limit.properties.htlim_bon3_cond !== null && height_limit.properties.htlim_bon3_appl === "Yes") {
                  item.display=true
                  item.sourceHr = true
                } else {
                  item.display = false
                }
                break;
              // 4
              case 22:
                if (height_limit.properties.htlim_bon4_cond !== null && height_limit.properties.htlim_bon4_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon4_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 23:
                if (height_limit.properties.htlim_bon4_cond !== null && height_limit.properties.htlim_bon4_appl === "Yes") {
                  if (height_limit.properties.htlim_bon4_valu !== null) {
                  item.display=true
                  item.title = Math.round(height_limit.properties.htlim_bon4_valu) + " feet";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 24:
                if (height_limit.properties.htlim_bon4_cond !== null && height_limit.properties.htlim_bon4_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon4_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 25:
                if (height_limit.properties.htlim_bon4_cond !== null && height_limit.properties.htlim_bon4_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon4_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 26:
                if (height_limit.properties.htlim_bon4_cond !== null && height_limit.properties.htlim_bon4_appl === "Yes") {
                  item.display=true
                  item.source_p = height_limit.properties.htlim_bon4_srce ?? "N/A";
                  item.link = height_limit.properties.htlim_bon4_link;
                } else {
                  item.display = false
                }
                break;
              case 27:
                if (height_limit.properties.htlim_bon4_cond !== null && height_limit.properties.htlim_bon4_appl === "Yes") {
                  item.display=true
                  item.sourceHr = true
                } else {
                  item.display = false
                }
                break;
              // 5
              case 28:
                if (height_limit.properties.htlim_bon5_cond !== null && height_limit.properties.htlim_bon5_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon5_cond ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 29:
                if (height_limit.properties.htlim_bon5_cond !== null && height_limit.properties.htlim_bon5_appl === "Yes") {
                  if (height_limit.properties.htlim_bon5_valu !== null) {
                  item.display=true
                  item.title = Math.round(height_limit.properties.htlim_bon5_valu) + " feet";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.display = false;
                }
                break;
              case 30:
                if (height_limit.properties.htlim_bon5_cond !== null && height_limit.properties.htlim_bon5_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon5_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 31:
                if (height_limit.properties.htlim_bon5_cond !== null && height_limit.properties.htlim_bon5_appl === "Yes") {
                  item.display=true
                  item.title = height_limit.properties.htlim_bon5_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 32:
                if (height_limit.properties.htlim_bon5_cond !== null && height_limit.properties.htlim_bon5_appl === "Yes") {
                  item.source_p = height_limit.properties.htlim_bon5_srce ?? "N/A";
                  item.display=true
                  item.link = height_limit.properties.htlim_bon5_link;
                } else {
                  item.display = false
                }
                break;
              // case 33:
                
                break;
            }
          });
        }

        break;

      case "max_height_bonuses":
        // if (
        //   height_limit === null ||
        //   height_limit.properties === undefined ||
        //   height_limit.properties.height_limit_bonx_appl === null
        // ) {
        //   // if height_limit or height_limit.properties or height_limit.properties.height_limit_bonx_appl is null or undefined, exit the switch statement
        //   break;
        // }
        height_limit = height_limit[4];
        // item.title = height_limit.title;
        item.subTitle = height_limit.description;
        if (height_limit.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }

        if (height_limit.properties.htlim_bonx_appl === 'No' || height_limit.properties.htlim_bonx_appl === null) { 
          item.show = false
          // item.properties.map(function (item, key) { 
          //   switch (item.id) {
          //     case 1:
          //       item.small_heading = "No Data to show";
          //       item.title = " "
          //       break;

          //   }
          // });
        } else {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 2:
                item.title = height_limit.properties.htlim_bonx_cond ?? "N/A";
                break;
              case 3:
                if (height_limit.properties.htlim_bonx_valu !== null)
                  item.title = Math.round(height_limit.properties.htlim_bonx_valu) + " feet";
                else {
                  item.title = "N/A"
                }
                break;
              case 4:
                item.title = height_limit.properties.htlim_bonx_cmmt ?? "N/A";
                break;
              case 5:
                item.title = height_limit.properties.htlim_bonx_appl ?? "N/A";
                break;
              case 6:
                item.source_p = height_limit.properties.htlim_bonx_srce ?? "N/A";
                item.link = height_limit.properties.htlim_bonx_link;
                break;
              case 7:
                if (height_limit.properties.envp_bldg_htlim_bonus !== null) {
                  var numericValue = parseFloat(height_limit.properties.envp_bldg_htlim_bonus?.replace(/,/g, ""));

                  if (!isNaN(numericValue)) {
                    item.title = Math.round(numericValue).toLocaleString() + " square feet";
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.title = "N/A";
                }
                break;
            }

          });
          break;
        }
    }
  });

  constantResponse.coverage.map(function (item, key) {
    let { coverage } = apiResponse;
    switch (item.id) {
      case "coverage":
        coverage = coverage[0];
        item.title = coverage.title;
        item.subTitle = coverage.description;
        if (coverage.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = coverage.properties.cvg_adjx_cond ?? "N/A";
              break;
            case 2:
              item.title = coverage.properties.envp_grnd_cvg_area?Number(coverage.properties.envp_grnd_cvg_area).toLocaleString() : "N/A";
              break;
            case 3:
              item.title = coverage.properties.cvg_adjx_cmmt ?? "N/A";
              break;
            case 4:
              item.source_p = coverage.properties.cvg_adjx_srce ?? "N/A";
              item.link = coverage.properties.cvg_adjx_link;
              break;
          }
        });
        break;
      case "coverage_bonus":
        coverage = coverage[1];
        // item.title = coverage.title;
        item.subTitle = coverage.description;
        if (coverage.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (coverage.properties.cvg_bon1_cond === null && coverage.properties.cvg_bon2_cond === null && coverage.properties.cvg_bon3_cond === null) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 32:
                item.small_heading = "No Coverage bonuses available for this lot";
                item.title = " "
                break;
              case 1:
                item.display = false;
                break;
              case 2:
                item.display = false;
                break;
              case 3:
                item.display = false;
                break;
              case 4:
                item.display = false;
                break;
              case 5:
                item.display = false;
                break;
              case 6:
                item.display = false;
                break;
              case 7:
                item.display = false;
                break;
              case 8:
                item.display = false;
                break;
              case 9:
                item.display = false;
                break;
              case 10:
                item.display = false;
                break;
              case 11:
                item.display = false;
                break;
              case 12:
                item.display = false;
                break;
              case 13:
                item.display = false;
                break;
              case 14:
                item.display = false;
                break;
              case 15:
                item.display = false;
                break;
              case 16:
                item.display = false;
                break;
              case 17:
                item.display = false;
                break;
              case 18:
                item.display = false;
                break;
              case 19:
                item.display = false;
                break;
              case 20:
                item.display = false;
                break;
              case 21:
                item.display = false;
                break;
              case 22:
                item.display = false;
                break;
              case 23:
                item.display = false;
                break;
              case 24:
                item.display = false;
                break;
              case 25:
                item.display = false;
                break;
            }

          });
        }
        else {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title = coverage.properties.cvg_bonx_appl
                break;
              case 2:
                item.sourceHr = true
                break;
              // 1
              case 3:
                if (coverage.properties.cvg_bon1_cond !== null) {
                  item.title = coverage.properties.cvg_bon1_cond ?? "N/A";
                }
                item.display = false
                break;
              case 4:
                if (coverage.properties.cvg_bon1_cond !== null) {
                  item.title = coverage.properties.cvg_bon1_valu ?? "N/A";
                }
                item.display = false
                break;
              case 5:
                if (coverage.properties.cvg_bon1_cond !== null) {
                  item.title = coverage.properties.cvg_bon1_cmmt ?? "N/A";
                }
                item.display = false
                break;
              case 6:
                if (coverage.properties.cvg_bon1_cond !== null) {
                  item.title = coverage.properties.cvg_bon1_appl ?? "N/A";
                }
                item.display = false
                break;
              case 7:
                if (coverage.properties.cvg_bon1_cond !== null) {
                  item.source_p = coverage.properties.cvg_bon1_srce ?? "N/A";
                  item.link = coverage.properties.cvg_bon1_link
                }
                item.display = false
                break;
              case 8:
                if (coverage.properties.cvg_bon1_cond !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                }

              // 2

              case 9:
                if (coverage.properties.cvg_bon2_cond !== null) {
                  item.title = coverage.properties.cvg_bon2_cond ?? "N/A";
                }
                item.display = false

                break;
              case 10:
                if (coverage.properties.cvg_bon2_cond !== null) {
                  item.title = coverage.properties.cvg_bon2_valu ?? "N/A";
                }
                item.display = false
                break;
              case 11:
                if (coverage.properties.cvg_bon2_cond !== null) {
                  item.title = coverage.properties.cvg_bon2_cmmt ?? "N/A";
                }
                item.display = false
                break;
              case 12:
                if (coverage.properties.cvg_bon2_cond !== null) {
                  item.title = coverage.properties.cvg_bon2_appl ?? "N/A";
                }
                item.display = false
                break;
              case 13:
                if (coverage.properties.cvg_bon2_cond !== null) {
                  item.source_p = coverage.properties.cvg_bon2_srce ?? "N/A";
                  item.link = coverage.properties.cvg_bon2_link
                }
                item.display = false
                break;
              case 14:
                if (coverage.properties.cvg_bon2_cond !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                }
              // 3
              case 15:
                if (coverage.properties.cvg_bon3_cond !== null) {
                  item.title = coverage.properties.cvg_bon3_cond ?? "N/A";
                }
                item.display = false
                break;
              case 16:
                if (coverage.properties.cvg_bon3_cond !== null) {
                  item.title = coverage.properties.cvg_bon3_valu ?? "N/A";
                }
                item.display = false
                break;
              case 17:
                if (coverage.properties.cvg_bon3_cond !== null) {
                  item.title = coverage.properties.cvg_bon3_cmmt ?? "N/A";
                }
                item.display = false
                break;
              case 18:
                if (coverage.properties.cvg_bon3_cond !== null) {
                  item.title = coverage.properties.cvg_bon3_appl ?? "N/A";
                }
                item.display = false
                break;
              case 19:
                if (coverage.properties.cvg_bon3_cond !== null) {
                  item.source_p = coverage.properties.cvg_bon3_srce ?? "N/A";
                  item.link = coverage.properties.cvg_bon3_link
                }
                item.display = false
                break;
              // case 20:
              //   if (coverage.properties.cvg_bon3_cond !== null) {
              //     item.sourceHr = true
              //   }
              //   else {
              //     item.sourceHr = false
              //   }
              // 4
              // case 21:
              //   if (coverage.properties.cvg_bon4_cond !== null) {
              //     item.title = coverage.properties.cvg_bon4_cond ?? "N/A";
              //   }
              //   item.display = false
              //   break;
              // case 22:
              //   if (coverage.properties.cvg_bon4_cond !== null) {
              //     item.title = coverage.properties.cvg_bon4_valu ?? "N/A";
              //   }
              //   item.display = false
              //   break;
              // case 23:
              //   if (coverage.properties.cvg_bon4_cond !== null) {
              //     item.title = coverage.properties.cvg_bon4_cmmt ?? "N/A";
              //   }
              //   item.display = false
              //   break;
              // case 24:
              //   if (coverage.properties.cvg_bon4_cond !== null) {
              //     item.title = coverage.properties.cvg_bon4_appl ?? "N/A";
              //   }
              //   item.display = false
              //   break;
              // case 25:
              //   if (coverage.properties.cvg_bon4_cond !== null) {
              //     item.source_p = coverage.properties.cvg_bon4_srce ?? "N/A";
              //     item.link = coverage.properties.cvg_bon4_link
              //   }
              //   item.display = false
              //   break;
              // case 26:
              //   if (coverage.properties.cvg_bon4_cond !== null) {
              //     item.sourceHr = true
              //   }
              //   else {
              //     item.sourceHr = false
              //   }
              // // 5
              // case 27:
              //   if (coverage.properties.cvg_bon5_cond !== null) {
              //     item.title = coverage.properties.cvg_bon5_cond ?? "N/A";
              //   }
              //   item.display = false
              //   break;
              // case 28:
              //   if (coverage.properties.cvg_bon5_cond !== null) {
              //     item.title = coverage.properties.cvg_bon5_valu ?? "N/A";
              //   }
              //   item.display = false
              //   break;
              // case 29:
              //   if (coverage.properties.cvg_bon5_cond !== null) {
              //     item.title = coverage.properties.cvg_bon5_cmmt ?? "N/A";
              //   }
              //   item.display = false
              //   break;
              // case 30:
              //   if (coverage.properties.cvg_bon5_cond !== null) {
              //     item.title = coverage.properties.cvg_bon5_appl ?? "N/A";
              //   }
              //   item.display = false
              //   break;
              // case 31:
              //   if (coverage.properties.cvg_bon5_cond !== null) {
              //     item.source_p = coverage.properties.cvg_bon5_srce ?? "N/A";
              //     item.link = coverage.properties.cvg_bon5_link;
              //   }
              //   item.display = false
              //   break;



            }
          });
        }
        break;
      case "coverage_restriction":
        coverage = coverage[2];
        // item.title = coverage.title;
        item.subTitle = coverage.description;
        if (coverage.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (coverage.properties.cvg_res1_cond === null && coverage.properties.cvg_res2_cond === null) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title = " "

                break;
              case 2:
                item.display = false;
                item.sourceHr = false;
                break;
              case 3:
                item.display = false;
                item.sourceHr = false;
                break;
              case 4:
                item.display = false;
                item.sourceHr = false;
                break;
              case 5:
                item.display = false;
                item.sourceHr = false;
                break;
              case 6:
                item.display = false;
                item.sourceHr = false;
                break;
              case 7:
                item.display = false;
                item.sourceHr = false;
                break;
              case 8:
                item.display = false;
                item.sourceHr = false;
                break;
              case 9:
                item.display = false;
                item.sourceHr = false;
                break;
              case 10:
                item.display = false;
                item.sourceHr = false;
                break;
              case 11:
                item.display = false;
                item.sourceHr = false;
                break;
              case 12:
                item.display = false;
                item.sourceHr = false;
                break;
              case 13:
                item.display = false;
                item.sourceHr = false;
                break;
              case 14:
                item.display = false;
                item.sourceHr = false;
                break;
              case 15:
                item.display = false;
                item.sourceHr = false;
                break;
              case 16:
                item.display = false;
                item.sourceHr = false;
                break;
              case 17:
                item.display = false;
                item.sourceHr = false;
                break;
              case 18:
                item.display = false;
                item.sourceHr = false;
                break;
              case 19:
                item.display = false;
                item.sourceHr = false;
                break;
              case 20:
                item.display = false;
                item.sourceHr = false;
                break;
              case 21:
                item.display = false;
                item.sourceHr = false;
                break;
              case 22:
                item.display = false;
                item.sourceHr = false;
                break;
              case 23:
                item.display = false;
                item.sourceHr = false;
                break;
              case 24:
                item.display = false;
                item.sourceHr = false;
                break;
              case 25:
                item.display = false;
                item.sourceHr = false;
                break;

            }
          });
        } else {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title = "Any Coverage restrictions that may apply to this site. See below for more details.";

                break;
              // 1
              case 2:
                if (coverage.properties.cvg_res1_cond !== null) {
                  item.title = coverage.properties.cvg_res1_cond ?? "N/A";
                }
                item.display = false;
                break;
              case 3:
                if (coverage.properties.cvg_res1_cond !== null) {
                  item.title = coverage.properties.cvg_res1_valu ?? "N/A";
                }
                item.display = false;
                break;
              case 4:
                if (coverage.properties.cvg_res1_cond !== null) {
                  item.title = coverage.properties.cvg_res1_cmmt ?? "N/A";
                }
                item.display = false;
                break;
              case 5:
                if (coverage.properties.cvg_res1_cond !== null) {
                  item.title = coverage.properties.cvg_res1_appl ?? "N/A";
                }
                item.display = false;
                break;
              case 6:
                if (coverage.properties.cvg_res1_cond !== null) {
                  item.source_p = coverage.properties.cvg_res1_srce ?? "N/A";
                  item.link = coverage.properties.cvg_res1_link;
                }
                item.display = false;
                break;
              case 26:
                if (coverage.properties.cvg_bon4_cond !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                }

              // 2

              case 7:
                item.title = coverage.properties.cvg_res2_cond ?? "N/A";
                break;
              case 8:
                item.title = coverage.properties.cvg_res2_valu ?? "N/A";
                break;
              case 9:
                item.title = coverage.properties.cvg_res2_cmmt ?? "N/A";
                break;
              case 10:
                item.title = coverage.properties.cvg_res2_appl ?? "N/A";
                break;
              case 11:
                item.source_p = coverage.properties.cvg_res2_srce ?? "N/A";
                item.link = coverage.properties.cvg_res2_link
                break;
              case 26:
                if (coverage.properties.cvg_bon4_cond !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                }
              // 3
              // case 12:
              //   item.title = coverage.properties.cvg_res3_cond ?? "N/A";
              //   break;
              // case 13:
              //   item.title = coverage.properties.cvg_res3_valu ?? "N/A";
              //   break;
              // case 14:
              //   item.title = coverage.properties.cvg_res3_cmmt ?? "N/A";
              //   break;
              // case 15:
              //   item.title = coverage.properties.cvg_res3_appl ?? "N/A";
              //   break;
              // case 16:
              //   item.source_p = coverage.properties.cvg_res3_srce ?? "N/A";
              //   item.link = coverage.properties.cvg_res3_link
              //   break;
              //    case 26:
              //   if (coverage.properties.cvg_bon4_cond !== null) {
              //     item.sourceHr = true
              //   }
              //   else {
              //     item.sourceHr = false
              //   }
              // // 4
              // case 17:
              //   item.title = coverage.properties.cvg_res4_cond ?? "N/A";
              //   break;
              // case 18:
              //   item.title = coverage.properties.cvg_res4_valu ?? "N/A";
              //   break;
              // case 19:
              //   item.title = coverage.properties.cvg_res4_cmmt ?? "N/A";
              //   break;
              // case 20:
              //   item.title = coverage.properties.cvg_res4_appl ?? "N/A";
              //   break;
              // case 21:
              //   item.source_p = coverage.properties.cvg_res4_srce ?? "N/A";
              //   item.link = coverage.properties.cvg_res4_link
              //   break;
              // // 5
              // case 22:
              //   item.title = coverage.properties.cvg_res5_cond ?? "N/A";
              //   break;
              // case 23:
              //   item.title = coverage.properties.cvg_res5_valu ?? "N/A";
              //   break;
              // case 24:
              //   item.title = coverage.properties.cvg_res5_cmmt ?? "N/A";
              //   break;
              // case 25:
              //   item.title = coverage.properties.cvg_res5_appl ?? "N/A";
              //   break;
              // case 26:
              //   item.source_p = coverage.properties.cvg_res5_srce ?? "N/A";
              //   item.link = coverage.properties.cvg_res5_link
              //   break;
              // case 28:
              //   item.sourceHr = true;
              //   break;


            }
          });
        }

        break;


      case "max_build_footpront":
        coverage = coverage[4];
        item.title = coverage?.title;
        item.subTitle = coverage?.description;
        if (coverage?.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              if (coverage?.properties.envp_grnd_ftpt_area !== null) {
                var numericValue = parseFloat(coverage.properties.envp_grnd_ftpt_area);
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + ' square feet';
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;
            case 2:
              if (coverage?.properties.bldgftpt !== null) {
                var numericValue = parseFloat(coverage.properties.bldgftpt);
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + ' square feet';
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;
            case 3:
              if (coverage?.properties.envp_grnd_ftpt_delta !== null) {
                var numericValue = parseFloat(coverage.properties.envp_grnd_ftpt_delta);
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + ' square feet';
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;
            case 4:
              item.title = coverage?.properties.envp_grnd_ftpt_cmmt ?? "N/A";

              break;
          }
        });
        break;


      case "max_bonus_coverage":
        coverage = coverage[3];
        item.title = coverage?.title;
        item.subTitle = coverage.description;
        if (coverage?.properties.cvg_bonx_appl !== null) {
          if (coverage.properties.locked) {
            item.lock = true;
            item.subTitle = "";
          } else {
            item.lock = false;
          }
          item.show = true;
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title = coverage.properties.cvg_bonx_cond ?? "N/A";
                break;
              case 2:
                item.title = coverage.properties.cvg_bonx_valu ?? "N/A";
                break;
              case 3:
                item.title = coverage.properties.cvg_bonx_cmmt ?? "N/A";
                break;
              case 4:
                item.title = coverage.properties.cvg_bonx_appl ?? "N/A";
                break;
              case 5:
                item.source_p = coverage.properties.cvg_bonx_srce ?? "N/A";
                item.link = coverage.properties.cvg_bonx_link;
                break;
            }
          });
          break;
        } else {
          item.show = false;
        }


    }
  });
  constantResponse.massing.map(function (item, key) {
    let { massing } = apiResponse;
    switch (item.id) {
      case "massing":
        massing = massing[0];
        item.title = massing.title;
        item.subTitle = massing.description;
        if (massing.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (massing.properties.mass1_cond === null && massing.properties.mass2_cond === null && massing.properties.mass3_cond === null && massing.properties.mass4_cond === null && massing.properties.mass5_cond === null && massing.properties.mass6_cond === null) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.small_heading = "No massing rules required for this lot"
                item.title = " "
                item.hr = false;
                item.display=true
                break;
              case 2:
                item.display = false;
                item.hr = false;
                break;
              case 3:
                item.display = false;
                item.hr = false;
                break;
              case 4:
                item.display = false;
                item.hr = false;
                break;
              case 5:
                item.display = false;
                item.hr = false;
                break;
              case 6:
                item.display = false;
                item.hr = false;
                break;
              case 7:
                item.display = false;
                item.hr = false;
                break;
              case 8:
                item.display = false;
                item.hr = false;
                break;
              case 9:
                item.display = false;
                item.hr = false;
                break;
              case 10:
                item.display = false;
                item.hr = false;
                break;
              case 11:
                item.display = false;
                item.hr = false;
                break;
              case 12:
                item.display = false;
                item.hr = false;
                break;
              case 13:
                item.display = false;
                item.hr = false;
                break;
              case 14:
                item.display = false;
                item.hr = false;
                break;
              case 15:
                item.display = false;
                item.hr = false;
                break;
              case 16:
                item.display = false;
                item.hr = false;
                break;
              case 17:
                item.display = false;
                item.hr = false;
                break;
              case 18:
                item.display = false;
                item.hr = false;
                break;
              case 19:
                item.display = false;
                item.hr = false;
                break;
              case 20:
                item.display = false;
                item.hr = false;
                break;
              case 21:
                item.display = false;
                item.hr = false;
                break;
              case 22:
                item.display = false;
                item.hr = false;
                break;
              case 23:
                item.display = false;
                item.hr = false;
                break;
              case 24:
                item.display = false;
                item.hr = false;
                break;
              case 25:
                item.display = false;
                item.hr = false;
                break;
                case 26:
                item.display = false;
                item.hr = false;
                break;
                case 27:
                  item.display = false;
                  item.hr = false;
                  break;
                  case 28:
                    item.display = false;
                    item.hr = false;
                    break;
                    case 29:
                      item.display = false;
                      item.hr = false;
                      break;
                      case 30:
                        item.display = false;
                        item.hr = false;
                        break;
                        case 31:
                          item.display = false;
                          item.hr = false;
                          break;
                        
              case 35:
                item.display=false

            }
          });
        } else {
          item.properties.map(function (item, key) {
            if(item.id!==1)
            {item.display=true}
            else item.display = false
            switch (item.id) {
              case 2:
                
                item.title = massing.properties.mass1_cond ?? "";
                break;
              case 3:

                item.title = massing.properties.mass1_cmmt ?? "";
                // item.title_desc = "massing.properties.mass1_cmmt" ?? "";
                break;
              case 4:
                item.title_desc = massing.properties.mass1_cmmt ?? "";
                break;
              case 5:
                item.source_p = massing.properties.mass1_srce ?? "";
                item.link = massing.properties.mass1_link;
                break;
              case 35:
                item.sourceHr = true;
                break;
              // 
              case 6:
              if(massing.properties.mass1_cond)
              {  item.onlyLine = true;
                item.hr=true
                item.sourceHr=true}
                else {  item.onlyLine = false;
                  item.hr=false
                  item.sourceHr=false}
                break;


//dd
case 7:
  item.title = massing.properties.mass2_cond ?? "";
  break;
case 8:

  item.title = massing.properties.mass2_cmmt ?? "";
  // item.title_desc = "massing.properties.mass2_cmmt" ?? "";
  break;
case 9:
  item.title_desc = massing.properties.mass2_cmmt ?? "";
  break;
case 10:
  item.source_p = massing.properties.mass2_srce ?? "";
  item.link = massing.properties.mass2_link;
  break;
case 35:
  item.sourceHr = true;
  break;
// 
case 11:
  if(massing.properties.mass2_cond)
  {  item.onlyLine = true;
    item.hr=true
    item.sourceHr=true}
    else {  item.onlyLine = false;
      item.hr=false
      item.sourceHr=false}
  break;

  case 12:
    item.title = massing.properties.mass3_cond ?? "";
    break;
  case 13:

    item.title = massing.properties.mass3_cmmt ?? "";
    // item.title_desc = "massing.properties.mass3_cmmt" ?? "";
    break;
  case 14:
    item.title_desc = massing.properties.mass3_cmmt ?? "";
    break;
  case 15:
    item.source_p = massing.properties.mass3_srce ?? "";
    item.link = massing.properties.mass3_link;
    break;
  case 35:
    item.sourceHr = true;
    break;
  // 
  case 16:
    if(massing.properties.mass3_cond)
    {  item.onlyLine = true;
      item.hr=true
      item.sourceHr=true}
      else {  item.onlyLine = false;
        item.hr=false
        item.sourceHr=false}
    break; 
    
//4
case 17:
  item.title = massing.properties.mass4_cond ?? "";
  break;
case 18:

  item.title = massing.properties.mass4_cmmt ?? "";
  // item.title_desc = "massing.properties.mass4_cmmt" ?? "";
  break;
case 19:
  item.title_desc = massing.properties.mass4_cmmt ?? "";
  break;
case 20:
  item.source_p = massing.properties.mass4_srce ?? "";
  item.link = massing.properties.mass4_link;
  break;
case 35:
  item.sourceHr = true;
  break;
// 
case 21:
  if(massing.properties.mass4_cond)
  {  item.onlyLine = true;
    item.hr=true
    item.sourceHr=true}
    else {  item.onlyLine = false;
      item.hr=false
      item.sourceHr=false}
  break; 

//5
case 22:
  item.title = massing.properties.mass5_cond ?? "";
  break;
case 23:

  item.title = massing.properties.mass5_cmmt ?? "";
  // item.title_desc = "massing.properties.mass5_cmmt" ?? "";
  break;
case 24:
  item.title_desc = massing.properties.mass5_cmmt ?? "";
  break;
case 25:
  item.source_p = massing.properties.mass5_srce ?? "";
  item.link = massing.properties.mass5_link;
  break;
case 35:
  item.sourceHr = true;
  break;
// 
case 26:
  if(massing.properties.mass5_cond)
  {  item.onlyLine = true;
    item.hr=true
    item.sourceHr=true}
    else {  item.onlyLine = false;
      item.hr=false
      item.sourceHr=false}
  break; 

//6  
case 27:
item.title = massing.properties.mass6_cond ?? "";
break;
case 28:

item.title = massing.properties.mass6_cmmt ?? "";
// item.title_desc = "massing.properties.mass6_cmmt" ?? "";
break;
case 29:
item.title_desc = massing.properties.mass6_cmmt ?? "";
break;
case 30:
item.source_p = massing.properties.mass6_srce ?? "";
item.link = massing.properties.mass6_link;
break;
case 35:
item.sourceHr = true;
break;
// 
case 31:
  if(massing.properties.mass6_cond)
  {  item.onlyLine = true;
    item.hr=true
    item.sourceHr=true}
    else {  item.onlyLine = false;
      item.hr=false
      item.sourceHr=false}
break; 
//dd
            }
          });
        }
        break;

    }
  });
  constantResponse.setbacks.map(function (item, key) {
    let { setbacks } = apiResponse;
    switch (item.id) {
      case "setback":
        setbacks = setbacks[0];
        item.title = setbacks.title;
        item.subTitle = setbacks.description;
        if (setbacks.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (setbacks.properties.setb1_appl === null &&
          setbacks.properties.setb2_appl === null &&
          setbacks.properties.setb3_appl === null &&
          setbacks.properties.setb4_appl === null &&
          setbacks.properties.setb5_appl === null &&
          setbacks.properties.setb6_appl === null &&
          setbacks.properties.setb7_appl === null) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.small_heading = "No setbacks required for this lot";
                item.title = " ";
                item.display = false;
                item.sourceHr = false;
                break;
              case 2:
                item.display = false;
                item.sourceHr = false;
                break;
              case 3:
                item.display = false;
                item.sourceHr = false;
                break;
              case 4:
                item.display = false;
                item.sourceHr = false;
                break;
              case 5:
                item.display = false;
                item.sourceHr = false;
                break;
              case 6:
                item.display = false;
                item.sourceHr = false;
                break;
              case 7:
                item.display = false;
                item.sourceHr = false;
                break;
              case 8:
                item.display = false;
                item.sourceHr = false;
                break;
              case 9:
                item.display = false;
                item.sourceHr = false;
                break;
              case 10:
                item.display = false;
                item.sourceHr = false;
                break;
              case 11:
                item.display = false;
                item.sourceHr = false;
                break;
              case 12:
                item.display = false;
                item.sourceHr = false;
                break;
              case 13:
                item.display = false;
                item.sourceHr = false;
                break;
              case 14:
                item.display = false;
                item.sourceHr = false;
                break;
              case 15:
                item.display = false;
                item.sourceHr = false;
                break;
              case 16:
                item.display = false;
                item.sourceHr = false;
                break;
              case 17:
                item.display = false;
                item.sourceHr = false;
                break;
              case 18:
                item.display = false;
                item.sourceHr = false;
                break;
              case 19:
                item.display = false;
                item.sourceHr = false;
                break;
              case 20:
                item.display = false;
                item.sourceHr = false;
                break;
              case 21:
                item.display = false;
                item.sourceHr = false;
                break;
              case 22:
                item.display = false;
                item.sourceHr = false;
                break;
              case 23:
                item.display = false;
                item.sourceHr = false;
                break;

            }
          });
        } else {
          item.properties.map(function (item, key) {
            switch (item.id) {
              // 1
              case 2:
                if (setbacks.properties.setb1_rule !== null && setbacks.properties.setb1_appl !== null) {
                  item.title = setbacks.properties.setb1_rule ?? "N/A";
                }
                else item.display = false
                break;
              case 3:
                if (setbacks.properties.setb1_rule !== null && setbacks.properties.setb1_appl !== null) {
                  item.title = setbacks.properties.setb1_dist ?? "N/A";
                }
                else item.display = false
                break;
              case 4:
                if (setbacks.properties.setb1_rule !== null && setbacks.properties.setb1_appl !== null) {
                  item.source_p = setbacks.properties.setb1_srce ?? "N/A";
                  item.link = setbacks.properties.setb1_link;
                }
                else item.display = false
                break;
              case 5:
                if (setbacks.properties.setb1_rule !== null && setbacks.properties.setb1_appl !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;

              // 

              // 2
              case 6:
                if (setbacks.properties.setb2_rule !== null && setbacks.properties.setb2_appl !== null) {
                  item.title = setbacks.properties.setb2_rule ?? "N/A";
                }
                else item.display = false
                break;
              case 7:
                if (setbacks.properties.setb2_rule !== null && setbacks.properties.setb2_appl !== null) {
                  item.title = setbacks.properties.setb2_dist ?? "N/A";
                }
                else item.display = false
                break;
              case 8:
                if (setbacks.properties.setb2_rule !== null && setbacks.properties.setb2_appl !== null) {
                  item.source_p = setbacks.properties.setb2_srce ?? "N/A";
                  item.link = setbacks.properties.setb2_link;
                }
                else item.display = false
                break;
              case 9:
                if (setbacks.properties.setb2_rule !== null && setbacks.properties.setb2_appl !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }

                break;
              // 3
              case 10:
                if (setbacks.properties.setb3_rule !== null && setbacks.properties.setb3_appl !== null) {
                  item.title = setbacks.properties.setb3_rule ?? "N/A";
                }
                else item.display = false
                break;
              case 11:
                if (setbacks.properties.setb3_rule !== null && setbacks.properties.setb3_appl !== null) {
                  item.title = setbacks.properties.setb3_dist ?? "N/A";
                }
                else item.display = false
                break;
              case 12:
                if (setbacks.properties.setb3_rule !== null && setbacks.properties.setb3_appl !== null) {
                  item.source_p = setbacks.properties.setb3_srce ?? "N/A";
                  item.link = setbacks.properties.setb3_link;
                }
                else item.display = false
                break;
              case 13:
                if (setbacks.properties.setb3_rule !== null && setbacks.properties.setb3_appl !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;
              // 4
              case 14:
                if (setbacks.properties.setb4_rule !== null && setbacks.properties.setb4_appl !== null) {
                  item.title = setbacks.properties.setb4_rule ?? "N/A";
                }
                else item.display = false
                break;
              case 15:
                if (setbacks.properties.setb4_rule !== null && setbacks.properties.setb4_appl !== null) {
                  item.title = setbacks.properties.setb4_dist ?? "N/A";
                }
                else item.display = false
                break;
              case 16:
                if (setbacks.properties.setb4_rule !== null && setbacks.properties.setb4_appl !== null) {
                  item.source_p = setbacks.properties.setb4_srce ?? "N/A";
                  item.link = setbacks.properties.setb4_link;
                }
                else item.display = false
                break;
              case 17:
                if (setbacks.properties.setb4_rule !== null && setbacks.properties.setb4_appl !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;
              // 5
              case 18:
                if (setbacks.properties.setb5_rule !== null && setbacks.properties.setb5_appl !== null) {
                  item.title = setbacks.properties.setb5_rule ?? "N/A";
                }
                else item.display = false
                break;
              case 19:
                if (setbacks.properties.setb5_rule !== null && setbacks.properties.setb5_appl !== null) {
                  item.title = setbacks.properties.setb5_dist ?? "N/A";
                }
                else item.display = false
                break;
              case 20:
                if (setbacks.properties.setb5_rule !== null && setbacks.properties.setb5_appl !== null) {
                  item.source_p = setbacks.properties.setb5_srce ?? "N/A";
                  item.link = setbacks.properties.setb1_link;
                }
                else item.display = false
                break;
              case 21:
                if (setbacks.properties.setb5_rule !== null && setbacks.properties.setb5_appl !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;
              // 6
              case 22:
                if (setbacks.properties.setb6_rule !== null && setbacks.properties.setb6_appl !== null) {
                  item.title = setbacks.properties.setb6_rule ?? "N/A";
                }
                else item.display = false
                break;
              case 23:
                if (setbacks.properties.setb6_rule !== null && setbacks.properties.setb6_appl !== null) {
                  item.title = setbacks.properties.setb6_dist ?? "N/A";
                }
                else item.display = false
                break;
              case 24:
                if (setbacks.properties.setb6_rule !== null && setbacks.properties.setb6_appl !== null) {
                  item.source_p = setbacks.properties.setb6_srce ?? "N/A";
                  item.link = setbacks.properties.setb6_link;
                }
                else item.display = false
                break;
              case 25:
                if (setbacks.properties.setb6_rule !== null && setbacks.properties.setb6_appl !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;
              // 7
              case 26:
                if (setbacks.properties.setb7_rule !== null && setbacks.properties.setb7_appl !== null) {
                  item.title = setbacks.properties.setb7_rule ?? "N/A";
                }
                else item.display = false
                break;
              case 27:
                if (setbacks.properties.setb7_rule !== null && setbacks.properties.setb7_appl !== null) {
                  item.title = setbacks.properties.setb7_dist ?? "N/A";
                }
                else item.display = false
                break;
              case 28:
                if (setbacks.properties.setb7_rule !== null && setbacks.properties.setb7_appl !== null) {
                  item.source_p = setbacks.properties.setb7_srce ?? "N/A";
                  item.link = setbacks.properties.setb7_link;
                }
                else item.display = false
                break;
              case 29:
                if (setbacks.properties.setb7_rule !== null && setbacks.properties.setb7_appl !== null) {
                  item.sourceHr = true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;


              //  
              case 30:
                item.title = setbacks.properties.setb_cmmt ?? "N/A";
                break;
            }
          });
        }
        break;

      case "max_build_footpront":
        setbacks = setbacks[1];
        item.title = setbacks.title;
        item.subTitle = setbacks.description;
        if (setbacks.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              if (setbacks.properties.envp_grnd_ftpt_area !== null) {
                var numericValue = parseFloat(setbacks.properties.envp_grnd_ftpt_area);
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + ' square feet';
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;
            case 2:
              if (setbacks.properties.bldgftpt !== null) {
                var numericValue = parseFloat(setbacks.properties.bldgftpt);
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + ' square feet';
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;
            case 3:
              if (setbacks.properties.envp_grnd_ftpt_delta !== null) {
                var numericValue = parseFloat(setbacks.properties.envp_grnd_ftpt_delta);
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + ' square feet';
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;
            case 4:
              item.title = setbacks.properties.envp_grnd_ftpt_cmmt ?? "N/A";

              break;
          }
        });
        break;
    }
  });
  constantResponse.other.map(function (item, key) {
    let { other } = apiResponse;
    switch (item.id) {
      case "other":
        other = other[0];
        item.title = other.title;
        item.subTitle = other.description;
        if (other.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (other.properties.otr1_cond === null &&
          other.properties.otr2_cond === null &&
          other.properties.otr3_cond === null &&
          other.properties.otr4_cond === null &&
          other.properties.otr5_cond === null &&
          other.properties.otr6_cond === null &&
          other.properties.otr7_cond === null &&
          other.properties.otr8_cond === null &&
          other.properties.otr9_cond === null) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 2:
                item.small_heading = "No other regulations to display";
                item.title = " ";
                item.display = true;
                item.sourceHr = false;
                break;
              // case 2:
              //   item.display = false;
              //   item.sourceHr = false;
              //   break;
              case 3:
                item.display = false;
                item.sourceHr = false;
                break;
              case 4:
                item.display = false;
                item.sourceHr = false;
                break;
              case 5:
                item.display = false;
                item.sourceHr = false;
                break;
              case 6:
                item.display = false;
                item.sourceHr = false;
                break;
              case 7:
                item.display = false;
                item.sourceHr = false;
                break;
              case 8:
                item.display = false;
                item.sourceHr = false;
                break;
              case 9:
                item.display = false;
                item.sourceHr = false;
                break;
              case 10:
                item.display = false;
                item.sourceHr = false;
                break;
              case 11:
                item.display = false;
                item.sourceHr = false;
                break;
              case 12:
                item.display = false;
                item.sourceHr = false;
                break;
              case 13:
                item.display = false;
                item.sourceHr = false;
                break;
              case 14:
                item.display = false;
                item.sourceHr = false;
                break;
              case 15:
                item.display = false;
                item.sourceHr = false;
                break;
              case 16:
                item.display = false;
                item.sourceHr = false;
                break;
              case 17:
                item.display = false;
                item.sourceHr = false;
                break;
              case 18:
                item.display = false;
                item.sourceHr = false;
                break;
              case 19:
                item.display = false;
                item.sourceHr = false;
                break;
              case 20:
                item.display = false;
                item.sourceHr = false;
                break;
              case 21:
                item.display = false;
                item.sourceHr = false;
                break;
              case 22:
                item.display = false;
                item.sourceHr = false;
                break;
              case 23:
                item.display = false;
                item.sourceHr = false;
                break;
              case 24:
                item.display = false;
                item.sourceHr = false;
                break;
              case 25:
                item.display = false;
                item.sourceHr = false;
                break;
              case 26:
                item.display = false;
                item.sourceHr = false;
                break;
              case 27:
                item.display = false;
                item.sourceHr = false;
                break;
              case 28:
                item.display = false;
                item.sourceHr = false;
                break;
              case 29:
                item.display = false;
                item.sourceHr = false;
                break;
              case 30:
                item.display = false;
                item.sourceHr = false;
                break;
              case 31:
                item.display = false;
                item.sourceHr = false;
                break;
              case 32:
                item.display = false;
                item.sourceHr = false;
                break;
              case 33:
                item.display = false;
                item.sourceHr = false;
                break;
              case 34:
                item.display = false;
                item.sourceHr = false;
                break;
              case 35:
                item.display = false;
                item.sourceHr = false;
                break;
              case 36:
                item.display = false;
                item.sourceHr = false;
                break;
              case 37:
                item.display = false;
                item.sourceHr = false;
                break;
              case 38:
                item.display = false;
                item.sourceHr = false;
                break;
              case 39:
                item.display = false;
                item.sourceHr = false;
                break;
              case 40:
                item.display = false;
                item.sourceHr = false;
                break;
              case 41:
                item.display = false;
                item.sourceHr = false;
                break;
              case 42:
                item.display = false;
                item.sourceHr = false;
                break;
              case 43:
                item.display = false;
                item.sourceHr = false;
                break;
              case 44:
                item.display = false;
                item.sourceHr = false;
                break;
              case 45:
                item.display = false;
                item.sourceHr = false;
                break;
              case 46:
                item.display = false;
                item.sourceHr = false;
                break;
              case 47:
                item.display = false;
                item.sourceHr = false;
                break;
              case 48:
                item.display = false;
                item.sourceHr = false;
                break;
              case 49:
                item.display = false;
                item.sourceHr = false;
                break;
              case 50:
                item.display = false;
                item.sourceHr = false;
                break;
              case 51:
                item.display = false;
                item.sourceHr = false;
                break;
              case 52:
                item.display = false;
                item.sourceHr = false;
                break;
              case 53:
                item.display = false;
                item.sourceHr = false;
                break;
              case 54:
                item.display = false;
                item.sourceHr = false;
                break;
              case 55:
                item.display = false;
                item.sourceHr = false;
                break;
            }
          });
        } else {
          item.properties.map(function (item, key) {
            switch (item.id) {
              // 1
              case 2:
                if (other.properties.otr1_cond !== null) {
                  item.small_heading="Regulation: "
                  item.title = other.properties.otr1_cond ?? "N/A";
                item.display = true
                item.sourceHr=false
                }
                break;
              case 3:
                if (other.properties.otr1_cond !== null) {
                  item.title = other.properties.otr1_valu ?? "N/A";
                item.display = true
                }
                
                break;
              case 4:
                if (other.properties.otr1_cond !== null) {
                  item.title = other.properties.otr1_cmmt ?? "N/A";
                item.display = true
                }
                
                break;
              case 5:
                if (other.properties.otr1_cond !== null) {
                  item.title = other.properties.otr1_appl ?? "N/A";
                item.display = true
                }
                
                break;
              case 6:
                if (other.properties.otr1_cond !== null) {
                  item.source_p = other.properties.otr1_srce ?? "N/A";
                  item.link = other.properties.otr1_link;
                item.display = true
                }
                
                break;
              case 7:
                if (other.properties.otr1_cond !== null) {
                  item.sourceHr = true
                  item.display=true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;



              // 2

              case 8:
                if (other.properties.otr2_cond !== null) {
                  item.title = other.properties.otr2_cond ?? "N/A";
                item.display = true
                }
                
                break;
              case 9:
                if (other.properties.otr2_cond !== null) {
                  item.title = other.properties.otr2_valu ?? "N/A";
                item.display = true
                }
                
                break;
              case 10:
                if (other.properties.otr2_cond !== null) {
                  item.title = other.properties.otr2_cmmt ?? "N/A";
                item.display = true
                }
                
                break;
              case 11:
                if (other.properties.otr2_cond !== null) {
                  item.title = other.properties.otr2_appl ?? "N/A";
                item.display = true
                }
                
                break;
              case 12:
                if (other.properties.otr2_cond !== null) {
                  item.source_p = other.properties.otr2_srce ?? "N/A";
                  item.link = other.properties.otr2_link;
                item.display = true
                }
                
                break;
              case 13:
                if (other.properties.otr2_cond !== null) {
                  item.sourceHr = true
                  item.display=true
                }
                else {
                  item.display = false
                  item.sourceHr = false
                  
                }
                break;


              // 3


              case 14:
                if (other.properties.otr3_cond !== null) {
                  item.title = other.properties.otr3_cond ?? "N/A";
                item.display = true
                }
                
                break;
              case 15:
                if (other.properties.otr3_cond !== null) {
                  item.title = other.properties.otr3_valu ?? "N/A";
                item.display = true
                }
                
                break;
              case 16:
                if (other.properties.otr3_cond !== null) {
                  item.title = other.properties.otr3_cmmt ?? "N/A";
                item.display = true
                }
                
                break;
              case 17:
                if (other.properties.otr3_cond !== null) {
                  item.title = other.properties.otr3_appl ?? "N/A";
                item.display = true
                }
                
                break;
              case 18:
                if (other.properties.otr3_cond !== null) {
                  item.source_p = other.properties.otr3_srce ?? "N/A";
                  item.link = other.properties.otr3_link;
                item.display = true
                }
                
                break;
              case 19:
                if (other.properties.otr3_cond !== null) {
                  item.sourceHr = true
                  item.display=true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;


              // 4

              case 20:
                if (other.properties.otr4_cond !== null) {
                  item.title = other.properties.otr4_cond ?? "N/A";
                item.display = true
                }
                
                break;
              case 21:
                if (other.properties.otr4_cond !== null) {
                  item.title = other.properties.otr4_valu ?? "N/A";
                item.display = true
                }
                
                break;
              case 22:
                if (other.properties.otr4_cond !== null) {
                  item.title = other.properties.otr4_cmmt ?? "N/A";
                item.display = true
                }
                
                break;
              case 23:
                if (other.properties.otr4_cond !== null) {
                  item.title = other.properties.otr4_appl ?? "N/A";
                item.display = true
                }
                
                break;
              case 24:
                if (other.properties.otr4_cond !== null) {
                  item.source_p = other.properties.otr4_srce ?? "N/A";
                  item.link = other.properties.otr4_link;
                item.display = true
                }
                
                break;
              case 25:
                if (other.properties.otr4_cond !== null) {
                  item.sourceHr = true
                  item.display=true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;



              // 5


              case 26:
                if (other.properties.otr5_cond !== null) {
                  item.title = other.properties.otr5_cond ?? "N/A";
                item.display=true
                }
                
                break;
              case 27:
                if (other.properties.otr5_cond !== null) {
                  item.title = other.properties.otr5_valu ?? "N/A";
                item.display=true
                }
                
                break;
              case 28:
                if (other.properties.otr5_cond !== null) {
                  item.title = other.properties.otr5_cmmt ?? "N/A";
                item.display=true
                }
                
                break;
              case 29:
                if (other.properties.otr5_cond !== null) {
                  item.title = other.properties.otr5_appl ?? "N/A";
                item.display=true
                }
                
                break;
              case 30:
                if (other.properties.otr5_cond !== null) {
                  item.source_p = other.properties.otr5_srce ?? "N/A";
                  item.link = other.properties.otr5_link;
                item.display=true
                }
                
                break;
              case 31:
                if (other.properties.otr5_cond !== null) {
                  item.sourceHr = true
                  item.display=true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;


              // 6

              case 32:
                if (other.properties.otr6_cond !== null) {
                  item.title = other.properties.otr6_cond ?? "N/A";
                item.display = true
                }
                
                break;
              case 33:
                if (other.properties.otr6_cond !== null) {
                  item.title = other.properties.otr6_valu ?? "N/A";
                item.display = true
                }
                
                break;
              case 34:
                if (other.properties.otr6_cond !== null) {
                  item.title = other.properties.otr6_cmmt ?? "N/A";
                item.display = true
                }
                
                break;
              case 35:
                if (other.properties.otr6_cond !== null) {
                  item.title = other.properties.otr6_appl ?? "N/A";
                item.display = true
                }
                
                break;
              case 36:
                if (other.properties.otr6_cond !== null) {
                  item.source_p = other.properties.otr6_srce ?? "N/A";
                  item.link = other.properties.otr6_link;
                item.display = true
                }
                
                break;
              case 37:
                if (other.properties.otr6_cond !== null) {
                  item.sourceHr = true
                  item.display = true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;


              // 7

              case 38:
                if (other.properties.otr7_cond !== null) {
                  item.title = other.properties.otr7_cond ?? "N/A";
                item.display = true
                }
                
                break;
              case 39:
                if (other.properties.otr7_cond !== null) {
                  item.title = other.properties.otr7_valu ?? "N/A";
                item.display = true
                }
                
                break;
              case 40:
                if (other.properties.otr7_cond !== null) {
                  item.title = other.properties.otr7_cmmt ?? "N/A";
                item.display = true
                }
                
                break;
              case 41:
                if (other.properties.otr7_cond !== null) {
                  item.title = other.properties.otr7_appl ?? "N/A";
                item.display = true
                }
                
                break;
              case 42:
                if (other.properties.otr7_cond !== null) {
                  item.source_p = other.properties.otr7_srce ?? "N/A";
                  item.link = other.properties.otr7_link;
                item.display = true
                }
                
                break;
              case 43:
                if (other.properties.otr7_cond !== null) {
                  item.sourceHr = true
                  item.display=true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;
              // 8


              case 44:
                if (other.properties.otr8_cond !== null) {
                  item.title = other.properties.otr8_cond ?? "N/A";
                item.display = true
                }
                
                break;
              case 45:
                if (other.properties.otr8_cond !== null) {
                  item.title = other.properties.otr8_valu ?? "N/A";
                item.display = true
                }
                
                break;
              case 46:
                if (other.properties.otr8_cond !== null) {
                  item.title = other.properties.otr8_cmmt ?? "N/A";
                item.display = true
                }
                
                break;
              case 47:
                if (other.properties.otr8_cond !== null) {
                  item.title = other.properties.otr8_appl ?? "N/A";
                item.display = true
                }
                
                break;
              case 48:
                if (other.properties.otr8_cond !== null) {
                  item.source_p = other.properties.otr8_srce ?? "N/A";
                  item.link = other.properties.otr8_link;
                item.display = true
                }
                
                break;
              case 49:
                if (other.properties.otr8_cond !== null) {
                  item.sourceHr = true
                  item.display=true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;


              // 9


              case 50:
                if (other.properties.otr9_cond !== null) {
                  item.title = other.properties.otr9_cond ?? "N/A";
                item.display = true
                }
                
                break;
              case 51:
                if (other.properties.otr9_cond !== null) {
                  item.title = other.properties.otr9_valu ?? "N/A";
                item.display = true
                }
                
                break;
              case 52:
                if (other.properties.otr9_cond !== null) {
                  item.title = other.properties.otr9_cmmt ?? "N/A";
                item.display = true
                }
                
                break;
              case 53:
                if (other.properties.otr9_cond !== null) {
                  item.title = other.properties.otr9_appl ?? "N/A";
                item.display = true
                }
                
                break;
              case 54:
                if (other.properties.otr9_cond !== null) {
                  item.source_p = other.properties.otr9_srce ?? "N/A";
                  item.link = other.properties.otr9_link;
                item.display = true
                }
                
                break;
              case 55:
                if (other.properties.otr9_cond !== null) {
                  item.sourceHr = true
                  item.display=true
                }
                else {
                  item.sourceHr = false
                  item.display = false
                }
                break;

            }
          });
        }
        break;


    }
  });
  constantResponse.residential_units.map(function (item, key) {
    let { residential_units } = apiResponse;
    switch (item.id) {
      case "unit_minimum":
        residential_units = residential_units[0];
        item.title = residential_units.title;
        item.subTitle = residential_units.description;
        if (residential_units.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title =
                residential_units.properties.umin_adjx_cond ?? "N/A";
              break;
            case 2:
              item.title = residential_units.properties.umin_adjx_valu
                ? residential_units.properties.umin_adjx_valu + " units"
                : "N/A";
              break;
            case 3:
              item.title =
                residential_units.properties.umin_adjx_cmmt ?? "N/A";
              break;
            case 4:
              item.source_p =
                residential_units.properties.umin_adjx_srce ?? "N/A";
              item.link = residential_units.properties.umin_adjx_link;
              break;
          }
        });
        break;


      case "Unit_maximum":
        residential_units = residential_units[1];
        item.title = residential_units.title;
        item.subTitle = residential_units.description;
        if (residential_units.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title =
                residential_units.properties.umax_adjx_cond ?? "N/A";
              break;
            case 2:
              item.title = residential_units.properties.umax_adjx_valu
                ? residential_units.properties.umax_adjx_valu + " units"
                : "N/A";
              break;
            case 3:
              item.title =
                residential_units.properties.umax_adjx_cmmt ?? "N/A";
              break;
            case 4:
              item.source_p =
                residential_units.properties.umax_adjx_srce ?? "N/A";
              item.link = residential_units.properties.umax_adjx_link;
              break;
          }
        });
        break;





      case "unit_bonus":
        residential_units = residential_units[2];
        item.title = residential_units.title;
        item.subTitle = residential_units.description;
        if (residential_units.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          if (residential_units.properties.umax_bon1_cond === null && residential_units.properties.umax_bon2_cond === null && residential_units.properties.umax_bon3_cond === null) {
            switch (item.id) {
              case 1:
                item.small_heading = "No Bonuses are available for this lot";
                item.title = " "
                item.hr = false;
                break;
              case 2:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 3:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 4:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 5:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 6:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 7:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 8:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 9:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 10:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 11:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 12:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 13:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 14:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 15:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 16:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 17:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 18:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 19:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 20:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 21:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 22:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;
              case 23:
                item.hr = false;
                item.sourceHr = false;
                item.display = false;
                break;

            }
          } else {
            switch (item.id) {
              case 2:
                if (residential_units.properties.umax_bon1_cond !== null && residential_units.properties.umax_bon1_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon1_cond
                } else {
                  item.display = false
                }
                break;
              case 3:
                if (residential_units.properties.umax_bon1_cond !== null && residential_units.properties.umax_bon1_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon1_valu ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 4:
                if (residential_units.properties.umax_bon1_cond !== null && residential_units.properties.umax_bon1_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon1_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 5:
                if (residential_units.properties.umax_bon1_cond !== null && residential_units.properties.umax_bon1_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon1_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 6:
                if (residential_units.properties.umax_bon1_cond !== null && residential_units.properties.umax_bon1_appl == "Yes") {
                  item.source_p = residential_units.properties.umax_bon1_srce ?? "N/A";
                  item.link = residential_units.properties.umax_bon1_link;
                } else {
                  item.display = false
                }
              case 7:
                if (residential_units.properties.umax_bon1_cond !== null && residential_units.properties.umax_bon1_appl == "Yes") {
                  item.sourceHr = true
                } else {
                  item.sourceHr = false
                  item.display = false
                }
                break;
              // 2
              case 8:
                if (residential_units.properties.umax_bon2_cond !== null && residential_units.properties.umax_bon2_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon2_cond
                } else {
                  item.display = false
                }
                break;
              case 9:
                if (residential_units.properties.umax_bon2_cond !== null && residential_units.properties.umax_bon2_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon2_valu ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 10:
                if (residential_units.properties.umax_bon2_cond !== null && residential_units.properties.umax_bon2_appl == "Yes") {
                  item.title =
                    residential_units.properties.umax_bon2_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 11:
                if (residential_units.properties.umax_bon2_cond !== null && residential_units.properties.umax_bon2_appl == "Yes") {
                  item.title =
                    residential_units.properties.umax_bon2_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 12:
                if (residential_units.properties.umax_bon2_cond !== null && residential_units.properties.umax_bon2_appl == "Yes") {
                  item.source_p = residential_units.properties.umax_bon2_srce ?? "N/A";
                  item.link = residential_units.properties.umax_bon2_link;
                } else {
                  item.display = false
                }
                break;
              case 13:
                if (residential_units.properties.umax_bon2_cond !== null && residential_units.properties.umax_bon2_appl == "Yes") {
                  item.sourceHr = true
                } else {
                  item.sourceHr = false
                  item.display = false
                }
                break;
              // 3
              case 14:
                if (residential_units.properties.umax_bon3_cond !== null && residential_units.properties.umax_bon3_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon3_cond
                } else {
                  item.display = false
                }
                break;
              case 15:
                if (residential_units.properties.umax_bon3_cond !== null && residential_units.properties.umax_bon3_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon3_valu ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 16:
                if (residential_units.properties.umax_bon3_cond !== null && residential_units.properties.umax_bon3_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon3_cmmt ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 17:
                if (residential_units.properties.umax_bon3_cond !== null && residential_units.properties.umax_bon3_appl == "Yes") {
                  item.title = residential_units.properties.umax_bon3_appl ?? "N/A";
                } else {
                  item.display = false
                }
                break;
              case 18:
                if (residential_units.properties.umax_bon3_cond !== null && residential_units.properties.umax_bon3_appl == "Yes") {
                  item.source_p = residential_units.properties.umax_bon3_srce ?? "N/A";
                  item.link = residential_units.properties.umax_bon3_link;
                } else {
                  item.display = false
                }
                break;
              case 19:
                if (residential_units.properties.umax_bon3_cond !== null && residential_units.properties.umax_bon3_appl == "Yes") {
                  item.sourceHr = true
                } else {
                  item.sourceHr = false
                  item.display = false
                }
                break;
              // change case
              case 20:
                if (residential_units.properties.umax_bon1_appl == "Yes" ||
                  residential_units.properties.umax_bon2_appl == "Yes" ||
                  residential_units.properties.umax_bon3_appl == "Yes") {
                  item.display = false
                }
                else {
                  item.title = "No Data Found"
                }
                break;
              // 4
              // case 17:
              //   if (residential_units.properties.umax_bon4_cond !== null && residential_units.properties.umax_bon4_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon4_cond
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 18:
              //   if (residential_units.properties.umax_bon4_cond !== null && residential_units.properties.umax_bon4_appl == "Yes") {
              //     item.title =
              //       residential_units.properties.umax_bon4_valu ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 19:
              //   if (residential_units.properties.umax_bon4_cond !== null && residential_units.properties.umax_bon4_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon4_cmmt ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 20:
              //   if (residential_units.properties.umax_bon4_cond !== null && residential_units.properties.umax_bon4_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon4_appl ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 21:
              //   if (residential_units.properties.umax_bon4_cond !== null && residential_units.properties.umax_bon4_appl == "Yes") {
              //     item.source_p = residential_units.properties.umax_bon4_srce ?? "N/A";
              //     item.link = residential_units.properties.umax_bon4_link;
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 7:
              //   if (residential_units.properties.umax_bon4_cond !== null && residential_units.properties.umax_bon4_appl == "Yes") {
              //     item.sourceHr = true
              //   } else {
              //     item.sourceHr = false
              //     item.display = false
              //   }
              //   break;
              // // 5
              // case 22:
              //   if (residential_units.properties.umax_bon5_cond !== null && residential_units.properties.umax_bon5_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon5_cond
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 23:
              //   if (residential_units.properties.umax_bon5_cond !== null && residential_units.properties.umax_bon5_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon5_valu ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 24:
              //   if (residential_units.properties.umax_bon1_cond !== null && residential_units.properties.umax_bon5_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon5_cmmt ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 25:
              //   if (residential_units.properties.umax_bon5_cond !== null && residential_units.properties.umax_bon5_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon5_appl ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 26:
              //   if (residential_units.properties.umax_bon5_cond !== null && residential_units.properties.umax_bon5_appl == "Yes") {
              //     item.source_p = residential_units.properties.umax_bon5_srce ?? "N/A";
              //     item.link = residential_units.properties.umax_bon5_link;
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 7:
              //   if (residential_units.properties.umax_bon5_cond !== null && residential_units.properties.umax_bon5_appl == "Yes") {
              //     item.sourceHr = true
              //   } else {
              //     item.sourceHr = false
              //     item.display = false
              //   }
              //   break;
              // // 6
              // case 27:
              //   if (residential_units.properties.umax_bon6_cond !== null && residential_units.properties.umax_bon6_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon6_cond
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 28:
              //   if (residential_units.properties.umax_bon6_cond !== null && residential_units.properties.umax_bon6_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon6_valu ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 29:
              //   if (residential_units.properties.umax_bon6_cond !== null && residential_units.properties.umax_bon6_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon6_cmmt ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 30:
              //   if (residential_units.properties.umax_bon6_cond !== null && residential_units.properties.umax_bon6_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon6_appl ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 31:
              //   if (residential_units.properties.umax_bon6_cond !== null && residential_units.properties.umax_bon6_appl == "Yes") {
              //     item.source_p = residential_units.properties.umax_bon6_srce ?? "N/A";
              //     item.link = residential_units.properties.umax_bon6_link;
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 7:
              //   if (residential_units.properties.umax_bon6_cond !== null && residential_units.properties.umax_bon6_appl == "Yes") {
              //     item.sourceHr = true
              //   } else {
              //     item.sourceHr = false
              //     item.display = false
              //   }
              //   break;
              // // 7
              // case 32:
              //   if (residential_units.properties.umax_bon7_cond !== null && residential_units.properties.umax_bon7_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon7_cond
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 33:
              //   if (residential_units.properties.umax_bon7_cond !== null && residential_units.properties.umax_bon7_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon7_valu ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 34:
              //   if (residential_units.properties.umax_bon7_cond !== null && residential_units.properties.umax_bon7_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon7_cmmt ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 35:
              //   if (residential_units.properties.umax_bon7_cond !== null && residential_units.properties.umax_bon7_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon7_appl ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 36:
              //   if (residential_units.properties.umax_bon7_cond !== null && residential_units.properties.umax_bon7_appl == "Yes") {
              //     item.source_p = residential_units.properties.umax_bon7_srce ?? "N/A";
              //     item.link = residential_units.properties.umax_bon7_link;
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 7:
              //   if (residential_units.properties.umax_bon7_cond !== null && residential_units.properties.umax_bon7_appl == "Yes") {
              //     item.sourceHr = true
              //   } else {
              //     item.sourceHr = false
              //     item.display = false
              //   }
              //   break;
              // // 8
              // case 37:
              //   if (residential_units.properties.umax_bon8_cond !== null && residential_units.properties.umax_bon8_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon8_cond
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 38:
              //   if (residential_units.properties.umax_bon8_cond !== null && residential_units.properties.umax_bon8_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon8_valu ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 39:
              //   if (residential_units.properties.umax_bon8_cond !== null && residential_units.properties.umax_bon8_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon8_cmmt ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 40:
              //   if (residential_units.properties.umax_bon8_cond !== null && residential_units.properties.umax_bon8_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon8_appl ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 41:
              //   if (residential_units.properties.umax_bon8_cond !== null && residential_units.properties.umax_bon8_appl == "Yes") {
              //     item.source_p = residential_units.properties.umax_bon8_srce ?? "N/A";
              //     item.link = residential_units.properties.umax_bon8_link;
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 7:
              //   if (residential_units.properties.umax_bon8_cond !== null && residential_units.properties.umax_bon8_appl == "Yes") {
              //     item.sourceHr = true
              //   } else {
              //     item.sourceHr = false
              //     item.display = false
              //   }
              //   break;
              // // 9
              // case 42:
              //   if (residential_units.properties.umax_bon9_cond !== null && residential_units.properties.umax_bon9_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon9_cond
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 43:
              //   if (residential_units.properties.umax_bon9_cond !== null && residential_units.properties.umax_bon9_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon9_valu ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 44:
              //   if (residential_units.properties.umax_bon9_cond !== null && residential_units.properties.umax_bon9_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon9_cmmt ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 45:
              //   if (residential_units.properties.umax_bon9_cond !== null && residential_units.properties.umax_bon9_appl == "Yes") {
              //     item.title = residential_units.properties.umax_bon9_appl ?? "N/A";
              //   } else {
              //     item.display = false
              //   }
              //   break;
              // case 46:
              //   if (residential_units.properties.umax_bon9_cond !== null && residential_units.properties.umax_bon9_appl == "Yes") {
              //     item.source_p = residential_units.properties.umax_bon9_srce ?? "N/A";
              //     item.link = residential_units.properties.umax_bon9_link;
              //   } else {
              //     item.display = false
              //   }
              //   break;
            }
          }
        });
        break;

      case "max_unit_bonus":
        residential_units = residential_units[3];
        if (residential_units?.properties.umax_bonx_appl !== null) {
          if (residential_units?.properties?.locked) {
            item.lock = true;
            item.subTitle = "";
          } else {
            item.lock = false;
          }
          item.show = true;
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title = residential_units.properties.umax_bonx_cond ?? "N/A";

                break;
              case 2:
                item.title = residential_units.properties.umax_bonx_valu + " units" ?? "N/A";

                break;
              case 3:
                item.title = residential_units.properties.umax_bonx_cmmt ?? "N/A";

                break;
              case 4:
                item.source_p = residential_units.properties.umax_bonx_srce ?? "N/A";
                item.link = residential_units.properties.umax_bonx_link;
                item.display = true;
                break;
            }
          });
          break;
        } else {
          switch (item.id) {
            case 5:
              item.display = true;
              break;
          }
          item.show = false;
        }
    }
  });

  constantResponse.existing_structures.map(function (item, key) {
    let { existing_structures } = apiResponse;
    switch (item.id) {
      case "assessor_details":
        existing_structures = existing_structures[0];
        item.title = existing_structures.title;
        item.subTitle = existing_structures.description;
        if (existing_structures.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (item.properties) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:

                if (existing_structures?.properties.totalval !== null) {
                  var numericValue = parseFloat(existing_structures?.properties?.totalval?.replace(/,/g, ""));
                  if (!isNaN(numericValue)) {
                    item.title = "$" + " " + Math.round(numericValue).toLocaleString();
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.title = "N/A";
                }
                break;
              case 2:
                // if (existing_structures.properties.landval !== null && !isNaN(existing_structures.properties.landval)) {
                //   item.title = "$" + "" + Math.round(existing_structures.properties.landval).toLocaleString() ?? "N/A";
                // }
                // else {
                //   item.display = false
                // }
                // break;
                if (existing_structures.properties.landval !== null) {
                  var numericValue = parseFloat(existing_structures?.properties?.landval?.replace(/,/g, ""));
                  if (!isNaN(numericValue)) {
                    item.title = "$" + " " + Math.round(numericValue).toLocaleString();
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.title = "N/A";
                }
                break;
              case 3:

                if (existing_structures.properties.bldgval !== null) {
                  var numericValue = parseFloat(existing_structures?.properties?.bldgval?.replace(/,/g, ""));
                  if (!isNaN(numericValue)) {
                    item.title = "$" + " " + Math.round(numericValue).toLocaleString();
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.title = "N/A";
                }
                break;

              case 4:

                if (existing_structures.properties.saleprice !== null) {
                  var numericValue = parseFloat(existing_structures?.properties?.saleprice?.replace(/,/g, ""));
                  if (!isNaN(numericValue)) {
                    item.title = "$" + " " + Math.round(numericValue).toLocaleString();
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.title = "N/A";
                }
                break;

              case 5:
                if (existing_structures.properties.saledate !== null) {
                  // var numericValue = parseFloat(existing_structures?.properties?.saledate?.replace(/,/g, ""));
                  // if (!isNaN(numericValue)) {
                  //   item.title = "$" + "" + Math.round(numericValue).toLocaleString();
                  // } else {
                  //   item.title = "N/A";
                  // }
                  item.title=existing_structures.properties.saledate
                } else {
                  item.title = "N/A";
                }
                break;

              case 6:
                item.source_p = existing_structures.properties.assr_srce ?? "N/A";
                item.link = existing_structures.properties.assr_link ?? "N/A"
                break;
            }
          });
          break;
        }
      case "structure_details":
        existing_structures = existing_structures[1];
        item.title = existing_structures.title;
        item.subTitle = existing_structures.description;
        if (existing_structures.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (item.properties) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title = existing_structures.properties.yearbuilt ?? "N/A";
                break;
              case 2:

                if (existing_structures.properties.bldgsqft !== null) {
                  var numericValue = parseFloat(existing_structures?.properties?.bldgsqft?.replace(/,/g, ""));
                  if (!isNaN(numericValue)) {
                    item.title = Math.round(numericValue).toLocaleString() + ' square feet';
                  } else {
                    item.title = "N/A";
                  }
                } else {
                  item.title = "N/A";
                }
                break;
              case 3:
                item.title = existing_structures.properties.bldguse ?? "N/A";
                break;
              case 4:
                item.source_p = existing_structures.properties.bldg_srce ?? "N/A";
                item.link = existing_structures.properties.bldg_link ?? "N/A";
                break;
            }
          });
          break;
        }
      case "existing_lot_coverage":
        existing_structures = existing_structures[2];
        item.title = existing_structures.title;
        item.subTitle = existing_structures.description;
        if (existing_structures.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (item.properties) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title = existing_structures.properties.yearbuilt ?? "N/A";
                break;
              case 2:
                item.title = existing_structures.properties.bldgsqft ?? "N/A";
                break;
              case 3:
                item.source_p = existing_structures.properties.bldg_srce ?? "N/A";
                item.link = existing_structures.properties.bldg_link ?? "N/A"
                break;

            }
          });
          break;
        }
      case "property_owner":
        const property_owner = existing_structures[3];
        item.title = property_owner.title;
        item.subTitle = property_owner.description;
        if (property_owner.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        if (item.properties) {
          item.properties.map(function (item, key) {
            switch (item.id) {
              case 1:
                item.title = property_owner.properties.owner ?? "N/A";
                break;
              case 2:
                item.title = property_owner.properties.owneraddr ?? "N/A";
                break;
              case 3:
                item.title =
                  property_owner.properties.ownercity +
                  ", " +
                  property_owner.properties.ownerstate;
                break;
              case 4:
                item.title = property_owner.properties.ownerzip ?? "N/A";
                break;
            }
          });
          break;
        }
    }
  });
  constantResponse.development.map(function (item, key) {
    let { max_build } = apiResponse;
    switch (item.id) {
      case "max_building_calculator":
        max_build = max_build[2];

        item.title = max_build.title;
        item.properties = max_build.properties;
        break;
      case "max_buildable_square_footage":
        max_build = max_build[0];
        item.title = max_build.title;
        item.subTitle = max_build.description;
        if (max_build.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map(function (item, key) {
          switch (item.id) {
            case 1:
              item.title = Number(max_build.properties.envp_bldg_max_area).toLocaleString() ?? "N/A";
              item.title_desc = Math.round(max_build.properties.envp_bldg_max_area).toLocaleString()
                ? "square feet"
                : "";
              break;
            case 2:
              item.title = max_build.properties.envp_bldg_max_cmmt ?? "N/A";
              break;
            case 4:
              item.display = true
              item.sourceHr = true
              break;
            case 5:
              item.title =max_build.properties.envp_bldg_bonus_area?
                Number(max_build.properties.envp_bldg_bonus_area).toLocaleString() : "N/A";
              item.title_desc = max_build.properties.envp_bldg_bonus_area
                ? "square feet"
                : "";
              break;
            case 6:
              item.title =
                max_build.properties.envp_bldg_bonus_cmmt ?? "N/A";
              break;
          }
        });
        break;
      case "building_delta":
        const building_delta = max_build[1];
        item.title = building_delta.title;
        item.subTitle = building_delta.description;
        if (building_delta.properties.locked) {
          item.lock = true;
          item.subTitle = "";
        } else {
          item.lock = false;
        }
        item.properties.map((item, key) => {
          switch (item.id) {
            case 1:

              if (building_delta.properties.envp_delt_area !== null) {
                var numericValue = parseFloat(building_delta?.properties?.envp_delt_area?.replace(/,/g, ""));
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + " square feet";
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;

            case 3:

              if (building_delta.properties.envp_delt_bonus !== null) {
                var numericValue = parseFloat(building_delta?.properties?.envp_delt_bonus?.replace(/,/g, ""));
                if (!isNaN(numericValue)) {
                  item.title = Number(Math.round(numericValue)).toLocaleString() + " square feet";
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;


            case 5:
              if (building_delta.properties.envp_grnd_ftpt_delta !== null) {
                var numericValue = parseFloat(building_delta?.properties?.envp_grnd_ftpt_delta?.replace(/,/g, ""));
                if (!isNaN(numericValue)) {
                  item.title = Math.round(numericValue).toLocaleString() + " square feet";
                } else {
                  item.title = "N/A";
                }
              } else {
                item.title = "N/A";
              }
              break;

            case 7:
              item.display = true
              item.sourceHr = true

          }
        });
    }
  });
  constantResponse.info_not_updated = false;
  return constantResponse;
}

export function setFiltersMaxValues(
  jurisdiction,
  zoning,
  location,
  lot_info,
  use,
  height,
  far,
  existing_structures,
  coverage,
  units,
  maxbuild,
  maxValues
) {

  far.forEach((data) => {
    // eslint-disable-next-line default-case 
    switch (data.column_name) {
      case "far_lot":
        if(data.name === "far_aor") 
          data.maxValue = Number(maxValues.far_aor_max_value);
        
        if (data?.fields)
          data.fields.forEach((field) => {

            // if (field.label === "Max" && field.column_name === "far_adjx_valu")
            //   field.value = Number(maxValues.far_aor_max_value);
          });
      case "far_bonx":
        if(data.name === "far_abt") 
          data.maxValue = Number(maxValues.far_abt_max_value);

        if (data?.fields)
          data.fields.forEach((field) => {
            // if (field.label === "Max" && field.column_name === "far_bonx_valu")
            //   field.value = Number(maxValues.far_abt_max_value);
          });

    }
  });
  height.forEach((data) => {
    // eslint-disable-next-line default-case
    switch (data.column_name) {
      case "htlim_adjx":
        data.maxValue = maxValues.height_limit_aor_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            // if (field.label === "Max")
            //   field.value = maxValues.height_limit_aor_max_value;
          });
        break;
      case "htlim_bonx":
        data.maxValue = maxValues.height_limit_abt_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            // if (field.label === "Max")
            //   field.value = maxValues.height_limit_abt_max_value;
          });
        break;
    }
  });
  coverage.forEach((data) => {
    // eslint-disable-next-line default-case
    switch (data.column_name) {
      case "envp_grnd_ftpt_delta":
        data.maxValue = maxValues.far_aor_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.coverage_max_value;
          });
    }
  });
  lot_info.forEach((land_stat) => {
    // eslint-disable-next-line default-case
    switch (land_stat.column_name) {
      case "envp_grnd_lot_area":
        land_stat.maxValue = maxValues.lot_size_max_value;
        if (land_stat?.fields)
          land_stat.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.lot_size_max_value;
          });
        // land_stat.maxValue = 200000;
        break;
      case "envp_grnd_ftpt_max":
        land_stat.maxValue = maxValues.max_foot_print_max_value ?? 99999;
        if (land_stat?.fields)
          land_stat.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.max_foot_print_max_value;
          });
        break;
    }
  });
  existing_structures.forEach((existing) => {
    // eslint-disable-next-line default-case
    switch (existing.column_name) {
      case "bldgsqft":
        existing.maxValue = maxValues.existing_square_footage_max_value;
        if (existing?.fields)
          existing.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.existing_square_footage_max_value;
          });
        break;
      case "totalval":
        existing.maxValue = maxValues.assessed_value_max_value;
        if (existing?.fields)
          existing.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.assessed_value_max_value;
          });
        break;
      case "yearbuilt":
        existing.maxValue = 2022;
        existing.fields.forEach((field) => {
          if (field.minValue) {
            field.minValue = 1800;
          }
          if (field.label === "Min") field.value = maxValues.min_year_value;;
          if (field.label === "Max") field.value = maxValues.max_year_value;;
        });
        break;
    }
  });
  units.forEach((data) => {
    // eslint-disable-next-line default-case
    switch (data.column_name) {
      case "umax_adjx_valu":
        data.maxValue = maxValues.allowable_units_aor_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.allowable_units_aor_max_value;
          });
        break;
      case "umax_bonx_valu":
        data.maxValue = maxValues.allowable_units_abt_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.allowable_units_abt_max_value;
          });
        break;
    }
  });
  location.forEach((data) => {
    // eslint-disable-next-line default-case
    switch (data.column_name) {
      case "umax_adjx_valu":
        data.maxValue = maxValues.allowable_units_aor_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.allowable_units_aor_max_value;
          });
        break;
      case "umax_bonx_valu":
        data.maxValue = maxValues.allowable_units_abt_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.allowable_units_abt_max_value;
          });
        break;
    }
  });
  maxbuild.forEach((data) => {
    switch (data.column_name) {
      case "envp_bldg_max_area":
        data.maxValue = maxValues.maximum_build_area_aor_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.maximum_build_area_aor_max_value;
          });
        break;
      case "envp_bldg_bonus_area":
        data.maxValue = maxValues.maximum_build_area_abt_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max") field.value = maxValues.maximum_build_area_abt_max_value;
          });
        break;
      case "envp_delt_area":
        data.maxValue = maxValues.buildable_area_aor_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.buildable_area_aor_max_value;
          });
        break;
      case "envp_delt_bonus":
        data.maxValue = maxValues.buildable_area_abt_max_value;
        if (data?.fields)
          data.fields.forEach((field) => {
            if (field.label === "Max")
              field.value = maxValues.buildable_area_abt_max_value;
          });
        break;
    }
  });
  return {
    jurisdiction,
    zoning,
    location,
    lot_info,
    use,
    height,
    far,
    existing_structures,
    coverage,
    units,
    maxbuild,
    maxValues
  };
}

export function Capitalize(str) {
  let value = str.split(" ");

  let new_value = value.map(function (item, key) {
    if (EARTH_DIRECTIONS.includes(item)) {
      return item;
    } else {
      return item.replace(
        /(^\w|\s\w)(\S*)/g,
        (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
      );
    }
  });
  let a_t_s = new_value.toString();
  return a_t_s.split(",").join(" ");
}
const searchUseRegulation = (search) => {
  let result = "";
  USE_REGULATION_CONSTANTS.map((obj) => {
    if (obj.index === search) result = obj.value;
  });
  return result;
};
export const USE_REGULATION_CONSTANTS = [
  {
    index: "use_a_01",
    value: "Parks and playgrounds including customary uses",
  },
  {
    index: "use_a_02",
    value: "Community gardens",
  },
  {
    index: "use_a_03",
    value: "Animal husbandry",
  },
  {
    index: "use_a_04",
    value: "Aquaculture",
  },
  {
    index: "use_a_05",
    value: "Horticulture",
  },
  {
    index: "use_a_06",
    value: "Urban farm",
  },
  {
    index: "use_a_07",
    value: "Parks and open space",
  },
  {
    index: "use_a_08",
    value: "Plant nurseries",
  },
  {
    index: "use_b_01",
    value: "Single-family dwelling",
  },
  {
    index: "use_b_02",
    value:
      "Apartments, carriage houses, cottage houses, rowhouses, and townhouses",
  },
  {
    index: "use_b_03",
    value: "Floating homes",
  },
  {
    index: "use_b_04",
    value: "Congregate use_bidences",
  },
  {
    index: "use_b_05",
    value: "Detached ADU",
  },
  {
    index: "use_b_06",
    value: "Adult family homes",
  },
  {
    index: "use_b_07",
    value: "Permanent supportive housing",
  },
  {
    index: "use_b_08",
    value: "Caretaker's quarters",
  },
  {
    index: "use_b_09",
    value: "Mobile home parks",
  },
  {
    index: "use_b_10",
    value: "Artists studio dwellings",
  },
  {
    index: "use_c_01",
    value: "Business support services",
  },
  {
    index: "use_c_02",
    value: "Food processing and craft work",
  },
  {
    index: "use_c_03",
    value: "Bed and breakfast",
  },
  {
    index: "use_c_04",
    value: "Nursing homes",
  },
  {
    index: "use_c_05",
    value: "Drive-in businesses",
  },
  {
    index: "use_c_06",
    value: "Ground-floor commercial uses",
  },
  {
    index: "use_c_07",
    value: "Medical service uses other than permitted ground-floor commercial",
  },
  {
    index: "use_c_08",
    value: "Uses not otherwise permitted in Landmark structures",
  },
  {
    index: "use_c_09",
    value: "Cemeteries",
  },
  {
    index: "use_c_10",
    value: "Animal shelters and kennels",
  },
  {
    index: "use_c_11",
    value: "Drinking establishments",
  },
  {
    index: "use_c_12",
    value: "Restaurants",
  },
  {
    index: "use_c_13",
    value: "Cabarets, adult",
  },
  {
    index: "use_c_14",
    value: "Motion picture theaters, adult",
  },
  {
    index: "use_c_15",
    value: "Panorams, adult",
  },
  {
    index: "use_c_16",
    value: "Sports and recreation, indoor",
  },
  {
    index: "use_c_17",
    value: "Sports and recreation, outdoor",
  },
  {
    index: "use_c_18",
    value: "Theaters and spectator sports facilities",
  },
  {
    index: "use_c_19",
    value: "Food processing and craft work",
  },
  {
    index: "use_c_20",
    value: "Laboratories, research and development",
  },
  {
    index: "use_c_21",
    value: "Lodging uses",
  },
  {
    index: "use_c_22",
    value: "Medical services",
  },
  {
    index: "use_c_23",
    value: "Offices",
  },
  {
    index: "use_c_24",
    value: "Retail sales and services, automotive",
  },
  {
    index: "use_c_25",
    value: "Sales and rental of motorized vehicles",
  },
  {
    index: "use_c_26",
    value: "Vehicle repair, major automotive",
  },
  {
    index: "use_c_27",
    value: "Sales and services, general",
  },
  {
    index: "use_c_28",
    value: "Retail sales, multipurpose",
  },
  {
    index: "use_c_29",
    value: "Commercial sales, heavy",
  },
  {
    index: "use_c_30",
    value: "Commercial services, heavy",
  },
  {
    index: "use_c_31",
    value: "Retail sales, major durables",
  },
  {
    index: "use_c_32",
    value: "Retail sales and services, non-household",
  },
  {
    index: "use_c_33",
    value: "Wholesale showrooms",
  },
  {
    index: "use_c_34",
    value: "Marine service stations",
  },
  {
    index: "use_c_35",
    value: "Sales and rental of large boats",
  },
  {
    index: "use_c_36",
    value: "Sales and rental of small boats, boat parts and accessories",
  },
  {
    index: "use_c_37",
    value: "Vessel repair, major",
  },
  {
    index: "use_c_38",
    value: "Vessel repair, minor",
  },
  {
    index: "use_c_39",
    value: "Live-work units",
  },
  {
    index: "use_c_40",
    value: "Major marijuana activity",
  },
  {
    index: "use_c_41",
    value: "Retail ice dispensaries",
  },
  {
    index: "use_c_42",
    value: "Frozen food lockers",
  },
  {
    index: "use_c_43",
    value: "Pet daycare",
  },
  {
    index: "use_c_44",
    value: "Bowling alleys",
  },
  {
    index: "use_c_45",
    value: "Skating rinks",
  },
  {
    index: "use_c_46",
    value: "Medical testing laboratories",
  },
  {
    index: "use_c_47",
    value: "Mortuary services",
  },
  {
    index: "use_c_48",
    value: "Motels",
  },
  {
    index: "use_c_49",
    value: "Shooting galleries",
  },
  {
    index: "use_c_50",
    value: "Formula fast food restaurants",
  },
  {
    index: "use_c_51",
    value: "Hotels",
  },
  {
    index: "use_d_01",
    value: "Childcare centers",
  },
  {
    index: "use_d_02",
    value: "Schools, elementary or secondary",
  },
  {
    index: "use_d_03",
    value: "Religious facilities",
  },
  {
    index: "use_d_04",
    value: "Major institutions subject to 23.69",
  },
  {
    index: "use_d_05",
    value: "Other institutions",
  },
  {
    index: "use_d_06",
    value: "Institutions meeting base zone development standards",
  },
  {
    index: "use_d_07",
    value: "Institutions",
  },
  {
    index: "use_d_08",
    value: "Public schools",
  },
  {
    index: "use_d_09",
    value: `Child care centers, preschools, public or private schools, educational and vocational
        training . . . and similar uses in existing or former public schools`,
  },
  {
    index: "use_d_10",
    value: "Community centers",
  },
  {
    index: "use_d_11",
    value: "Private schools",
  },
  {
    index: "use_d_12",
    value: "Public schools",
  },
  {
    index: "use_d_13",
    value: "Libraries",
  },
  {
    index: "use_d_14",
    value: "Existing institutes for advanced study",
  },
  {
    index: "use_d_15",
    value: "Hospitals",
  },
  {
    index: "use_d_16",
    value: "Colleges",
  },
  {
    index: "use_d_17",
    value: "Museums",
  },
  {
    index: "use_d_18",
    value: "Private clubs",
  },
  {
    index: "use_d_19",
    value: "Vocational schools",
  },
  {
    index: "use_d_20",
    value: "Adult care centerss",
  },
  {
    index: "use_e_01",
    value: "Manufacturing, light",
  },
  {
    index: "use_e_02",
    value: "Manufacturing, general",
  },
  {
    index: "use_e_03",
    value: "Manufacturing, heavy",
  },
  {
    index: "use_e_04",
    value: "High impact",
  },
  {
    index: "use_f_01",
    value: "Youth service centers",
  },
  {
    index: "use_f_02",
    value: "All other jails",
  },
  {
    index: "use_f_03",
    value: "Work-release centers",
  },
  {
    index: "use_g_01",
    value: "Mini-warehouses",
  },
  {
    index: "use_g_02",
    value: "Storage, outdoor",
  },
  {
    index: "use_g_03",
    value: "Warehouses",
  },
  {
    index: "use_h_01",
    value: "Cargo terminals",
  },
  {
    index: "use_h_02",
    value: "Boat moorage",
  },
  {
    index: "use_h_03",
    value: "Dry boat storage",
  },
  {
    index: "use_h_04",
    value: "Parking, flexible-use",
  },
  {
    index: "use_h_05",
    value: "Park and ride facilities",
  },
  {
    index: "use_h_06",
    value: "Park and pool lots",
  },
  {
    index: "use_h_07",
    value: "Towing services",
  },
  {
    index: "use_h_08",
    value: "Passenger terminals",
  },
  {
    index: "use_h_09",
    value: "Rail transit facilities",
  },
  {
    index: "use_h_10",
    value: "Airports (land-based)",
  },
  {
    index: "use_h_11",
    value: "Airports (water-based)",
  },
  {
    index: "use_h_12",
    value: "Heliports",
  },
  {
    index: "use_h_13",
    value: "Helistops",
  },
  {
    index: "use_h_14",
    value: "Bus bases",
  },
  {
    index: "use_h_15",
    value: "Railroad switchyards",
  },
  {
    index: "use_h_16",
    value: "Railroad switchyards with a mechanized hump",
  },
  {
    index: "use_h_17",
    value: "Transportation services, personal",
  },
  {
    index: "use_i_01",
    value: "Communication utilities, major",
  },
  {
    index: "use_i_02",
    value: "Communication utilities, minor",
  },
  {
    index: "use_i_03",
    value: "Power plants",
  },
  {
    index: "use_i_04",
    value: "Recycling",
  },
  {
    index: "use_i_05",
    value: "Sewage treatment plants",
  },
  {
    index: "use_i_06",
    value: "Solid waste management",
  },
  {
    index: "use_i_07",
    value: "Utility services uses",
  },
  {
    index: "use_j_01",
    value: "All other uses",
  },
];
export const updatePlanDetails = (plan, planPrice) => {
  const singlePlanPrice = planPrice.filter(
    (pln) => pln.code === plan.code + "_x1"
  )[0];
  if (plan?.code) {
    switch (plan.code) {
      case "ent":
        plan.productName = singlePlanPrice?.product_name;
        plan.price = singlePlanPrice?.product_price;
        plan.cost.content = singlePlanPrice?.product_description;
        break;
      case "pro":
        plan.productName = singlePlanPrice?.product_name;
        plan.price = singlePlanPrice?.product_price;
        plan.cost.content = singlePlanPrice?.product_description;
        break;
      case "one_time":
        let oneTimePlanPrice = planPrice.filter(
          (pln) => pln.code === plan.code
        )[0];
        plan.productName = oneTimePlanPrice?.product_name;
        plan.price = oneTimePlanPrice?.product_price;
        plan.cost.content = oneTimePlanPrice?.product_description;
        break;
      case "non-subscriber":
        const oneTimeSinglePlanPrice = planPrice.filter(
          (pln) => pln.code === "one_time"
        )[0];
        plan.productName = "Free Plan";
        const content = PLANS[0].cost.content;
        plan.cost.content = plan.cost.content.includes(
          oneTimeSinglePlanPrice.product_price
        )
          ? plan.cost.content
          : `${content} $${oneTimeSinglePlanPrice.product_price} each.`;
        break;
    }
  }
  return plan;
};

export let allSelectedPlans = [];
export let allCancelledPlans = [];

export const setAllCancelledPlans = (
  checked,
  cityCode,
  planName,
  city,
  plan,
  subscriptions,
  citiesAndPlans
) => {
  if (checked === false && isSubscribed(city, plan, subscriptions)) {
    allCancelledPlans = [...allCancelledPlans, { cityCode, planName, city }];
    return;
  }

  if (checked && planName === "ent") {
    const proPlan = getPlanStatus(city, citiesAndPlans, "pro");
    if (isSubscribed(city, proPlan, subscriptions)) {
      allCancelledPlans = uniqWith(
        [...allCancelledPlans, { cityCode, planName: "pro", city }],
        isEqual
      );
    }
    return;
  }

  allCancelledPlans = [
    ...allCancelledPlans.filter(
      (pln) => pln.city != cityCode && pln.planName != planName
    ),
  ];
};

const getPlanStatus = (city, citiesAndPlans, name) => {
  return citiesAndPlans[city].filter((plan) => plan.code === name)[0];
};

export const getTotalPrice = (plans, prices, roles) => {
  let selectedPlans = [];
  Object.keys(plans).map((city) => {
    plans[city].map((plan) => {
      if (plan.isChecked && plan.code != "basic") {
        if (plan.code === "ent") {
          let pro = selectedPlans.filter(
            (plan) => plan.city != city && plan.code != "pro"
          );
          selectedPlans = [...pro];
        }
        selectedPlans.push({ city, plan: plan.code });
      }
    });
  });
  const pro =
    getPlanPrice(
      "pro",
      prices,
      selectedPlans.filter((p) => p.plan === "pro"),
      roles
    ) || [];
  const ent =
    getPlanPrice(
      "ent",
      prices,
      selectedPlans.filter((p) => p.plan === "ent"),
      roles
    ) || [];
  allSelectedPlans = [...pro, ...ent];

  return allSelectedPlans.reduce(
    (acc, currentPlan) => acc + currentPlan.product_price,
    0
  );
};

const getPlanPrice = (planName, prices, selectedPlans, roles) => {
  const plans = selectedPlans.filter((p) => p.plan === planName);
  const roleId = getRoleId(planName, roles);

  const plan = prices.filter(
    (price) => price.code === `${planName}_x${plans.length}`
  );
  return plan.map((pl) => {
    return {
      product_price: pl.product_price,
      price_id: pl.product_price_id,
      regions: [...plans.map((pl) => pl.city)],
      role_id: roleId,
    };
  });
};

const getRoleId = (name, roles) => {
  return roles.filter((role) => role.name.toLowerCase().startsWith(name))[0]?.id;
};

export const manageCitiesAndPlans = (
  citiesAndPlans,
  activeContent,
  dispatch,
  subscriptions,
  inital = false
) => {
// v2.0
  let newstate = JSON.parse(selectedEnv.REACT_APP_CITIES_LIST); // add new section for any upcoming city
  Object.keys(citiesAndPlans).map((city) => {
    citiesAndPlans[city].map((plan) => {
      plan.isDisable = false;

      if (plan.code === "pro" || plan.code === "ent" || plan.code === "basic") {
        const isSub = isSubscribed(city, plan, subscriptions);

        if (isSub === true || plan.code === "basic")
          plan["isSubscribed"] = true;

        if (isSub || plan.code === "basic") {
          plan.isChecked = true;
          plan.isDisable = true;
        }
        if (plan.code === "ent" && plan.isChecked === true) {
          newstate[city][1].isDisable = true;
          newstate[city][1].isChecked = true;
          if (isSub) newstate[city][1]["isSubscribed"] = true;
        }
      }

      if (activeContent === "pro" && plan.code === "ent") {
        plan.isDisable = true;
        newstate[city].push(plan);
        return;
      }

      if (activeContent === "ent" && plan.code === "pro") {
        plan.isDisable = true;
        plan.isChecked =
          isEnterpriseChecked(citiesAndPlans[city]) === true
            ? true
            : plan.isChecked;
        newstate[city].push(plan);
        return;
      }

      if (activeContent === undefined && plan.isChecked === false) {
        plan.isDisable = false;
      }

      newstate[city].push(plan);
    });
  });
  dispatch(setCitiesAndPlans({ ...newstate }));
};

export const isEnterpriseChecked = (plans) => {
  return plans.filter((plan) => plan.code === "ent" && plan.isChecked === true)
    .length === 0
    ? false
    : true;
};

const isSubscribed = (region, plan, subscriptions) => {
  let subscribed = null;
  (subscriptions || []).map((sub) => {
    if (sub.code.startsWith(plan.code) && sub.region.includes(region))
      subscribed = true;
  });
  return subscribed;
};

export const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sept";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      return "Mom";
  }
};
