import React from "react";
import Container from "../Container";
import Header from "../Header";
import '../../../../css/Parking.scss';
import InfoSection from "../InfoSection";
import { useSelector } from "react-redux";
import ICON_LOCK from "../../../../images/lock-1.png";
import LinkImage from "../../../../images/link.jpg";

const Parking = () => {
    const { parking } = useSelector(state => state.info.infoDefaultValues);
    return (
        parking.map(field => {
            if (field.lock == true) {
                return (
                    <>
                        <div className="lock">
                            <div className='summary-data'>
                                <Container>
                                    <Header content={field} />
                                    <hr className='lock-hr' />
                                    <div className='section-data'>
                                        <div className='section-description'>
                                            <p className="sub-heading-lock">
                                                Download full report or sign up for a PRO account to see!</p>
                                            <img src={ICON_LOCK} className="lock-image" alt="" />

                                        </div>
                                    </div> 
                                </Container>
                            </div>
                        </div>
                    </>
                );
            } else {
                if (field.footerText === true) {
                    return (
                        <>
                            <div className='parking'>
                                <div className='summary-data'>
                                    <Container>
                                        <Header content={field} />
                                        <InfoSection content={field.properties} />
                                        <div className='footer-section'>
                                            <div className="link-image-footer">
                                                <img src={LinkImage} alt="" width="100%" />
                                            </div>

                                            {field.properties.map(field => {
                                                if (field.source != undefined) {
                                                    return (
                                                        <div className='footer-more-use-parking'>
                                                            <a className='link' href={field.link} title={field.source}
                                                                target="_blank">{field.source}</a>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </Container>
                                </div>
                            </div>
                        </>
                    );
                } else {
                    return (
                        <>
                            <div className='parking'>
                                <div className='summary-data'>
                                    <Container>
                                        <Header content={field} />
                                        <InfoSection content={field.properties} />
                                        <div className='footer-section'>
                                            <div className='more'></div>
                                        </div>
                                    </Container>
                                </div>
                            </div>
                        </>
                    );
                }
            }
        })
    )
}
export default Parking;
