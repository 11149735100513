import React, { useEffect, useState } from "react";
import { Input, Button, Form } from "semantic-ui-react";
import { useAlert } from "react-alert";

import { connect, useSelector } from "react-redux";
import {
    loginUser,
    setUserLoginError,
    setAlertMessage, setLoader
} from "../../../actions/actions_user";
import { PLANS, SIGNUP_FIELDS } from "../../../constants";
import { setDataWindow, setDataWindowContent } from "../../../actions/actions_datawindow";
import '../../../css/UserContent..scss'
import '../../../css/SecondWindowLogin.scss'
import { applyFilter, setFilterPlan } from "../../../actions/actions_filters";

const SecondWindowContent = ({ dispatch, loginError, currentContent }) => {
    const alert = useAlert();
    const { token, alertMessage, user } = useSelector(state => state.user)
    const [fieldError, setFieldError] = useState({
        email: false,
        password: false,
    });
    const { show } = useSelector(state => state.dataWindow);
    const [hasSignup, setHasSignup] = useState(false);
    const { filterValues } = useSelector(state => state.filters)

    const [userInfo, setUserInfo] = useState({
        email: "",
        password: "",
    });
    useEffect(() => {
        if (token && filterValues && user && ['Enterprise'].includes(user.status)) {
            const object = {
                filter_json: filterValues,
                page_number: 1
            }
            dispatch(setLoader(true));
            dispatch(applyFilter(object, token));
        } else if (['Basic', 'Professional'].includes(user?.status)) {
            // handle upgrade account
            dispatch(setDataWindow({
                dataWindow: true,
                content: {
                    isContact: null,
                    isForce: true,
                    choosePlan: { plans: PLANS, activeContent: user.status === 'Basic' ? 'pro' : 'ent' },
                    downloadPdf: true,
                }
            }));
            dispatch(setDataWindowContent({ show: { showData: 'choose-plan', showControl: 'choose-plan' } }))
        }
    }, [token, filterValues, dispatch, user]);

    const handleUserInfo = (e) => {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
        let isError = false;
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (e.target.name === 'email') {
            pattern.test(e.target.value) ? isError = false : isError = true;
        } else if (e.target.name === 'password') {
            e.target.name.trim().length > 0 ? isError = false : isError = true
        }
        setFieldError({ ...fieldError, [e.target.name]: isError })
    };

    const { email, password } = userInfo;

    const handleLogin = () => {
        if (!userInfo.email || !userInfo.password) {
            setFieldError({
                email: true,
                password: true,
            })
            alert.error("You have entered an invalid email or password.")
        }
        else if (!fieldError.email && !fieldError.password) {
            dispatch(setLoader(true));
            dispatch(setFilterPlan({
                status: 'Enterprise',
                order: 3
            }))
            dispatch(loginUser({ ...userInfo }, "Enterprise"))
        }
        setUserInfo({
            email: "",
            password: "",
        });
    };

    const handleSignup = () => {
        setHasSignup(true);
        dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: SIGNUP_FIELDS, choosePlan: {} } }));
        dispatch(setDataWindowContent({ show: { showData: 'sign-up', showControl: 'second-window-sign-up' } }));
    };

    useEffect(() => {
        loginError !== null && alert.error(loginError);
        dispatch(setUserLoginError(null))
        alertMessage !== null && alert.success(alertMessage);
        dispatch(setAlertMessage(null));
    }, [token, alertMessage, loginError]);

    useEffect(() => {
        setHasSignup(false);
        setUserInfo({
            email: "",
            password: "",
        });
        setFieldError({
            email: false,
            password: false,
        });
    }, [currentContent]);

    const handleForgetPassword = () => {
        dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: '', choosePlan: {} } }));
        dispatch(setDataWindowContent({ show: { showData: 'forget-password', showControl: 'login' } }));
    }

    return (
        <div className="user-content">
            {show.showData === 'login' && show.showControl === 'filter' &&
                <div className="user-login">
                    <Form error onSubmit={handleLogin} className="login-form">
                        <div className="login-fields-container">
                            <h4 className="grayed-text">Login</h4>
                            <Form.Field error={fieldError.email}>
                                <Input
                                    size="big"
                                    fluid
                                    placeholder="email"
                                    type='email'
                                    name="email"
                                    value={email}
                                    onChange={(e) => handleUserInfo(e)}
                                />
                            </Form.Field>
                            <Form.Field error={fieldError.password}>
                                <Input
                                    type="password"
                                    size="big"
                                    fluid
                                    placeholder="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => handleUserInfo(e)}
                                />
                            </Form.Field>
                            <a className="forget-password" onClick={handleForgetPassword}>Forget/Reset Password ?</a>
                        </div>
                        <div className="actions-container">
                            <Button fluid size="small" type="submit">
                                Login
                            </Button>
                            <div>
                                <h4 className="grayed-text">- or -</h4>
                            </div>
                            <Button fluid size="small" className="signup-styles" onClick={() => handleSignup()}>
                                Sign up
                            </Button>
                            <h4 className="grayed-text quote-top" style={{ "marginBottom": "0" }}>with an ENTERPRISE account</h4><br /><h4 className="grayed-text quote-bottom" style={{ "marginTop": "-15px" }}> to see Results!</h4>
                        </div>
                    </Form>
                </div>}
        </div>
    )
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    userToken: state.user.token,
    loginError: state.user.loginError,
});

export default connect(mapStateToProps)(SecondWindowContent);
