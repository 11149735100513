import React, { useState, useEffect } from "react";
import { Button, Form, Header, Icon, Image, Modal } from "semantic-ui-react";
import { setDataWindow, setDataWindowContent } from "../../actions/actions_datawindow";
import {
    applyFilter,
    setFilterPlan,
    saveFilter,
    updateFilterPayment,
    setFilterValues, getSaveFilters, setShowFilterFolder
} from "../../actions/actions_filters";
import { activityLogger, setLoader } from "../../actions/actions_user";

import {
    Filter_INFO,
    ICONS, JURIS_FILTER, PLANS, UNITS_FILTER
} from '../../constants';
import { connect, useSelector } from "react-redux";
import '../../css/Filter.scss';
import ICON_FILTER from '../../images/filter-plus.svg';
import ICON_FILE from '../../images/filter-folder.svg';
import ICON_RESET from '../../images/filter-reset.svg';
import ICON_RESULT from '../../images/filter-scanner.svg';
import ICON_FILEE from "../../images/folder-star-1.svg";
import ICON_HEART from "../../images/start-1.svg";
import ICON_ROTATE_ARROW from '../../images/rotate-left-circular-arrow-interface-symbol.png';
import { useAlert } from "react-alert";
import { updateResult } from "../../actions/actions_filters";
import { setFilterPrimeAccountNumbers } from "../../actions/actions_search";
import { setFiltersMaxValues } from "../../utils/Utils";
import { isMobile } from "react-device-detect";

const SearchContent = ({ dispatch }) => {
    const alert = useAlert();
    const { user, token } = useSelector(state => state.user);
    const { filterValues, defaultFilter } = useSelector(state => state.filters)
    const [activeSection, setActiveSection] = useState('');
    const { updateContent } = useSelector(state => state.dataWindow);
    const { filterUpgradeProcess, filterPaymentDone } = useSelector(state => state.filters.filterPayment)
    const [open, setOpen] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [filterNameTouched, setFilterNameTouched] = useState(false)
    const { showData } = useSelector(state => state.dataWindow.show)
    // change style for total icon due to large size
    const totalIconStyle = (code) => {
        switch (code) {
            case 'total':
                return { width: '28px' }
            default:
                return
        }
    }
    useEffect(() => {
        // if update modal of contact info is closed then reset activeSession
        if (updateContent === false) {
            setActiveSection('');
            setFilterName('');
            dispatch(setFilterPlan(null))
            dispatch(updateFilterPayment({ filterUpgradeProcess: false, filterPaymentDone: false }))
        }
    }, [updateContent]);

    useEffect(() => {
        if (filterUpgradeProcess && filterPaymentDone) {
            const object = {
                filter_json: filterValues,
                page_number: 1
            }
            dispatch(setLoader(true));
            dispatch(applyFilter(object, token));
            setActiveSection("seeResult")
        }
    }, [filterUpgradeProcess, filterPaymentDone])

    useEffect(() => {
        if (showData === "see_result") {
            setActiveSection("seeResult")
        }
    }, [showData])

    const handleFiltersControl = (control) => {
        if (control === activeSection) {
            setActiveSection('');
            dispatch(setDataWindowContent({ show: { showData: '', showControl: 'filters' } }));
            dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
            return
        }
        dispatch(setDataWindowContent({ show: { showData: control, showControl: 'filters' } }));
        setActiveSection(control);
        let body = {
            'event': 'Tab Selected: ' + control
        }
        dispatch(activityLogger(body))
        switch (control) {
            case 'zoning':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'zoning', showControl: 'filters' } }));
                break;
                case 'location':
                    dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
                    dispatch(setDataWindowContent({ show: { showData: 'location', showControl: 'filters' } }));
                    break;
            case 'lot_info':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'lot_info', showControl: 'filters' } }));
                break;
            case 'far':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'far', showControl: 'filters' } }));
                break;
            case 'maxbuild':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'maxbuild', showControl: 'filters' } }));
                break;
            case 'use':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'use', showControl: 'filters' } }));
                break;
            case 'existing_structures':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'existing_structures', showControl: 'filters' } }));
                break;
        }
    }
    const getIconHelper = (name) => {
        return ICONS.filter((icon) => name === icon.name)[0].icon
    }
    const handleLogin = () => {
        dispatch(setDataWindow({
            dataWindow: true,
            content: {
                isContact: null,
                isForce: true,
                choosePlan: {},
            }
        }));
        dispatch(setDataWindowContent({ show: { showData: 'login', showControl: 'filter' } }));
    }
    const handleUpgradeAccount = () => {
        dispatch(setFilterPlan({
            status: 'Enterprise',
            order: 3
        }))
        dispatch(setDataWindow({
            dataWindow: true,
            content: {
                isContact: null,
                isForce: true,
                choosePlan: { plans: PLANS, activeContent: 'ent' },
                downloadPdf: true,
            }
        }));
        dispatch(setDataWindowContent({ show: { showData: 'cities-plans', showControl: '' } }))
    }
    const handleResult = () => {
        setActiveSection("seeResult");
        if (user && ['Basic', 'Professional'].includes(user?.status)) {
            dispatch(updateFilterPayment({ filterUpgradeProcess: true, filterPaymentDone: false }))
            handleUpgradeAccount();
            alert.error("Please upgrade your account to see results.");
        }
        else if (user && ['Enterprise'].includes(user?.status)) {
            const object = {
                filter_json: filterValues,
                page_number: 1
            }
            dispatch(setLoader(true));
            dispatch(applyFilter(object, token));
        }
        else {
            handleLogin();
            alert.error("Please login to see results.");
        }
    }
    const handleSaveFilter = () => {
        if (token)
            setOpen(!open);
        else
            alert.error("Please Login!")
    }
    const handleFilterFormSubmit = () => {
        setFilterNameTouched(true)
        if (filterName === '')
            alert.error("Filter Name cannot be empty.")
        else {
            const object = {
                filter_name: filterName,
                filter_json: filterValues,
                user: user.id
            }
            dispatch(setLoader(true));
            dispatch(saveFilter(object, token))
            setOpen(!open);
        }
    }
    const handleResetFilter = () => { 
        dispatch(setFilterValues(defaultFilter));
        
        dispatch(updateResult({
            count: 0,
            data: null
        }));
        dispatch(setFilterPrimeAccountNumbers([]));
    }
    const handleShowFolder = () => {
        if (token) {
            dispatch(setLoader(true))
            dispatch(setShowFilterFolder(true));
            dispatch(getSaveFilters(user.id))
        } else
            alert.error("Please Login!")
    }
    return (
        <div className="filter-container">
            {/* <div className="filter-heading">
          <h1>Form Filter</h1>
          <h3>Search,
            filter, and sort through
            properties by any number
            of
            development
            parameters.</h3>
        </div> */}
          <div className='see-result-icons'>
                <div className="rotate">
                    <Image src={ICON_FILTER} className='image-size'   onClick={handleSaveFilter}/>
                </div>
                <div className="file">
                    <Image src={ICON_FILE}  className='image-size' style={{marginTop:"3px"}}  onClick={handleShowFolder} />
                </div>
            </div>
            <br/>
            <div className="filter-content" style={{ marginTop:"17px"}} >
                {Filter_INFO.map((info) => {
                    return (
                        <div key={info.id} className="filter-item">
                            <Button fluid size="large" className={`${activeSection === info.code ? 'active' : ''} curve-wrapper filter-contorls-btns`} onClick={() => { handleFiltersControl(info.code) }}>
                                {info.iconName && <Image src={getIconHelper(info.iconName)} className={info.iconName === 'ICON_FILTERS' ? 'filter-icon' : ''} style={totalIconStyle(info.code)} />}
                                <span className={info.iconName === null ? 'centered' : ''}>{info.title}</span>
                            </Button>
                        </div>
                    )
                })}
            </div>
            <br/>
            <br/>
            <div className="bottom">
                {/* <div className={[`${activeSection === "seeResult" ? 'btn-active' : ''} see-result-btn`]}> */}
                <div className= 'see-result-btn'  >
                    <Button className='reset-button' fluid onClick={handleResetFilter} >
                        <Image src={ICON_RESET} className='image-size' />
                        <span>Reset</span></Button>
                </div>
                <div className={[`${activeSection === "seeResult" ? '' : ''} see-result-btn`]}>

                    <Button className='result-button' fluid onClick={handleResult}>
                        <Image src={ICON_RESULT} className='image-size' style={{marginTop:"5px",height:"25px"}} />
                        <span>See Results</span></Button>
                </div>
            </div>

            {/* <div className={[`${activeSection === "seeResult" ? 'icon-active' : ''} see-result-icons ${user?.status !== 'Enterprise' ? 'unverified-active' : ''}`]}>
                {token && user?.status === 'Enterprise' &&
                    <>
                        <div className="rotate">
                            <Image src={ICON_HEART} className='image-size' onClick={handleSaveFilter} />
                        </div>
                        <div className="file">
                            <Image src={ICON_FILE} className='image-size' onClick={handleShowFolder} />
                        </div>
                    </>
                }

            </div> */}
            <Modal
                closeIcon
                size={"tiny"}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <Header content='Save Filter' />
                <Modal.Content>
                    <Form>
                        <Form.Field widths='equal' error={filterName === '' && filterNameTouched}>
                            <Form.Input
                                fluid
                                id='form-subcomponent-shorthand-input-filter-name'
                                label='Filter Name'
                                placeholder='Filter name'
                                value={filterName}
                                onChange={(e) => { setFilterName(e.target.value); setFilterNameTouched(true) }}
                                required
                            />
                            {filterName === '' && filterNameTouched && <span>Filter Name is required</span>}
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button style={{ "backgroundColor": "#f8d9e6" }} onClick={() => setOpen(false)}>
                        <Icon name='remove' /> No
                    </Button>
                    <Button color='primary' onClick={handleFilterFormSubmit}>
                        <Icon name='checkmark' /> Save
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    // SEARCH
    searchResult: state.search.search_result,

    // USER
    user: state.user.user,
});

export default connect(mapStateToProps)(SearchContent);
