import React from "react";
import "../../../../css/Summary.scss";
import "../../../../css/InfoTabs.scss";
import SummaryMaster from "./SummaryMaster";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

const Summary = () => {
  const { identifiers } = useSelector((state) => state.info.infoDefaultValues);
  const { photo_ref } = useSelector((state) => state.info);
  let imageWidth = isMobile ? "247x295" : "321x295";
  return (
    <>
      <div className="image-box">
        <img
          src={
            "https://maps.googleapis.com/maps/api/streetview?size=" +
            imageWidth +
            "&location=" +
            photo_ref +
            "&key=AIzaSyCMSrRiLxLUnqLB5aKrQrxw-9Qhg9WnZ1g"
          }
          style={{ width: window.innerWidth <= 768 ? " 100%" : "100%" }}
        />
      </div>
      <SummaryMaster />
    </>
  );
};
export default Summary;
