import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import InputMask from 'react-input-mask';
import check from "../../../images/check.png";
import '../../../css/SignUp.scss'
import { Image, Input, Form, Message, Button } from 'semantic-ui-react'
import { connect, useSelector } from "react-redux";
import { SET_DATA_WINDOW, setDataWindow, setDataWindowContent } from "../../../actions/actions_datawindow";
import { SIGNUP_FIELDS } from '../../../constants';
import { setLoader, signupUser, updateUser } from '../../../actions/actions_user';
import account from '../../../images/account.png';
import { validateUserAccount } from "../../../utils/Utils";
import { getUserRoles } from "../../../actions/actions_plans";

const SignUp = ({ dispatch }) => {
    const alert = useAlert();
    const { contactInfo, isContact, isError = null, isAlreadyUser = null, values = null, isPasswordMatched = null, isPhoneValid = true, isEmailValid = true } = useSelector(state => state.dataWindow.content);
    const { updateContent } = useSelector(state => state.dataWindow);
    const oldUser = localStorage.getItem('_uft')
    const dataWindowContent = useSelector(state => state.dataWindow.content);
    const { user, token } = useSelector(state => state.user);
    const [userInfo, setUserInfo] = useState(values ? values : {
        first_name: { value: '', required: true },
        last_name: { value: '', required: true },
        company: { value: '', required: false },
        title: { value: '', required: false },
        industry: { value: '', required: false },
        address_1: { value: '', required: true },
        address_2: { value: '', required: false },
        city: { value: '', required: true },
        state: { value: '', required: true },
        zip_code: { value: '', required: true },
        phone_number: { value: '', required: false },
        email: { value: '', required: true },
        password: { value: '', required: true },
        password_confirm: { value: '', required: true }
    });
    const options = [
        {
            label: "Individual",
            value: "Individual",
        },
        {
            label: "Real estate professional",
            value: "Real estate professional",
        },
        {
            label: "Developer",
            value: "Developer",
        },
        {
            label: "Architecture",
            value: "Architecture",
        },
        {
            label: "Contractor",
            value: "Contractor",
        },
        {
            label: "Urban planner",
            value: "Urban planner",
        },
        {
            label: "Government",
            value: "Government",
        },
        {
            label: "Other",
            value: "Other",
        },
    ];

    const handleUserInfo = (e) => {
        if (e.target.name === "zip_code" && e.target.value.length > 6) return
        setUserInfo({ ...userInfo, [e.target.name]: { value: e.target.value, required: e.target.required } });
        dispatch(setDataWindow({ dataWindow: true, updateContent: updateContent, content: { isContact: true, contactInfo: SIGNUP_FIELDS, values: { ...userInfo, [e.target.name]: { value: e.target.value, required: e.target.required } }, choosePlan: {} } }))
    };
    const handleSubmit = () => {
        dispatch(setLoader(true));
        dispatch(signupUser(userInfo));
    }

    useEffect(() => {
        dispatch(setDataWindow({ dataWindow: true, updateContent: updateContent, content: { isContact: true, contactInfo: SIGNUP_FIELDS, values: userInfo, choosePlan: {} } }))
    }, [])


    const handleUpdate = () => {
        let response = validateUserAccount(dataWindowContent);
        if (response.isError) {
            dispatch(setDataWindow({ dataWindow: true, updateContent: true, content: { isContact: true, contactInfo: SIGNUP_FIELDS, values: dataWindowContent.values, isError: response.isError, isPasswordMatched: response.isPasswordMatched, isPhoneValid: response.isPhoneValid ?? true, isEmailValid: response.isEmailValid ?? true, choosePlan: {} } }))
        } else {
            dispatch(setLoader(true));
            Object.keys(response.data).map(function (key, index) {
                if (response.data?.[key] === null || response.data?.[key] === undefined) delete response.data?.[key]
            }); 
            let result = false;
            result = dispatch(updateUser(response.data, user.id, token));
            if (result) {
                alert.success("Your account information has been updated successfully.")
                dispatch(setDataWindow({ dataWindow: false, content: { isContact: true, contactInfo: SIGNUP_FIELDS, values: userInfo, choosePlan: {} }, updateContent: false }))
            }
        }
    }

    const handleCreateAccount = () => {
        let response = validateUserAccount(dataWindowContent);
        if (response.isError) {
            dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: SIGNUP_FIELDS, values: dataWindowContent.values, isError: response.isError, isPasswordMatched: response.isPasswordMatched, isPhoneValid: response.isPhoneValid ?? true, isEmailValid: response.isEmailValid ?? true, choosePlan: {} } }))
        } else {
            dispatch(setLoader(true));
            dispatch(signupUser(response.data));
            dispatch(getUserRoles());
        }
    };
    const handleChangePassword = () => {
        dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: '', choosePlan: {} } }))
        dispatch(setDataWindowContent({ show: { showData: 'change-password', showControl: 'dashboard' } }));
    }
    return (
        <div className="sign-up">
            {isContact === true ?
                <>
                    {/* <div className="signup-header">
                        <Image src={account} className="signup-icon-contact" />
                        <h4>Account Info</h4>
                    </div> */}
                    <h1 style={{ color: "#000" }}>Hello, {user?.first_name}!</h1>
                    <div className="signup-form">
                        <Form onSubmit={handleSubmit}>
                            <h3 style={{ color: "#000" }}>CONTACT INFORMATION</h3>
                            <div className="form-body">
                                {isError && isAlreadyUser && <Message
                                    error
                                    header='Action Forbidden'
                                    content='You can only sign up for an account once with a given e-mail address.'
                                />}
                                {
                                    contactInfo.map((field, index) => {
                                        let fieldName = field.name;
                                        if (!((updateContent && fieldName === "password") || (updateContent && fieldName === "password_confirm"))) {
                                            // return (<Form.Field key={index}
                                            //     error={(isError && field.required && userInfo[field.name] && userInfo[field.name].value === '') || (field.name === 'password_confirm' && isPasswordMatched === false) || (fieldName === 'phone_number' && !isPhoneValid) || (fieldName === 'email' && !isEmailValid)}>
                                            //     {field.label && <label><b>{field.label}:</b></label>}
                                            //     {fieldName === 'phone_number' ?
                                            //         <div className="ui transparent input">
                                            //             <InputMask mask="+199999999999999" maskChar=" " value={values?.[fieldName].value || ""} className="phone-number-input" transparent required={field.required} onChange={(e) => handleUserInfo(e)} placeholder={field.placeHolder} name={field.name} type={field.type} />
                                            //         </div>
                                            //         :
                                            //         <Input transparent required={field.required} disabled={(updateContent && fieldName === "email")} name={field.name} type={field.type} value={values?.[fieldName].value || ""}
                                            //             placeholder={field.placeHolder} onChange={(e) => handleUserInfo(e)} />
                                            //     }
                                            //     {(isError && field.required && userInfo[field.name] && userInfo[field.name].value === '' && <span className='errorMessage'>This {field.placeHolder} field is required!</span>) || (field.name === 'password_confirm' && isPasswordMatched === false && <span className='errorMessage'>This password filed not  matched!</span>) || (fieldName === 'phone_number' && !isPhoneValid && <span className='errorMessage'>This phone number is not valid!</span>) || (fieldName === 'email' && !isEmailValid && <span className='errorMessage'>This email is not valid!</span>)}
                                            // </Form.Field>)
                                            return (<Form.Field key={index}
                                                error={(isError && field.required && userInfo[field.name] && userInfo[field.name].value === '') || (field.name === 'password_confirm' && isPasswordMatched === false) || (fieldName === 'phone_number' && !isPhoneValid) || (fieldName === 'email' && !isEmailValid)}>
                                                {field.label && <label><b>{field.label}</b></label>}

                                                {fieldName === 'phone_number' ?
                                                    <div className="ui transparent input">
                                                        <InputMask mask="+199999999999999" maskChar=" " value={values?.[fieldName].value || ""} className="phone-number-input" transparent required={field.required} onChange={(e) => handleUserInfo(e)} placeholder={field.placeHolder} name={field.name} type={field.type} />
                                                    </div>
                                                    :
                                                    field?.type != 'select' &&
                                                    <div className={field.type}>
                                                        <Input transparent required={field.required} disabled={(updateContent && fieldName === "email")} name={field.name} type={field.type} value={values?.[fieldName].value || ""}
                                                            placeholder={field.placeHolder} onChange={(e) => handleUserInfo(e)} />
                                                    </div>

                                                }
                                                {fieldName === 'industry' &&
                                                    <select transparent className="ui search dropdown" value={values?.[fieldName].value || ""} required={field.required} name={field.name} type={field.type} onChange={(e) => handleUserInfo(e)}>
                                                        <option value="">-select industry-</option>
                                                        {options.map((option) => (
                                                            <option value={option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                }
                                                {(isError && field.required && userInfo[field.name] && userInfo[field.name].value === '' && <span className='errorMessage'>This {field.placeHolder} field is required!</span>) || (field.name === 'password_confirm' && isPasswordMatched === false && <span className='errorMessage'>This password filed not  matched!</span>) || (fieldName === 'phone_number' && !isPhoneValid && <span className='errorMessage'>This phone number is not valid!</span>) || (fieldName === 'email' && !isEmailValid && <span className='errorMessage'>This email is not valid!</span>)}
                                            </Form.Field>)
                                        }
                                    })
                                }
                            </div>
                        </Form>
                    </div>
                    <div className="policy-text">
                        <p>
                            By creating and maintaining an account, I agree to the Terms of Use and Privacy Policy
                        </p>
                    </div>


                    <div className="update-account-btn">
                        <Button onClick={oldUser?handleUpdate:handleCreateAccount}>
                            <Image src={check} />
                           {oldUser?<span> Update info</span>: <span>Create Account</span>}

                        </Button>
                    </div>
                    {/* {updateContent === true ? updateContent &&
                        (<div className="update-account-btn">
                            <Button onClick={handleUpdate}>
                                <Image src={check} />
                                <span>Update</span>

                            </Button>
                        </div>)
                        :
                        window.innerWidth <= 768 && (<div className="update-account-btn">
                            <Button fluid onClick={handleCreateAccount}>
                                <Image src={check} />
                                <span>Create Account</span>

                            </Button>
                        </div>)
                    } */}
                    {/* {updateContent && <div className='update-account-btn'>
                            <Button className='upgrade-button' fluid onClick={handleChangePassword}>Change
                                Password</Button>
                        </div>} */}

                </> : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    type: SET_DATA_WINDOW,
    dataWindow: state.dataWindow,
    content: state.dataWindow.content
});

export default connect(mapStateToProps)(SignUp);
