import React , {useState} from 'react';
import '../../../../css/zone.scss';
import Container from "../Container";
import Header from "../Header";
import { useSelector } from "react-redux";
import CheckBoxGrid from "../CheckBoxGrid";
import { Accordion, Icon } from 'semantic-ui-react'

const Zone = ({ dispatch }) => {
    const [activeIndexes, setActiveIndexes] = useState([0]);

    const handleAccordionClick = (index) => {
      if (activeIndexes.includes(index)) {
        setActiveIndexes(activeIndexes.filter((item) => item !== index));
      } else {
        setActiveIndexes([...activeIndexes, index]);
      }
    };
    const { zoning } = useSelector(state => state.filters.filterValues)
    const zoneHeader = [
        {
            title: 'Zone Category 1',
            subTitle: 'Filter by specific zone designations',
            className: 'zone-sub-title'
        },
        {
            title: 'Zone Category 2',
            subTitle: 'Filter by specific zone designations',
            className: 'zone-sub-title'
        },
        {
            title: 'Zone Name',
            subTitle: 'Filter by specific zone designations',
            className: 'zone-sub-title'
        },

    ]
    return (
        <div className='zone-general' style={{marginTop:"22px"}}>
            {
                zoning.map((info, index) => (
                    <div className="zone-filter" key={index}> 
                    <Accordion styled>
                      <Accordion.Title  onClick={() => handleAccordionClick(index)}> 
                        <Header content={zoneHeader[index]} />
                        <Icon name={activeIndexes.includes(index )? 'angle down' : 'angle right'} />
                      </Accordion.Title>
                      <Accordion.Content active={activeIndexes.includes(index)}>
                        <div className="zone-fields">
                          <CheckBoxGrid info={info} dispatch={dispatch} columns={1} selectedFilter={zoning} />
                        </div>
                      </Accordion.Content>
                    </Accordion>
                    </div>
 
 
                ))
            }

        </div>
    );
};

export default Zone;