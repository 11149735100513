import React from 'react'
import Container from '../Container'
import ICON_LOCK from "../../../../images/lock-1.png";

const UseRegulationWindowLocked = ({heading}) => {
  return (
    <div>
        <>
                        <div className="lock">
                            <div className='summary-data' style={0? {display:"none"}: {}}>
                                <Container>
                                    <div className="data-header">
                                        <div className='header-display'>
                                            <h2>{heading}</h2>
                                        </div>
                                    </div>
                                    <hr className='lock-hr' style={{marginTop:0}} />
                                    <div className='section-data'>
                                        <div className='section-description'>
                                            <p className="sub-heading-lock">
                                                Download full report or sign up for a PRO account to see!</p>
                                            <img src={ICON_LOCK} className="lock-image" alt="" />
                                        </div>
                                    </div> 
                                </Container>
                            </div>
                        </div>
                    </>
    </div>
  )
}

export default UseRegulationWindowLocked
