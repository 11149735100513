export const CITY_BOUNDARY = [
    [
        [
            [-122.658233400085351, 45.45323646400194],
            [-122.658075274137445, 45.452980575513223],
            [-122.657601829643369, 45.452238322940268],
            [-122.657539582682361, 45.452128800306262],
            [-122.657249671187387, 45.451644496707551],
            [-122.656998918153917, 45.451249338409951],
            [-122.656745947179999, 45.450869785179506],
            [-122.656494056879396, 45.450501427256242],
            [-122.656493252887202, 45.450500253210762],
            [-122.655802151091351, 45.449424814143775],
            [-122.655786111671972, 45.449399161731797],
            [-122.655667469375771, 45.449240638956994],
            [-122.655465240639018, 45.449001185800071],
            [-122.655121115816584, 45.448610748176733],
            [-122.654492161268735, 45.44790459507675],
            [-122.653888402667974, 45.447253451988111],
            [-122.653415408229833, 45.446773250934662],
            [-122.65289936382834, 45.446293734013011],
            [-122.652895854110525, 45.446290235562806],
            [-122.652891956320516, 45.446287436046191],
            [-122.652350363832355, 45.445863946742172],
            [-122.651940963828125, 45.44557397641254],
            [-122.651560439270412, 45.445331225985861],
            [-122.651137697487599, 45.4451071879324],
            [-122.650747507057417, 45.444934298455024],
            [-122.650342029097729, 45.444789571382337],
            [-122.649858562998361, 45.444658096964886],
            [-122.649335889928381, 45.444536707621545],
            [-122.648732037902818, 45.444377651847844],
            [-122.648707424962339, 45.444371270472928],
            [-122.6471631778698, 45.443947621823163],
            [-122.647154779520207, 45.443945023244545],
            [-122.646996268195096, 45.44387974991028],
            [-122.646715506041232, 45.443763642412819],
            [-122.645989228913805, 45.443276946957212],
            [-122.645511288351742, 45.44266322681532],
            [-122.645372523793156, 45.442118548331969],
            [-122.64550955011164, 45.441142836865765],
            [-122.645758190307575, 45.440430206568308],
            [-122.645760871778691, 45.440423926855978],
            [-122.645765287896637, 45.440415887965997],
            [-122.646282598922653, 45.439590332491626],
            [-122.646543950279835, 45.439330519519608],
            [-122.646710419780092, 45.439165295909348],
            [-122.647231054572686, 45.438771938631326],
            [-122.647675359515574, 45.43853188004686],
            [-122.647924435394415, 45.438397506044097],
            [-122.647928564051455, 45.438395737979562],
            [-122.648866096187604, 45.438030222067916],
            [-122.649595688098429, 45.437719851071442],
            [-122.650138602906694, 45.437449610653594],
            [-122.650514229849506, 45.437229041573758],
            [-122.65078897598589, 45.43699640122027],
            [-122.650962095714178, 45.436795407593841],
            [-122.650964886779789, 45.436792705309387],
            [-122.650965875824895, 45.436790390056025],
            [-122.651102932686086, 45.436551431190651],
            [-122.651180445617015, 45.436288190882877],
            [-122.651180785180216, 45.436287396012013],
            [-122.651180706128471, 45.43628686462732],
            [-122.651198053494895, 45.43606555538161],
            [-122.651104495754709, 45.435775593942878],
            [-122.651104382566984, 45.43577484003788],
            [-122.651103885798619, 45.435774123954232],
            [-122.650902611072695, 45.435447665766461],
            [-122.650708574073022, 45.435203723879404],
            [-122.648927664022253, 45.432633871708376],
            [-122.648934505591441, 45.43263393474745],
            [-122.650641300139682, 45.432649511692205],
            [-122.653182334939473, 45.432672655204087],
            [-122.661352456752994, 45.432710315318971],
            [-122.665678941688995, 45.432744423796251],
            [-122.666159184633131, 45.432748199196858],
            [-122.6663203414968, 45.432749466279034],
            [-122.666450019596297, 45.432750484987793],
            [-122.666450113021071, 45.432750485618186],
            [-122.66645018847953, 45.432750486248594],
            [-122.667081452595994, 45.432755444889075],
            [-122.667614566784522, 45.432759630041794],
            [-122.668521331622188, 45.432766743350889],
            [-122.668520933668518, 45.43276536279911],
            [-122.669656206558287, 45.432775361397205],
            [-122.669938397931745, 45.432777844498737],
            [-122.669939643895063, 45.432777855215335],
            [-122.671629241993827, 45.43279271032543],
            [-122.673422341284677, 45.432801016325428],
            [-122.675574319328177, 45.432810947465633],
            [-122.676765902213191, 45.43281642932314],
            [-122.681902571415748, 45.432839923892722],
            [-122.681905398413946, 45.432839942174013],
            [-122.696357440805926, 45.432939324032105],
            [-122.697275479500433, 45.43294250433442],
            [-122.697368640185303, 45.432944536701967],
            [-122.697363384142562, 45.432539323784702],
            [-122.697485893686078, 45.432545987652503],
            [-122.697525433033292, 45.432548139178245],
            [-122.69813280735309, 45.432552358387525],
            [-122.69874017448636, 45.432556578857202],
            [-122.698740477218593, 45.432652604386632],
            [-122.698740779950867, 45.432748629752645],
            [-122.698741132090447, 45.432833336967064],
            [-122.698741487823312, 45.432919124538031],
            [-122.698741514772763, 45.43295520726064],
            [-122.699085659358119, 45.432957470349251],
            [-122.699467563728106, 45.43295998748367],
            [-122.699930694664417, 45.432963040446701],
            [-122.700125452112957, 45.432964301220125],
            [-122.700943406806715, 45.432969691656666],
            [-122.701293975244653, 45.432971987524844],
            [-122.701882897270181, 45.432975854946683],
            [-122.702098696855941, 45.432978299585827],
            [-122.702499331099617, 45.432981008356819],
            [-122.702538285643584, 45.432981268706442],
            [-122.706691245711099, 45.433007933419866],
            [-122.71283432357761, 45.433047100563442],
            [-122.722762867017053, 45.43314800758769],
            [-122.723122473405056, 45.433151643646298],
            [-122.724794264199943, 45.433161921436287],
            [-122.733435012492521, 45.433214660010051],
            [-122.733435016984075, 45.433213285773121],
            [-122.735181735358523, 45.433227342702715],
            [-122.73602326184529, 45.433232440616123],
            [-122.736249187240958, 45.433234172910581],
            [-122.736703116733793, 45.433237650737325],
            [-122.737057592843243, 45.433240362017408],
            [-122.737412067156029, 45.433243073927756],
            [-122.737766545062087, 45.433245790880981],
            [-122.738124913571639, 45.433248526745615],
            [-122.738280728154294, 45.433249717540122],
            [-122.738611831896677, 45.433252247899553],
            [-122.743721265078122, 45.433289064805507],
            [-122.743707837061223, 45.445131546500662],
            [-122.743780712888665, 45.445134335990595],
            [-122.744348484080845, 45.445137472118326],
            [-122.744739337467692, 45.445139786398933],
            [-122.744737469870216, 45.445031251451105],
            [-122.74473400955975, 45.44480737040017],
            [-122.744772641608549, 45.444811416633364],
            [-122.745734066236324, 45.444813867692588],
            [-122.745736159310937, 45.445052372449361],
            [-122.745738914443905, 45.445344175701649],
            [-122.745741243775441, 45.445637029953865],
            [-122.74535264695659, 45.445630750821124],
            [-122.74534688875562, 45.445731698367936],
            [-122.745347129504083, 45.445897693072816],
            [-122.745347094469793, 45.446057684249233],
            [-122.745347287607601, 45.446222472348438],
            [-122.745347076503506, 45.446389847718308],
            [-122.745347327133473, 45.446581743764455],
            [-122.74534767657812, 45.446775174404053],
            [-122.745347418761625, 45.446966037452434],
            [-122.745347608306133, 45.447156397563127],
            [-122.745347404388568, 45.447348631953908],
            [-122.745347743951754, 45.447702683630965],
            [-122.745155312241494, 45.44770307436864],
            [-122.74490877859489, 45.447703535060974],
            [-122.744836163278919, 45.447703666147177],
            [-122.744488287091883, 45.447704297629656],
            [-122.744480957737451, 45.447704276202124],
            [-122.744398637921464, 45.4477044595967],
            [-122.744400519891983, 45.447294966921831],
            [-122.743685307313939, 45.447297158219769],
            [-122.74368386551788, 45.447496986334983],
            [-122.743682423721836, 45.44769681248183],
            [-122.743681388862655, 45.448121878064363],
            [-122.743680357596702, 45.448546949266699],
            [-122.743679690148454, 45.44882122029059],
            [-122.743679022700192, 45.449095494392218],
            [-122.743678218707998, 45.44935623858126],
            [-122.743677406630994, 45.449616979674339],
            [-122.743677058982982, 45.449750002907571],
            [-122.743676719419824, 45.44988302267604],
            [-122.74367630439815, 45.450040727541669],
            [-122.74367588937649, 45.450198435117329],
            [-122.743675564186333, 45.450321859773766],
            [-122.743675242589475, 45.450445281009202],
            [-122.7436747736689, 45.450626301315474],
            [-122.743674301155067, 45.450807320410618],
            [-122.743673913981183, 45.450955064497805],
            [-122.743673526807299, 45.451102808197973],
            [-122.743673022852406, 45.451296365124946],
            [-122.743668321968514, 45.453096139115047],
            [-122.743663620186339, 45.454895914904505],
            [-122.743667814420377, 45.455794438134532],
            [-122.743672005061185, 45.456692958390128],
            [-122.743983566852847, 45.456694645225681],
            [-122.744295012761867, 45.456698440447916],
            [-122.746307950464015, 45.456722951540954],
            [-122.746317746592169, 45.458500045765547],
            [-122.745199474319165, 45.458495860001669],
            [-122.744867999572591, 45.458494617441559],
            [-122.744318605216165, 45.458489413433149],
            [-122.744225604431421, 45.458488532551208],
            [-122.744130753117219, 45.458487633396274],
            [-122.743678875376474, 45.458483350595785],
            [-122.743680266866846, 45.458757843612176],
            [-122.743680923535308, 45.458887266334287],
            [-122.743682859404785, 45.45926878442436],
            [-122.743684419778418, 45.459575694246602],
            [-122.743685752878292, 45.45983899476132],
            [-122.743686982671903, 45.460080365875605],
            [-122.743687677967941, 45.460217502179653],
            [-122.743689000288057, 45.460478058852246],
            [-122.74369025164124, 45.460724903856473],
            [-122.743691503892748, 45.460971744629624],
            [-122.743692755245931, 45.461218591882975],
            [-122.743694011989007, 45.461465436165334],
            [-122.743697108481783, 45.462074285502993],
            [-122.743689093712831, 45.465568829577279],
            [-122.743927351180687, 45.465567333273974],
            [-122.744191560977129, 45.465568176243998],
            [-122.74438559618018, 45.465569348715725],
            [-122.744662996838215, 45.465571470001173],
            [-122.74493848767797, 45.465573627827844],
            [-122.745278803643572, 45.465576245570738],
            [-122.745626916985856, 45.465578846932935],
            [-122.746146657056414, 45.465582917506964],
            [-122.746302055719127, 45.465581248577955],
            [-122.746680920190187, 45.465584479332158],
            [-122.747072935098714, 45.465587945714475],
            [-122.747160696010383, 45.465588614798072],
            [-122.747519199267231, 45.465594039288966],
            [-122.747520267364123, 45.465363279975868],
            [-122.747521860975425, 45.465144267836024],
            [-122.747523848048829, 45.464923354675982],
            [-122.747525067960979, 45.464745149250618],
            [-122.747526348060276, 45.464592158461947],
            [-122.747528862444753, 45.464249493626781],
            [-122.748082729512959, 45.464254067066385],
            [-122.748071235568872, 45.464924652537029],
            [-122.748167930226089, 45.46500334381232],
            [-122.748317386533145, 45.46498653275281],
            [-122.748818159574824, 45.464991292621185],
            [-122.748818502731254, 45.465196380509425],
            [-122.748816660286607, 45.465396880444395],
            [-122.74913918242305, 45.465023771860707],
            [-122.749334445623091, 45.464797863972571],
            [-122.749334732185687, 45.46496470791628],
            [-122.749728709014789, 45.464968488721269],
            [-122.75012120811526, 45.464967635661779],
            [-122.750121109300565, 45.465068929766645],
            [-122.750120997909448, 45.465165616932694],
            [-122.750767333061447, 45.465176871102685],
            [-122.750766942294305, 45.464970079544791],
            [-122.750766770716083, 45.464793438634366],
            [-122.751406848203786, 45.464796854032798],
            [-122.751408029488388, 45.464679012901378],
            [-122.751410690298272, 45.464413499624243],
            [-122.751833833627998, 45.464415488645784],
            [-122.752010304970625, 45.464416306431609],
            [-122.751888025395843, 45.464664274552213],
            [-122.751717086777049, 45.465011808247908],
            [-122.752034306158379, 45.465010221205695],
            [-122.752030487420114, 45.465209056012931],
            [-122.752106971780037, 45.465207978667671],
            [-122.752109145703031, 45.46501055071068],
            [-122.752399954615001, 45.464713081891517],
            [-122.752694962252633, 45.464412247113991],
            [-122.753581438639614, 45.464421853892198],
            [-122.753994430108349, 45.464426420387447],
            [-122.754241469506411, 45.464522403017348],
            [-122.754675591147233, 45.464691015016726],
            [-122.75478152767208, 45.464732159886964],
            [-122.754852526020557, 45.464674978173932],
            [-122.754941299333538, 45.464729724184053],
            [-122.755351907571821, 45.464980752237025],
            [-122.755678336481466, 45.464719001041729],
            [-122.756289532234476, 45.465099872323925],
            [-122.757053387686867, 45.464487915569023],
            [-122.757052512727768, 45.46458860255364],
            [-122.757044152107412, 45.46567906638343],
            [-122.757043504422114, 45.465761148004894],
            [-122.755305723386456, 45.465747853945459],
            [-122.75356731622503, 45.465732315749598],
            [-122.751764021954017, 45.46717268950178],
            [-122.749960634258215, 45.46861303967939],
            [-122.749670526930473, 45.46844939365436],
            [-122.749380416009473, 45.468285748414218],
            [-122.748902664093592, 45.468014819047447],
            [-122.748897835648947, 45.4670798059717],
            [-122.748904700574329, 45.466513246073411],
            [-122.748065075820989, 45.466506891773342],
            [-122.747550739116861, 45.466638115547987],
            [-122.747531410066884, 45.466642348588188],
            [-122.747491787176344, 45.466651095022293],
            [-122.747471494234077, 45.46665560778613],
            [-122.74738263737774, 45.466676554364021],
            [-122.747260141308971, 45.46670965571208],
            [-122.747118488666786, 45.466751362967251],
            [-122.747104178504301, 45.466759270842282],
            [-122.747105324754585, 45.46707551815382],
            [-122.747101550932072, 45.467389087442378],
            [-122.747018861010176, 45.467389087442378],
            [-122.74624228631167, 45.467385931131844],
            [-122.746240079151036, 45.467182084128112],
            [-122.746195900005333, 45.467198890122852],
            [-122.746131991161107, 45.467222611666344],
            [-122.746068099384829, 45.467245812186768],
            [-122.746030354871536, 45.467259158813185],
            [-122.745954620604863, 45.467285595015333],
            [-122.745878391366475, 45.467311355211876],
            [-122.745801643800206, 45.467336343643375],
            [-122.74573183751609, 45.467358369792493],
            [-122.74570053841498, 45.467368077499032],
            [-122.745646562242811, 45.467384128065753],
            [-122.745528976365378, 45.467417721659722],
            [-122.745459525814141, 45.467436420738956],
            [-122.745390053703318, 45.467454571710775],
            [-122.74532054835484, 45.467471872805213],
            [-122.745250048571336, 45.467488698873609],
            [-122.74517998896232, 45.467504803586074],
            [-122.745144477660816, 45.4675125355852],
            [-122.745073420023573, 45.467527562359962],
            [-122.745037872789453, 45.467534855875691],
            [-122.745002330945212, 45.467541806670248],
            [-122.744966789101028, 45.467548758093955],
            [-122.744945924830219, 45.468007732862226],
            [-122.744946536582944, 45.468146611345922],
            [-122.744947558865732, 45.468382747578325],
            [-122.744948029582915, 45.468567422200678],
            [-122.744948741946942, 45.468745396937422],
            [-122.744949383344064, 45.468922522522504],
            [-122.744950999413263, 45.469098303167328],
            [-122.744950079538427, 45.469186953672398],
            [-122.744949220748993, 45.469214573335947],
            [-122.744948226313966, 45.469268779453515],
            [-122.744309147752858, 45.469264118853339],
            [-122.743666859511222, 45.469252973226183],
            [-122.743666934969738, 45.469305954624403],
            [-122.743666995156872, 45.469327014243888],
            [-122.743667175718215, 45.469390095408279],
            [-122.743667488331923, 45.469499816349007],
            [-122.743669610152651, 45.471254946267109],
            [-122.743669820358406, 45.471323497720128],
            [-122.743671505597874, 45.471877136203851],
            [-122.743672541355409, 45.472214492715743],
            [-122.743673392059989, 45.47249424758477],
            [-122.743673811573245, 45.472631383929411],
            [-122.743674973094869, 45.473009883573923],
            [-122.743675830087668, 45.473290677668935],
            [-122.743677625819913, 45.473877615072468],
            [-122.74367856635601, 45.474184802534481],
            [-122.74368219285482, 45.474662087349714],
            [-122.743811409220243, 45.474718709641273],
            [-122.744222374988013, 45.474718551531311],
            [-122.744659620071062, 45.474718234681454],
            [-122.744951636318802, 45.474718012319627],
            [-122.74495907886093, 45.474968592114614],
            [-122.744966336350103, 45.475213496524674],
            [-122.744969096872978, 45.475468969161035],
            [-122.744806763216303, 45.475468521923659],
            [-122.744806257464802, 45.475529772621776],
            [-122.744531899400613, 45.475779661577278],
            [-122.744517968327173, 45.475772553691549],
            [-122.744488355363842, 45.475763364587422],
            [-122.744456963736212, 45.475758490985299],
            [-122.744440640449213, 45.475758467048742],
            [-122.74442431806051, 45.475758443112241],
            [-122.744400552231326, 45.475762007764516],
            [-122.744306533655376, 45.475466997537147],
            [-122.7438618819628, 45.475465564487806],
            [-122.743684499728474, 45.475470573546282],
            [-122.743687038367455, 45.476363982272311],
            [-122.744023124166418, 45.476367275398687],
            [-122.744015330382993, 45.476179210364556],
            [-122.744436643844523, 45.476169245914953],
            [-122.744427140567126, 45.476323604294791],
            [-122.744421775828243, 45.476335891166158],
            [-122.744414313523166, 45.476344433267627],
            [-122.744404348511722, 45.476351816978173],
            [-122.744392413494865, 45.476357729867061],
            [-122.744379390618178, 45.476361743601409],
            [-122.74436483701227, 45.47636356464745],
            [-122.744280196847882, 45.476360732608988],
            [-122.744295519411679, 45.47712650721644],
            [-122.745455600176328, 45.47738025850817],
            [-122.745463635606569, 45.476416530542927],
            [-122.745847562084279, 45.476419096130343],
            [-122.745854655181731, 45.477128587123893],
            [-122.746232048212377, 45.47713012154378],
            [-122.746602621233393, 45.477131787611278],
            [-122.74663452579901, 45.476424354544939],
            [-122.747516258182998, 45.476429856100772],
            [-122.747415293833257, 45.476601617251966],
            [-122.747381272836833, 45.476703648847398],
            [-122.747311207837924, 45.476977493174914],
            [-122.747172196242644, 45.477492942639763],
            [-122.747147825847321, 45.477813590577028],
            [-122.747195959376867, 45.47805150478046],
            [-122.747067395188353, 45.478026934302804],
            [-122.747061736700388, 45.478110707443392],
            [-122.747210398896726, 45.478404289752142],
            [-122.747387242141912, 45.47862070377932],
            [-122.747280584269888, 45.47865571671565],
            [-122.747217628538152, 45.478654561522795],
            [-122.747183481777583, 45.478653915269845],
            [-122.746889773103845, 45.478648466210828],
            [-122.746843966210889, 45.478641062015264],
            [-122.746614730523433, 45.515689873411688],
            [-122.74641879987513, 45.515736186690646],
            [-122.746418151291479, 45.515897107665452],
            [-122.746402951796881, 45.515898328817272],
            [-122.746415568635058, 45.515976420162183],
            [-122.746417373350454, 45.516576108049975],
            [-122.746412409260202, 45.519519810515526],
            [-122.746427695891384, 45.519588101196682],
            [-122.764482300576319, 45.519557070860046],
            [-122.790188773715983, 45.519348829793437],
            [-122.790755029450281, 45.548419393157936],
            [-122.80553280725249, 45.548459784240478],
            [-122.805495728390824, 45.562916617214938],
            [-122.826201508515894, 45.563046335237843],
            [-122.826396380050497, 45.577484325177053],
            [-122.847040340812626, 45.577504078625786],
            [-122.846862230044636, 45.591841929764691],
            [-122.867654310420733, 45.591917651403648],
            [-122.867541136169663, 45.606102464714951],
            [-122.867234386652953, 45.606386069616427],
            [-122.816532706652652, 45.627892784548052],
            [-122.815744612857245, 45.627076437801072],
            [-122.814328189741417, 45.625907119170236],
            [-122.812020064738604, 45.624445143190776],
            [-122.810202999294305, 45.623084218035082],
            [-122.808651955548314, 45.622108394165579],
            [-122.80666256179147, 45.620976712893672],
            [-122.803966248703247, 45.620199027916001],
            [-122.802577871888914, 45.619871713862949],
            [-122.8016207115638, 45.619665653250195],
            [-122.801620674732888, 45.619665621207012],
            [-122.798817714552442, 45.619451777709379],
            [-122.795874081791766, 45.619386836548671],
            [-122.795310883024385, 45.619433993037141],
            [-122.794413425631362, 45.61947745194027],
            [-122.793583602396097, 45.619585024345504],
            [-122.792505989669465, 45.619754377645677],
            [-122.792319775199289, 45.619783641196719],
            [-122.791684333018438, 45.624242825517527],
            [-122.791662691704914, 45.624367255910997],
            [-122.791613711064045, 45.624636660022624],
            [-122.791573606676508, 45.624768156622778],
            [-122.790055739510592, 45.629929973530167],
            [-122.788637231405048, 45.63320356523856],
            [-122.785159790956428, 45.636153704468562],
            [-122.771334937024463, 45.644907050105409],
            [-122.76778085501077, 45.649075866656425],
            [-122.765288535848853, 45.652606105889646],
            [-122.765090799586829, 45.652886168305365],
            [-122.762792044012429, 45.657436217283077],
            [-122.759749980151128, 45.655907619473695],
            [-122.758890556528939, 45.655475741338066],
            [-122.752710745652166, 45.652369874211743],
            [-122.746636983589639, 45.649316644883939],
            [-122.742454564170686, 45.64721379917728],
            [-122.741351124879671, 45.646658956689649],
            [-122.73695870270349, 45.644450106386316],
            [-122.733622978677502, 45.643553161444807],
            [-122.7231641049286, 45.640740054317291],
            [-122.718258967507381, 45.639420302673052],
            [-122.713208694675615, 45.63806121836987],
            [-122.71263576805201, 45.63773171919928],
            [-122.709607113342983, 45.635989783025728],
            [-122.704757117208842, 45.633199884929702],
            [-122.702602870020939, 45.631960526710266],
            [-122.695853367353948, 45.62807688119598],
            [-122.693108689869135, 45.626497333755509],
            [-122.689973969277844, 45.624858446056095],
            [-122.688407306524041, 45.62403900308616],
            [-122.683818909682131, 45.622126888563294],
            [-122.682424178185485, 45.621545602271347],
            [-122.675964242060417, 45.618852893706752],
            [-122.674163967925381, 45.618321776330433],
            [-122.672064242186991, 45.617702885865043],
            [-122.666045854075222, 45.616112651530536],
            [-122.665751622582022, 45.616034895936046],
            [-122.665730162728181, 45.615624358081554],
            [-122.637741351624243, 45.608759247319902],
            [-122.588888955145904, 45.605041261072785],
            [-122.588872717198825, 45.598197957468102],
            [-122.586000265144648, 45.596952656857141],
            [-122.583582960698834, 45.595904579299891],
            [-122.58116574518624, 45.594856453252902],
            [-122.57874861681023, 45.593808273685845],
            [-122.576498381040963, 45.592958661930886],
            [-122.574248217136883, 45.592109011540906],
            [-122.569748088754807, 45.59040957599229],
            [-122.559771674093824, 45.584839156067687],
            [-122.54979720787874, 45.579267863315572],
            [-122.547912138170773, 45.578262829388464],
            [-122.546027143022997, 45.57725776175279],
            [-122.545580952516318, 45.577005965276044],
            [-122.54522867548269, 45.577743626993716],
            [-122.544737588974741, 45.579293005155009],
            [-122.544488701742111, 45.580815099449339],
            [-122.544479940473153, 45.580870124260997],
            [-122.544458045834745, 45.582457455920732],
            [-122.54467325343073, 45.584037656658623],
            [-122.545032156437884, 45.585281795997034],
            [-122.545122030187116, 45.585593470448536],
            [-122.545554690268972, 45.58662059029237],
            [-122.545556790530114, 45.586624667833739],
            [-122.545558891689552, 45.586628746003448],
            [-122.546112730909996, 45.587656793661395],
            [-122.546985791856542, 45.589124805638725],
            [-122.548272099393316, 45.591200511668816],
            [-122.548429235397762, 45.591420427180523],
            [-122.548586809780076, 45.591664799228774],
            [-122.548971223144676, 45.592347958881952],
            [-122.549355639204194, 45.5930311133624],
            [-122.54958391369432, 45.593449046649908],
            [-122.549812185489472, 45.593866976196537],
            [-122.550044047850818, 45.594434429586606],
            [-122.550275918297004, 45.595001878496042],
            [-122.550460567003668, 45.595461378226489],
            [-122.550645220201872, 45.595920874194476],
            [-122.550740879101554, 45.59620338155738],
            [-122.550836539797842, 45.596485886869473],
            [-122.550883231531373, 45.596655883795982],
            [-122.549252783900783, 45.596382365216989],
            [-122.549231972630594, 45.596319610500323],
            [-122.549042719159814, 45.595857033430512],
            [-122.548853471977225, 45.595394456318949],
            [-122.548619998038262, 45.594844399680355],
            [-122.548386526794218, 45.594294342050283],
            [-122.548162645065844, 45.593873170920794],
            [-122.54793875615097, 45.593452006687912],
            [-122.547554868300793, 45.592783044814581],
            [-122.547170980450673, 45.592114083139208],
            [-122.54683962158677, 45.591638216493628],
            [-122.545552869383883, 45.589562504020279],
            [-122.544816611973644, 45.588338621586516],
            [-122.544770703571075, 45.588257476157906],
            [-122.544768114626407, 45.58825340690759],
            [-122.544766013466955, 45.588249328855916],
            [-122.544715582945244, 45.588164662224976],
            [-122.54407527728543, 45.586973019911163],
            [-122.543782360925803, 45.586312756440357],
            [-122.543300621388383, 45.584857831872718],
            [-122.54323371486602, 45.584654630783199],
            [-122.542935014254269, 45.582965485320798],
            [-122.542889336718687, 45.581263614506334],
            [-122.543047480632907, 45.579973248379069],
            [-122.543097328148022, 45.579567525619019],
            [-122.543556228917652, 45.57789612680795],
            [-122.544261789996312, 45.576267665712628],
            [-122.544278397150961, 45.576233268069757],
            [-122.540783179941229, 45.574528499395775],
            [-122.536813548056898, 45.572585457185966],
            [-122.533739425119748, 45.571169850477936],
            [-122.529760747200513, 45.570116830387583],
            [-122.521672013650033, 45.567854128560107],
            [-122.513022174406814, 45.56659125070658],
            [-122.49563183614525, 45.563813690044022],
            [-122.47525886835821, 45.560809021373473],
            [-122.475258405725839, 45.558812540718336],
            [-122.47447701336246, 45.558847130176694],
            [-122.473897064608309, 45.558872799436877],
            [-122.473320234804788, 45.558897816434659],
            [-122.472814358434036, 45.55891519070601],
            [-122.472814824659665, 45.558095776281426],
            [-122.472815199257113, 45.557434616572031],
            [-122.472818561651238, 45.551488749003738],
            [-122.472037843024339, 45.551488941496515],
            [-122.472038164621196, 45.550934403467231],
            [-122.472818876959906, 45.550936054769807],
            [-122.472819221912957, 45.55033421623471],
            [-122.472814688115747, 45.550244815256995],
            [-122.472801025638574, 45.550155967095257],
            [-122.472778298261886, 45.550068039762323],
            [-122.472746620071717, 45.549981488715254],
            [-122.472617521385615, 45.549721634097487],
            [-122.472572983812128, 45.549594346283548],
            [-122.472548710434836, 45.54948631632945],
            [-122.47253765307201, 45.549389189469338],
            [-122.472537264999815, 45.549264631766498],
            [-122.472635702388629, 45.549241063041116],
            [-122.472734318542223, 45.549211947017461],
            [-122.472950040872874, 45.549148260105987],
            [-122.472939768637588, 45.549328482108372],
            [-122.47526341473187, 45.549208090085251],
            [-122.4752636348191, 45.548784741254579],
            [-122.476314928704554, 45.548844305515253],
            [-122.476946877337255, 45.548880917974095],
            [-122.477375398880611, 45.548898846425764],
            [-122.477375574950386, 45.548312760057343],
            [-122.47851726720117, 45.54841462586964],
            [-122.484141566194452, 45.54898331937008],
            [-122.488119493122113, 45.549385394199625],
            [-122.491581344207233, 45.549735187022165],
            [-122.491568112023103, 45.548618824985986],
            [-122.491944043494811, 45.548691425484463],
            [-122.491939388424981, 45.546065707694929],
            [-122.491938973403336, 45.545832581591071],
            [-122.491938561076608, 45.545599454520364],
            [-122.49193814964822, 45.545366328370278],
            [-122.491937793915369, 45.545166607555245],
            [-122.491937432792611, 45.54496365039202],
            [-122.491937072568177, 45.544760693125198],
            [-122.491936720428598, 45.544560976449894],
            [-122.491936191320903, 45.544264767590896],
            [-122.491935637958662, 45.543953863473078],
            [-122.491941056596502, 45.54285171632403],
            [-122.496064906757198, 45.542799898836613],
            [-122.49606815596357, 45.541981243517952],
            [-122.496009592095277, 45.5419811271219],
            [-122.496010636835933, 45.541717832334307],
            [-122.496011721102491, 45.5414435710723],
            [-122.496014012704791, 45.540859920548648],
            [-122.495936286873132, 45.54086059503004],
            [-122.49593806104582, 45.54067135926514],
            [-122.495940199934509, 45.540443745732702],
            [-122.49588138633456, 45.540444222025805],
            [-122.495885054155835, 45.540029798942953],
            [-122.495943863264245, 45.540029327050704],
            [-122.495945608690846, 45.539836252305264],
            [-122.495947849987459, 45.539589219707238],
            [-122.495949977198066, 45.539353721727949],
            [-122.495891437584277, 45.539353887206822],
            [-122.495898289933265, 45.5386031170883],
            [-122.495904501783443, 45.537922939753791],
            [-122.495963022532635, 45.537922982540316],
            [-122.495964776942373, 45.537729254986942],
            [-122.49596652416561, 45.537536011263697],
            [-122.495986034675269, 45.537535877869558],
            [-122.495986779378612, 45.537453601313928],
            [-122.495967267970656, 45.537453734079037],
            [-122.49596874480099, 45.537290651724192],
            [-122.495971967956237, 45.536934085887793],
            [-122.495973204038052, 45.536797603185327],
            [-122.495974962939385, 45.536603038656828],
            [-122.495976746095209, 45.536405503488396],
            [-122.495918226244356, 45.536405455037645],
            [-122.495921439518114, 45.536090050595057],
            [-122.495999860645796, 45.536090170149478],
            [-122.496000537975533, 45.536021608793568],
            [-122.496005728441233, 45.535494247352823],
            [-122.496010247865414, 45.535035230923263],
            [-122.496014786154234, 45.534574657756238],
            [-122.496016532479132, 45.534397841792739],
            [-122.496019790668683, 45.534066605956049],
            [-122.496023132401561, 45.533727535766872],
            [-122.496029450252948, 45.533555503937329],
            [-122.496062102216882, 45.53314945281771],
            [-122.496351204329812, 45.533148939336058],
            [-122.496741401048226, 45.533148240220775],
            [-122.496741646288299, 45.532738159453721],
            [-122.496816939482159, 45.532738181478216],
            [-122.496833375058571, 45.532534486618914],
            [-122.496849940890741, 45.5323291190359],
            [-122.49722855922343, 45.532344153693138],
            [-122.497239739655427, 45.532207247674137],
            [-122.497262008891354, 45.531934757383475],
            [-122.497359399742876, 45.531938796104228],
            [-122.497748871520827, 45.53195494028656],
            [-122.497770908991384, 45.531680991353745],
            [-122.497868360928337, 45.53168502002422],
            [-122.4978837751203, 45.531493315528287],
            [-122.497884215294789, 45.53148784074655],
            [-122.497899189312264, 45.531301638067568],
            [-122.497912398140201, 45.531137341592412],
            [-122.497425440289277, 45.531117487514294],
            [-122.49732805033608, 45.531113517327093],
            [-122.497350060857187, 45.530839772591705],
            [-122.49725266012419, 45.530835821892985],
            [-122.496960491162852, 45.530823976717429],
            [-122.496982498989013, 45.530550319303465],
            [-122.497004498730305, 45.530276664963083],
            [-122.496821290921375, 45.530269317898657],
            [-122.49652910309544, 45.530257604756585],
            [-122.496562082046154, 45.529847467042131],
            [-122.496647905291752, 45.529850881643583],
            [-122.496658912348934, 45.529714002908563],
            [-122.496669924796009, 45.529577094262997],
            [-122.496704599765962, 45.529578473073926],
            [-122.496942636248264, 45.529587939103777],
            [-122.497016270253781, 45.529590871672198],
            [-122.497195865936973, 45.52959801052485],
            [-122.497449098320615, 45.529608081314805],
            [-122.497466931675646, 45.529386337872985],
            [-122.497471115129912, 45.529334275760981],
            [-122.497493129244276, 45.529060464469104],
            [-122.497493787709374, 45.529052249423813],
            [-122.497515146951883, 45.528786654990903],
            [-122.497515807213617, 45.528778439905615],
            [-122.497535847729281, 45.52852919576199],
            [-122.497536507092718, 45.52852097812184],
            [-122.497537161066234, 45.528512843550537],
            [-122.497559175180584, 45.528239033923832],
            [-122.49758119378653, 45.527965224852231],
            [-122.497603207002555, 45.527691416335763],
            [-122.497992782086783, 45.527706880175515],
            [-122.498370792260019, 45.527721884601974],
            [-122.498760441904409, 45.527737347174636],
            [-122.498812498376807, 45.527089985972225],
            [-122.498471013702996, 45.527088923026263],
            [-122.498080882561609, 45.527087709669324],
            [-122.498084438093514, 45.52653925328962],
            [-122.497694303358884, 45.5265380361448],
            [-122.497304164132686, 45.52653682025862],
            [-122.496954707809067, 45.526535724576455],
            [-122.496960074344571, 45.526013156654983],
            [-122.496962890562983, 45.525738896833097],
            [-122.496964724922805, 45.525560654671587],
            [-122.497721566329446, 45.525563179000784],
            [-122.497725078742192, 45.525220168129813],
            [-122.497726834050269, 45.525048753481528],
            [-122.497728585765074, 45.524877157684763],
            [-122.497730341971447, 45.524705741991255],
            [-122.497732099076131, 45.524534146406999],
            [-122.497735606997352, 45.524191132408347],
            [-122.497739118511774, 45.523848118835765],
            [-122.497742627331291, 45.523505107577336],
            [-122.497746137947402, 45.52316209674504],
            [-122.498331433474164, 45.523164130266743],
            [-122.498339599160076, 45.522358372850896],
            [-122.497754413227796, 45.522353332086759],
            [-122.497756442522046, 45.522184224226663],
            [-122.474002243853363, 45.52188869303896],
            [-122.47306290700179, 45.506459066760712],
            [-122.474340053519313, 45.475487707773276],
            [-122.467273064671303, 45.475587675909303],
            [-122.466719818338973, 45.461122811552009],
            [-122.466798673352912, 45.45346140744293],
            [-122.475249040788995, 45.453423596076185],
            [-122.479488034307934, 45.453419488075056],
            [-122.658074019190977, 45.453104207706254],
            [-122.658233400085351, 45.45323646400194]
        ],
        [
            [-122.666715529254901, 45.550181171034865],
            [-122.666746725948101, 45.552061880837321],
            [-122.666735600313302, 45.554124167900689],
            [-122.666727173217623, 45.554446310602771],
            [-122.666733740800652, 45.554468848684479],
            [-122.666721011673076, 45.556828086084572],
            [-122.6666812279841, 45.557078494878041],
            [-122.666717821755526, 45.557419333251552],
            [-122.666722161516645, 45.55682085067756],
            [-122.666741202207419, 45.556399000871558],
            [-122.666756732282053, 45.555578635794035],
            [-122.666782288453561, 45.555531751461181],
            [-122.666767621659915, 45.554585130700509],
            [-122.666756546330774, 45.553323438469207],
            [-122.666747428430654, 45.552061880208257],
            [-122.666736360288013, 45.550800357793847],
            [-122.666715529254901, 45.550181171034865]
        ],
        [
            [-122.666715529254901, 45.550181171034865],
            [-122.666710489706162, 45.549877377412471],
            [-122.666707406688118, 45.549939743690501],
            [-122.666715529254901, 45.550181171034865]
        ],
        [
            [-122.666710489706162, 45.549877377412471],
            [-122.666727236998, 45.54953862651675],
            [-122.666700161775353, 45.548266945419662],
            [-122.666682429031653, 45.548185599276238],
            [-122.666710489706162, 45.549877377412471]
        ],
        [
            [-122.66668076086016, 45.543054175270512],
            [-122.666656398549648, 45.543796488723586],
            [-122.666666783074348, 45.544631046762454],
            [-122.666669287577349, 45.545361494764393],
            [-122.666673082959406, 45.546074599706849],
            [-122.666676872053273, 45.546787532662755],
            [-122.666682110129713, 45.547205839754547],
            [-122.66668076086016, 45.543054175270512]
        ],
        [
            [-122.66668076086016, 45.543054175270512],
            [-122.666687084101426, 45.542861528044206],
            [-122.666693136949831, 45.541013017553155],
            [-122.666712467796415, 45.538164443878081],
            [-122.666698148650781, 45.539009067168429],
            [-122.666680693486512, 45.542846552193872],
            [-122.66668076086016, 45.543054175270512]
        ],
        [
            [-122.666712467796415, 45.538164443878081],
            [-122.666714358750085, 45.538052943247912],
            [-122.666713941931789, 45.53794729596558],
            [-122.666712467796415, 45.538164443878081]
        ],
        [
            [-122.666713941931789, 45.53794729596558],
            [-122.666723352682723, 45.536560423541516],
            [-122.666710531028684, 45.537083306873619],
            [-122.666713941931789, 45.53794729596558]
        ],
        [
            [-122.666723352682723, 45.536560423541516],
            [-122.666724274354209, 45.536522828382637],
            [-122.666741369294073, 45.535692208570438],
            [-122.666759772180981, 45.535100433943541],
            [-122.666766267000483, 45.534810341243372],
            [-122.666743448893939, 45.534458194018875],
            [-122.666723607804244, 45.536522784965861],
            [-122.666723352682723, 45.536560423541516]
        ],
        [
            [-122.666743448893939, 45.534458194018875],
            [-122.666756593043175, 45.533090319965744],
            [-122.666737767049767, 45.534370522149644],
            [-122.666743448893939, 45.534458194018875]
        ],
        [
            [-122.660721288756278, 45.522937465467756],
            [-122.658692407754486, 45.522930755637034],
            [-122.657677978033362, 45.522927387189682],
            [-122.654634687073397, 45.522915172160701],
            [-122.653620223216308, 45.522914015987411],
            [-122.653243884807793, 45.522918246058381],
            [-122.660721288756278, 45.522937465467756]
        ],
        [
            [-122.652601460022254, 45.522912018960788],
            [-122.651998698551452, 45.522895970995748],
            [-122.65158529745095, 45.522853127098372],
            [-122.651196478748204, 45.522907596928043],
            [-122.651593463136891, 45.522857445916763],
            [-122.651904042171637, 45.522895133289062],
            [-122.652601460022254, 45.522912018960788]
        ],
        [
            [-122.650576919679892, 45.522903552523751],
            [-122.642191042510589, 45.522874729282172],
            [-122.643477803484103, 45.522879959530272],
            [-122.645508783848726, 45.522886267821654],
            [-122.650576919679892, 45.522903552523751]
        ],
        [
            [-122.641362519461723, 45.52287188155146],
            [-122.639292751103056, 45.522864767515301],
            [-122.637275861711998, 45.522880000440075],
            [-122.641362519461723, 45.52287188155146]
        ],
        [
            [-122.636025170486334, 45.522878456245849],
            [-122.635732589832159, 45.522877646029897],
            [-122.635922459904933, 45.522878329431904],
            [-122.636025170486334, 45.522878456245849]
        ],
        [
            [-122.63460792178401, 45.522874531592969],
            [-122.63439524307357, 45.522873942641915],
            [-122.633993856939995, 45.522872217506766],
            [-122.634508813481688, 45.522874431042091],
            [-122.63460792178401, 45.522874531592969]
        ],
        [
            [-122.631710845239269, 45.522871352736324],
            [-122.628739382671114, 45.522865242698558],
            [-122.628926523304358, 45.522865629768326],
            [-122.629126393065164, 45.522866043272145],
            [-122.631710845239269, 45.522871352736324]
        ],
        [
            [-122.623019207385894, 45.523542547171751],
            [-122.622679472630253, 45.523525035461773],
            [-122.62282585400412, 45.523532581030679],
            [-122.623019207385894, 45.523542547171751]
        ],
        [
            [-122.615357120570707, 45.523067710300644],
            [-122.615316804204809, 45.523056627295738],
            [-122.615000802938994, 45.522978418012578],
            [-122.615357120570707, 45.523067710300644]
        ],
        [
            [-122.614866818575351, 45.52294752730657],
            [-122.614821325833432, 45.522937038747472],
            [-122.614763646447273, 45.522928407496053],
            [-122.614866818575351, 45.52294752730657]
        ],
        [
            [-122.614763623243945, 45.522928404023865],
            [-122.614725342641961, 45.52292267564286],
            [-122.614615267578628, 45.522912198334424],
            [-122.614585843264152, 45.522911474717333],
            [-122.614763623243945, 45.522928404023865]
        ],
        [
            [-122.614585835272862, 45.522911474520804],
            [-122.614469877046517, 45.522908622819543],
            [-122.608869227999733, 45.522888220792488],
            [-122.614585835272862, 45.522911474520804]
        ],
        [
            [-122.598560380988118, 45.522876576604098],
            [-122.598390670366328, 45.522884908357554],
            [-122.597144553455323, 45.523116044476552],
            [-122.598367335728497, 45.522889236614198],
            [-122.598560380988118, 45.522876576604098]
        ],
        [
            [-122.591945570816478, 45.523116715394472],
            [-122.588948889843337, 45.523108702770571],
            [-122.588165628085861, 45.523106659176818],
            [-122.591945570816478, 45.523116715394472]
        ],
        [
            [-122.587900844903658, 45.523054144525979],
            [-122.587343191697158, 45.522869304098855],
            [-122.587217096977369, 45.522849705778277],
            [-122.587399564574511, 45.522888843250776],
            [-122.587891056222659, 45.52305160232865],
            [-122.587900844903658, 45.523054144525979]
        ],
        [
            [-122.585856609548514, 45.522837790953602],
            [-122.58254789094353, 45.522812994669039],
            [-122.583577729808937, 45.522834689982155],
            [-122.584317922738435, 45.522850243270454],
            [-122.584790477002045, 45.522858131313612],
            [-122.585856609548514, 45.522837790953602]
        ],
        [
            [-122.677530028058499, 45.501459989528044],
            [-122.677526862395425, 45.501624533227023],
            [-122.67751632246221, 45.502173020923031],
            [-122.677530028058499, 45.501459989528044]
        ],
        [
            [-122.677268393731993, 45.45311163290129],
            [-122.677267891573763, 45.453146209229374],
            [-122.677267428941363, 45.45317814075112],
            [-122.677266924986498, 45.453212891592941],
            [-122.677268393731993, 45.45311163290129]
        ],
        [
            [-122.666444543686907, 45.588850223196737],
            [-122.666427347016807, 45.588913785853222],
            [-122.666356531026352, 45.589197174638564],
            [-122.666444543686907, 45.588850223196737]
        ],
        [
            [-122.66782335861275, 45.582935862428037],
            [-122.667730662200171, 45.583676039423842],
            [-122.667730792612602, 45.583675004792774],
            [-122.66782335861275, 45.582935862428037]
        ],
        [
            [-122.668114593115149, 45.580618800480174],
            [-122.668110726977176, 45.580644951427928],
            [-122.667998738502277, 45.581564169635897],
            [-122.668114593115149, 45.580618800480174]
        ],
        [
            [-122.666807982965636, 45.575187981531862],
            [-122.666808150950601, 45.576123709188913],
            [-122.666808689939757, 45.575808546460728],
            [-122.666807982965636, 45.575187981531862]
        ],
        [
            [-122.666799130287004, 45.572135321000268],
            [-122.666799846924121, 45.572508824081417],
            [-122.666800922117801, 45.572753196266987],
            [-122.666799130287004, 45.572135321000268]
        ],
        [
            [-122.666927494678262, 45.56116885168116],
            [-122.666930820394214, 45.56143216663758],
            [-122.666898700605387, 45.566747229372893],
            [-122.666911480564465, 45.565153291195543],
            [-122.666918544915859, 45.564099214928326],
            [-122.66692076644955, 45.563891647573804],
            [-122.666920170866504, 45.563874310060974],
            [-122.66693581323058, 45.562722320438816],
            [-122.666933659070509, 45.56164325763956],
            [-122.666949340062104, 45.561491375974775],
            [-122.666927494678262, 45.56116885168116]
        ],
        [
            [-122.666913470797525, 45.560058505039237],
            [-122.666922176045574, 45.560747746706028],
            [-122.6669219791752, 45.560519274538464],
            [-122.666913470797525, 45.560058505039237]
        ],
        [
            [-122.66671428406697, 45.558941332034031],
            [-122.666722074380061, 45.558982081883528],
            [-122.666747143657346, 45.559018774223013],
            [-122.66671428406697, 45.558941332034031]
        ],
        [
            [-122.666703849350668, 45.558601951871395],
            [-122.666698303161027, 45.558857738537199],
            [-122.666700680367427, 45.5588701733138],
            [-122.666703849350668, 45.558601951871395]
        ],
        [
            [-122.666163323171645, 45.532529644358554],
            [-122.666383789505318, 45.532721753089973],
            [-122.666757310797095, 45.533041539768135],
            [-122.666163323171645, 45.532529644358554]
        ],
        [
            [-122.66589851086853, 45.532152651013874],
            [-122.665930876905378, 45.532219740713785],
            [-122.666019999663021, 45.532357562927629],
            [-122.66589851086853, 45.532152651013874]
        ],
        [
            [-122.66680083507093, 45.527970639561694],
            [-122.666787304646121, 45.528423059856372],
            [-122.666787553479452, 45.528414750320032],
            [-122.66680083507093, 45.527970639561694]
        ],
        [
            [-122.668216671586862, 45.526748148057592],
            [-122.668616716535709, 45.52705715421142],
            [-122.669244709886186, 45.527542225180518],
            [-122.668216671586862, 45.526748148057592]
        ]
    ]
]