import React, { useEffect, useState } from "react";
import { Menu, Icon, Image } from "semantic-ui-react";
import logo from "../images/logo.svg";
import home from "../images/home.png";
import activeInfo from "../images/info.svg";
import Infoo from "../images/infoo.svg";
import filter from "../images/filter-1.svg";
import activeFilter from "../images/active-filter.svg";
import account from "../images/user-1.svg";
import activeAccount from "../images/active-account.svg";
import help from "../images/help-1.svg";
import activeHelp from "../images/active-help.svg";
import map from "../images/map.png";
import search from "../images/search.png";
import { setDataWindow, setDataWindowContent, setMapSearch } from "../actions/actions_datawindow";
import { useSelector } from "react-redux";
import { citiesAndPlans, PLANS } from "../constants";
import { getUserRoles } from "../actions/actions_plans";
import { activityLogger, getPlanPrice } from "../actions/actions_user";
import { isMobile } from "react-device-detect";
import { manageCitiesAndPlans } from "../utils/Utils";

const SideMenu = ({ currentContent, setCurrentContent, dispatch, showPanel, setShowPanel, showMap }) => {
  const loggedUser = useSelector(state => state.user);
  const userRoles = useSelector(state => state.plans.plans);
  const { planPrice } = useSelector(state => state.user);
  const { subscriptions } = useSelector(state => state.plans);
  const [mapShow, setMapShow] = useState(false);
  const [activeSection, setActiveSection] = useState('Info');
  useEffect(() => {
    // set the current content state to "Info" by default
    setCurrentContent("Info");
  }, []);
  useEffect(() => {
    if (!userRoles.length > 0 && loggedUser && loggedUser.token) dispatch(getUserRoles(loggedUser.token));
  }, [loggedUser])
  useEffect(() => {
    if (showMap) {
      setShowPanel(false) 
      setMapShow(showMap)
    }
  }, [showMap])
  useEffect(() => {
    changeMenuHandler("Info") 
  }, [])
  const changeMenuHandler = (menuItem) => { 
    let body = {
      'event': 'Tab Selected: ' + menuItem
    }
    
    dispatch(activityLogger(body))
    dispatch(setMapSearch(true));
    setMapShow(false)
    if (planPrice == null) {
      dispatch(getPlanPrice());
    }
    if (currentContent != menuItem) {
      (loggedUser.user && (loggedUser.user.status === null || loggedUser.user.status === 'null') && menuItem === 'User') ? dispatch(setDataWindow({ dataWindow: true, content: { isContact: false, choosePlan: { plans: PLANS, activeContent: 'non-subscriber' } } }))
        : dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {} }, updateContent: false }))
      setCurrentContent(menuItem);
      if (loggedUser === null || loggedUser.user === null) {
        dispatch(setDataWindowContent({ show: { showData: '', showControl: 'login' } }))
      } else {
        (['null', null].includes(loggedUser.user.status) === true) ? dispatch(setDataWindowContent({ show: { showData: 'choose-plan', showControl: 'choose-plan' } })) : dispatch(setDataWindowContent({ show: { showData: 'dashboard', showControl: 'dashboard' } }));;
      }
    }
    if (menuItem === 'User') manageCitiesAndPlans(citiesAndPlans, loggedUser?.user?.status === 'Basic' ? 'pro' : 'ent', dispatch, subscriptions, true);
  }
  return (
    <Menu vertical className="side-menu">
      <Menu.Menu >
        {/* <Menu.Item className="icon-logo" onClick={() => changeMenuHandler("Home")}> */}
        <Menu.Item className="icon-logo" onClick={() => changeMenuHandler("Info")}>
          <img src={logo} alt="" />
          <span>Urban</span>
          <span>Form</span>
        </Menu.Item>
        <div className="icon-items">
          {/* {isMobile == true &&
            <div>
              <div className={[(currentContent === 'searchBar') ? "active " : '', " border-management"]}>
                <span className='helper-border'></span>
                <span className='helper-border-second'></span>
              </div>
              <Menu.Item className={[currentContent === 'searchBar' && "active", 'icon-item']}
                onClick={() => changeMenuHandler("searchBar")}>
                <Image src={search} className={currentContent === "searchBar" ?? "active-content"} />
                <span>Search</span>
              </Menu.Item>
              <div className={[(currentContent === 'searchBar') ? "active " : '', " border-management"]}>
                <span className='helper-border-top'></span>
                <span className='helper-border-second'></span>
              </div>
            </div>
          } */}
          <div>
            {/* <div className={[(currentContent === "Info") ? "active " : '', " border-management"]}>
              <span className='helper-border'></span>
              <span className='helper-border-second'></span>
            </div> */}

            <Menu.Item className={[currentContent === "Info" && "active", 'icon-item']} onClick={() => changeMenuHandler("Info")}>
              <Image src={`${(currentContent === "Info" && "active") ? activeInfo : Infoo} `} className={currentContent === "Info" ?? "active-content"} />
              <span>Info</span>
            </Menu.Item>
            {/* <div className={[(currentContent === "Info") ? "active " : '', " border-management"]}>
              <span className='helper-border-top'></span>
              <span className='helper-border-second'></span>
            </div> */}
          </div>
          {isMobile == false &&
            <div>
              <div className={[(currentContent === "Search") ? "active " : '', " border-management"]}>
                <span className='helper-border'></span>
                <span className='helper-border-second'></span>
              </div>
              <Menu.Item className={[currentContent === "Search" && "active", 'icon-item']}
                onClick={() => changeMenuHandler("Search")}>
                <Image src={`${(currentContent === "Search" && "active") ? activeFilter : filter} `} className={currentContent === "Search" ?? "active-content"} />
                <span>Filter</span>
              </Menu.Item>

              <div className={[(currentContent === "Search") ? "active " : '', " border-management"]}>
                <span className='helper-border-top'></span>
                <span className='helper-border-second'></span>
              </div>
            </div>
          }
          <div className="bottom-icons" >
            <div>
              {/* <div className={[(currentContent === "Help") ? "active " : '', " border-management"]}>
                <span className='helper-border'></span>
                <span className='helper-border-second'></span>
              </div> */}
              <Menu.Item className={[currentContent === "Help" && "active", 'icon-item']} onClick={() => changeMenuHandler("Help")}>
                <Image src={`${(currentContent === "Help" && "active") ? activeHelp : help} `} className={currentContent === "Help" ?? "active-content"} />
                <span>Help</span>
              </Menu.Item>
              {/* <div className={[(currentContent === "Help") ? "active " : '', " border-management"]}>
                <span className='helper-border-top'></span>
                <span className='helper-border-second'></span>
              </div> */}
            </div>
            <div>
              {/* <div className={[(currentContent === "User") ? "active " : '', " border-management"]}>
                <span className='helper-border'></span>
                <span className='helper-border-second'></span>
              </div> */}
              <Menu.Item className={[currentContent === "User" && "active", 'icon-item']} onClick={() => changeMenuHandler("User")} >
                <Image src={`${(currentContent === "User" && "active") ? activeAccount : account} `} className={currentContent === "User" ?? "active-content"} />
                <span style={{ fontSize: "11px", display: "flex", justifyContent: "center" }}>Account</span>
              </Menu.Item>
              {/* <div className={[(currentContent === "User") ? "active " : '', " border-management"]}>
                <span className='helper-border-top'></span>
                <span className='helper-border-second'></span>
              </div> */}
            </div>
          </div>

          {/* {isMobile == true &&
            <div>
              <div className={[(currentContent === 'mobileMap') ? "active " : '', " border-management"]}>
                <span className='helper-border'></span>
                <span className='helper-border-second'></span>
              </div>
              <Menu.Item className={[currentContent === 'mobileMap' && "active", 'icon-item']} onClick={() => changeMenuHandler("mobileMap")}>
                <Image src={map} className={currentContent === "mobileMap" ?? "active-content"} />
                <span>Map</span>
              </Menu.Item>
              <div className={[(currentContent === 'mobileMap') ? "active " : '', " border-management"]}>
                <span className='helper-border-top'></span>
                <span className='helper-border-second'></span>
              </div>
            </div>
          } */}
        </div>
      </Menu.Menu>
    </Menu >
  );
};

export default SideMenu;
