import React, { useEffect, useState } from "react";
import { Button, Image } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import { setDataWindow, setDataWindowContent } from "../../actions/actions_datawindow";
import { INFO, ICONS, INFO_CONSTANT, PLANS } from '../../constants';
import '../../css/info.scss';
import ICON_ROTATE_ARROW from "../../images/rotate-left-circular-arrow-interface-symbol.png";
import ICON_FILE from "../../images/folder-star-1.svg";
import ICON_HEART from "../../images/start-1.svg";
import ICON_DOWNLOAD from "../../images/download-file-1.svg";
import { parseInfoResponse } from "../../utils/Utils";
import SavedModel from "../dataWindow/components/SavedModel";
import { getSaveReport } from "../../actions/actions_save_report";

// import ICON_FILE from "../../images/folder.png";
// import ICON_HEART from "../../images/heart.png";
import {
    activityLogger,
    downloadUserReport,
    getPaymentMethodsQuota,
    setLoader,
    setUserLoginError
} from "../../actions/actions_user";
import { setInfoCurrentValue, setLinkValue } from "../../actions/actions_info";
import { getSaveProperty, postSaveProperty, setFlagFile } from "../../actions/actions_save_property";
import { useAlert } from "react-alert";
import { isMobile } from "react-device-detect";

const InfoContent = ({ dispatch }) => {
    const [activeSection, setActiveSection] = useState('');
    const { user } = useSelector(state => state.user);
    const { info_not_updated, identifiers } = useSelector(state => state.info.infoDefaultValues);
    const { reports } = useSelector(state => state.info);
    const { selectedProperty } = useSelector(state => state.filters)
    const { geojson } = useSelector(state => state.taxlot);
    console.log(reports, "reports");
    const alert = useAlert();
    const token = localStorage.getItem("_uft")
    // const [modelOpen,setModelOpen] = useState(false);
    const [open, setOpen] = React.useState(false)
    const { infoDefaultValues, infoValuesUpdated, info_link } = useSelector(state => state.info);
    useEffect(() => {
        if (info_not_updated === false) {
            setActiveSection('summary');
        }
    }, [info_not_updated])

    useEffect(() => {
        if (info_link !== null) {

            setActiveSection(info_link);
        }
    }, [info_link])
    // change style for total icon due to large size
    const handleInfoControl = (control) => {
        if (control === activeSection) {
            setActiveSection('');
            dispatch(setDataWindowContent({ show: { showData: '', showControl: 'info' } }));
            dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
            return
        }
        dispatch(setDataWindowContent({ show: { showData: control, showControl: 'info' } }));
        setActiveSection(control);
        let body = {
            'event': 'Tab Selected: ' + control
        }
        dispatch(activityLogger(body))
        switch (control) {
            case 'summary':
                dispatch(setLinkValue(null));
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'summary', showControl: 'info' } }));
                break;
            case 'identifiers':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'identifiers', showControl: 'info' } }));
                break;
            case 'zone_detail':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'zone_detail', showControl: 'info' } }));
                break;
            case 'land_detail':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'land_detail', showControl: 'info' } }));
                break;
            case 'use_regulations':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'use_regulations', showControl: 'info' } }));
                break;
            case 'far':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'far', showControl: 'info' } }));
                break;
            case 'height_limit':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'height_limit', showControl: 'info' } }));
                break;
            case 'massing':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'massing', showControl: 'info' } }));
                break;
            case 'coverage':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'coverage', showControl: 'info' } }));
                break;
            case 'residential_units':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'residential_units', showControl: 'info' } }));
                break;
            case 'parking':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'parking', showControl: 'info' } }));
                break;
            case 'existing_structure_info':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'existing_structure_info', showControl: 'info' } }));
                break;
            case 'development':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'development', showControl: 'info' } }));
                break;
            case 'downloadBtn':
                if (['Professional', 'Enterprise'].includes(user?.status)) {
                    handleReportDownload();
                } else if (user?.status === 'Basic'||user?.status === undefined) {
                    dispatch(setDataWindow({
                        dataWindow: true,
                        content: {
                            isContact: null,
                            isForce: true,
                            isPayment: true,
                            oneTimePayment: true,
                            choosePlan: { plans: '', activeContent: '' }
                        }
                    }));
                    dispatch(setDataWindowContent({ show: { showData: 'payment', showControl: 'info' } }))
                } else {
                    dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                    dispatch(setDataWindowContent({ show: { showData: 'purchaseInfo', showControl: 'info' } }));
                }
                ['Professional', 'Enterprise'].includes(user?.status) && dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                break;
        }
    }
    const handleReportDownload = () => {
        dispatch(getPaymentMethodsQuota(token, true));
        if (selectedProperty?.id) {
            let lng = parseFloat(selectedProperty.centre_geom.split(';')[1].split(' ')[0].substr(6));
            let lat = parseFloat(selectedProperty.centre_geom.split(';')[1].split(' ')[1].split(')')[0]);
            let geom = JSON.parse(selectedProperty.geom)
            let geoJson = {
                type: "FeatureCollection", features: [{
                    "properties": {
                        "fill-opacity": 0,
                        "stroke-width": 4,
                        "stroke-opacity": 1,
                        "stroke": "%2300adef"
                    }, "type": "Feature", "geometry": { ...geom }
                }]
            }
            let zooming1 = `${lng},${lat},16`
            let zooming2 = `${lng},${lat},16.5`
            let zooming3 = `${lng},${lat},16.8`
            let body = {
                map_id: summary[0].properties[0].map_id,
                geojson: geoJson,
                zoom1: zooming1,
                zoom2: zooming2,
                zoom3: zooming3,
                // file_name: selectedProperty.addr,
            }
            dispatch(setLoader(true))
            dispatch(downloadUserReport(body, token))
        }
        else if (geojson) {
            let coordinates = geojson.features[0].geometry.coordinates[0][0];
            let zooming1 = `${coordinates[0]},${coordinates[1]},16`
            let zooming2 = `${coordinates[0]},${coordinates[1]},16.5`
            let zooming3 = `${coordinates[0]},${coordinates[1]},16.8`
            // geojson.features[0].geometry.coordinates[0] = coordinates;
            let body = {
                map_id: parseInt(summary[0].properties[0].map_id),
                geojson: geojson,
                zoom1: zooming1,
                zoom2: zooming2,
                zoom3: zooming3,
                // file_name: place_name,
            }
            dispatch(setLoader(true))
            dispatch(downloadUserReport(body, token))
        } else
            alert.error("Data not found.");
    }
    const getIconHelper = (name) => {
        return ICONS.filter((icon) => name === icon.name)[0].icon
    }
    const handleInfoDetails = () => {
        dispatch(setInfoCurrentValue(INFO_CONSTANT))
    }
    useEffect(() => {
        if (info_not_updated === false) {
            setActiveSection('summary')
        }
    }, [infoValuesUpdated])
    const { summary } = useSelector(state => state.info.infoDefaultValues);
    const { place_name } = useSelector(state => state.info.infoSearchMapAddress);
    const { infoCoords, infoGeo } = useSelector(state => state.info);

    const handleSaveReport = () => {
        if (summary && infoGeo) {
            if (token == null) {
                dispatch(setUserLoginError('Please login your account!'))
            } else {
                let p_name = identifiers[0].properties[0].title + ', ' + identifiers[0].properties[0].title_desc + ' ' + identifiers[0].properties[0].title_zip_code + ', United States';
                let body = {
                    name: p_name,
                    lookup_id: summary[0].properties[0].map_id,
                    primaccnum: summary[0].properties[0].premacuim_no,
                    user: user.id,
                    centre_geom: infoGeo
                }
                setActiveSection('summary')
                dispatch(setLoader(true))
                dispatch(postSaveProperty(body))
            }
        }
        // setOpen(!open);
    }

    const handleFileReport = () => {
        if (token == null) {
            dispatch(setUserLoginError('Please login your account!'))
        } else {
            setActiveSection('')
            dispatch(setFlagFile(true));
            dispatch(setLoader(true));
            dispatch(getSaveProperty(user.id));
        }
    }
    const handleInfoUpgrade = () => {
        dispatch(setDataWindow({
            dataWindow: true,
            content: {
                isContact: null,
                isForce: true,
                choosePlan: { plans: PLANS, activeContent: user.status === 'Basic' ? 'pro' : 'ent' },
                downloadPdf: true,
            }
        }));
        dispatch(setDataWindowContent({ show: { showData: 'choose-plan', showControl: 'choose-plan' } }))
    }
    return (
        <div className="info-container">

            <div className='see-result-icons'>
                <div className="heart">
                    <Image src={ICON_HEART} className='image-size' disabled={info_not_updated} onClick={handleSaveReport} />
                </div>
                <div className="file">
                    <Image src={ICON_FILE} className='image-size' onClick={handleFileReport} />
                </div>
            </div>
            <br />
            <div className="info-content" style={{marginTop:"17px"}}>
                {INFO.map((info) => {
                    if (info.code !== 'downloadBtn') {
                        return (
                            <div key={info.id} className="info-item">
                                <Button fluid size="large" id="info-buttons" className={`${activeSection === info.code ? 'active' : ''} curve-wrapper info-controls-btns`} onClick={() => { handleInfoControl(info.code) }} disabled={info_not_updated}>
                                    {info.iconName && <Image src={getIconHelper(info.iconName)} className={info.iconName === 'ICON_INFO' ? 'info-icon' : ''} />}
                                    <span className={info.iconName === null ? 'centered' : ''}>{info.title}</span>
                                </Button>
                            </div>
                        )

                    } else {
                        if (isMobile == false) {
                            return (
                                <div key={info.code} className={`${activeSection === 'downloadBtn' ? 'download-btn-active' : ''} see-result-btn`}>
                                    <Button className='result-button' fluid onClick={() => {
                                        handleInfoControl(info.code)
                                    }} disabled={info_not_updated}>
                                        <Image src={ICON_DOWNLOAD} />
                                        <span>Download full report</span></Button>
                                    <div style={{ height: "20px" }}></div>
                                </div>
                            )
                        }
                    }
                })}
            </div>
            {token && !isMobile && ['Professional', 'Enterprise'].includes(user?.status) &&
                <div className="info-reports section">
                    <>
                        <div className='info-report-counter'>
                            <p className="sub-text">{`You have downloaded ${reports.downloaded ?? 0} reports out of ${reports.total ?? 0} allowed per month in your subscription plan.`}</p>
                            {/* <div className="package">
                            <p className="sub-text upgrade-package" onClick={handleInfoUpgrade}>click here to upgrade
                                your plan</p>
                        </div> */}
                        </div>
                    </>
                </div>
            }
            {/*<SavedModel content={open} handleModal={handleSaveReport} dispatch = {dispatch}/>*/}
        </div>
    );
};

const mapStateToProps = (state) => ({
    // SEARCH
    searchResult: state.search.search_result,

    // USER
    user: state.user.user,
});
export default connect(mapStateToProps)(InfoContent);
