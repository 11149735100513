import React from 'react';
import '../../../css/Container.scss';

const Container = ({ children }) => {
    return (
        <div className="container-styles">
            {children}
        </div>
    )

}
export default Container