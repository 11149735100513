import React from "react";
import { isIOS } from "react-device-detect";

const SearchBar = () => {
    return (
        <div className='search-bar'>
            <div className="info-container" style={{ height: "inherit" }}>
                <div className="info-heading" style={{ height: "inherit", marginBottom: "-20px", padding: "0" }}>
                    <div id="mapbox-geocoder-search-bar" style={{ position: "absolute", top: "38%", padding: "13px", marginLeft: isIOS === true ? '18px' : '' }}></div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
