import selectedEnv from "../Universal_CONFIG";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { setAlertMessage, setLoader, setUserLoginError } from "./actions_user";


export const SET_SAVED_REPORT_VALUE = "SET_SAVED_REPORT_VALUE";

export function getSaveReport(id) {
    return (dispatch) => {
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/report/" + id + "/saved-reports", {
            method: 'GET',
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (Object.keys(resp).includes("tokenExpired")) {
                    window.location.reload(false);
                } else {
                    dispatch(getReport(resp)); // Here we are also passing the TYPE so we can set it dynamically in the reducer
                    dispatch(setLoader(false));
                    dispatch(setDataWindow({
                        dataWindow: true,
                        content: { isContact: true, contactInfo: {}, choosePlan: {}, isPayment: false }
                    }));
                    dispatch(setDataWindowContent({ show: { showData: 'saved-report', showControl: 'dashboard' } }));
                }
            });
    };
}

export function getReportLink(object) {
    return (dispatch) => {
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/report/pdf", {
            method: 'POST',
            body: JSON.stringify(object)
        })
                .then((resp) => resp.json())
                .then((resp) => {
                    if(resp.status!==404){window.open(selectedEnv.REACT_APP_API_URL + resp.path, "_blank");
                    dispatch(setLoader(false));
                    dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: {}, choosePlan: {}, isPayment: false } }));
                    dispatch(setDataWindowContent({ show: { showData: 'saved-report', showControl: 'dashboard' } }));}
                    else{
                    dispatch(setLoader(false));
                    dispatch(setUserLoginError("Error 404: Data Not found!"))    
                    }
                })
                .catch((err)=>{
                    dispatch(setLoader(false));
                   
                    dispatch(setUserLoginError("Error 404: Data Not found!"))    
                    
                });
    };
}

export function setReportDelete(resp) {
    return (dispatch) => {
        dispatch(getReport(resp));
    }
}

export function deleteSaveReport(id, object) {
    return (dispatch) => {
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/report/" + id + "/saved-reports", {
            method: 'DELETE',
            body: JSON.stringify(object),
        }).then(() =>
            dispatch(setLoader(false)),
            dispatch(setAlertMessage('Your property has been delete successfully'))
        )
    };
}


export function getReport(report) {
    return {
        type: SET_SAVED_REPORT_VALUE,
        payload: report
    };
}