import { SET_TAXLOT, SET_TAXLOT_ERROR, SET_GEOJSON } from "../actions/actions_taxlot";

const initialState = {
  currentTaxlot: false,
  geojson: null,
  taxlotError: null,
};

const taxlot = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TAXLOT:
      return {
        ...state,
        currentTaxlot: payload
      };
    case SET_GEOJSON:
      return {
        ...state,
        geojson: payload
      };
    case SET_TAXLOT_ERROR:
      return {
        ...state,
        taxlotError: payload
      };
    default:
      return state;
  }
};

export default taxlot;
