import React, { useEffect, useState } from "react";
import email from '../../../../images/send-email.png'
import '../../../../css/ContactSupport.scss';
import { Button, Form, Input, Message , Image} from "semantic-ui-react";
import { CONTACT_SUPPORT } from "../../../../constants";
import { setDataWindow } from "../../../../actions/actions_datawindow";
import { useDispatch, useSelector } from "react-redux";
import { contactSupport, setLoader, getNewsFeed } from "../../../../actions/actions_user";

const ContactUs = () => {
   
    const dispatch = useDispatch();
    const { contactValues = null } = useSelector(state => state.dataWindow.content)
    const [isError, setIsError] = useState(false);
    const [contact, setContact] = useState(contactValues ? contactValues : {
        name: { value: '', required: true },
        email: { value: '', required: true },
        subject: { value: '', required: true },
        message: { value: '', required: true },
    });
    const handleContactMessage = (e) => {
        if (e.target.name === "message" && e.target.value.length > 1000) return
        if (e.target.name === "subject" && e.target.value.length > 150) return
        setContact({ ...contact, [e.target.name]: { value: e.target.value, required: e.target.required } });
        dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactFields: CONTACT_SUPPORT, contactValues: { ...contact, [e.target.name]: { value: e.target.value, required: e.target.required } }, choosePlan: {} } }))
    }
    const handleSubmit = () => {
        if (contact.name.value === '' || contact.email.value === '' || contact.subject.value === '' || contact.message.value === '')
            setIsError(true);
        else {
            setIsError(false);
            const contactSupportPayload = {
                email: contact.email.value,
                name: contact.name.value,
                subject: contact.subject.value,
                message: contact.message.value
            }
            setContact({
                name: { value: '', required: true },
                email: { value: '', required: true },
                subject: { value: '', required: true },
                message: { value: '', required: true },
            });
            dispatch(setLoader(true));
            dispatch(contactSupport(contactSupportPayload));
        }
    }
    return (
        <div className="contact-us" style={{ margin: "18px" }}>
          <h1 style={{color: "#000"}}>Contact Us</h1>
            <div className="contact-form"> 
                <Form>
                    <h3 style={{ color: "#000" }}>CONTACT INFORMATION</h3>
                    <div className="form-body">
                        {isError && <Message
                            error
                            header='Action Forbidden'
                            content='You can only sign up for an account once with a given e-mail address.'
                        />}
                        {
                            CONTACT_SUPPORT.map((field, index) => {
                                let fieldName = field.name;
                                return (

                                    <Form.Field key={index}
                                        error={(isError && field.required && contact[field.name] && contact[field.name].value === '')}>
                                        {field.label && <label><b>{field.label}:</b></label>}
                                        {fieldName !== 'message' && <Input transparent required={field.required} name={field.name} type={field.type}
                                            value={contactValues?.[fieldName].value || ""}
                                            placeholder={field.placeHolder} onChange={(e) => handleContactMessage(e)} />}
                                        {fieldName === 'message' && <textarea required={field.required} name={field.name} cols="30" rows="10" value={contactValues?.[fieldName].value || ""}
                                            placeholder={field.placeHolder} onChange={(e) => handleContactMessage(e)} />}
                                        {(isError && field.required && contact[field.name] && contact[field.name].value === '') && <span className='errorMessage'>This {field.placeHolder} field is required!</span>}

                                    </Form.Field>
                                )
                            })
                        }
                        <div className="contact-message">
                            <Button onClick={handleSubmit}>
                                <Image src={email}/>
                                <span>SEND</span>
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ContactUs;
