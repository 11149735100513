import React, { useState } from "react";
import { Image } from "semantic-ui-react";
import '../../css/DefaultDataWindowContent.scss';
import ICON_BULB from '../../images/bulb.svg';

const DefaultHelpContent = () => {
    return (
        <div className="default-help">
            <h1>How can we help?</h1>
            <div className="d-help-card">
                <div className="d-help-header">
                    <h3>Mission and goal</h3>
                </div>
                <div className="d-help-body">

                    <Image src={ICON_BULB} /> 
                    <p>UrbanForm’s mission is to help create better buildings, cities, and environments.<br /><br />
                        UrbanForm does this by providing efficient, accurate, and verifiable zoning information to architects, developers, and builders.<br/><br/>

                        UrbanForm was built by architects, urban planners, and engineers to provide instant, easy access to what was one of the most complex, confusing, and inaccessible aspects of building design and development—the zoning.<br/><br/>

                        If there’s anything we can do to help the professionals and citizens who are engaged with our built environment, please do not hesitate to let us know.</p>



                </div>
            </div>
        </div>
    );

}



export default DefaultHelpContent;
