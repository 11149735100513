import { SET_FILTER_SEARCH, SET_FILTER_PRIME_ACCNUM, SET_MAP , SET_HOME_GEOCODER} from "../actions/actions_search";
import mapboxgl from "mapbox-gl";
import { BBOX } from "../constants";
const initialState = {
  search_result: {},
  primaccnum: [],
  map: {
    container: "mapbox-map",
    style: "mapbox://styles/qvt282/cl6xw9x4v000414o4u54o1ou8",
    center: [-122.339630,47.603385],
    zoom: 12.8,
  },
  homeGeocoder: {
    accessToken: "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw",
    mapboxgl: mapboxgl,
    placeholder: "Search For an Address...",
    marker: false,
    countries: "us",
    bbox: BBOX,
    zoom: 18,
    types: "address",
  },
};

const search = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FILTER_SEARCH:
      var searched_result = payload;
      var newState = JSON.parse(JSON.stringify(state));
      newState.search_result = searched_result;
      return newState;
    case SET_FILTER_PRIME_ACCNUM:
      var newState = JSON.parse(JSON.stringify(state));
      newState.primaccnum = payload;
      return newState;
      case SET_MAP:  
      return {
        ...state,
        map: payload
      };
      case SET_HOME_GEOCODER:
      return {
        ...state,
        homeGeocoder: payload,
      };
        // const mapState = {
        //   ...state,
        //   map: payload
        // };
      //   var newState = JSON.parse(JSON.stringify(state));
      // newState.map = payload;
        // return newState;
    default:
      return state;
  }
};

export default search;
