import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button, Form, Input } from "semantic-ui-react";

import { isMobile } from "react-device-detect";
import { connect, useSelector } from "react-redux";
import selectedEnv from "../../Universal_CONFIG";
import { setDataWindow, setDataWindowContent } from "../../actions/actions_datawindow";
import { setFilterValues } from "../../actions/actions_filters";
import { getUserRoles } from '../../actions/actions_plans';
import {
  loginUser,
  logoutUser,
  setALLPaymentMethod,
  setAlertMessage,
  setDefaultPaymentMethod,
  setLoader, setQuotaExceed,
  setUserLoginError,
  signupUser
} from "../../actions/actions_user";
import { SIGNUP_FIELDS } from "../../constants";
import '../../css/UserContent..scss';
import { validateUserAccount } from '../../utils/Utils';
import ChoosePlan from "./components/ChoosePlan";
import Dashboard from "./components/Dashboard";
const UserContent = ({ props, dispatch, user, userToken, loginError, currentContent }) => {
  const alert = useAlert();
  const { defaultFilter } = useSelector(state => state.filters)
  const { token, alertMessage } = useSelector(state => state.user);
  const { infoDefaultValues, infoNotLoggedIn } = useSelector(state => state.info)
  const [fieldError, setFieldError] = useState({
    email: false,
    password: false,
  });
  const [subDate, setSubDate] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  // const [userInfo, setUserInfo] = useState({});
  const [activeSection, setActiveSection] = useState('');
  const dataWindowContent = useSelector(state => state.dataWindow.content);
  const { show } = useSelector(state => state.dataWindow);
  const { updateContent } = useSelector(state => state.dataWindow);
  const { setFlagFile } = useSelector(state => state.property);
  const contactButton = isMobile ? {  "fontWeight": "400"} : { "fontWeight": "400"}

  const handleCancelSubscription = async () => {
    const { user_id } = props;
    const res = await fetch(`${selectedEnv.REACT_APP_API_URL}/api/users/${user_id}/cancel_subscription`, {
      method: "POST",
      body: JSON.stringify({
        reason: "not happy with the service",
      }),
    });
    if (res.status === 204) {
    } else {
      try {
        const json = await res.json(); 
      } catch (err) {
        console.log(err);
      }
    }
  };

  const [hasSignup, setHasSignup] = useState(false);

  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });

  const handleUserInfo = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    let isError = false;
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (e.target.name === 'email') {
      pattern.test(e.target.value) ? isError = false : isError = true;
    } else if (e.target.name === 'password') {
      e.target.name.trim().length > 0 ? isError = false : isError = true
    }
    setFieldError({ ...fieldError, [e.target.name]: isError })
  };

  const { email, password } = userInfo;

  const handleLogin = () => {
    if (!userInfo.email || !userInfo.password) {
      setFieldError({
        email: true,
        password: true,
      })
      alert.error("You have entered an invalid email or password.")
    }
    else if (!fieldError.email && !fieldError.password) {
      if (infoDefaultValues?.identifiers && infoNotLoggedIn === true) {
        let primeAccountNumber = infoDefaultValues.identifiers[1].properties[1].title_desc;
        let body = {
          "primaccnum": primeAccountNumber,
        }
        dispatch(loginUser({ ...userInfo }, null, infoNotLoggedIn === true, body));
      } else
        dispatch(loginUser({ ...userInfo }));
    }
    setUserInfo({
      email: "",
      password: "",
    });
  };

  const handleSignup = () => {
    setHasSignup(true);
    dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: SIGNUP_FIELDS, choosePlan: {} } }));
    dispatch(setDataWindowContent({ show: { showData: 'sign-up', showControl: 'sign-up' } }));
  };

  const handleCreateAccount = () => {
    let response = validateUserAccount(dataWindowContent);
    if (response.isError) {
      dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: SIGNUP_FIELDS, values: dataWindowContent.values, isError: response.isError, isPasswordMatched: response.isPasswordMatched, isPhoneValid: response.isPhoneValid ?? true, isEmailValid: response.isEmailValid ?? true, choosePlan: {} } }))
    } else {
      dispatch(setLoader(true));
      dispatch(signupUser(response.data));
      dispatch(getUserRoles());
    }
  };

  useEffect(() => {
    loginError !== null && alert.error(loginError);
    dispatch(setUserLoginError(null))
    alertMessage !== null && alert.success(alertMessage);
    dispatch(setAlertMessage(null));
  }, [token, alertMessage, loginError]);

  useEffect(() => {
    setHasSignup(false);
    setUserInfo({
      email: "",
      password: "",
    });
    setFieldError({
      email: false,
      password: false,
    });
  }, [currentContent]);

  const handleLogout = () => {
    dispatch(setQuotaExceed(false))
    dispatch(setFilterValues(defaultFilter));
    dispatch(logoutUser());
    dispatch(setDefaultPaymentMethod(null));
    dispatch(setALLPaymentMethod(null));
    dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, choosePlan: {} } }));
  }
  const handleForgetPassword = () => {
    dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: '', choosePlan: {} } }));
    dispatch(setDataWindowContent({ show: { showData: 'forget-password', showControl: 'login' } }));
  }
//   useEffect(() => {
//     dispatch(getPaymentMethods(token, true, user));
// }, [token]);

// useEffect(() => {
//     // if update modal of contact info is closed then reset activeSession
//     updateContent === false && setActiveSection('');
// }, [updateContent]);
//   useEffect(() => {
//     if (setFlagFile === true) {
//         setActiveSection("properties");
//     }
// }, [setFlagFile])
//   useEffect(() => {
//     if (user != null) setUserInfo({
//         first_name: { value: user.first_name, required: true },
//         last_name: { value: user.last_name, required: true },
//         company: { value: user.company, required: false },
//         industry: { value: user.industry, required: false },
//         title: { value: user.title, required: false },
//         address_1: { value: user.address_1, required: true },
//         address_2: { value: user.address_2, required: false },
//         city: { value: user.city, required: true },
//         state: { value: user.state, required: true },
//         zip_code: { value: user.zip_code, required: true },
//         phone_number: { value: user.phone_number, required: false },
//         email: { value: user.email, required: true },
//         password: { value: user.password, required: false },
//         password_confirm: { value: user.password_confirm, required: false }
//     });
//     let date = user != null && ['null', null].includes(user.last_payment_date) === false ? user.last_payment_date : user != null && user.date_joined;
//     if (user != null && user.last_payment_date) setSubDate(new Date(date || '').toUTCString().slice(0, 12));
// }, [user])
// const handleConfirm = () => {
//   setShowConfirm(false);
//   dispatch(cancelSubscription(token));
// }
// const handleCancel = () => {
//   setShowConfirm(false);
// }
//   const getIconHelper = (name) => {
//     return ICONS.filter((icon) => name === icon.name)[0].icon
//   }
//   const handleAccountContorls = (control) => {
//     if (control === activeSection) {
//       setActiveSection('');
//       dispatch(setDataWindowContent({ show: { showData: '', showControl: 'dashboard' } }));
//       dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
//       return
//     }
//     dispatch(setDataWindowContent({ show: { showData: control, showControl: 'dashboard' } }));
//     setActiveSection(control);
//     if (control != 'logout') {
//       let body = {
//         'event': 'Tab Selected: ' + control
//       }
//       dispatch(activityLogger(body))
//     }
//     switch (control) {
//       case 'profile':
//         dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: SIGNUP_FIELDS, values: userInfo, choosePlan: {} }, updateContent: true }))
//         dispatch(setDataWindowContent({ show: { showData: 'sign-up', showControl: 'dashboard' } }));
//         break;
//       case 'cities-plans':
//         dispatch(setDataWindow({
//           dataWindow: true, content: { isContact: true, contactInfo: {}, choosePlan: {}, isPayment: false }
//         }));
//         dispatch(setDataWindowContent({ show: { showData: 'cities-plans', showControl: 'dashboard' } }));
//         break;
//       case 'properties':
//         dispatch(setLoader(true));
//         dispatch(getSaveProperty(user.id));
//         break;
//       case 'filters':
//         dispatch(setLoader(true));
//         dispatch(getSaveFilters(user.id));
//         break;
//       case 'info':
//         dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));

//         break;
//       case 'reports':
//         dispatch(setLoader(true));
//         dispatch(getSaveReport(user.id));
//         break;
//       case 'payment':
//         dispatch(getPaymentMethods(token));

//         break;
//       case 'logout':
//         let body = {
//           'event': 'User Logout'
//         }
//         dispatch(activityLogger(body))
//         dispatch(setQuotaExceed(false))
//         dispatch(setFilterValues(defaultFilter));
//         dispatch(logoutUser());
//         dispatch(setDefaultPaymentMethod(null));
//         dispatch(setALLPaymentMethod(null));
//         dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, choosePlan: {} } }));
//         dispatch(updateResult({
//           count: 0,
//           data: null,
//         }));
//         dispatch(setFilterPrimeAccountNumbers([]))
//         break;
//       default:
//         break;
//     }
//   };
//   const handleprofessionalEmterprise = (user) => {
//     if (!user) {
//         dispatch(setDataWindowContent({ show: { showData: '', showControl: 'login' } }))
//         return false
//     }
//     return ['Professional', 'Enterprise'].includes(user.status)
// }

  return (
    <div className="user-content">
      {/* eslint-disable-next-line no-mixed-operators */}
      {(show.showControl === 'sign-up' || show.showControl === 'second-window-sign-up') && <Dashboard dispatch={dispatch} />}
      {/* {(show.showControl === 'sign-up' || show.showControl === 'second-window-sign-up') && <CreateAccount handleCreateAccount={handleCreateAccount} dataWindowContent={dataWindowContent} />} */}
      {show.showControl === 'choose-plan' && <ChoosePlan dataWindowContent={dataWindowContent} dispatch={dispatch} />}
      {show.showControl === 'dashboard' && <Dashboard dispatch={dispatch} />}
      {show.showControl === 'login' &&
        <div className="user-login">
          <Form error onSubmit={handleLogin} className="login-form">
            <div className="login-fields-container">
              <h4 className="grayed-text">Login</h4>
              <Form.Field error={fieldError.email}>
                <Input
                  size="big"
                  fluid
                  placeholder="email"
                  type='email'
                  name="email"
                  value={email}
                  onChange={(e) => handleUserInfo(e)}
                />
              </Form.Field>
              <Form.Field error={fieldError.password}>
                <Input
                  type="password"
                  size="big"
                  fluid
                  placeholder="password"
                  name="password"
                  value={password}
                  onChange={(e) => handleUserInfo(e)}
                />
              </Form.Field>
              <a className="forget-password" onClick={handleForgetPassword}>Forget/Reset Password ?</a>
            </div>
            <div className="actions-container">
              <Button fluid size="small" type="submit">
                Login
              </Button>
              <div>
                <h4 className="grayed-text">- or -</h4>
              </div>
              <Button fluid size="small" className="signup-styles" onClick={() => handleSignup()}>
                Sign up
              </Button>
            </div>
          </Form>
        </div>
        // <div className="dashboard-container">
        //   {ACCOUNT_INFO.map((info) => {

        //     return (
        //       <div key={info.id} className="account-item">
        //         <Button fluid size="large" className={`${activeSection === info.code ? 'active' : ''} curve-wrapper account-contorls-btns`} onClick={() => handleAccountContorls(info.code)}>
        //           {info.iconName && <Image src={getIconHelper(info.iconName)} className={info.iconName === 'ICON_ACCOUNT' ? 'account-icon' : ''} />}
        //           <span className={info.iconName === null ? 'centered' : ''}>{info.title}</span>
        //         </Button>
        //       </div>
        //     )
        //   })}

  }
{/* {(handleprofessionalEmterprise(user)) && <>
             
                <div className='contact-support'>
              
                    <a className='contact-support-button' target="_blank" href="mailto:support@urbanform.us" style={contactButton}>
                        <Image src={contact}/>
                        <span>Contact Support</span></a>
                </div>
            </>} */}


            {/* <Confirm
                open={showConfirm}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                cancelButton='No'
                confirmButton="Yes"
                content='Are you sure, you want to cancel the subscription ?'
                size='tiny'
            /> */}


        </div>

      
    
  )
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  userToken: state.user.token,
  loginError: state.user.loginError,
});

export default connect(mapStateToProps)(UserContent);
