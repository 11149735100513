import {
    USER_ROLES, USER_ROLES_ERROR,
    SET_CURRENT_PLAN,
    SET_CUSTOMER_ID,
    SET_SUBSCRIPTIONS,
    SET_CITIES_AND_PLANS
} from "../actions/actions_plans.js";
import { citiesAndPlans } from "../constants.js";

const initialState = {
    plans: [],
    planError: null,
    currentPlan: [],
    customerId: null,
    subscriptions: [],
    citiesAndPlans: { ...citiesAndPlans },
};

const Plans = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case USER_ROLES:
            var newState = { ...state };
            newState.plans = payload;
            return newState;
        case USER_ROLES_ERROR:
            var newState = { ...state };
            newState.planError = payload;
            return newState;
        case SET_CURRENT_PLAN:
            var newState = { ...state };
            newState.currentPlan = payload;
            return newState;
        case SET_CUSTOMER_ID:
            return { ...state, customerId: payload }
        case SET_SUBSCRIPTIONS:
            return { ...state, subscriptions: payload }
        case SET_CITIES_AND_PLANS:
            return { ...state, citiesAndPlans: payload }
        default:
            return state;
    }
};

export default Plans;