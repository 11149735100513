import selectedEnv from "../Universal_CONFIG";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { setAlertMessage, setLoader } from "./actions_user";

export const SET_SAVED_PROPERTY_DEFAULT_VALUE = "SET_SAVED_PROPERTY_DEFAULT_VALUE";
export const SET_SAVED_PROPERTY_VALUE = "SET_SAVED_PROPERTY_VALUE";
export const SET_FLAG_FILE = "SET_FLAG_FILE";

export function postSaveProperty(object) {
    return (dispatch) => {
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/info/user/", {
            method: 'POST',
            body: JSON.stringify(object)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(setLoader(false));
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                dispatch(setDataWindowContent({ show: { showData: 'summary', showControl: 'info' } }));
                dispatch(setAlertMessage('You have successfully saved the property!'));

            });
    };
}

export function getSaveProperty(id) {
    return (dispatch) => {
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/info/user/" + id, {
            method: 'GET',
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (Object.keys(resp).includes("tokenExpired")) {
                    window.location.reload(false);
                } else {
                    dispatch(setSavedPropertyDefaultValue(resp)); // Here we are also passing the TYPE so we can set it dynamically in the reducer
                    dispatch(setLoader(false));
                    dispatch(setDataWindow({
                        dataWindow: true,
                        content: { isContact: true, contactInfo: {}, choosePlan: {}, isPayment: false }
                    }));
                    dispatch(setDataWindowContent({ show: { showData: 'saved-property', showControl: 'dashboard' } }));
                }
            });
    };
}


export function setDelete(resp) {
    return (dispatch) => {
        dispatch(setSavedPropertyDefaultValue(resp));
    }
}

export function deleteSaveProperty(id, user_id) {
    return (dispatch) => {
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/info/user/" + id, {
            method: 'DELETE',
        }).then(() =>
            dispatch(setLoader(false)),
            dispatch(setAlertMessage('Your property has been delete successfully'))
        )
    };
}

export function setSavedPropertyDefaultValue(res) {
    return {
        type: SET_SAVED_PROPERTY_DEFAULT_VALUE,
        payload: res,
    };
}

export function setSavedPropertyCurrentValue(infoValue) {
    return {
        type: SET_SAVED_PROPERTY_VALUE,
        payload: {
            data: infoValue,
        },
    };
}

export function setFlagFile(flag) {
    return {
        type: SET_FLAG_FILE,
        payload: flag,
    };
}