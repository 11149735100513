import React, { useState } from "react";
import "../../../../css/zone.scss";
import Container from "../Container";
import Header from "../Header";
import { useSelector } from "react-redux";
import LSCheckbox from "./LSCheckbox";
import { Accordion, Icon } from 'semantic-ui-react';
import SingleCheckBox from "../SingleCheckBox";




const Location = ({ dispatch }) => {
  const [activeIndexes, setActiveIndexes] = useState([0]);

  const handleAccordionClick = (index) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((item) => item !== index));
    } else {
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };
  const [size, setSize] = useState("middle");
  const { location } = useSelector((state) => state.filters.filterValues
  );

  return (
    <div className='zone-general' style={{ marginTop: "22px" }}>


      {location.map((info, index) => { 
        const isCheckboxLength2 = info.checkbox.length === 2;

        return (
          <div className="zone-filter" key={index}>
            <Accordion styled>
              <Accordion.Title onClick={() => handleAccordionClick(index)}>
                <Header content={info} />
                <Icon name={activeIndexes.includes(index) ? 'angle down' : 'angle right'} />
              </Accordion.Title>
              <Accordion.Content active={activeIndexes.includes(index)}>
                <div className="zone-fields">
                  {isCheckboxLength2 ? (
                    <SingleCheckBox
                      info={info}
                      dispatch={dispatch}
                      columns={1}
                      selectedFilter={location}
                    />
                  ) : (
                    <LSCheckbox
                      info={info}
                      dispatch={dispatch}
                      columns={1}
                      selectedFilter={location}
                    />
                  )}
                </div>
              </Accordion.Content>
            </Accordion>
          </div>
        );
      })}


    </div>
  );

 

  // return (
  //   <>
  //     {location_specifics.map((field) => {
  //       return (
  //         <>
  //              <Container>
  //             <Header content={field} />
  //           {field.checkbox.map((check)=>{
  //              return(

  //             <div className="slider-section">
  //                 <CheckBoxGrid
  //                   info={check}
  //                   dispatch={dispatch}
  //                   columns={1}
  //                   selectedFilter={location_specifics}
  //                   style={GridStyle}
  //                 />
  //             </div>

  //              )
  //           })}
  //              </Container>
  //         </>
  //       );
  //     })}
  //     ;
  //   </>
  // );
};

export default Location;
