import React from 'react';
import { Grid } from "semantic-ui-react";
import '../../../css/zone.scss';
import { useSelector } from "react-redux";
import { singleCheckboxUpdate } from "../../../utils/Utils";
import { UpdateFilter } from "../../../actions/actions_filters";

const SingleCheckBox = ({ dispatch, info, columns, selectedFilter, style }) => {
    const { show } = useSelector(state => state.dataWindow)
    // const onChangeSelect = (e) => {
    //     let { name, checked } = e.target;
    //     let currentFilter = show.showData;
    //     let UpdatedFilter = {
    //         name: currentFilter,
    //         filterData: singleCheckboxUpdate(currentFilter, selectedFilter, name, checked)
    //     }
    //     dispatch(UpdateFilter(UpdatedFilter));
    // }

    const onChangeSelect = (e) => {
        const { name, checked } = e.target;
        const currentFilter = show.showData;
        const groupId = info.title_column_name; // Get the groupId from info.id
        const UpdatedFilter = {
          name: currentFilter,
          filterData: singleCheckboxUpdate(currentFilter, selectedFilter, name, checked, groupId)
        };
        dispatch(UpdateFilter(UpdatedFilter));
      };
      

    const groupId = info.title_column_name ? `group_${info.title_column_name}` : '';
    console.log(groupId, "groupId");
    return (
        <> 
            <Grid columns={columns} style={style ?? {}}> 
                {info?.checkbox && info.checkbox.map((field,index) => {
                     const uniqueName = groupId ? `${field.name}_${groupId}` : field.name;
                    return (
                        <Grid.Column key={uniqueName} style={{padding:"7px 0px"}}>
                            <div className="ui checkbox">
                                <input
                                    type={field.type}
                                    name={field.name}
                                    required={field.required}
                                    onChange={onChangeSelect}
                                    checked={field.action}
                                />
                                <label>{field.label}</label>
                            </div>
                        </Grid.Column>
                    )
                })
                }
            </Grid>
            {/* <hr className="horizontal-row" /> */}
        </>

    );
};

export default SingleCheckBox;