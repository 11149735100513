import React, { useEffect, useState } from "react";
import "../../../css/Dashboard.scss";
import { ACCOUNT_INFO, SIGNUP_FIELDS, PLANS } from "../../../constants";
import { Button, Image, Confirm } from "semantic-ui-react";
import { useSelector } from "react-redux";
import contact from "../../../images/help-contact-us.svg";
import { ICONS } from "../../../constants";
import {
  setDataWindow,
  setDataWindowContent,
} from "../../../actions/actions_datawindow";
import {
  activityLogger,
  logoutUser,
  setALLPaymentMethod,
  setDefaultPaymentMethod,
  setLoader,
  setQuotaExceed,
} from "../../../actions/actions_user";
import { setLinkValue } from "../../../actions/actions_info";
import {
  cancelSubscription,
  getPaymentMethods,
} from "../../../actions/actions_plans";
import { getSaveProperty } from "../../../actions/actions_save_property";
import { getSaveReport } from "../../../actions/actions_save_report";
import {
  getSaveFilters,
  setFilterValues,
} from "../../../actions/actions_filters";
import { updateResult } from "../../../actions/actions_filters";
import { setFilterPrimeAccountNumbers } from "../../../actions/actions_search";
import { isMobile } from "react-device-detect";

const Dashboard = ({ dispatch }) => {
  const { user, token } = useSelector((state) => state.user);
  const loggedUser = useSelector((state) => state.user);
  const { updateContent } = useSelector((state) => state.dataWindow);
  const { citiesAndPlans } = useSelector((state) => state.plans);
  const [activeSection, setActiveSection] = useState("cities-plans");
  const [loginUser, setLoginUser] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [subDate, setSubDate] = useState("");
  const dataWindow = useSelector((state) => state.dataWindow);
  const { showFolder } = useSelector((state) => state.filters);
  const { setFlagFile } = useSelector((state) => state.property);
  const { defaultFilter } = useSelector((state) => state.filters);
  const contactButton = isMobile
    ? { fontWeight: "400" }
    : { fontWeight: "400" };

  // const contactButton = isMobile ? { "paddingLeft": "20%", "paddingRight": "20%", "fontWeight": "500" } : { "paddingLeft": "25%", "paddingRight": "26%", "fontWeight": "500" }

  console.log(user, "user");
  useEffect(() => {
    dispatch(getPaymentMethods(token, true, user));
  }, [token]);

  useEffect(() => {
    // if update modal of contact info is closed then reset activeSession
    updateContent === false && setActiveSection("");
  }, [updateContent]);

//   useEffect(() => {
//     setActiveSection("cities-plans");
//   }, [loggedUser]);

  useEffect(() => {
    if (setFlagFile === true) {
      setActiveSection("properties");
    }
  }, [setFlagFile]);
  const handleConfirm = () => {
    setShowConfirm(false);
    dispatch(cancelSubscription(token));
  };
  const handleCancel = () => {
    setShowConfirm(false);
  };
  useEffect(() => {
    showFolder && setActiveSection("filters");
  }, [showFolder]);

  const Storedtoken = window.localStorage.getItem("_uft");

  const handleAccountContorls = (control) => {
    if (control === activeSection) {
      setActiveSection("");
      dispatch(
        setDataWindowContent({
          show: { showData: "", showControl: "dashboard" },
        })
      );
      dispatch(
        setDataWindow({
          dataWindow: false,
          content: {
            isContact: null,
            contactInfo: {},
            choosePlan: {},
            isPayment: false,
          },
        })
      );
      return;
    }
    setActiveSection(control);
    dispatch(
      setDataWindowContent({
        show: { showData: control, showControl: "dashboard" },
      })
    );
   

    if (control != "logout") {
      let body = {
        event: "Tab Selected: " + control,
      };
      dispatch(activityLogger(body));
    }
    switch (control) {
      case "profile":
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: true,
              contactInfo: SIGNUP_FIELDS,
              values: userInfo,
              choosePlan: {},
            },
            updateContent: true,
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "sign-up", showControl: "dashboard" },
          })
        );
        break;
      case "cities-plans":
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: {
              isContact: true,
              contactInfo: {},
              choosePlan: {},
              isPayment: false,
            },
          })
        );
        dispatch(
          setDataWindowContent({
            show: { showData: "cities-plans", showControl: "dashboard" },
          })
        );
        break;
      case "properties":
        dispatch(setLoader(true));
        dispatch(getSaveProperty(user.id));
        break;
      case "filters":
        dispatch(setLoader(true));
        dispatch(getSaveFilters(user.id));
        break;
      case "info":
        dispatch(
          setDataWindow({
            dataWindow: false,
            content: {
              isContact: null,
              contactInfo: {},
              choosePlan: {},
              isPayment: false,
            },
          })
        );

        break;
      case "reports":
        dispatch(setLoader(true));
        dispatch(getSaveReport(user.id));
        break;
      case "payment":
        dispatch(getPaymentMethods(token));

        break;
      case "logout":
        let body = {
          event: "User Logout",
        };
        dispatch(activityLogger(body));
        dispatch(setQuotaExceed(false));
        dispatch(setFilterValues(defaultFilter));
        dispatch(logoutUser(Storedtoken));
        dispatch(setDefaultPaymentMethod(null));
        dispatch(setALLPaymentMethod(null));
        dispatch(
          setDataWindow({
            dataWindow: true,
            content: { isContact: null, choosePlan: {} },
          })
        );
        dispatch(
          updateResult({
            count: 0,
            data: null,
          })
        );
        dispatch(setFilterPrimeAccountNumbers([]));
        break;
      default:
        break;
    }
  };
  console.log('jani',activeSection)
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (user != null)
      setUserInfo({
        first_name: { value: user.first_name, required: true },
        last_name: { value: user.last_name, required: true },
        company: { value: user.company, required: false },
        industry: { value: user.industry, required: false },
        title: { value: user.title, required: false },
        address_1: { value: user.address_1, required: true },
        address_2: { value: user.address_2, required: false },
        city: { value: user.city, required: true },
        state: { value: user.state, required: true },
        zip_code: { value: user.zip_code, required: true },
        phone_number: { value: user.phone_number, required: false },
        email: { value: user.email, required: true },
        password: { value: user.password, required: false },
        password_confirm: { value: user.password_confirm, required: false },
      });
    let date =
      user != null && ["null", null].includes(user.last_payment_date) === false
        ? user.last_payment_date
        : user != null && user.date_joined;
    if (user != null && user.last_payment_date)
      setSubDate(new Date(date || "").toUTCString().slice(0, 12));
  }, [user]);
  const getIconHelper = (name) => {
    return ICONS.filter((icon) => name === icon.name)[0].icon;
  };

  const getActiveContent = () => {
    let active = "pro";
    Object.values(citiesAndPlans).map((plans) => {
      plans.forEach((plan) => {
        if (plan.isChecked && plan.code !== "basic") {
          if (active !== "ent") active = plan.code;
        }
      });
    });
    return active;
  };

  const handleAccountUpgrade = () => {
    dispatch(
      setDataWindow({
        dataWindow: true,
        content: {
          isContact: null,
          isForce: true,
          choosePlan: { plans: PLANS, activeContent: getActiveContent() },
        },
      })
    );
    dispatch(
      setDataWindowContent({
        show: { showData: "choose-plan", showControl: "choose-plan" },
      })
    );
  };
  const handleCancelSubscription = () => {
    setShowConfirm(true);
  };

  const handleBasicProfessional = (user) => {
    if (!user) {
      dispatch(
        setDataWindowContent({ show: { showData: "", showControl: "login" } })
      );
      return false;
    }
    return ["Basic", "Professional", "Enterprise"].includes(
      user && user.status
    );
  };

  const handleprofessionalEmterprise = (user) => {
    if (!user) {
      dispatch(
        setDataWindowContent({ show: { showData: "", showControl: "login" } })
      );
      return false;
    }
    return ["Professional", "Enterprise"].includes(user.status);
  };

  // localStorage.setItem('token', token);
  // const storedToken = localStorage.getItem('token');
  // console.log(storedToken, "storedToken");
  // if (storedToken) {
  //     // Set the retrieved token in your application's state or context
  //     setLoginUser({ token: storedToken });
  // } else {
  //     // No token found, treat as logged out
  //     setLoginUser(null);
  // }

  return (
    <div className="dashboard-container">
      {/* <div className="profile-section">
                <h1>Hello, {user?.first_name}!</h1>
                <p>{user && user.status || ''} user since {subDate}</p>
            </div> */}

      {ACCOUNT_INFO.map((info) => {
        return (
          <div key={info.id} className={info.code==="logout"?"account-item-no-hover":"account-item"} style={info.code==="logout"?{background:"#43c1f3",borderRadius:14}:{}}>
            {info.code === "logout" && !user ? null : (
              <Button
                size="large"
                fluid
                className={`${
                  activeSection === info.code ? "active" : ""
                } curve-wrapper account-contorls-btns disable`}
                onClick={() => handleAccountContorls(info.code)}
                disabled={!user}
              >
                {info.iconName && (
                  <Image
                    src={getIconHelper(info.iconName)}
                    className={
                      info.iconName === "ICON_ACCOUNT" ? "account-icon" : ""
                    }
                  />
                )}
                <span
                  className={info.iconName === null ? "centered" : ""}
                  style={{
                    marginLeft: info.code === "logout" ? "45px" : "",
                    textAlign:
                      info.code === "cities-plans" ? "left" : "justify",
                      color:info.code==="logout"?"white":""
                  }}
                >
                  {info.title}
                </span>
              </Button>
              
            )}
          </div>
        );
      })}
      {/* {(handleBasicProfessional(user)) && <div className='upgrade-plan-btn'>
                <Button className='upgrade-button' fluid onClick={handleAccountUpgrade}>Upgrade</Button>
            </div>} */}
      {/* {(handleprofessionalEmterprise(user)) && <>
                
                <div className='contact-support'>
              
                    <a className='contact-support-button' target="_blank" href="mailto:support@urbanform.us" style={contactButton}>
                        <Image src={contact}/>
                        <span>Contact Support</span></a>
                </div>
            </>} */}
      <div className="contact-support">
        <a
          className="contact-support-button"
          target="_blank"
          href="mailto:support@urbanform.us"
          style={contactButton}
        >
          <Image src={contact} />
          <span>Contact Support</span>
        </a>
      </div>

      <Confirm
        open={showConfirm}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        cancelButton="No"
        confirmButton="Yes"
        content="Are you sure, you want to cancel the subscription ?"
        size="tiny"
      />
    </div>
  );
};
export default Dashboard;
