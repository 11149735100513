import React from "react";
import '../../../css/Header.scss';
import { ICONS } from "../../../constants";
const Header = ({ content }) => {
    const getIconHelper = (name) => {
        return ICONS.filter((icon) => name === icon.name)[0].icon
    }
    return (
        <div className="data-header">
            <div className='header-display'>
                {/* {content?.icon && <img src={getIconHelper(content.icon)} className='header-logo' alt=""/>} */}
              
                 <h2>{content?.title}</h2>
                 <h3 style={{color:"black"}}>{content?.megaTitle}</h3>
            </div>
            {/* {content?.subTitle && <p className={content.className}>{content.subTitle}</p>} */}
        </div>
    )
}
export default Header;