import React, { useEffect, useState } from "react";
import "../../../css/SliderSection.scss";
import { Grid } from "semantic-ui-react";
import { checkboxUpdate, updateMinMax, updateSlider } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { UpdateFilter } from "../../../actions/actions_filters";
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';

const SliderSection = ({ content, range, checkboxField, dispatch, selectedFilter, disabled = false, maxRangeValue, columnName }) => {
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(maxRangeValue);
    const [randNumber, setRandNumber] = useState(Math.random(2000000))
    const { show } = useSelector(state => state.dataWindow)

    const { Range } = Slider;
    const getPreviousMinMaxValues = (columnName) => {
        let minMax = [];
        content.forEach(field => {
            if (field.column_name === columnName && columnName !== 'residential_units')
                minMax.push(field.value);
            else if (columnName === 'residential_units')
                if (['unit_min_lot_valu', 'unit_max_lot_valu'].includes(field.column_name))
                    minMax.push(field.value);
        })
        return minMax;
    }
    const handleValueChange = e => {
        let { name, value } = e.target;
        if (Number(value) > Number(maxRangeValue)) return
        let minMax = getPreviousMinMaxValues(columnName);
        let UpdatedFilter = updateMinMax(show, selectedFilter, name, value, columnName, columnName === 'yearbuilt' ? 2022 : maxRangeValue, minMax, columnName === 'yearbuilt' ? 1800 : 0)
        dispatch(UpdateFilter(UpdatedFilter));
        setRandNumber(Math.random(2000000));
    };
    useEffect(() => {
        content.forEach(field => {
            field.label === 'Min' && setMinValue(field.value);
            field.label === 'Max' && setMaxValue(field.value);
        })
    }, [content])
    const handleSelect = e => {
        let { name, checked } = e.target;
        let currentFilter = show.showData;
        let UpdatedFilter = {
            name: currentFilter,
            filterData: checkboxUpdate(currentFilter, selectedFilter, name, checked)
        }
        dispatch(UpdateFilter(UpdatedFilter));
    }
    const onSliderAfterChange = (value) => {
        if (value.length !== 1) {
            let UpdatedFilter = updateSlider(show, selectedFilter, columnName, value, "doubleSlider", columnName === 'yearbuilt' ? 2022 : maxRangeValue, columnName === 'yearbuilt' ? 1800 : 0)
            dispatch(UpdateFilter(UpdatedFilter));
        } else {
            let UpdatedFilter = updateSlider(show, selectedFilter, columnName, value, "singleSlider", maxRangeValue)
            dispatch(UpdateFilter(UpdatedFilter));
        }
    }
    const onSliderChange = (value) => {
        if (Array.isArray(value)) {
            setMinValue(value[0])
            setMaxValue(value[1])
        } else
            setMinValue(value)
    }
    let sliderMinValue = columnName === 'yearbuilt' ? 1800 : 0;
    return (
        <>
            <div className='slider'>
                {content && <div className="ui two column centered grid">
                    <div className="four centered row color" style={{justifyContent:"space-around"}}>
                        {content && content.map(field => {
                            let subLabelStyle = field.subLabel === '$' ? '98px' : field.subLabel === 'square feet' ? '40px' : '82px';
                            let style = { paddingLeft: 0 }

                            return (
                                <>
                                    <div style={{position:"relative"}}>
                                        <p style={{ float: "left", margin: "0 0 5px 0", fontSize: "13px" }}>{field.labelText}</p>
                                        <div className={[`${field.className}`]} >
                                            {/* <label htmlFor="">{field.labelText}</label> */}
                                            <div className="ui input focus">
                                                <input type="number"
                                                    className="fields"
                                                    id={field.name}
                                                    value={field.label === 'Min' ? minValue : maxValue}
                                                    style={field.label === 'Min' ? style : {}}
                                                    name={field.name}
                                                    min={sliderMinValue}
                                                    max={maxRangeValue}
                                                    onChange={handleValueChange}
                                                    disabled={disabled}
                                                />
                                            </div>
                                            {/* <label htmlFor="" className='height-input-label' style={{ marginLeft: subLabelStyle, "whiteSpace": "nowrap" }}>{field.subLabel}</label> */}
                                            <p className='height-input-label' style={{ float: "right", position: "absolute", right:"0px", marginTop:"0px", marginLeft:"0px", fontSize:"13px"}}>{field.subLabel}</p>
                                        </div>
                                    </div>

                                </>
                            )
                        })}
                    </div>
                </div>}
                {/* {content && (<Grid className='range-grid'>
                    <Grid.Column width={16}>
                        <Range
                            allowCross={false}
                            min={sliderMinValue}
                            max={maxRangeValue}
                            value={columnName === 'htlim_lot' ? [minValue] : [minValue, maxValue]}
                            trackStyle={[{ backgroundColor: '#bfbfbf' }]}
                            handleStyle={[
                                { backgroundColor: '#fff', border: "5px solid rgb(0, 212, 255)", boxShadow: "0px 1px 2px 0px,0px 0px 0px 1px" },
                                { backgroundColor: '#fff', border: "5px solid rgb(255, 0, 135)", boxShadow: "0px 1px 2px 0px,0px 0px 0px 1px" }
                            ]}
                            onChange={onSliderChange}
                            onAfterChange={onSliderAfterChange}
                            key={randNumber}
                            disabled={disabled}
                        />
                    </Grid.Column>
                </Grid>)} */}
                {/* {checkboxField &&
                    checkboxField.map(checkboxField => {
                        return (
                            <Grid.Column className="slider-checkbox">
                                <div className="ui checkbox">
                                    <input type={checkboxField.type}
                                        value={checkboxField.value}
                                        name={checkboxField.name}
                                        onChange={handleSelect}
                                        checked={checkboxField.action}
                                    />
                                    <label>{checkboxField.label}</label>
                                </div>
                            </Grid.Column>
                        )
                    })
                } */}
            </div>
        </>
    );
};
export default SliderSection;
