import React, { useState } from "react";

export default function useInfoMaxUnit(props) {
  const { defaultValue, name, min, max, getSliderValue, disabled } = props;
  const [value, setvalue] = useState(defaultValue);
  const [useDefault, setuseDefault] = useState(false);
  //   useEffect(() => {
  //     setvalue(defaultValue);
  //   }, [defaultValue]);

   
  const onSliderChange = (value) => {
    if (disabled || useDefault) {
      return;
    }
    setvalue(value[0]);
    getSliderValue(name, value[0]);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value <= max && value >= min) {
      setvalue(value);
      getSliderValue(name, value);
    }
  };
  return {
    value,
    setvalue,
    useDefault,
    setuseDefault,
    onSliderChange,
    handleChange,
  };
}
