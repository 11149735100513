export const SET_DATA_WINDOW = "SET_DATA_WINDOW";
export const SET_CONTENT = 'SET_CONTENT';
export const SET_MAP_SEARCH_FIELD = 'SET_MAP_SEARCH_FIELD';

export function setDataWindow(results) {
    return {
        type: SET_DATA_WINDOW,
        dataWindow: results.dataWindow,
        content: results.content,
        updateContent: results.updateContent,
    };
}

export function setDataWindowContent(results) {
    return {
        type: SET_CONTENT,
        show: results
    };
}

export function setMapSearch(data) {
    return {
        type: SET_MAP_SEARCH_FIELD,
        show: data
    };
}