import React from "react";
import '../../../../css/LandStats.scss';
import "semantic-ui-css/semantic.min.css";
import Container from "../Container";
import Header from "../Header";
import SliderSection from "../SliderSection";
import { useSelector } from "react-redux";
import CheckBoxGrid from "../CheckBoxGrid";


const LandStats = ({ dispatch }) => {
    const { lot_info } = useSelector(state => state.filters.filterValues)
    const GridStyle = {
        margin: "20px 0px 20px 32px",
    }
    return (
        <>
            {lot_info.map(field => { 
                return (
                    <>
                    
                        <Container>
                            <Header content={field} />
                            <div className='slider-section'>
                                {field.name !== 'lot_type' && <SliderSection
                                    content={field.fields}
                                    range={field.range}
                                    dispatch={dispatch}
                                    selectedFilter={lot_info}
                                    maxRangeValue={field?.maxValue ?? 99999999}
                                    columnName={field.column_name}
                                />}
                                {field.name === 'lot_type' && <CheckBoxGrid
                                    info={field}
                                    dispatch={dispatch}
                                    columns={1}
                                    selectedFilter={lot_info}
                                    style={GridStyle}
                                />}
                            </div>
                            <br />
                        </Container>
                    </>
                )
            })};
        </>
    )
}
export default LandStats;