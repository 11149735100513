import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Image } from "semantic-ui-react";
import { setDataWindow, setDataWindowContent } from "../../../actions/actions_datawindow";
import { PLANS } from "../../../constants";
import '../../../css/Dashboard.scss';
import Container from "./Container";
import ChooseCitiesAndPlans from "./payment/ChooseCitiesAndPlans";
import SegmentFooter from "./SegmentFooter";
import SegmentHeader from "./SegmentHeader";
import ICON_UPGRADE from '../../../images/upgrade-icon.svg'
import MarkedCitiesAndPlans from "./payment/MarkedCitiesAndPlans";

const CitiesAndPlans = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
   
    const { citiesAndPlans } = useSelector(state => state.plans);

    const getActiveContent = () => {
        let active = 'pro';
        Object.values(citiesAndPlans).map((plans) => {
            plans.forEach((plan) => {
                if (plan.isChecked && plan.code !== 'basic') {
                    if (active !== 'ent') active = plan.code;
                }
            })
        })
        return active
    }

    const handleAddMore = () => {
        dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, isForce: true, choosePlan: { plans: PLANS, activeContent: getActiveContent() } } }));
        dispatch(setDataWindowContent({ show: { showData: 'choose-plan', showControl: 'choose-plan' } }))
    };

    return (
        <>
            <div className="acc_data_window">
                <h1 style={{ color: "#000", fontFamily: "Inter-Black" }}>Hello, {user?.first_name}!</h1>
                <div className='property-data'>
                    <SegmentHeader image={true} content={{ productName: 'Cities & plans' }} />
                    {/* <ChooseCitiesAndPlans allowAll={true} /> */}
                    <MarkedCitiesAndPlans allowAll={true} />
                    {/* <SegmentFooter /> */}

                </div>

                {/* <div className="dashboard-container"> 
                <div className='upgrade-plan-btn'>
                <Button  onClick={() => handleAddMore()}>
                    <Image src={ICON_UPGRADE} />
                    <span>UPGRADE</span>
                </Button>
                </div>
            </div> */}



                <div className="upgrade-container">
                    <div className='upgrade-plan-btn'>
                        <a className='contact-support-button' target="_blank" href="mailto:support@urbanform.us">
                            <Image src={ICON_UPGRADE} />
                            <span>UPGRADE</span>
                        </a>
                    </div>
                </div>
            </div>
        </>

    )
}
export default CitiesAndPlans;