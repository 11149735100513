import React, { useEffect } from "react";
import { Image } from "semantic-ui-react";
import '../../../../css/ContactCity.scss'
import { getNewsFeed } from "../../../../actions/actions_user";
import { useDispatch, useSelector } from "react-redux";
import newss from '../../../../images/newss.jpg'
const NewsFeed = () => {
    const dispatch = useDispatch();
    const { news } = useSelector(state => state.user);
    useEffect(() => {
        dispatch(getNewsFeed());

    }, [dispatch])
    useEffect(() => {
        console.log(news, "newss");
    }, [news]);

    const News = news?.rss?.channel;
    
    return (
        <div>

            {News?.item.map((newsCard, index) => {
                return (

                    <div key={index} className="news-feed" style={{marginTop:"15px"}}>

                        <div className="news-header">
                            <h2>{newsCard?.title?._cdata}</h2>
                        </div>
                        <div className="news-body">
                            <Image src={newsCard?.enclosure?._attributes?.url} />
                            <p>{newsCard?.description?._cdata}</p>
                        </div>
                    </div>
                )
            })}


        </div>
    );
};

export default NewsFeed;
