import jwt_decode from "jwt-decode";
import selectedEnv from "../Universal_CONFIG";
import { INFO_CONSTANT, UF_TOKEN, citiesAndPlans } from '../constants';
import { downloadPdf, manageCitiesAndPlans } from "../utils/Utils";
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { setFilterPlan, updateFilterPayment } from "./actions_filters";
import { infoUserNotLoggedIn, updatedInfoSection } from "./actions_info";
import {
    handleUserLogin,
    logoutUser,
    setAlertMessage,
    setLoader,
    setUserLoginError
} from "./actions_user";

export const USER_ROLES = "USER_ROLES";
export const USER_ROLES_ERROR = "USER_ROLES_ERROR"
export const USER_PLAN_SUBSCRIPTION = "USER_PLAN_SUBSCRIPTION";
export const USER_PLAN_SUBSCRIPTION_ERROR = "USER_PLAN_SUBSCRIPTION_ERROR";
export const SET_CURRENT_PLAN = "SET_CURRENT_PLAN";
export const SET_CUSTOMER_ID = "SET_CUSTOMER_ID";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const SET_CITIES_AND_PLANS = "SET_CITIES_AND_PLANS";


export function getUserRoles(token) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/users/roles/`, {
            method: "GET",
        })
            .then((resp) => {
                if (!resp.ok) throw new Error(resp.status);
                else return resp.json();
            })
            .then((resp) => {
                dispatch(handleGetUserRoles(resp))
            })
            .catch((error) => {
                // dispatch(handleUserRolesError(error.detail))
            });
    };
}
export function handleGetUserRoles(roles) {
    return {
        type: USER_ROLES,
        payload: roles,
    };
}
export function handleUserRolesError(loginError) {
    return {
        type: USER_ROLES_ERROR,
        payload: loginError,
    };
}
export function getPlanSubscription(body, token, filterUpgradeProcess, infoNotLoggedIn = false, primeAccountNumber) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/`, {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (Object.keys(resp).includes("message") && !Object.keys(resp).includes("token")) {
                    dispatch(setUserLoginError(resp.message))
                } else {
                    dispatch(handlePlanSubscription(resp));
                    let user = jwt_decode(resp.token);
                    dispatch(handleUserLogin({ user: user.user, token: resp.token }));
                    window.localStorage.setItem(UF_TOKEN, resp.token)
                    if (filterUpgradeProcess) {
                        dispatch(updateFilterPayment({ filterUpgradeProcess: true, filterPaymentDone: true }))
                        dispatch(setDataWindow({ dataWindow: true, content: { isContact: true, contactInfo: {}, choosePlan: {}, isPayment: false, isFilter: true } }));
                        dispatch(setDataWindowContent({ show: { showData: 'see_result', showControl: 'filters' } }));
                    }
                    else {
                        dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, choosePlan: {} } }));
                        dispatch(setDataWindowContent({ show: { showData: '', showControl: 'dashboard' } }));
                    }
                    dispatch(setAlertMessage("Your account plan has been updated successfully."));
                }
                dispatch(setFilterPlan(null));
                dispatch(setLoader(false));
                if (infoNotLoggedIn === true) {
                    dispatch(setLoader(true));
                    dispatch(infoUserNotLoggedIn(false));
                    let infoPayload = {
                        "primaccnum": primeAccountNumber,
                    }
                    dispatch(updatedInfoSection(infoPayload, INFO_CONSTANT))
                }
            })
            .catch((error) => {
                dispatch(handlePlanSubscriptionError(error.detail))
                dispatch(setLoader(false));
            });
    };
}
export function handlePlanSubscription(plan) {
    return {
        type: USER_PLAN_SUBSCRIPTION,
        payload: plan,
    };
}
export function handlePlanSubscriptionError(loginError) {
    return {
        type: USER_PLAN_SUBSCRIPTION_ERROR,
        payload: loginError,
    };
}

export function getPaymentMethods(token, isPayment = false, user = {}) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/user/get-payment-methods`, {
            method: "POST",
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (isPayment) {
                    if (resp?.data) {
                        dispatch(setCustomerId(resp.data[0].customer_id));
                        dispatch(getAllSubsctions(resp.data[0].customer_id, user));
                    }
                } else {
                    dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPaymentMethod: true, paymentMethods: resp.data } }));
                }
            })
            .catch((error) => {
                dispatch(setAlertMessage("An error has occurred please try again."));
            });
    };
}

export function addPaymentMethod(body, token) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/user/add-payment-method`, {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (Object.keys(resp).includes("error")) {
                    if (resp.error === "Payment matching query does not exist.")
                        dispatch(setUserLoginError("A valid saved credit card is needed"));
                    else
                        dispatch(setUserLoginError("An error has occurred please try again."))
                } else {
                    dispatch(setLoader(false));
                    dispatch(getPaymentMethods(token));
                    dispatch(setAlertMessage("New Payment method has been added successfully."));
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                dispatch(setLoader(false));
                dispatch(setAlertMessage("An error has occured please try again."));
            });
    };
}

export function setDefaultPaymentMethod(body, token) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/user/change-default-payment`, {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(getPaymentMethods(token));
                dispatch(setAlertMessage("Default payment method has been changed successfully."));
            })
            .catch((error) => {
                dispatch(setAlertMessage("An error has occurred please try again."));
            });
    };
}

export function cancelSubscription(token) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/user/cancel-subscription`, {
            method: "POST",
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(setAlertMessage("Your plan subscription has been canceled."));
                dispatch(logoutUser());
            })
            .catch((error) => {
                dispatch(setAlertMessage("An error has occurred please try again."));
            });
    };
}

export function oneTimePaymentReport(object,alert) {
  const headers = { 'region': localStorage.getItem("sub-city") }

    return async (dispatch) => {

        try {
            return fetch(
              `${selectedEnv.REACT_APP_API_URL}/api/report/one-time`,
              {
                method: "POST",
                headers,
                body: JSON.stringify(object),
              }
            )
              .then((resp) => resp.json())
              .then((resp) => {
                if (!Object.keys(resp).includes("file_name")) {
                  if (Object.keys(resp).includes("error")) {
                    alert.error(resp.error);
                    dispatch(setLoader(false));
                  } else if (Object.keys(resp).includes("message")) {
                    alert.error(resp.message);
                    dispatch(setLoader(false));
                  } else {
                    alert.error("Payment Failed. Please Try Again!");
                    dispatch(setLoader(false));
                  }
                } else {
                  downloadPdf(resp.path, resp.file_name);
                  alert.success("Your download will begin in a moment.");
                  dispatch(setLoader(false));
                  dispatch(
                    setDataWindowContent({
                      show: { showData: "", showControl: "info" },
                    })
                  );
                  dispatch(
                    setDataWindow({
                      dataWindow: false,
                      content: {
                        isContact: null,
                        contactInfo: {},
                        choosePlan: {},
                        isPayment: false,
                      },
                    })
                  );
                }
              })
              .catch((error) => {
                dispatch(setLoader(false));
                dispatch(alert.error("Server not responding."));
              });

        } catch (err) {
            dispatch(setLoader(false))
            dispatch(alert.error("Server not responding."));
        }
    }
}

export function updateSubscription(object, token) {
    return async (dispatch) => {
        try {
            return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/change-user-subscription`, {
                method: 'POST',
                body: JSON.stringify(object)
            })
                .then((resp) => resp.json())
                .then((resp) => {
                    if (Object.keys(resp).includes("message"))
                        dispatch(setAlertMessage(resp.message));
                    if (Object.keys(resp).includes("error"))
                        dispatch(setAlertMessage(resp.error));
                    dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, choosePlan: {} } }));
                    dispatch(setDataWindowContent({ show: { showData: '', showControl: 'dashboard' } }));
                    dispatch(setLoader(false));
                })
                .catch((error) => {
                    dispatch(setLoader(false))
                    dispatch(setUserLoginError("Server not responding."));
                });

        } catch (err) {
            dispatch(setLoader(false))
            dispatch(setUserLoginError("Server not responding."));
        }
    }
}

export function deletePaymentMethod(object) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/user/delete-user-card`, {
            method: "DELETE",
            body: JSON.stringify(object)
        }).then((resp) => resp.json())
            .then((resp) => {
                if (Object.keys(resp).includes("message")) {
                    dispatch(setAlertMessage(resp.message))
                    dispatch(getPaymentMethods(window.localStorage.getItem('_uft')));
                }
                else if (Object.keys(resp).includes("error"))
                    dispatch(setUserLoginError(resp.error))
                dispatch(setLoader(false));
            })
            .catch(error => {
                dispatch(setLoader(false));
                dispatch(setUserLoginError("Server not responding! Please try again."))
            })
    }
}
export function updatePaymentMethod(object) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/user/update-card-info`, {
            method: "POST",
            body: JSON.stringify(object)
        }).then((resp) => resp.json())
            .then((resp) => {
                
                if (Object.keys(resp).includes("message")) {
                    dispatch(setAlertMessage("User card information updated successfully!"))
                    dispatch(getPaymentMethods(window.localStorage.getItem('_uft')));
                }
                else if (Object.keys(resp).includes("error"))
                    dispatch(setUserLoginError(resp.error))
                dispatch(setLoader(false));
            })
            .catch(error => {
                dispatch(setLoader(false));
                dispatch(setUserLoginError("Server not responding! Please try again."))
            })
    }
}

export function getAllSubsctions(customerId, user) {
    return (dispatch) => {
        return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/subscription/${customerId}`, {
            method: "GET",
        }).then((resp) => resp.json())
            .then((resp) => {
               
                dispatch(setLoader(false));
                dispatch(setCustomerSubscriptions(resp.data));
                dispatch(manageCitiesAndPlans(citiesAndPlans, user.status === 'Basic' ? 'pro' : 'ent', dispatch, resp.data, true))
            })
            .catch(error => {
                dispatch(setLoader(false));
            })
    }
}

export const setCurrentPlan = (data) => {
    return {
        type: SET_CURRENT_PLAN,
        payload: data,
    };
}

export const setCustomerId = (data) => {
    return {
        type: SET_CUSTOMER_ID,
        payload: data,
    };
}

export const setCustomerSubscriptions = (data) => {
    return {
        type: SET_SUBSCRIPTIONS,
        payload: data,
    };
}

export const setCitiesAndPlans = (data) => {
    return {
        type: SET_CITIES_AND_PLANS,
        payload: data,
    };
}