import { setLoader, setUserLoginError, userNotAuth } from "./actions_user";
import { parseInfoResponse } from "../utils/Utils";
import { EARTH_DIRECTIONS } from '../constants'
import { setDataWindow, setDataWindowContent } from "./actions_datawindow";
import { setTaxlot, setTaxlotError } from "./actions_taxlot";
import { unregister } from "../components/Intercept";
import { saveInfoResponse } from "./actions_dyno_info";

import selectedEnv from "../Universal_CONFIG";


export const SET_INFO_DEFAULT_VALUE = "SET_INFO_DEFAULT_VALUE";
export const SET_INFO_CURRENT_VALUE = "SET_INFO_CURRENT_VALUE";
export const RESET_INFO_CURRENT_INFO_SELECTOR = "RESET_INFO_CURRENT_INFO_SELECTOR";
export const INFO_API_RESPONSE = "INFO_API_RESPONSE";
export const INFO_VALUES_UPDATED = "INFO_VALUES_UPDATED";
export const INFO_MAP_ADDRESS = "INFO_MAP_ADDRESS";
export const INFO_COORDS = "INFO_COORDS";
export const SET_INFO_UPDATED = "SET_INFO_UPDATED";
export const INFO_GEO = "INFO_GEO";
export const SET_LINK_VALUE = "SET_LINK_VALUE";
export const SET_PHOTO_REF = "SET_PHOTO_REF";
export const INFO_NOT_LOGGED_IN = "INFO_NOT_LOGGED_IN";
export const USE_ALLOWANCE_UPDATE = "USE_ALLOWANCE_UPDATE";
export const USE_CATEGORY_UPDATE = "USE_CATEGORY_UPDATE";
export const INFO_REPORT_REMAIN = "INFO_REPORT_REMAIN";
export const RESET_INFO ='RESET_INFO'
// This is for info

export function getSelectInfo(object, infoDefaultValues, lngLat, number, user = null) {
    let token = window.localStorage.getItem('_uft');
    const headers = { 'region': localStorage.getItem("sub-city") }

    return (dispatch) => {
        dispatch(resetInfo())

        // dispatch(resetAllCurrentFilters())
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/info/", {
            method: 'POST',
            headers,
            body: JSON.stringify(object)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (Object.keys(resp).includes("tokenExpired")) {
                    window.location.reload(false);
                } else if (Object.keys(resp).includes("error_message")) {
                    dispatch(setUserLoginError(resp.error_message))
                    dispatch(setLoader(false))
                }
                else if (Object.keys(resp).includes("primaccnum")) {
                    dispatch(setUserLoginError("Prime account number is required!"))
                    dispatch(setLoader(false))
                }
                else if (Object.keys(resp).includes("error")) {
                    dispatch(setUserLoginError(resp.msg))
                    dispatch(setLoader(false))
                } else if (Object.keys(resp).includes("code")) {
                    window.localStorage.removeItem('_uft');
                    dispatch(setUserLoginError("You logged in many days ago. Please login again."));
                    dispatch(userNotAuth());
                    dispatch(setDataWindowContent({ show: { showData: 'invalidToken', showControl: 'login' } }));
                    dispatch(setLoader(false))
                } else if (Object.keys(resp).includes("message")) {
                    if (Object.keys(resp).includes("availableIn")) {
                        dispatch(setUserLoginError("Request limit exceeded. Please try again in " + resp.availableIn + " sec!"));
                        dispatch(setLoader(false));
                    }
                    if(resp?.message.includes("exceeded"))
                    {
                       dispatch(setUserLoginError(("Your limit for unique lot selections has been exceeded. Please contact support to discuss your options.")))
                         dispatch(setLoader(false))
                      }
                }
             if(resp.taxLots)   {
                    const res = resp.taxLots;
                  
                        let address = resp.taxLots.identifiers[0].properties;
                        let addres = Capitalize(address.addr) + ' ' + Capitalize(address.addr_city) + ', '+ Capitalize(address.addr_state) + ' ' + address.addr_zip;
                        dispatch(setPhotoRef(addres));
                  
                    const updatedInfo = parseInfoResponse(infoDefaultValues, res)
                    dispatch(saveInfoResponse(res))
                    if (updatedInfo) {
                        dispatch(setInfoDefaultValue(updatedInfo));
                    } else {
                        dispatch(setInfoDefaultValue(updatedInfo));
                    }
                    dispatch(setLoader(false));
                    if (lngLat && resp.taxLots) {
                        resp.taxLots['mapboxCoords'] = lngLat;
                        dispatch(setTaxlot(resp));
                    }
                    if (resp && !resp.taxLots) {
                        dispatch(setTaxlotError('No tax lot data found'))
                    }

                    if (resp.taxLots.centre_geom) {
                        let lng = parseFloat(resp.taxLots.centre_geom.split(';')[1].split(' ')[1].split(')')[0]);
                        let lat = parseFloat(resp.taxLots.centre_geom.split(';')[1].split(' ')[0].substr(6));
                        let cords = {
                            'lng': lng,
                            'lat': lat,
                        }
                        dispatch(infoCoords(cords));
                    }
                    dispatch(infoGeometry(resp.taxLots.centre_geom));
                    dispatch(infoValuesUpdated(number));
                    dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
                    dispatch(setDataWindowContent({ show: { showData: 'summary', showControl: 'info' } }));
                }

                if (!(token != null && token !== 'null') || (token && user?.status !== 'Enterprise'))
                    dispatch(infoUserNotLoggedIn(true));
            }) 
            .catch((err)=>{
                console.log("error occured",err)
                dispatch(setUserLoginError(err))
                dispatch(setLoader(false))
            });
    };

}

export function getAddressByLngLat(object, lat, lng, infoGeocoder, homeGeocoder = null, searchBarGeocoder) {
  const headers = { 'region': localStorage.getItem("sub-city") }

    return (dispatch) => {
        let token = window.localStorage.getItem('_uft');
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/info/", {
            method: 'POST',
            headers,
            body: JSON.stringify(object)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.taxLots) {
                    let address = resp.taxLots.identifiers[0].properties;
                    infoGeocoder.setInput(Capitalize(address.addr) + ' ' + Capitalize(address.addr_city) + ', '+ Capitalize(address.addr_state) + ' ' + address.addr_zip);
                    if (homeGeocoder)
                        homeGeocoder.setInput(Capitalize(address.addr) + ' ' + Capitalize(address.addr_city) + ', '+ Capitalize(address.addr_state) + ' ' + address.addr_zip);
                    if (searchBarGeocoder)
                        searchBarGeocoder.setInput(Capitalize(address.addr) + ' ' + Capitalize(address.addr_city) + ', '+ Capitalize(address.addr_state) + ' ' + address.addr_zip);
                        let addres = Capitalize(address.addr) + ' ' + Capitalize(address.addr_city) + ', '+ Capitalize(address.addr_state) + ' ' + address.addr_zip;
                    dispatch(setPhotoRef(addres));
                    if (!(token != null && token !== 'null'))
                        dispatch(infoUserNotLoggedIn(true));
                }
            });
    };
}

export function updatedInfoSection(object, infoDefaultValues) {
    return (dispatch) => {
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/info/", {
            method: 'POST',
            body: JSON.stringify(object)
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (Object.keys(resp).includes("tokenExpired")) {
                    window.location.reload(false);
                } else if (Object.keys(resp).includes("error_message")) {
                    dispatch(setUserLoginError(resp.error_message))
                    dispatch(setLoader(false))
                }
                else if (Object.keys(resp).includes("primaccnum")) {
                    dispatch(setUserLoginError("Prime account number is required!"))
                    dispatch(setLoader(false))
                }
                else if (Object.keys(resp).includes("error")) {
                    dispatch(setUserLoginError(resp.msg))
                    dispatch(setLoader(false))
                } else if (Object.keys(resp).includes("code")) {
                    window.localStorage.removeItem('_uft');
                    dispatch(setUserLoginError("You logged in many days ago. Please login again."));
                    dispatch(userNotAuth());
                    dispatch(setDataWindowContent({ show: { showData: 'invalidToken', showControl: 'login' } }));
                    dispatch(setLoader(false))
                } else if (Object.keys(resp).includes("message")) {
                    if (Object.keys(resp).includes("availableIn")) {
                        dispatch(setUserLoginError("Request limit exceeded. Please try again in " + resp.availableIn + " sec!"));
                        dispatch(setLoader(false));
                    }
                }
                else {
                    const res = resp.taxLots;
                    const updatedInfo = parseInfoResponse(infoDefaultValues, res)
                    dispatch(saveInfoResponse(res))
                    if (updatedInfo) {
                        dispatch(setInfoDefaultValue(updatedInfo));
                    } else {
                        dispatch(setInfoDefaultValue(updatedInfo));
                    }
                    dispatch(setLoader(false));
                }
            });
    };
}

export function getReportCount(token) {
    return (dispatch) => {
        return fetch(selectedEnv.REACT_APP_API_URL + "/api/report/counter", {
            method: 'GET',
        })
            .then((resp) => resp.json())
            .then((resp) => {
                dispatch(updateReportCount(resp));
            })
            .catch((error) => {
                dispatch(setLoader(false));
               
            });
    };
}

function Capitalize(str) {
    let value = str.split(' ');

    let new_value = value.map(function (item, key) {
        if (EARTH_DIRECTIONS.includes(item)) {
            return item;
        } else {
            return item.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
        }
    })
    let a_t_s = new_value.toString();
    return a_t_s.split(',').join(" ");
}


export function setInfoDefaultValue(res) {
    return {
        type: SET_INFO_DEFAULT_VALUE,
        payload: res,
    };
}

export function setInfoCurrentValue(infoValue) {
    return {
        type: SET_INFO_CURRENT_VALUE,
        payload: {
            data: infoValue,
        },
    };
}

export function resetInfo(){
    return{
        type: RESET_INFO
    }
}
export function resetAllCurrentFilters(data) {
    
    return {
        type: RESET_INFO_CURRENT_INFO_SELECTOR,
        payload: data,
       
    };
   
}

export function infoValuesUpdated(data) {
    return {
        type: INFO_VALUES_UPDATED,
        payload: data,
    }
}
export function infoSearchMapAddress(data) {
    return {
        type: INFO_MAP_ADDRESS,
        payload: data,
    }
}
export function infoCoords(coords) {
    return {
        type: INFO_COORDS,
        payload: coords,
    }
}
export function infoGeometry(geometry) {
    return {
        type: INFO_GEO,
        payload: geometry,
    }
}
export function setInfoUpdated(data) {
    return {
        type: SET_INFO_UPDATED,
        payload: data,
    }
}

export function setLinkValue(data) {
    return {
        type: SET_LINK_VALUE,
        payload: data,
    }
}

export function setPhotoRef(data) {
    return {
        type: SET_PHOTO_REF,
        payload: data,
    }
}

export function infoUserNotLoggedIn(data) {
    return {
        type: INFO_NOT_LOGGED_IN,
        payload: data,
    }
}

export function updateUseAllowance(data) {
    return {
        type: USE_ALLOWANCE_UPDATE,
        payload: data,
    }
}

export function updateUseCategory(data) {
    return {
        type: USE_CATEGORY_UPDATE,
        payload: data,
    }
}

export function updateReportCount(data) {
    return {
        type: INFO_REPORT_REMAIN,
        payload: data,
    }
}

