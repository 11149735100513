import React from 'react';
import '../../../../css/zone.scss';
import Container from "../Container";
import Header from "../Header";
import { useSelector } from "react-redux";
import CheckBoxGrid from "../CheckBoxGrid";
import LSCheckbox from './LSCheckbox';

const JurisFilter = ({ dispatch }) => {
     
    const { jurisdiction } = useSelector(state => state.filters.filterValues)
    const zoneHeader = { 
        title: 'jurisdiction', 
        className: 'zone-sub-title'
    }
    
    return (
        <div className="zone-filter">
            <Container>
            <Header content={zoneHeader} />
                <div className="zone-fields"> 
                    {
                        jurisdiction.map(info => {
                            return (
                                <LSCheckbox info={info} dispatch={dispatch} columns={1} selectedFilter={jurisdiction} />
                            )
                        })
                    }
                </div>
            </Container>
        </div>
    );
};

export default JurisFilter;