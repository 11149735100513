import React from "react";
import Container from "../Container";
import Header from "../Header";
import '../../../../css/Identifiers.scss';
import InfoSection from "../InfoSection";
import { useSelector } from "react-redux";

const Identifiers = () => {
    const { identifiers } = useSelector(state => state.info.infoDefaultValues);
    return (
        identifiers.map(field => {
            return (
                <>
                    <div className="identifiers">
                        <div className='summary-data'>
                            <Container>
                                <Header content={field} />
                                <InfoSection content={field.properties} />
                                <div className='footer-section'>
                                    <div className='more'></div>
                                </div>
                            </Container>
                        </div>
                    </div>
                </>
            );
        })
    )
}
export default Identifiers;
