import React, { useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import {
    useElements,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useStripe
} from '@stripe/react-stripe-js';
import { Button, Confirm, Dropdown, Form, Input, Radio } from "semantic-ui-react";
import '../../../../css/Payment.scss';
import { useSelector } from "react-redux";
import { setLoader } from '../../../../actions/actions_user';
import {
    getPlanSubscription,
    addPaymentMethod,
    oneTimePaymentReport
} from '../../../../actions/actions_plans';
import Container from "../Container";
import '../../../../css/CheckoutForm.scss';
import { PLANS, QUOTA_EXCEEDS } from "../../../../constants";
import Segment from "../Segment";
import SegmentHeader from "../SegmentHeader";
import SegmentFooter from "../SegmentFooter";
import { allCancelledPlans, allSelectedPlans, updatePlanDetails } from "../../../../utils/Utils";
import '../../../../css/InfoFontStyle.scss';
import { setDataWindow, setDataWindowContent } from "../../../../actions/actions_datawindow";
import { isMobile } from "react-device-detect";
import ChooseCitiesAndPlans from './ChooseCitiesAndPlans';

const CheckoutForm = ({ dispatch, title, quota }) => {
    const alert = useAlert();
    const stripe = useStripe();
    const elements = useElements();
    const user = useSelector((state) => state.user);
    const quotaExceed = useSelector((state) => state.user.quotaExceed);
    const dataWindow = useSelector((state) => state.dataWindow);
    const token = useSelector(state => state.user.token)
    const allPaymentMethod = useSelector(state => state.user);
    const { oneTimePayment } = useSelector(state => state.dataWindow.content);
    const { geojson } = useSelector(state => state.taxlot);
    const [oneTimeEmail, setOneTimeEmail] = useState('')
    const [oneTimeName, setOneTimeName] = useState('')
    const userRoles = useSelector((state) => state.plans.plans);
    const { customerId, subscriptions } = useSelector((state) => state.plans);
    const { summary, taxlot } = useSelector(state => state.info.infoDefaultValues);
    const { place_name } = useSelector(state => state.info.infoSearchMapAddress);
    const { filterUpgradeProcess } = useSelector(state => state.filters.filterPayment)
    const { infoDefaultValues, infoNotLoggedIn } = useSelector(state => state.info)
    const [selectedMethod, setSelectedMethod] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    const [display, setDisplay] = useState(false);
    const { planPrice } = useSelector(state => state.user);
    const [defaultPay, setDefaultPay] = useState(false);
    const [cardPay, setCardPay] = useState(false);
    const [customPay, setCustomtPay] = useState(false);
    const [dropDownLabel, setDropDownLabel] = useState(null)
    const [showMenu, setShowMenu] = useState(false);
    if (quota === true && quotaExceed === true) {
        title = 'Pay with this card';
    }
    let getCount = 0;
    if (allPaymentMethod.allPaymentMethod !== null) {
        let cardCount = allPaymentMethod?.allPaymentMethod.filter(method => method.card_brand);
        getCount = cardCount.length;
    }

    const handleChange = (e, { value }) => {
        setShowConfirm(true);
        setSelectedMethod(value);
        allPaymentMethod.allPaymentMethod.filter((el, il) => {
            if (el.payment_method_id === value)
                setDropDownLabel(`${el.card_brand} **** **** **** ${el.last_four_digits}`)
        })
    }

    const handleConfirm = () => {
        setShowConfirm(false);
        // dispatch(setDefaultQuotaPaymentMethod({'payment_method_id': selectedMethod}, user.token))
    }
    const handleCancel = () => {
        setShowConfirm(false);
        setSelectedMethod('');
    }
    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardNumberElement);

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        if (!oneTimePayment) {
            if (error) {
                alert.error(error.message)
                // dispatch(setAlertMessage(error.message))
            } else {
                dispatch(setLoader(true));
                let primeAccountNumber = null;
                if (infoDefaultValues?.identifiers && infoNotLoggedIn === true) {
                    primeAccountNumber = infoDefaultValues.identifiers[1].properties[1].title_desc;
                }
                (title === 'Pay' || title === 'options') ?
                    dispatch(getPlanSubscription({
                        "email": user.user.email,
                        "payment_method_id": paymentMethod.id,
                        "is_subscribe": true,
                        ...(customerId && { "customer_id": customerId }),
                        ...(subscriptions.length > 0 && { "subscription_id": subscriptions.length > 0 ? subscriptions[0]?.subscription_id : [] }),
                        "subscriptions": allSelectedPlans,
                        ...((subscriptions[0]?.region.length > 0) && { "canceled_subscription_regions": [...subscriptions[0]?.region] })
                    }, user.token, filterUpgradeProcess, infoNotLoggedIn === true, infoNotLoggedIn === true ? primeAccountNumber : null)) :
                    dispatch(addPaymentMethod({
                        "payment_method_id": paymentMethod.id,
                    }, user.token))
            }
        } else {
            if (error) {
                alert.error(error.message)
                // dispatch(setAlertMessage(error.message))
            } else {
                if (geojson) {
                    let coordinates = geojson.features[0].geometry.coordinates[0][0];
                    let zooming1 = `${coordinates[0]},${coordinates[1]},16`
                    let zooming2 = `${coordinates[0]},${coordinates[1]},16.5`
                    let zooming3 = `${coordinates[0]},${coordinates[1]},16.8`
                    let payment = '';
                    if (quotaExceed === true) {
                        if (user.user && user.user.status === 'Enterprise') {
                            payment = {
                                name: oneTimeName !== "" ? oneTimeName : ((user.user && user.user.status === 'Enterprise') && user.user.first_name + user.user.last_name),
                                email: oneTimeEmail !== "" ? oneTimeEmail : ((user.user && user.user.status === 'Enterprise') && user.user.email),
                                payment_method_id: paymentMethod.id,
                                map_id: summary[0].properties[0].map_id,
                                geojson: geojson,
                                zoom1: zooming1,
                                zoom2: zooming2,
                                zoom3: zooming3,
                                // file_name: place_name,
                                existing_account: false,
                            }
                        }
                        if (user.user && user.user.status === 'Professional') {
                            payment = {
                                name: oneTimeName !== "" ? oneTimeName : ((user.user && user.user.status === 'Professional') && user.user.first_name + user.user.last_name),
                                email: oneTimeEmail !== "" ? oneTimeEmail : ((user.user && user.user.status === 'Professional') && user.user.email),
                                payment_method_id: paymentMethod.id,
                                map_id: summary[0].properties[0].map_id,
                                geojson: geojson,
                                zoom1: zooming1,
                                zoom2: zooming2,
                                zoom3: zooming3,
                                // file_name: place_name,
                                existing_account: false,
                            }
                        }
                    } else {
                        payment = {
                            name: oneTimeName !== "" ? oneTimeName : ((user.user && user.user.status === 'Basic') && user.user.first_name + user.user.last_name),
                            email: oneTimeEmail !== "" ? oneTimeEmail : ((user.user && user.user.status === 'Basic') && user.user.email),
                            payment_method_id: paymentMethod.id,
                            map_id: summary[0].properties[0].map_id,
                            geojson: geojson,
                            zoom1: zooming1,
                            zoom2: zooming2,
                            zoom3: zooming3,
                            // file_name: place_name,
                            existing_account: false,
                        }
                    }
                    dispatch(setLoader(true));
                    dispatch(oneTimePaymentReport(payment,alert));
                } else
                    alert.error("Please select property to continue");
            }
        }
    };
    const handleOneTimeMail = (e) => {
        if (e.target.name === 'email') {
            setOneTimeEmail(e.target.value);
        }
    };
    const handleOneTimeName = (e) => {
        if (e.target.name === 'name') {
            setOneTimeName(e.target.value);
        }
    };
    const handleALLPaymentMethod = (e) => {
        if (display === true) {
            setDisplay(false);
        } else {
            setDisplay(true);
        }
    }
    const handleDefaultPaymentMethod = (e) => {
        let payment_id = cardPay ? selectedMethod : user.defaultPaymentMethod ? user.defaultPaymentMethod.payment_method_id : null;
        if (!oneTimePayment) {
            let primeAccountNumber = null;
            dispatch(setLoader(true));
            dispatch(getPlanSubscription({
                "email": user.user.email,
                "payment_method_id": payment_id,
                "is_subscribe": true,
                ...(customerId && { "customer_id": customerId }),
                ...(subscriptions.length > 0 && { "subscription_id": subscriptions.length > 0 ? subscriptions[0]?.subscription_id : [] }),
                "subscriptions": allSelectedPlans,
                ...((subscriptions[0]?.region.length > 0) && { "canceled_subscription_regions": [...subscriptions[0]?.region] })
            }, user.token, filterUpgradeProcess, infoNotLoggedIn === true, infoNotLoggedIn === true ? primeAccountNumber : null))
        }
        else if (geojson && payment_id) {
            let coordinates = geojson.features[0].geometry.coordinates[0][0];
            let zooming = `${coordinates[0]},${coordinates[1]},16`
            let payment = '';
          
                let zooming1 = `${coordinates[0]},${coordinates[1]},16`
                let zooming2 = `${coordinates[0]},${coordinates[1]},16.5`
                let zooming3 = `${coordinates[0]},${coordinates[1]},16.8`
                if (user.user && user.user.status === 'Basic') {
                    payment = {
                        name: oneTimeName !== "" ? oneTimeName : ((user.user && user.user.status === 'Basic') && user.user.first_name + user.user.last_name),
                        email: oneTimeEmail !== "" ? oneTimeEmail : ((user.user && user.user.status === 'Basic') && user.user.email),
                        payment_method_id: payment_id,
                        map_id: summary[0].properties[0].map_id,
                        geojson: geojson,
                        zoom1: zooming1,
                        zoom2: zooming2,
                        zoom3: zooming3,
                        // file_name: place_name,
                        existing_account: true,
                    }
                }
                if (user.user && user.user.status === 'Enterprise') {
                    payment = {
                        name: oneTimeName !== "" ? oneTimeName : ((user.user && user.user.status === 'Enterprise') && user.user.first_name + user.user.last_name),
                        email: oneTimeEmail !== "" ? oneTimeEmail : ((user.user && user.user.status === 'Enterprise') && user.user.email),
                        payment_method_id: payment_id,
                        map_id: summary[0].properties[0].map_id,
                        geojson: geojson,
                        zoom1: zooming1,
                        zoom2: zooming2,
                        zoom3: zooming3,
                        // file_name: place_name,
                        existing_account: true,
                    }
                }
                if (user.user && user.user.status === 'Professional') {
                    let zooming1 = `${coordinates[0]},${coordinates[1]},16`
                    let zooming2 = `${coordinates[0]},${coordinates[1]},16.5`
                    let zooming3 = `${coordinates[0]},${coordinates[1]},16.8`
                    payment = {
                        name: oneTimeName !== "" ? oneTimeName : ((user.user && user.user.status === 'Professional') && user.user.first_name + user.user.last_name),
                        email: oneTimeEmail !== "" ? oneTimeEmail : ((user.user && user.user.status === 'Professional') && user.user.email),
                        payment_method_id: payment_id,
                        map_id: summary[0].properties[0].map_id,
                        geojson: geojson,
                        zoom1: zooming1,
                        zoom2: zooming2,
                        zoom3: zooming3,
                        // file_name: place_name,
                        existing_account: true,
                    }
                }
            
            dispatch(setLoader(true));
            dispatch(oneTimePaymentReport(payment,alert));
        } else
            alert.error("You have no payment method!");
    };
    const { activeContent, plans } = dataWindow.content.choosePlan;
    let plan = plans && activeContent
        && plans.filter((pl) => pl.code === activeContent)[0]
    plan && userRoles && userRoles.map((role) => {
        if (role.name === plan.role) {
            plan['id'] = role.id;
        }
    })
    if (plan == '') {
        plan = PLANS[3];
    }
    if (quotaExceed === true && quota === true) {
        let quotaExceed = { ...QUOTA_EXCEEDS }
        const priceObj = planPrice.filter((price) => price.code === "one_time_exceed")[0]
        quotaExceed.price = priceObj.product_price;
        quotaExceed.productName = priceObj.product_name
        quotaExceed.cost.content = priceObj.product_description
        plan = { ...plan, ...quotaExceed };
    } else
        if (plan) plan = updatePlanDetails(plan, planPrice);

    const paymentMethod = {
        productName: "Payment Method"
    }

    const handlePayment = (event) => {
        if ((quotaExceed === true && quota === true) || (user.defaultPaymentMethod != null && title === 'Pay')) {
            if (defaultPay)
                handleDefaultPaymentMethod();
            if (cardPay)
                handleDefaultPaymentMethod();
            else if (!cardPay && !defaultPay && !customPay)
                alert.error("Please select payment method!");
        }
    }

    const selectMethod = (method) => {
        setShowMenu(false);
        switch (method) {
            case 'defaultPay':
                setDefaultPay(true);
                setCardPay(false);
                setCustomtPay(false);
                setDisplay(false);
                break;

            case 'cardPay':
                setDefaultPay(false);
                setCardPay(true);
                setCustomtPay(false);
                setDisplay(true);
                break;

            case 'customCard':
                setDefaultPay(false);
                setCardPay(false);
                setCustomtPay(true);
                setDisplay(false);
                break;
        }
    }
    const formDisplayCondition = (quotaExceed === true && quota === true) || (user.defaultPaymentMethod != null && title === 'Pay');
    const modalText = formDisplayCondition ? "Are you sure, you want to use this card ?" : "Are you sure, you want to change the default payment method ?";
    const formCondition = quotaExceed === true && quota === true ? quotaExceed === true && quota === true && customPay === true : title === 'Pay' && user.defaultPaymentMethod != null ? (title === 'Pay' && customPay === true) : oneTimePayment && user.defaultPaymentMethod === null ? true : true;

    const handleDropDown = () => {
        setShowMenu(!showMenu);
    }
    const handleComparePlans = () => {
        window.open("https://urbanform.us/pricing");
    }
    const handleUpgradeAccount = () => {
        dispatch(setDataWindow({
            dataWindow: true,
            content: {
                isContact: null,
                isForce: true,
                choosePlan: { plans: PLANS, activeContent: user.user?.status === 'Basic' ? 'pro' : 'ent' },
                downloadPdf: true,
            }
        }));
        dispatch(setDataWindowContent({ show: { showData: 'choose-plan', showControl: 'choose-plan' } }))
    }
    const upgradeAccount = {
        productName: "Upgrade Account",
        cost: {
            content: "Purchase Subscriptions that allow more PDF downloads per month",
            header: null,
        },
        custom: true,
        buttons: [
            {
                label: "Compare plans",
                buttonFunction: handleComparePlans,
                className: "primary",
                nextTab: true,
            },
            {
                label: "Upgrade Account",
                buttonFunction: handleUpgradeAccount,
                className: "warning",
                nextTab: false,
            }
        ],
        sectionClass: "bottom-margin"
    }
    return (
        <>
            {plan &&
                <Container>
                    <SegmentHeader content={plan} />
                    <Segment content={plan} />
                    <SegmentFooter />
                </Container>
            }
            {title === 'options' && <Container>
                <SegmentHeader image={true} content={{ productName: 'Select Cities' }} />
                <ChooseCitiesAndPlans />
                <SegmentFooter />
            </Container>}
            <Container>
                <SegmentHeader content={paymentMethod} />
                <div className="payment-method-section">
                    <div className="section-display">
                        <Form onSubmit={handlePayment} id="handle-payment">
                            {
                                formDisplayCondition &&
                                <div className='quota-exceed-buttons'>
                                    {user.defaultPaymentMethod &&
                                        <Form.Field key={Math.random()} className="radio-group" style={{ width: isMobile && "89%" }}>
                                            <Radio
                                                name='radioGroup'
                                                value='defaultPay'
                                                checked={defaultPay}
                                                onClick={() => { selectMethod('defaultPay') }}
                                            />
                                            <label htmlFor="" className="value-title">Use default saved payment method</label>
                                        </Form.Field>
                                    }
                                    {user.defaultPaymentMethod && <p style={{ marginLeft: "35px" }}
                                        className="a-gap value-03">{`${user.defaultPaymentMethod.card_brand} **** **** **** ${user.defaultPaymentMethod.last_four_digits}`}</p>
                                    }
                                    {getCount > 1 && <hr className="horizontal-line" />}
                                    {getCount > 1 &&
                                        <Form.Field key={Math.random()} className="radio-group" style={{ width: isMobile && "89%" }}>
                                            <Radio
                                                label={""}
                                                name='radioGroup'
                                                value='otherCardPay'
                                                onClick={() => { selectMethod('cardPay') }}
                                                checked={cardPay}
                                            />
                                            <label htmlFor="" className="value-title">Use other saved payment method</label>
                                        </Form.Field>
                                    }
                                    <Form className="dropdown-form"
                                        style={{ display: display == false ? 'none' : '', width: isMobile && "78%" }}>
                                        <Dropdown item simple className={'right'} text={dropDownLabel ? dropDownLabel : "Select Card"} onClick={handleDropDown}>
                                            <Dropdown.Menu style={{ display: showMenu === true ? "block" : "none" }}>
                                                {allPaymentMethod.allPaymentMethod && allPaymentMethod.allPaymentMethod.map((method, index) => {
                                                    return <div>
                                                        <Dropdown.Item>
                                                            <Form.Field className='radio-fields'
                                                                key={Math.random()}>
                                                                <Radio
                                                                    label={`${method.card_brand} **** **** **** ${method.last_four_digits}`}
                                                                    name='radioGroupCard'
                                                                    value={method.payment_method_id}
                                                                    checked={selectedMethod === method.payment_method_id}
                                                                    onChange={handleChange}
                                                                />
                                                            </Form.Field>
                                                        </Dropdown.Item>
                                                        <Confirm
                                                            open={showConfirm}
                                                            onCancel={handleCancel}
                                                            onConfirm={handleConfirm}
                                                            cancelButton='Cancel'
                                                            confirmButton="Let's do it"
                                                            content={modalText}
                                                            size='mini'
                                                        />
                                                    </div>
                                                })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Form>
                                    <hr className="horizontal-line" />
                                </div>
                            }
                            {formDisplayCondition && <Form.Field key={Math.random()} className="radio-group" style={{ width: isMobile && "89%" }}>
                                <Radio
                                    name='radioGroup'
                                    value='customCard'
                                    checked={customPay}
                                    onClick={() => { selectMethod('customCard') }}
                                />
                                <label htmlFor="" className="value-title">Use the card below</label>
                            </Form.Field>}
                            <form onSubmit={handleSubmit} className='payment-form' id="custom-payment-form" >
                                {!token && <Form.Field>
                                    <label className="value-title">Name</label>
                                    <Input transparent required={true} name="name" type="text"
                                        placeholder="Enter Name" va onChange={handleOneTimeName} />
                                </Form.Field>}
                                {oneTimePayment && <Form.Field>
                                    {quotaExceed === false && !token && <div>
                                        <label className="value-title">Email</label>
                                        <Input transparent required={true} name="email" type="email"
                                            placeholder="Enter Email" onChange={handleOneTimeMail} /></div>}
                                    {quotaExceed === false && user.user && user.user.status === 'Basic' && <div>
                                        <label className="value-title">Email</label>
                                        <Input transparent required={true} name="email" type="email"
                                            placeholder="Enter Email"
                                            value={user.user && user.user.status === 'Basic' && user.user.email}
                                            onChange={handleOneTimeMail} /></div>}
                                    {quotaExceed === true && quota === false && user.user && user.user.status === 'Professional' && <div>
                                        <label className="value-title">Email</label>
                                        <Input transparent required={true} name="email" type="email"
                                            placeholder="Enter Email"
                                            value={user.user && user.user.status === 'Professional' && user.user.email}
                                            onChange={handleOneTimeMail} /></div>}
                                    {quotaExceed === true && quota === false && user.user && user.user.status === 'Enterprise' && <div>
                                        <label className="value-title">Email</label>
                                        <Input transparent required={true} name="email" type="email"
                                            placeholder="Enter Email"
                                            value={user.user && user.user.status === 'Enterprise' && user.user.email}
                                            onChange={handleOneTimeMail} /> </div>}
                                </Form.Field>}
                                <label className="value-title">Card number</label>
                                <CardNumberElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                />
                                <label className="value-title">Expiration date</label>
                                <CardExpiryElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                />
                                <label className="value-title">CVC number</label>
                                <CardCvcElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#424770',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#9e2146',
                                            },
                                        },
                                    }}
                                />
                            </form>
                        </Form>
                        <Button fluid type="submit" className='pay-btn' disabled={!stripe} {...formCondition ? { form: 'custom-payment-form' } : { form: 'handle-payment' }}>
                            {title === "Add" ? "Add credit card" : "Purchase"}
                        </Button>
                        {title !== "Add" && <p className="sub-text terms-service" style={{ width: isMobile && "80%" }}>By purchasing, you are agreeing to the <a
                            href="https://urbanform.us/terms-of-service" target="_blank">Terms of Service</a></p>}
                    </div>
                </div>
                <SegmentFooter />
            </Container>
            {oneTimePayment && token && (user.user?.status === 'Basic' || user.user?.status === 'Professional') && <Container>
                <SegmentHeader content={upgradeAccount} />
                <Segment content={upgradeAccount} />
                <SegmentFooter />
            </Container>}
        </>
    );
};
export default CheckoutForm;