import React from "react";
import realEstate from '../../../../images/realestate.jpg'

const RealEstate = () => {
    return (
        <div className="real-estate" style={{ margin: "18px" }}>
            <img src={realEstate} alt="" style={{ width: "96%" }} />
            <h1 style={{ color: "#000" }}>This feature is
                coming soon!</h1>
        </div>
    );
};

export default RealEstate;
