import React from "react";
import architect from '../../../../images/architect.jpg'

const Architect = () => {
    return (
        <div className="architect" style={{ margin: "18px" }}>
            <img src={architect} alt="" style={{ width: "96%" }} />
            <h1 style={{ color: "#000" }}>This feature is
                coming soon!</h1>
        </div>
    );
};

export default Architect;
