import React from "react";
import SavedSection from "../SavedSection";
import Container from "../Container";
import { useSelector } from "react-redux";

const SavedProperty = (dispatch) => {
    const { savedDefaultValues } = useSelector(state => state.property)
    const { user } = useSelector(state => state.user)
    return (
        <div className="acc_data_window">
            <h1 style={{ color: "#000", fontFamily: "Inter-Black" }}>Hello, {user?.first_name}!</h1>
            <div className='property-data'>
                <div className='property-heading'>
                    <h2>Saved Properties</h2>
                </div>
                <SavedSection content={savedDefaultValues} dispatch={dispatch} report={false} />

            </div>
        </div>

    )
}
export default SavedProperty;
