import {
    SET_INFO_DEFAULT_VALUE,
    INFO_VALUES_UPDATED,
    SET_INFO_CURRENT_VALUE,
    RESET_INFO_CURRENT_INFO_SELECTOR,
    INFO_MAP_ADDRESS, INFO_COORDS,
    SET_INFO_UPDATED, INFO_GEO, SET_LINK_VALUE, SET_PHOTO_REF, INFO_NOT_LOGGED_IN, USE_ALLOWANCE_UPDATE, USE_CATEGORY_UPDATE, INFO_REPORT_REMAIN, RESET_INFO
} from "../actions/actions_info";

const initialState = {
    infoDefaultValues: {
        info_not_updated: true,
    },
    infoValuesUpdated: 0,
    infoSearchMapAddress: '',
    infoCoords: null,
    infoGeo: null,
    info_link: null,
    photo_ref: null,
    infoNotLoggedIn: false,
    useRegulationCheck: {
        allowance: false,
        category: true,
    },
    reports: {
        total: null,
        downloaded: null,
    }
};

const info = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_INFO_DEFAULT_VALUE:
            var newState = JSON.parse(JSON.stringify(state));
            newState.infoDefaultValues = payload;
            return newState;
        case SET_INFO_CURRENT_VALUE:
            var newState = JSON.parse(JSON.stringify(state));
            var thisInfoData = payload.data;
            newState.infoDefaultValues = thisInfoData;
            return newState;
        case INFO_VALUES_UPDATED:
            var newState = JSON.parse(JSON.stringify(state));
            newState.infoValuesUpdated = payload;
            return newState;
        case INFO_MAP_ADDRESS:
            var newState = JSON.parse(JSON.stringify(state));
            newState.infoSearchMapAddress = payload;
            return newState;
        case INFO_COORDS:
            var newState = JSON.parse(JSON.stringify(state));
            newState.infoCoords = payload;
            return newState;
        case INFO_GEO:
            var newState = JSON.parse(JSON.stringify(state));
            newState.infoGeo = payload;
            return newState;
        case SET_INFO_UPDATED:
            var newState = JSON.parse(JSON.stringify(state));
            newState.infoDefaultValues.info_not_updated = payload;
            return newState;
        case SET_LINK_VALUE:
            var newState = JSON.parse(JSON.stringify(state));
            newState.info_link = payload;
            return newState;
        case SET_PHOTO_REF:
            var newState = JSON.parse(JSON.stringify(state));
            newState.photo_ref = payload;
            return newState;
        case RESET_INFO_CURRENT_INFO_SELECTOR:
            var newInfoState = {
                summary: payload.summary,
                identifiers: payload.identifiers,
                zoning: payload.zoning,
                lot_info: payload.lot_info,
                setbacks: payload.setbacks,
                other:payload.other,
                max_foot_print: payload.max_foot_print,
                use_regulation: payload.use_regulation,
                far: payload.far, 
                height_limit: payload.height_limit,
                massing:payload.massing, 
                coverage:payload.coverage,
                residential_units: payload.residential_units,
                existing_structures: payload.existing_structures,
                development: payload.development,
            };
            return {
                ...state,
                infoSelectedValues: newInfoState,
            };
        case INFO_NOT_LOGGED_IN:
            var newState = JSON.parse(JSON.stringify(state));
            newState.infoNotLoggedIn = payload;
            return newState;
        case USE_ALLOWANCE_UPDATE:
            var newState = JSON.parse(JSON.stringify(state));
            newState.useRegulationCheck.allowance = payload;
            newState.useRegulationCheck.category = !payload;
            return newState;
        case USE_CATEGORY_UPDATE:
            var newState = JSON.parse(JSON.stringify(state));
            newState.useRegulationCheck.category = payload;
            newState.useRegulationCheck.allowance = !payload;
            return newState;
        case INFO_REPORT_REMAIN:
            var newState = JSON.parse(JSON.stringify(state));
            newState.reports.total = payload.total_count ?? null;
            newState.reports.downloaded = payload.downloaded_reports ?? null;
            return newState;
        case RESET_INFO:
            var newInfoState = {
                summary: [],
                identifiers: [],
                zoning: [],
                lot_info: [],
                setbacks: [],
                other:[],
                max_foot_print: [],
                use_regulation: [],
                far: [], 
                height_limit: [],
                massing:[], 
                coverage:[],
                residential_units: [],
                existing_structures: [],
                development: [],
            };
            var newState = JSON.parse(JSON.stringify(state))
            
            newState.infoDefaultValues={
                ...newInfoState
            }
            return newState
        default:
            return state;
    }
};

export default info;
