import {
  UPDATE_RESULT_FILTER,
  SET_FILTER_DEFAULT_VALUE,
  SET_FILTER_CURRENT_VALUE,
  RESET_ALL_CURRENT_FILTERS,
  SET_FILTER_VALUES,
  UPDATE_FILTER_BY_NAME,
  UPDATE_SELECTED_PROPERTY,
  UPDATE_FILTER_PAYMENT, SET_FILTER_PLAN,
  SET_SAVED_FILTER,
  SET_FILTER,
  SET_SHOW_FILTER_FOLDER,
  SET_DEFAULT_FILTER,
  SET_LOCATION_SPECIFCS_VALUES,
  SET_ZONE_VALUES
} from "../actions/actions_filters";

const initialState = {
  filterDefaultValues: {
    zone: [],
    zone_cat: [],
    lot_type: [],
    htlim_adjx: [],
    htlim_bonx: [],
    umax_adjx_valu: [],
    umax_bonx_valu: [],
    envp_grnd_ftpt_delta: [],
    envp_grnd_lot_area: [],
    envp_grnd_ftpt_max: [],
    envp_bldg_max_area: [],
    envp_bldg_bonus_area:[],
    envp_bldg_max_res: [],
    envp_delt_area:[],
    envp_delt_bonus:[],
    far_lot: [],
    far_bonx: [],
    envp_bldg_delt_area: [],
  },



  filterSelectedValues: {
    zone: [],
    zone_cat: [],
    lot_type: [],
    htlim_adjx: [],
    htlim_bonx: [],
    umax_adjx_valu: [],
    umax_bonx_valu: [],
    envp_grnd_ftpt_delta: [],
    envp_grnd_lot_area: [],
    envp_grnd_ftpt_max: [],
    envp_bldg_max_area: [],
    envp_bldg_bonus_area:[],
    envp_bldg_max_res: [],
    envp_delt_area:[],
    envp_delt_bonus:[],
    far_lot: [],
    far_bonx: [],
    envp_bldg_delt_area: [],
  },


 
  filterValues: {
    jurisdiction:[],
    zoning: [], 
    location:[],
    lot_info: [],
    height:[],
    far:[],
    units:[],
    coverage:[],
    use: [], 
    existing_structures: [],
    maxbuild: [],
  },
  defaultFilter: {
    jurisdiction:[],
    zoning: [], 
    location:[],
    lot_info: [],
    height:[],
    far:[],
    units:[],
    coverage:[],
    use: [], 
    existing_structures: [],
    maxbuild: [],
  },
  filterResult: {
    count: 0,
    data: null,
  },
  selectedProperty: {},
  filterPayment: {
    filterUpgradeProcess: false,
    filterPaymentDone: false
  },
  savedFilter: {},
  filterPlan: null,
  runFilter: false,
  showFolder: false
};

const filters = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FILTER_DEFAULT_VALUE: 
      var thisFilterType = payload.type;
      var thisFilterData = payload.data;
      var newState = JSON.parse(JSON.stringify(state));
      newState.filterDefaultValues[thisFilterType] = thisFilterData;
      newState.filterSelectedValues[thisFilterType] = thisFilterData;
      // newState.filterSelectedValues["zone"] = [];
      // newState.filterSelectedValues["zone_cat1"] = [];
      newState.filterSelectedValues["lot_type"] = [];
      return newState;
      
    case SET_FILTER_CURRENT_VALUE:
      var thisFilterType = payload.type;
      var thisFilterData = payload.data;
      var newState = JSON.parse(JSON.stringify(state));
      newState.filterSelectedValues[thisFilterType] = thisFilterData;
      return newState;
    case RESET_ALL_CURRENT_FILTERS: 
      var newFilterState = { 
        zone: payload.zone,
        zone_cat1: payload.zone_cat1, 
        lot_type: payload.lot_type,
        htlim_adjx: payload.htlim_adjx,
        htlim_bonx: payload.htlim_bonx,
        umax_adjx_valu: payload.umax_adjx_valu,
        umax_bonx_valu: payload.umax_bonx_valu,
        envp_grnd_ftpt_delta:payload.envp_grnd_ftpt_delta,
        envp_grnd_lot_area: payload.envp_grnd_lot_area,
        envp_grnd_ftpt_max: payload.envp_grnd_ftpt_max,
        envp_bldg_max_area: payload.envp_bldg_max_area,
        envp_bldg_bonus_area:payload.envp_bldg_bonus_area,
        envp_bldg_max_res: payload.envp_bldg_max_res,
        envp_delt_area:payload.envp_delt_area,
        envp_delt_bonus:payload.envp_delt_bonus,
        far_lot: payload.far_lot,
        far_bonx: payload.far_bonx,
        envp_bldg_delt_area: payload.envp_bldg_delt_area,
      };
      return {
        ...state,
        filterSelectedValues: newFilterState,
      };
    case SET_FILTER_VALUES: 
      var newState = JSON.parse(JSON.stringify(state));
      newState.filterValues.jurisdiction = payload.jurisdiction;
      newState.filterValues.zoning = payload.zoning; 
      newState.filterValues.location = payload.location;
      newState.filterValues.lot_info = payload.lot_info;
      newState.filterValues.use = payload.use; 
      newState.filterValues.height = payload.height;
      newState.filterValues.far = payload.far;
      newState.filterValues.units = payload.units;
      newState.filterValues.coverage = payload.coverage;  
      newState.filterValues.existing_structures = payload.existing_structures;
      newState.filterValues.maxbuild = payload.maxbuild;
      return newState;

      case SET_LOCATION_SPECIFCS_VALUES:
      var newState = JSON.parse(JSON.stringify(state));
      newState.filterValues.location=payload.location; 
      newState.defaultFilter.location=payload.location;
      return newState;

    case SET_DEFAULT_FILTER:
      var newState = JSON.parse(JSON.stringify(state));
      newState.defaultFilter.jurisdiction = payload.jurisdiction;
      newState.defaultFilter.zoning = payload.zoning; 
      newState.defaultFilter.location = payload.location;
      newState.defaultFilter.lot_info = payload.lot_info;
      newState.defaultFilter.use = payload.use; 
      newState.defaultFilter.height = payload.height;
      newState.defaultFilter.far = payload.far;
      newState.defaultFilter.units = payload.units;
      newState.defaultFilter.coverage = payload.coverage;  
      newState.defaultFilter.existing_structures = payload.existing_structures;
      newState.defaultFilter.maxbuild = payload.maxbuild;
       
      return newState;
    case UPDATE_FILTER_BY_NAME:
      var newState = JSON.parse(JSON.stringify(state));
      newState.filterValues[payload.name] = payload.filterData;
      return newState;
    case UPDATE_RESULT_FILTER:
      var newState = JSON.parse(JSON.stringify(state));
      newState.filterResult.data = payload.data;
      newState.filterResult.count = payload.count;
      newState.filterResult.pageNumber = payload.page;
      newState.filterResult.totalCount = payload.total_count;
      return newState;
    case UPDATE_SELECTED_PROPERTY:
      var newState = JSON.parse(JSON.stringify(state));
      newState.selectedProperty = payload;
      return newState;
    case UPDATE_FILTER_PAYMENT:
      var newState = JSON.parse(JSON.stringify(state));
      newState.filterPayment.filterUpgradeProcess = payload.filterUpgradeProcess;
      newState.filterPayment.filterPaymentDone = payload.filterPaymentDone;
      return newState;
    case SET_SAVED_FILTER:
      var newState = JSON.parse(JSON.stringify(state));
      newState.savedFilter = payload
      return newState;
    case SET_FILTER_PLAN:
      var newState = JSON.parse(JSON.stringify(state));
      newState.filterPlan = payload
      return newState;
    case SET_FILTER:
      var newState = JSON.parse(JSON.stringify(state));
      newState.runFilter = payload
      return newState;
    case SET_SHOW_FILTER_FOLDER:
      var newState = JSON.parse(JSON.stringify(state));
      newState.showFolder = payload
      return newState;
    default:
      return state;
  }
};

export default filters;
