import selectedEnv from "../Universal_CONFIG";
import { infoUserNotLoggedIn } from "./actions_info";

export const SET_TAXLOT = "SET_TAXLOT";
export const SET_GEOJSON = "SET_GEOJSON";
export const SET_TAXLOT_ERROR = "SET_TAXLOT_ERROR";

export function getTaxlotData(primaccnum, lngLat) {
  return (dispatch) => {
    // fetch('https://urbanform-backend-staging.herokuapp.com/api/primaccnum/'+thisTaxLot[0].properties.PRIMACCNUM).then(response => response.json()).then(resp => {
    //   alert(JSON.stringify(resp));
    // })
    return fetch(selectedEnv.REACT_APP_API_URL + "/api/info/" + primaccnum)
      .then((response) => response.json())
      .then((resp) => {
        let token = window.localStorage.getItem('_uft');
        // alert(JSON.stringify(resp));
        if (lngLat && resp.taxLots) {
          resp.taxLots['mapboxCoords'] = lngLat;
        }
        if (resp && !resp.taxLots) {
          dispatch(setTaxlotError('No tax lot data found'))
        }
        dispatch(setTaxlot(resp));
        if (!(token != null && token !== 'null'))
          dispatch(infoUserNotLoggedIn(true));
      });
  };
}

export function setTaxlot(result) {
  return {
    type: SET_TAXLOT,
    payload: result,
  };
}

export function setGeojson(result) {
  return {
    type: SET_GEOJSON,
    payload: result,
  };
}

export function setTaxlotError(msg) {
  return {
    type: SET_TAXLOT_ERROR,
    payload: msg
  }
}
