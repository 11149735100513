export const yam8_env = {
  REACT_APP_API_URL: "https://sea-dev.urbanform.us/api",
  REACT_APP_MAPBOX_ACCESS_TOKEN:
    "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw",
  REACT_APP_MAPBOX_STYLE_URL_BASIC:
    "mapbox://styles/qvt282/cm2f1z553000e01pye6678wq9", //multi-region-style
  REACT_APP_MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/cm2f1z9cu002b01pp0uhc9v2w",
  REACT_APP_MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/cm2f1yyfz00mo01ps2vuf5f4y",
  REACT_APP_MAPBOX_LAYER_NAME: "sea_blv_high_zoom",
  REACT_APP_STRIPE_KEY:
    "pk_test_51J3keRKwuRPRmetLYA1Zgpg1T50OlJj7L5U7p60MB7hvR8VaaXhoDk0llLi95tOaWUU77c4rNEx7fa22nyEStrxD00X4iN7RXg",
  REACT_APP_REGION: "newberg",
  REACT_APP_BBOX:
    "-123.50547329501424,45.03061643785577,-122.89449190329049,45.36919904610863",
  REACT_APP_MAP_CENTER: "-123.20015959652537,45.20015959652537",
  REACT_APP_SUBREGION_LAYER: "blv_region_9-16",
  REACT_APP_SUB_REGION: "newberg, CA",
  REACT_APP_CITY_NAME: "Newberg, CA",

  REACT_APP_SUB_CITY_NAME_1: "Amity, OR",
  REACT_APP_SUB_CITY_NAME_2: "Carlton, IL",
  REACT_APP_SUB_CITY_NAME_3: "Dundee, TX",
  REACT_APP_SUB_CITY_NAME_4: "Dayton, AZ",
  REACT_APP_SUB_CITY_NAME_6: "Yamhill, TX",
  REACT_APP_SUB_CITY_NAME_7: "Willamina, CA",
  REACT_APP_SUB_CITY_NAME_8: "Lafayette, CA",

  REACT_APP_CITIES_LIST:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [], "los_angeles": [], "yam8":[], "yam9":[] }',
  REACT_APP_PROXIMITY: "-123.20015959652537,45.20015959652537",
  REACT_APP_MB_TILES_LIST: "taxlots-fill",
  REACT_APP_ZOOM_LEVEL: 10.4,
};
