import React from "react";
import { Dropdown, Radio } from "semantic-ui-react";
import "../../../../css/SliderSection.scss";
import useInfoMaxBuildCalculator from "../../../../hooks/useInfoMaxBuildCalculator";
import InfoMaxUnit from "./InfoMaxUnit";
import "../../../../css/InfoMaxBuild.scss";
const InfoMaxBuildCalculator = ({ content }) => {
 
  const {
    state,
    handleChangeRadio,
    handleChange,
    setState,
    getResultingValues,
    getSliderValue,
    options,
  } = useInfoMaxBuildCalculator(content);
  let margin = { marginLeft: "14px" };
  
  return (
    <div
      className="section"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 18px",
      }}
    >
      <div
        className={[`top-bottom-rule spacing`]}
        style={{
          width: "94%",
        }}
      >
        <div className="vertical-center">
          <Radio
            className="testing"
            name="maxBuildType"
            value={"MaxBuild AOR"}
            onChange={handleChangeRadio}
            checked={state.maxBuildType === "MaxBuild AOR"}
          />
          <p htmlFor="html" className="value-03" style={margin}>
            MaxBuild AOR
          </p>
        </div>
        <div className="vertical-center">
          <Radio
            className="testing"
            name="maxBuildType"
            value={"MaxBuild ABT"}
            onChange={handleChangeRadio}
            checked={state.maxBuildType === "MaxBuild ABT"}
          />
          <p htmlFor="html" className="value-03" style={margin}>
            MaxBuild ABT
          </p>
        </div>
        <div>
          <div className="vertical-center">
            <Radio
              className="testing"
              name="maxBuildType"
              value={"Custom FAR"}
              onChange={handleChangeRadio}
              checked={state.maxBuildType === "Custom FAR"}
            />
            <p htmlFor="html" className="value-03" style={margin}>
              Custom FAR
            </p>
          </div>
          <div className="slider" style={{ padding: "0 0 0 31px" }}>
            <div className="inputRow" style={{ paddingLeft: "0" }}>
              <input
                min={1}
                name="customFarValue"
                value={state?.customFarValue}
                onChange={handleChange}
                disabled={state.maxBuildType !== "Custom FAR"}
                type="number"
                className="fields value-03"
                style={{ paddingLeft: "0", width: "116px !important" }}
              />
              <p
                htmlFor=""
                className="height-input-label"
                style={{ paddingLeft: "10px" }}
                // style="margin-left: 82px; white-space: nowrap;"
              >
                to 1
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="vertical-center">
            <Radio
              className="testing"
              name="maxBuildType"
              value={"Custom SF"}
              onChange={handleChangeRadio}
              checked={state.maxBuildType === "Custom SF"}
            />
            <p htmlFor="html" className="value-03" style={margin}>
              Custom SF
            </p>
          </div>
          <div className="slider" style={{ padding: "0 0 0 31px" }}>
            <div className="inputRow" style={{ paddingLeft: "0" }}>
              <input
                min={1}
                name="customSfValue"
                value={state?.customSfValue}
                onChange={handleChange}
                disabled={state.maxBuildType !== "Custom SF"}
                type="number"
                className="fields value-03"
                style={{ paddingLeft: "0", width: "116px !important" }}
              />
              <p
                htmlFor=""
                className="height-input-label"
                style={{ paddingLeft: "10px" }}
                // style="margin-left: 82px; white-space: nowrap;"
              >
                sf
              </p>
            </div>
            <hr style={{ margin: "10px 0" }} />
          </div>
        </div>
        <div className="slider" style={{ padding: "0 0 0 31px" }}>
          <p className="value-title" style={{ marginBottom: "10px" }}>
            Resulting SF:
          </p>
          {/* <div className="inputRow" style={{ paddingLeft: "0" }}> */}
          <input
            readOnly
            value={state?.resultingSfValue? parseFloat(state?.resultingSfValue):" "}
            disabled={true}
            type="number"
            className="fields value-02"
            style={{
              paddingLeft: "0",
              width: "100%",
              padding: "7px",
              border: "1px solid rgba(34, 36, 38, 0.35)",
            }}
          />
        </div>

        <div className="slider" style={{ padding: "0 0 0 31px" }}>
          <p className="value-title" style={{ marginBottom: "5px" }}>
            square feet
          </p>
          <p className="heading6 value-title" style={{ marginBottom: "5px" }}>
            Units:
          </p>
          <Dropdown
            className="paragraph3"
            value={state?.sfUnit}
            onChange={(e, { value }) =>
              setState((prev) => ({
                ...prev,
                sfUnit: value,
                ...getResultingValues({ ...prev, sfUnit: value }),
              }))
            }
            placeholder="Select a unit"
            fluid
            selection
            options={options}
          />
          <hr style={{ margin: "10px 0" }} />
        </div>
        {state?.sfUnit !== "5" ? (
          <>
            <InfoMaxUnit 
            // defaultValue="22222"
              defaultValue={content?.bldgsqft?parseFloat(content?.bldgsqft?.replace(/,/g, "")):""}
              value={state?.unit1}
              key="unit1"
              label={"Unit 1"}
              name="unit1"
              min={0}
              max={+state.resultingSfValue}
              getSliderValue={getSliderValue}
              useExistingBldgSf={true}
              setState={setState}
            />
            <InfoMaxUnit
              value={state?.unit2}
              key="unit2"
              label={"Unit 2"}
              name="unit2"
              min={0}
              max={+state.resultingSfValue - +state.unit1 || 0}
              getSliderValue={getSliderValue}
            />
            {state.sfUnit >= 3 && (
              <InfoMaxUnit
                value={state?.unit3}
                key="unit3"
                label={"Unit 3"}
                name="unit3"
                min={0}
                max={
                  +state.resultingSfValue - (+state.unit1 + +state.unit2) || 0
                }
                getSliderValue={getSliderValue}
              />
            )}
            {state.sfUnit >= 4 && (
              <InfoMaxUnit
                value={state?.unit4}
                key="unit4"
                label={"Unit 4"}
                name="unit4"
                line={true}
                min={0}
                max={
                  +state?.resultingSfValue -
                    (+state?.unit1 + +state?.unit2 + +state?.unit3) || 0
                }
                getSliderValue={getSliderValue}
              />
            )}
          </>
        ) : (
          <>
            {" "}
            <InfoMaxUnit
              key="Units"
              label={"Units"}
              name="units"
              range={false}
              min={5}
              max={100000}
              getSliderValue={getSliderValue}
              defaultValue={5}
            />
            <InfoMaxUnit
              defaultValue={[0.85]}
              key="efficiency"
              label={"Efficiency %"}
              name="efficiency"
              line={true}
              min={0.0}
              max={1}
              getSliderValue={getSliderValue}
              step={0.01}
            />
            <div className="slider" style={{ padding: "0 0 0 31px" }}>
              <p className="value-title" style={{ marginBottom: "10px" }}>
                Resulting Net SF:
              </p>
              {/* <div className="inputRow" style={{ paddingLeft: "0" }}> */}
              <input
                readOnly
                type="number"
                className="fields value-02"
                value={state?.resultingNetSf.toFixed()}
                style={{
                  paddingLeft: "0",
                  width: "100%",
                  padding: "7px",
                  border: "1px solid rgba(34, 36, 38, 0.35)",
                }}
              />
            </div>
            <div className="slider" style={{ padding: "0 0 0 31px" }}>
              <p className="value-title" style={{ marginBottom: "10px" }}>
                Resulting Net SF / unit:
              </p>
              {/* <div className="inputRow" style={{ paddingLeft: "0" }}> */}
              <input
                readOnly
                type="number"
                className="fields value-02"
                value={state?.resultingNetSfPrUnit.toFixed()}
                style={{
                  paddingLeft: "0",
                  width: "100%",
                  padding: "7px",
                  border: "1px solid rgba(34, 36, 38, 0.35)",
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default InfoMaxBuildCalculator;
