import React, { useState } from "react";
import '../../css/Help.scss'
import { Button, Image } from "semantic-ui-react";
import { HELP_CONTENT, ICONS } from "../../constants";
import { useDispatch } from "react-redux";
import { setDataWindow, setDataWindowContent } from "../../actions/actions_datawindow";
import { activityLogger } from "../../actions/actions_user";

const HelpContent = () => {
    const dispatch = useDispatch();
    const [activeSection, setActiveSection] = useState('');

    const handleHelpControl = (control) => {
        if (control === activeSection) { 
            setActiveSection('');
            dispatch(setDataWindowContent({ show: { showData: '', showControl: 'help' } }));
            dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
            return
        }
        dispatch(setDataWindowContent({ show: { showData: control, showControl: 'help' } }));
        setActiveSection(control);
        let body = {
            'event': 'Tab Selected: ' + control
        }
        dispatch(activityLogger(body))
        switch (control) {
            case 'contact_city':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
                dispatch(setDataWindowContent({ show: { showData: 'contact_city', showControl: 'help' } }));
                break;
            case 'urbanform_news':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
                dispatch(setDataWindowContent({ show: { showData: 'urbanform_news', showControl: 'help' } }));
                break;
            case 'architect':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
                dispatch(setDataWindowContent({ show: { showData: 'architect', showControl: 'help' } }));
                break;
            case 'real_estate_pro':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
                dispatch(setDataWindowContent({ show: { showData: 'real_estate_pro', showControl: 'help' } }));
                break;
            case 'contact_support':
                dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false } }));
                dispatch(setDataWindowContent({ show: { showData: 'contact_support', showControl: 'help' } }));
                break;
        }
    }
    const getIconHelper = (name) => {
        return ICONS.filter((icon) => name === icon.name)[0].icon
    }
    return (
        <div className="help-container help-section">
         
            <div className="help-content">
                {
                    HELP_CONTENT.map(info => {
                        return (
                            <div key={info.id} className="help-item">
                                <Button fluid size="large" className={`${activeSection === info.code ? 'active' : ''} curve-wrapper help-controls-btn`} onClick={() => { handleHelpControl(info.code) }}>
                                    {info.iconName && <Image src={getIconHelper(info.iconName)} className={info.iconName === 'ICON_INFO' ? 'info-icon' : ''} />}
                                    <span className={info.iconName === null ? 'centered' : ''}>{info.title}</span>
                                </Button>
                            </div>
                        )
                    })
                }
            </div>
            <div className="footer-content">
                <p>UrbanForm is a product of </p>
                <p>POLYTECHNICA</p>
                <p>&copy; {new Date().getFullYear()} All rights reserved</p>
                <p><a href="https://www.polytechnica.design/">www.polytechnica.design</a></p>


                <div className="help-item">
                    <Button fluid size="large" className="curve-wrapper help-controls-btn"  >
                        <span className='centered'><a href="https://www.urbanform.us/termsofuse" target="_blank">Terms of Use</a></span>
                    </Button>
                </div>


                <div className="help-item">
                    <Button fluid size="large" className="curve-wrapper help-controls-btn"  >
                        <span className='centered'><a href="https://www.urbanform.us/terms-of-service" target="_blank">Terms of Service</a></span>
                    </Button>
                </div>


                <div className="help-item">
                    <Button fluid size="large" className="curve-wrapper help-controls-btn"  >
                        <span className='centered' ><a href="https://www.urbanform.us/privacypolicy" target="_blank">privacy policy</a></span>
                    </Button>
                </div>


            </div>
        </div>
    );
};

export default HelpContent;
