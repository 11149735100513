import React from "react";
import { useDispatch, useSelector } from "react-redux";
import selectedEnv from "../../../../Universal_CONFIG";
import '../../../../css/Segment.scss';


const MarkedCitiesAndPlans = ({ allowAll }) => {

    const { user } = useSelector(state => state.user); 
    const { plans, citiesAndPlans } = useSelector(state => state.plans);
    const dispatch = useDispatch();
    const header = ['', 'Basic', 'Pro', 'Ent.']; 
    // v2.0
    const regions = JSON.parse(selectedEnv.REACT_APP_CITIES_LIST);
    const userRegionPlans = user?.region_plans || {};
    

    return (
        <div className="section">
            <div className={[`section-display section-display-cities`]}>
            <div className="plan-body">
                    {Object.keys(regions).map((city, index) => {
                        
                        const subscriptionText = userRegionPlans[city]  ;
                        const formattedCity = city.replace('_', ' ');
                        
                        return (
                            <div key={index}>
                                <p>
                                    {formattedCity}:
                                    <span>{subscriptionText}</span>
                                </p>
                            </div>
                        );
                    })}
                </div> 
            </div>
        </div>
    );
}
export default MarkedCitiesAndPlans;