import jwt_decode from "jwt-decode";
import selectedEnv from '../Universal_CONFIG';
import { CONTACT_SUPPORT, INFO_CONSTANT, PLANS, UF_TOKEN } from '../constants';
import { downloadPdf } from "../utils/Utils";
import { setDataWindow, setDataWindowContent } from './actions_datawindow';
import { updateFilterPayment } from "./actions_filters";
import {
  getReportCount,
  infoUserNotLoggedIn,
  updatedInfoSection
} from "./actions_info";
// import XMLParser from 'react-xml-parser';


export const USER_HAS_SIGNEDUP = "USER_HAS_SIGNEDUP";
export const USER_HAS_LOGGEDIN = "USER_HAS_LOGGEDIN";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const SET_USER_LOGIN_ERROR = "SET_USER_LOGIN_ERROR";
export const USER_HAS_SUBSCRIBED = "USER_HAS_SUBSCRIBED";
export const USER_LOG_OUT = "USER_LOG_OUT";
export const USER_NO_AUTH = "USER_NO_AUTH";
export const USER_HAS_UPDATED = "USER_HAS_UPDATED";
export const SET_USER_UPDATE_ERROR = "SET_USER_UPDATE_ERROR";
export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
export const SET_LOADER = "SET_LOADER";
export const SET_ALREADY_LOGIN = "SET_ALREADY_LOGIN";
export const SET_FORGET_PASSWORD_ERROR = "SET_FORGET_PASSWORD_ERROR";
export const SET_PLAN_PRICE = "SET_PLAN_PRICE";
export const SET_NEWS_FEED = "SET_NEWS_FEED";
export const SET_QUOTA_EXCEED = "SET_QUOTA_EXCEED";
export const SET_DEFAULT_PAYMENT_METHOD = "SET_DEFAULT_PAYMENT_METHOD";
export const SET_ALL_PAYMENT_METHOD = "SET_ALL_PAYMENT_METHOD";
export const SET_ACCOUNT_ACTIVATION_PROCESS = "SET_ACCOUNT_ACTIVATION_PROCESS";



export function getUpdatedAccessToken(navigate, token) {
  return async (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/users/update-token`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (Object.keys(resp).includes("msg")) {
          dispatch(setUserLoginError(resp.msg))
        } else {
          let user = jwt_decode(resp.token);
          dispatch(handleUserLogin({ user: user.user, token: resp.token }));
          dispatch(setUserLoginError(null))
          // store token in local storage
          window.localStorage.setItem(UF_TOKEN, resp.token)
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 10);
        }
      })
      .catch((error) => {
        dispatch(setUserLoginError("Server not responding."));
      });
  }
}






export function signupUser(userInfo) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/users/`, {
      method: "POST",
      body: JSON.stringify(userInfo),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (!Object.keys(resp).includes("token")) {
          let errorMessages = [];
          dispatch(setLoader(false));
          Object.keys(resp).includes("email") && errorMessages.push('Account with this email already exist!')
          Object.keys(resp).includes("phone_number") && errorMessages.push('You have entered an invalid phone number!')
          Object.keys(resp).includes("zip_code") && errorMessages.push('You have entered an invalid zip code!')
          if (Object.keys(resp).includes("password")) {
            Array.isArray(resp.password) && resp.password.forEach(msg => {
              errorMessages.push(msg);
              errorMessages.push(' ');
            })
            !Array.isArray(resp.password) && errorMessages.push(resp.password);
          }
          dispatch(setUserLoginError(errorMessages))
          // if(errorMessages.length > 0) dispatch(setDataWindow({ dataWindow: true, ...dataWindow }));
        } else {
          // let user = jwt_decode(resp.token);
          dispatch(setAccountActivationProcess(true));
          dispatch(setLoader(false));
          dispatch(setUserLoginError(null))
          dispatch(setDataWindow({ dataWindow: true, content: { isContact: false, choosePlan: {} } }));
          dispatch(setDataWindowContent({ show: { showData: 'account-verification', showControl: 'sign-up' } }));
          dispatch(setAlertMessage("Email with verification code has been sent to your account!"));
        }
      })
      .catch((error) => {
        dispatch(setLoader(false));
     
        dispatch(setUserLoginError(error.message))
      });
  };
}

export function activityLogger(object) {
  return (dispatch) => {
    return fetch(selectedEnv.REACT_APP_API_URL + "/api/auth/event-logger/", {
      method: 'POST',
      body: JSON.stringify(object)
    })
      .then((resp) => resp.json())
      .then((resp) => {
        
      });
  };
}
export function handleUserSignup(userInfo) {
  return {
    type: USER_HAS_SIGNEDUP,
    payload: userInfo,
  };
}

export function loginUser(userInfo, filterPlan = null, infoNotLoggedIn = false, infoPayload = {}) {
  
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/auth/login/`, {
      method: "POST",
      body: JSON.stringify(userInfo),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (Object.keys(resp).includes("is_active") && !resp.is_active) {
          dispatch(setAlertMessage("Please enter the verification code"));
          dispatch(setDataWindow({ dataWindow: true, content: { isContact: false, choosePlan: {} } }));
          dispatch(setDataWindowContent({ show: { showData: 'account-verification', showControl: 'sign-up' } }));
          dispatch(setLoader(false));
        }  
        else if (Object.keys(resp).includes("is_valid") && !resp.is_valid) {
          dispatch(setUserLoginError("You have entered an invalid email or password."))
          dispatch(setLoader(false));
        } 
        else if (Object.keys(resp).includes("message") && !resp.is_valid) {
          dispatch(setUserLoginError(resp.message))
          dispatch(setLoader(false));
        }
        //  else if (Object.keys(resp).includes("access") && !resp.is_valid) {
        //   const token = resp.access; 
        //   dispatch(saveToken(token));
        // }
        else { 
          let user = jwt_decode(resp.access);
          if (Object.keys(user).includes("msg")) {
            dispatch(setUserLoginError(user.msg))
            dispatch(setLoader(false));
          } else {
            dispatch(handleUserLogin({ user: user.user, token: resp.access }));
            (['null', null].includes(user.user.status) === true) ? dispatch(setDataWindowContent({
              show: {
                showData: 'choose-plan',
                showControl: 'choose-plan'
              }
            })) : dispatch(setDataWindowContent({ show: { showData: 'dashboard', showControl: 'dashboard' } }));
            if (filterPlan && filterPlan === "Enterprise" && user.user.status !== 'Enterprise') {
              dispatch(setDataWindow({
                dataWindow: true,
                content: {
                  isContact: null,
                  isForce: true,
                  choosePlan: { plans: PLANS, activeContent: 'ent' },
                  downloadPdf: true,
                }
              }));
              user.user.status !== "Enterprise" && dispatch(updateFilterPayment({
                filterUpgradeProcess: true,
                filterPaymentDone: false
              }))
            }
            dispatch(setUserLoginError(null))
            // dispatch(setAlertMessage("You have successfully logged in."))
            dispatch(setLoader(false));
            // store token in local storage
            window.localStorage.setItem(UF_TOKEN, resp.access)
            
            if (infoNotLoggedIn === true) {
              dispatch(setLoader(true));
              dispatch(infoUserNotLoggedIn(false));
              if(infoPayload.primaccnum?.length)
              dispatch(updatedInfoSection(infoPayload, INFO_CONSTANT))
            }
          }
          dispatch(setAlertMessage("Logged in successfully"))
        }
      })
      .catch((error) => {
        
        dispatch(setUserLoginError(error))
        dispatch(setLoader(false));

      });
  };
}


export function logoutUser( token) {
  return async (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/auth/logout/`, {
      method: 'Post',
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    })
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(setAlertMessage("Logged out successfully"))
        window.localStorage.removeItem(UF_TOKEN)
       dispatch(handleUserlogout())  
      })
      .catch((error) => {
        dispatch(setUserLoginError("Server not responding."));
      });
  }
}
export function saveToken(token) {
  return {
    type: SAVE_TOKEN,
    payload: token,
  };
}

export function handleUserLogin(userInfo) {
  return {
    type: USER_HAS_LOGGEDIN,
    payload: userInfo,
  };
}

export function setUserLoginError(loginError) {
  
  return {
    type: SET_USER_LOGIN_ERROR,
    payload: loginError,
  };
}

export function patchUserSubscription(body) {
  const { user_id, user_token } = body["user"];
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/users/${user_id}`, {
      method: "PATCH",
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .then((resp) => (Object.keys(resp).includes("msg") ? console.log("Error: ", resp) : dispatch(setUserSubscription(resp))));
  };
}

export function setUserSubscription(subscriptionInfo) {
  return {
    type: USER_HAS_SUBSCRIBED,
    payload: subscriptionInfo,
  };
}

export function verifyToken(token) {
  return (dispatch) => {
    const t = token || window.localStorage.getItem(UF_TOKEN)
    if (!t) {
      dispatch(userNotAuth());
      // dispatch(handleUserlogout());wi
      dispatch(setDataWindowContent({ show: { showData: '', showControl: 'login' } }));
      return
    }
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/auth/verify/`, {
      method: "POST",
      body: JSON.stringify({ token: t })
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.is_valid) {
          let user = jwt_decode(t);
          dispatch(handleUserLogin({ user: user.user, token: t }));
          if (user === null) {
            dispatch(logoutUser());
            dispatch(setDataWindowContent({ show: { showData: 'resetLogin', showControl: 'login' } }))
          } else {
            (['null', null].includes(user.user.status) === true) ? dispatch(setDataWindowContent({ show: { showData: 'choose-plan', showControl: 'choose-plan' } })) : dispatch(setDataWindowContent({ show: { showData: 'dashboard', showControl: 'dashboard' } }));
          }
          window.localStorage.setItem(UF_TOKEN, t)
        } else if (!resp.is_valid) {
          dispatch(logoutUser());
          dispatch(setUserLoginError("You have been logged out due to inactivity"))
          dispatch(setDataWindowContent({ show: { showData: 'resetLogin', showControl: 'login' } }))
        }
      })
      .catch((error) => {
       
        dispatch(setUserLoginError("You have entered an invalid email or password."))
        dispatch(setLoader(false));
      });
  };
}
export function handleUserlogout() {
  // localStorage.clear()
  // window.localStorage.removeItem(UF_TOKEN)
  return {
    type: USER_LOG_OUT,
    payload: null,
  }
}
// export function logoutUser() {
//   window.localStorage.removeItem(UF_TOKEN)
//   return {
//     type: USER_LOG_OUT,
//     payload: null,
//   }
// }

export function userNotAuth() {
  return {
    type: USER_NO_AUTH,
    payload: null,
  }
}

export function updateUser(data, id, token) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/users/${id}`, {
      method: "PATCH",
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(setLoader(false));
        let user = jwt_decode(resp.token);
        dispatch(handleUserLogin({ user: user.user, token: resp.token }));
        window.localStorage.setItem(UF_TOKEN, resp.token);
        if (Object.keys(data).includes("status")) dispatch(setDataWindowContent({ show: { showData: '', showControl: 'dashboard' } }))
        return true;
      })
      .catch((error) => {
        dispatch(setLoader(false)); 
        dispatch(setUserUpdateError(error.message))
        return false;
      });
  };
}

export function handleUserUpdate(userInfo) {
  return {
    type: USER_HAS_UPDATED,
    payload: userInfo,
  };
}

export function setUserUpdateError(updateError) {
  return {
    type: SET_USER_UPDATE_ERROR,
    payload: updateError,
  };
}

export function setAlertMessage(alertMessage) {
  return {
    type: SET_ALERT_MESSAGE,
    payload: alertMessage,
  };
}

export function setLoader(loader) {
  return {
    type: SET_LOADER,
    payload: loader,
  };
}
export function setAlreadyLogIn(loader) {
  return {
    type: SET_ALREADY_LOGIN,
    payload: loader,
  };
}

export function downloadUserReport(object, token) {
  const headers = { 'region': localStorage.getItem("sub-city") }

  return async (dispatch) => {
    try {
      return fetch(`${selectedEnv.REACT_APP_API_URL}/api/report/`, {
        method: 'POST',
        headers,
        body: JSON.stringify(object)
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (Object.keys(resp).includes("message")) {
            dispatch(setQuotaExceed(true))
            dispatch(setUserLoginError(resp.message));
            dispatch(setDataWindow({
              dataWindow: true,
              content: {
                isContact: null,
                isForce: true,
                isPayment: true,
                oneTimePayment: true,
                choosePlan: { plans: '', activeContent: '' }
              }
            }));
            dispatch(setDataWindowContent({ show: { showData: 'payment-quota', showControl: 'info' } }))
          } else if (Object.keys(resp).includes("error")) {
            if (resp.error === "Payment matching query does not exist.")
              dispatch(setUserLoginError("A valid saved credit card is needed"));
            else
              dispatch(setUserLoginError("An error has occurred, please try again."));
          } else {
            downloadPdf(resp.path, resp.file_name);
            dispatch(getReportCount(token))
            dispatch(setAlertMessage("Your download will begin in a moment."))
          }
          dispatch(setLoader(false))
        })
        .catch((error) => {
          dispatch(setLoader(false))
          dispatch(setUserLoginError("Server not responding."));
        });
    } catch (err) {
      dispatch(setLoader(false))
      dispatch(setUserLoginError("Server not responding."));
    }
  }
}

export function resetPassword(data) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/password_reset/`, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (Object.keys(resp).includes("email")) {
          dispatch(setUserLoginError(resp.email))
          dispatch(setForgetPasswordError(true))
          dispatch(setLoader(false))
        } else {
          dispatch(setForgetPasswordError("success"))
          dispatch(setAlertMessage("Email has sent. Please enter code to continue."))
          dispatch(setLoader(false))
          return true;
        }
      })
      .catch((error) => {
        dispatch(setLoader(false)); 
        dispatch(setUserUpdateError(error.message))
        return false;
      });
  };
}
export function updateResetPassword(data) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/password_reset/confirm/`, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (Object.keys(resp).includes("password")) {
          dispatch(setUserLoginError(resp.password[0]))
          dispatch(setLoader(false))
        } else {
          dispatch(setAlertMessage("Password has been updated successfully."))
          dispatch(setLoader(false))
          dispatch(setForgetPasswordError(false))
          dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, choosePlan: {} } }));
        }
      })
      .catch((error) => {
        dispatch(setLoader(false)); 
        dispatch(setUserUpdateError(error.message))
        return false;
      });
  };
}

export function changePassword(data, token) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/auth/update-password`, {
      method: "PATCH",
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (Object.keys(resp).includes("current_password")) {
          dispatch(setUserLoginError(resp.current_password[0]))
          dispatch(setLoader(false))
        } else if (Object.keys(resp).includes("new_password")) {
          dispatch(setUserLoginError(resp.new_password[0]))
          dispatch(setLoader(false))
        } else {
          if (Object.keys(resp).includes("new_password")) {
            let errorMessages = [];
            dispatch(setLoader(false));
            Object.keys(resp).includes("new_password") && errorMessages.push('This password is too short. It must contain at least 8 characters!')
            dispatch(setUserLoginError(errorMessages));
          } else {
            dispatch(setAlertMessage("Password has been updated successfully."))
            dispatch(setLoader(false))
            dispatch(setDataWindow({ dataWindow: false, content: { isContact: null, choosePlan: {} } }));
          }
        }
      })
      .catch((error) => {
        dispatch(setLoader(false));
        console.log("Update Error: ", error.message)
        dispatch(setUserLoginError(error.message))
        return false;
      });
  };
}
export function setForgetPasswordError(error) {
  return {
    type: SET_FORGET_PASSWORD_ERROR,
    payload: error,
  };
}


export function getPlanPrice() {
  return async (dispatch) => {
    try {
      return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/account/prices`)
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(setPlanPrice(resp));
        })
        .catch((error) => {
          dispatch(setUserLoginError("Server not responding."));
        });
    } catch (err) {
      dispatch(setUserLoginError("Server not responding."));
    }
  }
}
// const parseString = require('react-native-xml2js').parseString;
//  var XMLParser = require('react-xml-parser');
export function getNewsFeed() {
  return async (dispatch) => {
    try {
      return fetch(
        `${selectedEnv.REACT_APP_API_URL}/api/info/get-news-blog/`,
      { method: 'POST',
      body: JSON.stringify( { blogs_url: "https://www.urbanform.us/stories/blog-feed.xml" })}
       
      )
        .then((response) => response.json())
        .then((textResponse) => {
          // const x = textResponse.blogs.join("")
          const convert = require("xml-js");
          const news = convert.xml2json(textResponse.blogs, {
            compact: true,
            spaces: 2,
          });
          dispatch(setNewsFeed(JSON.parse(news)));

        })

        .catch((error) => {
          console.log("err1", error);
          dispatch(setUserLoginError("Server not responding."));
        });
    } catch (err) {
          console.log("err2",err)
          dispatch(setUserLoginError("Server not responding."));
    }

  }
}

export function contactSupport(contactInfo) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/help/support`, {
      method: "POST",
      body: JSON.stringify(contactInfo),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(setAlertMessage("Your query has been submitted successfully."));
        dispatch(setLoader(false))
        dispatch(setDataWindow({
          dataWindow: true, content: {
            isContact: true, contactFields: CONTACT_SUPPORT, contactValues: {
              name: { value: '', required: true },
              email: { value: '', required: true },
              subject: { value: '', required: true },
              message: { value: '', required: true },
            }, choosePlan: {}
          }
        }));
      })
      .catch((error) => {
        dispatch(setLoader(false));
        console.log("Contact Support: ", error);
        dispatch(setUserLoginError(error.message))
      });
  };
}


export function setPlanPrice(price) {
  return {
    type: SET_PLAN_PRICE,
    payload: price,
  };
}
export function setNewsFeed(news) {
  return {
    type: SET_NEWS_FEED,
    payload: news,
  };
}
export function setQuotaExceed(quota) {
  return {
    type: SET_QUOTA_EXCEED,
    payload: quota,
  };
}

export function getPaymentMethodsQuota(token) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/user/get-payment-methods`, {
      method: "POST",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        let data = resp.data;
        data.map(function (d) {
          if (d.default_payment_method === true) {
            dispatch(setDefaultPaymentMethod(d));
          }
        });
        dispatch(setALLPaymentMethod(resp.data));
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  };
}
export function setDefaultQuotaPaymentMethod(body, token) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/payment/user/change-default-payment`, {
      method: "POST",
      body: JSON.stringify(body)
    })
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getPaymentMethodsQuota(token));
      })
  };
}
export function activateUserAccount(body) {
  return (dispatch) => {
    return fetch(`${selectedEnv.REACT_APP_API_URL}/api/users/activation`, {
      method: "POST",
      body: JSON.stringify(body)
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (Object.keys(resp).includes("token")) {
          let user = jwt_decode(resp.token);
          let object = { user: user.user, token: resp.token }
          dispatch(setAccountActivationProcess(false));
          dispatch(handleUserSignup(object))
          dispatch(setDataWindow({ dataWindow: true, content: { isContact: false, choosePlan: { plans: PLANS, activeContent: 'non-subscriber' } } }));
          dispatch(setDataWindowContent({ show: { showData: 'choose-plan', showControl: 'choose-plan' } }));
          // store token in local storage
          dispatch(setAlertMessage("Congratulations, your account has been created successfully."));
          dispatch(handleUserLogin({ user: user.user, token: resp.token }));
          setQuotaExceed(false)
          window.localStorage.setItem(UF_TOKEN, resp.token)
          dispatch(getPlanPrice());
        } else if (Object.keys(resp).includes("message") && !Object.keys(resp).includes("token")) {
          dispatch(setUserLoginError(resp.message));
        }
        dispatch(setLoader(false));
      })
      .catch((error) => { 
        dispatch(setUserLoginError(error));
        dispatch(setLoader(false));
      });
  };
}

export function setDefaultPaymentMethod(data) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD,
    payload: data,
  };
}

export function setALLPaymentMethod(data) {
  return {
    type: SET_ALL_PAYMENT_METHOD,
    payload: data,
  };
}
export function setAccountActivationProcess(data) {
  return {
    type: SET_ACCOUNT_ACTIVATION_PROCESS,
    payload: data,
  }
}