import React, { useState, useEffect, useMemo } from 'react';
import { Form, Radio, Confirm, Button, Icon, Input, Dropdown } from 'semantic-ui-react'
import { useSelector } from "react-redux";
import '../../../../css/PaymentMethods.scss';
import Payment from './Payment';
import {
    deletePaymentMethod,
    setCurrentPlan,
    setDefaultPaymentMethod,
    updatePaymentMethod
} from '../../../../actions/actions_plans';
import { EDIT_PAYMENT_DETAILS, MONTHS } from "../../../../constants";
import countryList from "react-select-country-list";
import Select from "react-select";
import SegmentHeader from "../SegmentHeader";
import SegmentFooter from "../SegmentFooter";
import { setLoader } from "../../../../actions/actions_user";
import masterCard from '../../../../images/mc.svg';
import unionPay from '../../../../images/UnionPay.svg';
import JCB from '../../../../images/jcb.gif';
import amex from '../../../../images/amex .png';
import visa from '../../../../images/visa.png';
import diners from '../../../../images/diners.svg';
import discover from '../../../../images/discover.png';
import { getMonthName } from "../../../../utils/Utils";
import { isMobile } from "react-device-detect";

const styles = {
    option: (provided, state) => ({
        ...provided,
        color: 'black',
    }),
};
const PaymentMethods = ({ dispatch, user }) => {
    const [selectedMethod, setSelectedMethod] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    const [showNewSource, setShowNewSource] = useState(false);
    const [methodCount, setMethodCount] = useState(0);
    const [editForm, setEditFrom] = useState(false);
    const [cardExpiryMonth, setCardExpiryMonth] = useState(null)
    const [cardExpiryYear, setCardExpiryYear] = useState(null)
    const [currentMethod, setCurrentMethod] = useState(null);
    const currentUser = useSelector(state => state.user.user)
    const countryOptions = useMemo(() => countryList().getData(), [])
    const { currentPlan } = useSelector(state => state.plans);
    const [country, setCountry] = useState(null);
    const [deleteModel, setDeleteModel] = useState(false);
    const [defaultDeleteModal, setDefaultDeleteModal] = useState(false);

    const handleChange = (e, { value }) => {
        setShowConfirm(true);
        setSelectedMethod(value);
    }
    const { paymentMethods } = useSelector(state => state.dataWindow.content);

    const handleConfirm = () => {
        setShowConfirm(false);
        dispatch(setDefaultPaymentMethod({ 'payment_method_id': selectedMethod }, user.token))
    }
    const handleCancel = () => {
        setShowConfirm(false);
        setSelectedMethod('');
    }
    const handleAddPaymentMethod = () => {
        setShowNewSource(true);
    }

    useEffect(() => {
        if (paymentMethods && paymentMethods.length > methodCount) setShowNewSource(false)
        setMethodCount(paymentMethods && paymentMethods.length || 0)
    }, [paymentMethods])

    const handleEditPaymentMethod = (paymentMethodId) => {
        setEditFrom(false);
        setCurrentMethod(paymentMethodId);
        let paymentMethod = null;
        paymentMethods && paymentMethods.filter(item => {
            if (item.payment_method_id === paymentMethodId)
                paymentMethod = item;
        })
        if (paymentMethod) {
            EDIT_PAYMENT_DETAILS.map(item => {
                if (item.name === 'name')
                    item.value = paymentMethod.name;
                if (item.name === 'year') {
                    item.value = paymentMethod.exp_year;
                    setCardExpiryYear(paymentMethod.exp_year)
                }
                if (item.name === 'month') {
                    item.value = paymentMethod.exp_month;
                    setCardExpiryMonth(paymentMethod.exp_month)
                }
                if (item.name === 'street1')
                    item.value = paymentMethod.street_1;
                if (item.name === 'street2')
                    item.value = paymentMethod.street_2;
                if (item.name === 'city')
                    item.value = paymentMethod.city;
                if (item.name === 'state')
                    item.value = paymentMethod.state;
                if (item.name === 'country') {
                    item.value = paymentMethod.country;
                    setCountry(paymentMethod.country);
                }
            })
        }
        dispatch(setCurrentPlan(EDIT_PAYMENT_DETAILS));
        setEditFrom(true);
    }
    const YEARS = []
    const getYearOption = () => {
        let year = new Date().getFullYear();
        year = (parseInt(year) - 1);
        for (let i = 0; parseInt(i) < 20;) {
            year = (parseInt(year) + 1);
            YEARS.push(
                {
                    key: year,
                    text: year,
                    value: year
                }
            )
            i = parseInt(i) + 1
        }
        return YEARS;
    }
    const handleChangeInput = (e) => {
        const value = e.target.value;
        const inputName = e.target.name;
        if (inputName === 'postalCode' && value.length > 6)
            return;
        if (currentPlan)
            currentPlan.map(item => {
                if (item.name === inputName)
                    item.value = value;
            })
        dispatch(setCurrentPlan(currentPlan));
    }
    const handleChangeYear = (e, { value }) => {
        if (currentPlan)
            currentPlan.map(item => {
                if (item.name === 'year')
                    item.value = value;
            })
        dispatch(setCurrentPlan(currentPlan));
        setCardExpiryYear(value)
    }
    const handleChangeMonth = (e, { value }) => {
        if (currentPlan)
            currentPlan.map(item => {
                if (item.name === 'month')
                    item.value = value;
            })
        dispatch(setCurrentPlan(currentPlan));
        setCardExpiryMonth(value);
    }
    const handleSubmit = () => {
        let payload = {}
        if (currentPlan) {
            currentPlan.map(item => {
                if (!['', null, 'null', undefined].includes(item.value)) {
                    if (item.name === 'name')
                        payload.card_name = item.value;
                    if (item.name === 'year')
                        payload.exp_year = item.value;
                    if (item.name === 'month')
                        payload.exp_month = item.value;
                    if (item.name === 'street1')
                        payload.street_1 = item.value;
                    if (item.name === 'street2')
                        payload.street_2 = item.value;
                    if (item.name === 'city')
                        payload.city = item.value;
                    if (item.name === 'state')
                        payload.state = item.value;
                    if (item.name === 'country')
                        payload.country = item.value;
                    if (item.name === 'postalCode')
                        payload.zip_code = item.value;
                }
            })
            payload.payment_method_id = currentMethod;
            payload.email = currentUser.email;
        }
        dispatch(setLoader(true));
        dispatch(updatePaymentMethod(payload));
        setEditFrom(false);
    }
    const handleDeletePaymentMethod = () => {
        let paymentMethod = null;
        paymentMethods.filter((e, il) => {
            if (e.payment_method_id === currentMethod.payment_method_id)
                paymentMethod = e;
        })
        let payload = {
            payment_method_id: paymentMethod.payment_method_id,
        }
        dispatch(setLoader(true))
        dispatch(deletePaymentMethod(payload));
        handleDeleteModel();
    }
    const changeCountryHandler = (country) => {
        setCountry(country.value);
        if (currentPlan)
            currentPlan.map(item => {
                if (item.name === 'country')
                    item.value = country.value;
            })
        dispatch(setCurrentPlan(currentPlan));
    }
    const content = {
        productName: "Edit Card Information"
    }
    const handleDeleteModel = () => {
        setDeleteModel(!deleteModel);
    }
    const handleDeleteButton = (paymentMethod) => {
        if (paymentMethod.default_payment_method)
            handleDefaultDeleteModal();
        else {
            setCurrentMethod(paymentMethod);
            handleDeleteModel();
        }
    }
    const handleDefaultDeleteModal = () => {
        setDefaultDeleteModal(!defaultDeleteModal)
    }
    const handleCancelButton = () => {
        setEditFrom(false);
    }
    const getImages = (brand) => {
        let image = null
        switch (brand) {
            case 'mastercard':
                image = masterCard;
                break;
            case 'unionpay':
                image = unionPay;
                break;
            case 'jcb':
                image = JCB;
                break;
            case 'amex':
                image = amex;
                break;
            case 'visa':
                image = visa;
                break;
            case 'diners':
                image = diners;
                break;
            case 'discover':
                image = discover;
                break;
        }
        return image;
    }
    return (
        <div className='p-methods'>
            <div className='choose-header'>
                <span>Payment methods</span>
            </div>
            <Form>
                {paymentMethods && paymentMethods.map((method) => {
                    return (
                        <div className="card-payment-method" style={{ height: isMobile && "100px" }}>
                            <div className="card-payment-method-number" >
                                <Form.Field className='radio-fields' key={Math.random()}>
                                    <label htmlFor={method.payment_method_id} style={{ marginLeft: "15px", display: "flex" }}><img src={getImages(method.card_brand)} style={{ marginLeft: "10px", alignSelf: "center", width: isMobile ? "40px" : "60px" }} alt="" /><span style={{ alignSelf: "center", textTransform: "capitalize", marginLeft: "5px" }}>{method.card_brand} **** {method.last_four_digits} <br /> Expires {getMonthName(method.exp_month)} {method.exp_year}</span></label>
                                    <Radio
                                        id={method.payment_method_id}
                                        name='radioGroup'
                                        value={method.payment_method_id}
                                        checked={selectedMethod === method.payment_method_id ? true : false || method.default_payment_method}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            </div>
                            <div className="card-payment-method-action" style={{ marginRight: isMobile && "-7px" }}>
                                <a onClick={() => handleEditPaymentMethod(method.payment_method_id)} style={{ marginBottom: isMobile && "10px" }}><Icon name='edit outline' size="large" /></a>
                                <a onClick={() => handleDeleteButton(method)}><Icon name='delete' size="large" /></a>
                            </div>
                        </div>
                    )
                })
                }
            </Form>
            <Confirm
                open={showConfirm}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                cancelButton='Cancel'
                confirmButton="Let's do it"
                content='Are you sure, you want to change the default payment method ?'
                size='mini'
            />
            <Confirm
                open={deleteModel}
                onCancel={handleDeleteModel}
                onConfirm={handleDeletePaymentMethod}
                cancelButton='Cancel'
                confirmButton="Delete"
                content={`Are you sure, you want to delete this ${currentMethod?.card_brand} **** **** **** ${currentMethod?.last_four_digits} payment method ?`}
                size='mini'
            />
            <Confirm
                open={defaultDeleteModal}
                onConfirm={handleDefaultDeleteModal}
                content='You cannot delete the default payment method. Please change the default payment method.'
                size='mini'
                cancelButton={false}
            />
            {showNewSource && <Payment dispatch={dispatch} title={'Add'} />}
            {!showNewSource && <div className='upgrade-plan-btn'>
                <Button className='upgrade-button' fluid onClick={handleAddPaymentMethod}>Add new payment method</Button>
            </div>}
            {
                editForm &&
                <>
                    <div className="edit-card-info">
                        <SegmentHeader content={content} />--
                        <div className="edit-form">
                            <Form onSubmit={handleSubmit}>
                                {
                                    currentPlan.map((field, index) => {
                                        if (field.name === 'year') {
                                            return (
                                                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                    {field.label &&
                                                        <label style={{ color: '#000', opacity: "0.6", marginLeft: "6px" }}><b>{field.label}</b></label>}
                                                    <Dropdown
                                                        placeholder='Select Year'
                                                        fluid
                                                        selection
                                                        search
                                                        clearable
                                                        options={getYearOption()}
                                                        name={field.name}
                                                        defaultValue={field.value}
                                                        onChange={handleChangeYear}
                                                    />
                                                </div>
                                            )
                                        } else if (field.name === 'month') {
                                            return (
                                                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                    {field.label &&
                                                        <label style={{ color: '#000', opacity: "0.6", marginLeft: "6px" }}><b>{field.label}</b></label>}
                                                    <Dropdown
                                                        placeholder='Month'
                                                        fluid
                                                        selection
                                                        search
                                                        clearable
                                                        options={MONTHS}
                                                        name={field.name}
                                                        defaultValue={field.value}
                                                        onChange={handleChangeMonth}
                                                    />
                                                </div>
                                            )
                                        } else if (field.name === 'country') {
                                            return <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                                <label style={{ color: '#000', opacity: "0.6", marginLeft: "6px" }}><b>{field.label}</b></label>
                                                <Select
                                                    styles={styles}
                                                    options={countryOptions}
                                                    value={countryOptions.filter(function (option) {
                                                        return option.value === country;
                                                    })}
                                                    onChange={changeCountryHandler}
                                                />
                                            </div>
                                        } else {
                                            return (
                                                <Form.Field key={index}>
                                                    {field.label && <label><b>{field.label}</b></label>}
                                                    <Input transparent required={field.required} name={field.name}
                                                        type={field.type}
                                                        placeholder={field.placeHolder}
                                                        onChange={handleChangeInput}
                                                        value={field.value === 'null' ? '' : field.value} />
                                                </Form.Field>
                                            )
                                        }
                                    })
                                }
                                <div className="edit-form-button">
                                    <button type="submit" className="button">Update</button>
                                </div>
                                <div className="cancel-button" >
                                    <button type="button" className="button" onClick={handleCancelButton}>Cancel</button>
                                </div>
                            </Form>
                        </div>
                        <SegmentFooter />
                    </div>
                </>
            }
        </div>
    );
};

export default PaymentMethods;