import React from "react";
import Container from "../Container";
import Header from "../Header";
import '../../../../css/Summary.scss';
import InfoSection from "../InfoSection";
import { useDispatch, useSelector } from "react-redux";
import ICON_LOCK from "../../../../images/lock-1.png";
import { setLinkValue } from "../../../../actions/actions_info";
import { setDataWindow, setDataWindowContent } from "../../../../actions/actions_datawindow";
import { isMobile } from "react-device-detect";

const SummaryMaster = () => {
    const dispatch = useDispatch();
    const { summary } = useSelector(state => state.info.infoDefaultValues)
    const { info_link } = useSelector(state => state.info)
    const goTOSelectedWindow = (code) => {
    
        dispatch(setDataWindow({ dataWindow: true, content: { isContact: null, contactInfo: {}, choosePlan: {}, isPayment: false, isInfo: true } }));
        dispatch(setDataWindowContent({ show: { showData: code, showControl: 'info' } }));
        dispatch(setLinkValue(code));
    }

    return (
        summary.map(field => {
            if (field.lock == true) {
                return (
                    <>
                        <div className="lock" key={field.id}>
                            <div className='summary-data'>
                                <Container>
                                    <Header content={field} />
                                    <hr className='lock-hr' />
                                    <div className='section-data'>
                                        <div className='section-description'>
                                            <p className="sub-heading-lock">
                                                Download full report or sign up for a PRO account to see!</p>
                                            <img src={ICON_LOCK} className="lock-image" alt="" />

                                        </div>
                                    </div> 
                                </Container>
                            </div>
                        </div>
                    </>
                );
            } else {
                if (field.properties) {
                    return (
                        <>
                            <div className="summary" key={field.id}>
                                <div className='summary-data'>
                                    <Container>
                                        <Header content={field} />
                                        <InfoSection content={field?.properties} />
                                        <div className='footer-section'>
                                            {field.properties.map(field2 => { 
                                                if (field2.source != undefined) {
                                                    return (
                                                        <div className={[`${field2?.property_class} footer-more`]}>
                                                            <u>
                                                            <a className='link summary-footer' onClick={() => {
                                                                goTOSelectedWindow(field.code)
                                                            }} title={field2.source} style={{ marginLeft: isMobile == true ? '-35px' : '' }} target="_blank">{field2.source}</a>
                                                            </u>
                                                           
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className='footer-section'>
                                                            <div className='more'></div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </Container>
                                </div>
                            </div>
                        </>
                    );
                }
            }
        })
    )
}
export default SummaryMaster;
